import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/terms-of-service.css";

const TermsOfService = (props) => {
  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="ts-main">
        <div className="ts-bf">
          Before trading read the&nbsp;
          <Link
            to="/security-guide"
            onClick={(e) =>
              props.onClick(
                handleLink(
                  e,
                  "/security-guide",
                  "Security Guide",
                  "Security-Guide"
                )
              )
            }
          >
            SECURITY GUIDE
          </Link>
        </div>
        <div className="ts-bd">
          <div className="ts-m-hd">Terms of Service</div>
          <div className="ts-wc">Welcome to Peer2Punt</div>
          <div className="ts-lu">Last updated: March 10th, 2024</div>
          <div className="ts-hd">INTRODUCTION</div>
          <p>
            Peer2Punt is platform operated by CodeFlame Ltd. (“CodeFlame Ltd” or "us" or "our" or "we"). Your usage of Peer2Punt is subject to these Peer2Punt Terms of Service (“Terms of Service”) and the&nbsp;
            <Link
              to="/terms-and-conditions"
              onClick={(e) =>
                props.onClick(
                  handleLink(
                    e,
                    "/terms-and-conditions",
                    "Terms and Conditions",
                    "Terms-and-Conditions"
                  )
                )
              }
            >
              CodeFlame Ltd Terms and Conditions
            </Link>
            &nbsp;("General Terms"), and together these are referred to as the Terms ("Terms").
          </p>
          <p>
            If there is a conflict between the Peer2Punt Terms of Service and the General Terms, the Peer2Punt Terms of Service will prevail.
          </p>
          <p>
            Please read these Terms of Service carefully before using the services, because these Terms of Service affect your legal rights and obligations.
          </p>
          <p>
            Your use of the Peer2Punt platform will be governed by these Terms of Service, along with the peer2punt.com&nbsp;
            <Link
              target="_blank"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
            , and&nbsp;
            <Link
              target="_blank"
              to="/dispute-rules"
            >
              Dispute Rules
            </Link>
            .
          </p>
          <p>
            Should you not accept the General Terms, Terms of Service, Privacy Policy, and/or any single rule and/or rules of them, you shall immediately stop using the Peer2Punt platform and delete your account. By using the Peer2Punt platform, you are deemed to have irrevocably agreed to these Terms of Service. Please note that any provision of the Terms of Service may be revised and/or reissued unilaterally by us without notice at any time. You should visit this page regularly to review the current version of the Terms of Service, since your continued use of the platform will be deemed as irrevocable acceptance of any revisions.
          </p>
          <div className="ts-hd">DEFINITIONS</div>
          <p>
            Advertiser - a User who has published an Offer on the peer2punt.com trading platform, a User can be a Buyer or a Seller;
          </p>
          <p>
            Agreement - Agreement regarding the Peer2Punt platform concluded between CodeFlame Ltd as and a platform User. These Terms of Service constitute the rules of the Agreement. The Agreement is deemed to have concluded between CodeFlame Ltd and a User if a User has actually started to use the platform;
          </p>
          <p>Bet - a selection of Punts;</p>
          <p>Punt - a prediction of an Event or to predict an Event;</p>
          <p>Repunt - a way of Punting an Event by duplicating another User's Punt;</p>
          <p>Event - a sporting event e.g Football, Basketball or Golf;</p>
          <p>Punt Option - an option for Punting an Event e.g 1X, Over 2.5 or BTTS:No;</p>
          <p>Punt Option Type - a group name for Punt Options e.g Double-Chance, Over/Under or BTTS;</p>
          <p>Punter - a User;</p>
          <p>Peer2Punt Rating - a way for Peer2Punt to express its opinions and satisfaction with a Punter;</p>
          <p>Up Rating - a certain amount of Peer2Punt Rating to be gained if event punt is correct;</p>
          <p>Down Rating - a certain amount of Peer2Punt Rating to be lost if event punt is not correct;</p>
          <p>Accumulated Rating - a total amount of Peer2Punt Rating gained by a Punter;</p>
          <p>Cryptocurrency (or Crypto) - any digital currency;</p>
          <p>
            Blockchain Network - the peer-to-peer payment network that operates on a cryptographic protocol;
          </p>
          <p>
            Buyer - a User who purchases Bet from any other User (Seller) using the peer2punt.com trading platform;
          </p>
          <p>
            Escrow Address - a P2P multi-signature address at Blockchain Network, which requires two out of three keys (one belongs to the Seller, one to the Buyer, and another to CodeFlame Ltd) to sign the Bet transaction and is created automatically by the means of decentralized smart contracts.
          </p>
          <p>
            Fiat money (or Fiat currency) - currency without intrinsic value and established as money by government regulation and/or law;
          </p>
          <p>
            peer2punt.com (“Peer2Punt” or "platform") - Internet website owned by CodeFlame Ltd where Users can:
          </p>
          <ul>
            <li>predict Events for free with over 90 available Punt Options and gain Peer2Punt Ratings;</li>
            <li>
              advertise their intention to buy / sell Bet and/or accept advertisements of the other Users;
            </li>
            <li>
              create multi-signature Bet Escrow P2P addresses in order to ensure that both the Buyer and the Seller are equally protected during the course of the Bet buy/sell deal.
            </li>
          </ul>
          <p>
            Offer - advertisement published on the peer2punt.com platform by any User. Offer indicates a respective User’s intention to purchase or sell Bet;
          </p>
          <p>Parties - both the Buyer and the Seller;</p>
          <p>
            Payment window - timeframe within which the trade between the Buyer and the Seller shall be completed (i.e. Bet shall be received by the Buyer and the payment shall be received by the Seller). Payment window must be defined by the Advertiser in the Terms of Trade during the Offer creation process;
          </p>
          <p>
            Purchase Contract - Bet buy/sell contract concluded between the Advertiser and the User who has accepted Advertiser’s created Offer. Terms of Trade specified within the respective Offer creation process (“Create new Bet Offer”) become the rules of the Purchase Contract;
          </p>
          <p>
            Seller - User who sells Bet to another User (Buyer) using the peer2punt.com platform;
          </p>
          <p>
            Terms of Trade - terms of the potential trade specified by the Advertiser within the Offer creation process. Terms of Trade include a country where the Advertiser is willing to buy or sell Bet, Bet price, Fiat currency and/or Cryptocurrency of payment, minimum and maximum amount of payment, acceptable payment methods, Payment window, as well as other terms the Advertiser consider necessary. CodeFlame Ltd is entitled to, at any time, unilaterally amend the list of mandatory fields and/or conditions which must be filled or defined by the Advertiser upon creation the Offer in the Terms of Trade creation interface. If any User accepts the Offer, he is deemed to have entered into the Purchase Contract with the Advertiser and in this event, the Terms of Trade specified within the Offer creation process (“Create new Bet Offer”) become the rules of this Purchase Contract;
          </p>
          <p>Terms of Service - these Terms of Service;</p>
          <p>
            User - an individual who has an account (record) created within the peer2punt.com authentication system and who is actually using Peer2Punt platform. Any User is deemed to have entered into the Agreement;
          </p>
          <p>
            User Account - an account (record) created within the peer2punt.com authentication system that is associated with the specific User and allows this User to access Peer2Punt platform’s functionality;
          </p>
          <p>
            Wallet - a standalone Wallet that operates on the Blockchain Network and is not associated with Peer2Punt platform;
          </p>
          <p>
            Working Hours - daily hours when the Advertiser is ready to perform a trade with the other Users (i.e. when the Advertiser’s Offer will be available/visible for other Users). Working Hours must be defined by the Advertiser in the Terms of Trade of the respective Offer.
          </p>
          <div className="ts-hd">USING PEER2PUNT</div>
          <div className="ts-sub">Peer2Punt Platform</div>
          <p>
            We operate and provide to Users the Peer2Punt platform that has the functionality of the advertising board where any User may express their intention to buy or sell Bet by a creation of a corresponding Offer as well as the functionality of the graphic user interface to add events, add punts and interact with decentralized smart contracts at Blockchain Network.
          </p>
          <p>
            While the Peer2Punt platform provides the technical functionality that its Users may create and/or accept Offers and conclude Purchase Contracts, we are not a party of Purchase Contract as well as a not a party of execution and/or delivery of the Purchase Contract. Any execution of any Purchase Contract takes place outside of the Peer2Punt platform according to the Terms of Trade determined by the Advertiser and agreed by the second party of a Purchase Contract. You acknowledge, agree, and understand that we are not a party to the relationship, transactions or any dealings between a Buyer and a Seller.
          </p>
          <p>
            When we provide you with the functionality of interaction with smart contracts, all actual execution of your instructions and transactions happens in a complete decentralized fashion by such smart contract operated at the peer-to-contract or peer-to-peer model (depending on technical functionality of a corresponding smart contract).
          </p>
          <p>
            You hereby acknowledge and agree that we do not execute any instructions or transactions between a Buyer and a Seller or when you interact with a smart contract, we do not accept, hold or store any of your assets or funds, we do not act as a custodian for your crypto assets, we do not access, control or hold any of your private keys for Wallets.
          </p>
          <p>
            You also acknowledge and agree that we do not operate any betting activity or betting platform and that we do not accept, hold or store any form of wager or stake on any event, punt or bet.
          </p>
          <div className="ts-sub-2">Accessing Peer2Punt</div>
          <p>
            You will be able to access Peer2Punt by using your User Account.
          </p>
          <div className="ts-sub-2">Reputation and user rating</div>
          <p>
            After each deal, every User has a right to rate his cooperation with the other User. Such ratings are used by CodeFlame Ltd to define the User’s reputation. The User’s reputation is an indicator which shows the other Users and CodeFlame Ltd, how honestly and responsibly this particular User has behaved during the course of his previous deals.
          </p>
          <div className="ts-sub-2">Peer2Punt rating</div>
          <p>For each event, Peer2Punt assigns a certain amount of rating which a User will gain if their punt was correct, or lose if their punt was incorrect, after the event is settled. More information about Peer2Punt Ratings shall be published on the peer2punt.com website on the How-to page, which is in addition to further information about the Peer2Punt Ratings visible on the Event page.</p>
          <p>
            The administrator of Peer2Punt has the right to, at any time, change the amount of Peer2Punt Rating for a User without changing the amount of Peer2Punt Rating for other Users, and to change the amount of Peer2Punt Rating for all Users on the Peer2Punt trading platform.
          </p>
          <p>Accumulated Peer2Punt Rating can be found in each User’s profile.</p>
          <p>The minimum amount of Peer2Punt Rating for a User is 0 and the maximum amount of Peer2Punt Rating for a User is 99999.</p>
          <p>Peer2Punt Rating will last 30 days from the date it was gained before it expires, and is thereby subtracted from the total Accumulated Rating.</p>
          <div className="ts-sub-2">Limits</div>
          <p>
            In order to reduce the possibility of fraud, Peer2Punt platform includes several trading limitations (e.g. single-trade limit, number of active Offers at the same time etc.), which are determined by the limit level applied to Users who have not reached a certain number of successful deals and/or have not bought / sold a certain Bet. The table of these limitations shall be published on the peer2punt.com website on the FAQ/Help page, which is in addition to further information about the current limits visible on the page Dashboard.
          </p>
          <p>
            The administrator of Peer2Punt has the right to, at any time, change the limits for a User without changing the limits for other Users, and to change the limits for all Users on the Peer2Punt trading platform.
          </p>
          <p>Limit levels can be found in each User’s profile.</p>
          <div className="ts-sub-2">Events, Punts, Offers and Contracts</div>
          <p>
            After your account has been registered and your email has been verified, you can start interacting with other Users of the peer2punt.com platform.
          </p>
          <p>Users can also:</p>
          <ul>
            <li>Add Upcoming Events to the peer2punt.com platform;</li>
            <li>Choose a Punt Option for Upcoming Events on the peer2punt.com platform;</li>
            <li>Initialize a Purchase Contract on the peer2punt.com platform.</li>
          </ul>
          <p>Upcoming Events can be added to the peer2punt.com platform by:</p>
          <ul>
            <li>Entering or pasting a link of the Event into the URL field on the Add Event window or page and fetching the Event details from an external source, and choosing any Punt Option for the Event;</li>
            <li>Manually providing/selecting the Event details such as Sport Type, Event Country, Event Division/League, Event Team 1/Home Team, Event Team 2/Away Team, Event Date and Event Time, and choosing any Punt Option for the Event.</li>
          </ul>
          <p>Events added via an external link are categorised or grouped under Verified Events, while Events added manually are categorised or grouped under Unverified Events.</p>
          <p>Unverified Events can be verified when a User provides a link to a Sporting Event on an external source with matching Event details (inluding Event Type, Event Country, Event Division, Event Team 1/Home Team, Event Team 2/Away Team and Event Date) by clicking on the Verify button on the top corner of the Event's window or page.</p>
          <p>
          Since all Events on the peer2punt.com platform were added by Users, CodeFlame Ltd will not be held responsible for the authenticity of any Event on the peer2punt.com platform. Buyers are hereby adviced to mark/check the Verified Events Only checkbox on the Contract page while accepting the Purchase Contract.
          </p>
          <p>A Punt is added to an Upcoming Event on the peer2punt.com platform when:</p>
          <ul>
            <li>A User Clicks on an Upcoming Event and the User chooses a Punt Option, then the User clicks the Add Button;</li>
            <li>A User Clicks on the Repunt button on other User's Punts if available;</li>
            <li>A Buyer receives a Bet from the Seller while the Repunt checkbox is marked/checked. The Punts contained in the received Bet will be added for each corresponding Event on the Buyer's peer2punt.com account.</li>
          </ul>
          <p>A Purchase Contract is initialized if:</p>
          <ul>
            <li>Your Offer is accepted by another User or;</li>
            <li>You accepted an Offer created by another User.</li>
          </ul>
          <p>
            Offers are a User’s and/or Users’ advertisements to buy and/or sell Bet published in the relevant sections (i.e. "Buy Bets" or "Sell Bets") of the peer2punt.com website.
          </p>
          <p>
            You can create a new Offer to advertise your intention to buy or sell Bet. Within the Offer creation process you will be asked to specify certain Terms of Trade. Terms of Trade shall only contain true and accurate information.
          </p>
          <p>
            From time to time we may unilaterally amend the list of terms which must be defined within the Offer creation process.
          </p>
          <p>
            If you do not want to display an Offer in the public Offer list, when creating the Offer, you need to mark the Offer type as “Private”. When Users want to create a contract for this Offer, they can only do so if you have shared a link to this Offer with them.
          </p>
          <p>
            After the Offer with a fixed price is created, the price is locked. However, it can be changed by manually editing the Offer. If the Offer’s price is tied to one of the available exchanges, the price fluctuates accordingly. Whether the Offer’s price is fixed or tied to an exchange, the price of the Bet shall not deviate more than 30% from the market rate. Private Offers are not subject to this rule and may use any price.
          </p>
          <p>
            Multiple Offers with the same payment methods are not allowed in the same country unless the Offers have a price difference of at least 2% (two percent).
          </p>
          <p>
            A published Offer can be withdrawn by the Advertiser at any time, even if it has already been accepted by the other User.
          </p>
          <p>
            The Offers can have one of 3 statuses: Active, Inactive, Disabled. To improve the operations of Peer2Punt platform, an Offer’s status may be automatically and/or temporarily changed to “Inactive” or “Disabled”.
          </p>
          <p>
            An Offer’s transition to “Inactive” or “Disabled” status may occur in, but is not limited to, the following situations:
          </p>
          <ul>
            <li>
              If a User has reached their limit for contracts, this User's Offers will be temporarily removed from the Offer list, and/or the links to their Offers will be unavailable. If the User is able to start a new contract according to his limits, the Offers will be visible again in the Offer list, and/or available through the link.
            </li>
            <li>
              If an Offer’s Bet price deviates more than 30% from the market rate, the Offer will be removed from the Offer list, and/or the link to the Offer will be unavailable. If the Offer’s Bet price no longer deviates more than 30% from the market rate, the Offer will be visible again in the Offer list, and/or available through the link.
            </li>
            <li>
              If the User has been blocked and/or banned, then at the time of blocking and/or banning, all of the User's Offers will be removed from the Offer list, and/or the link to the Offer will be unavailable. If the User is unblocked and/or unbanned, the Offers will be visible again in the Offer list, and/or available through the link.
            </li>
            <li>
              If the User’s account has been deleted, his or her Offers will be removed from the Offer list, and/or the links to his or her Offers will be unavailable.
            </li>
            <li>If the User has not been online within the past 10 days.</li>
            <li>If the Seller has not punted an Event within the past 30 days.</li>
            <li>If the Seller's Accumulated Peer2Punt Rating has been reduced to 0.</li>
          </ul>
          <p>
            We may hide, and/or remove your Offer / Offers, if you violate these Terms of Service and/or any other Agreement you enter into with CodeFlame Ltd.
          </p>
          <p>
            By publishing an Offer, you express your will to enter into a Purchase Contract with any other User who will accept your Offer. Unless your Offer is withdrawn, you are deemed to have an intention and will to enter into a Purchase Contract with any other peer2punt.com User who will accept your Offer in accordance with the Terms of Trade.
          </p>
          <p>
            By accepting an Offer created by another User (the Advertiser), you are deemed to have accepted all Terms of Trade of the respective Offer and entered into a Purchase Contract with the Advertiser. In this event, the respective Terms of Trade shall constitute the rules of the Purchase Contract concluded between you and the Advertiser.
          </p>
          <p>
            The minimum amount for entering the Purchase Contract will be displayed on the Contract page;
          </p>
          <p>
            In accordance with this Purchase Contract, the Seller agrees to sell a Bet with a minimum amount of Odds to the Buyer, and the Buyer agrees to buy that Bet with a minimum amount of Odds from the Seller, for the price specified by an Advertiser (a Buyer or a Seller depending on who has publishedthe Offer) in full compliance with the Terms of Trade.
          </p>
          <p>
            The Terms of Trade, as determined by an Advertiser, are binding for both the Buyer and the Seller until the completion or termination of the Purchase Contract, unless the Terms of Trade contradict these Terms of Service and/or are illegal.
          </p>
          <p>
            The Purchase Contract is deemed to be completed and all mutual obligations of both Parties are deemed to be fulfilled, if the Seller has received the payment from the Buyer and the Buyer has received Bet from the Seller within the Payment window, and in full compliance with the Terms of Trade.
          </p>
          <p>
            If the Seller has not received the payment from the Buyer and/or if the Buyer has not received the Bet as it was stipulated by the Terms of Trade, and neither party has initiated a dispute, the Purchase Contract is deemed to be terminated. In this event, the Bet (if any) that was transferred to the Bet Escrow Address associated with this specific Purchase Contract shall be transferred back to its sender and all sums (if any) that were transferred (or passed) to the Seller by the Buyer shall be repaid back to the Buyer by the Seller. Please be aware that fees associated with any transfers, in this case, are covered by the User that performs the sending transaction.
          </p>
          <p>
            Both the Seller and the Buyer can also cancel the Purchase Contract unilaterally, if and when they are allowed to do so according to these Terms of Service. In this event, the Bet (if any) that was transferred to the Bet Escrow Address associated with this specific Purchase Contract shall be transferred back to its sender and all sums (if any) that were transferred (or passed) to the Seller by the Buyer shall be repaid back to the Buyer by the Seller. Please be aware that fees associated with any transfers, in this case, are covered by the User that performs the sending transaction.
          </p>
          <p>
            Within 180 days after the respective Purchase Contract is completed, terminated, or the respective dispute is resolved, upon request, the buyer must be able to provide explicit and sustaining proof of the sent payment.
          </p>
          <div className="ts-sub-2">Course of the deal</div>
          <p>
            During the course of the Bet purchase/sell deal, both the Buyer and the Seller will receive instructions on how to act, in order to finalize the deal and complete the Purchase Contract. These instructions can be found on the respective contract page (additional notifications will also be sent via email and/or peer2punt.com internal notification system and/or Telegram Bot if you have activated such).
          </p>
          <p>
            The Purchase Contract page can be opened by clicking on the respective contract in the All contracts section of the Peer2Punt website.
          </p>
          <p>
            There are different status indicators, which indicate the current status of the deal, and make it easier for the User to understand what he has to do at a specific moment in order to finalize the deal.
          </p>
          <ul>
            <li>
              <b>Pending</b> — The very beginning of a contract workflow. The Offer is accepted, but the Seller has not yet sent the Bet to the Escrow Address. At this stage, both the Seller and the Buyer can unilaterally cancel the Purchase Contract.
            </li>
            <li>
              <b>Depositing</b> — The Seller is currently in the process of sending the Bet to the Escrow Address. Only the Seller can cancel the Purchase Contract at this stage.
            </li>
            <li>
              <b>In progress</b> — The Bet has been transferred to the Escrow Address and locked there. The Buyer can cancel the Purchase Contract at this stage. The Seller can cancel the contract if the Buyer has failed to make a Fiat or Cryptocurrency payment within the Payment window.
            </li>
            <li>
              <b>Paid</b> — Bet is still locked in the Escrow Address, but the Buyer has already sent / passed the Fiat money or Cryptocurrency, that is used as payment for the Bet, to the Seller. Neither Party can cancel the Purchase Contract at this stage; the Seller can start a dispute right after the status has become “Paid” and the Buyer can start a dispute once the “Payment window” has expired.
            </li>
            <li>
              <b>Completed</b> – The Buyer has successfully received Bet, and the Seller has successfully received Fiat or Cryptocurrency in accordance with the rules of the respective Purchase Agreement. A completed deal cannot be appealed, revised or reversed.
            </li>
            <li>
              <b>Cancelled</b> — Contract was cancelled by one of the Parties.
            </li>
            <li>
              <b>Disputed</b> — Dispute has been initiated by one of the Parties and has not yet been resolved.
            </li>
            <li>
              <b>Resolved</b> — Dispute is resolved. A resolved dispute cannot be revised or reversed.
            </li>
          </ul>
          <p>
            Only the Buyer may send payments, the Buyer must be the sole registered account owner and the person who uses the account. The Seller’s payment details must match the account owner's name. Third parties may not be involved in making and/or receiving payments. It is a violation of this Agreement to attempt to provide and/or make payments to and/or from third parties.
          </p>
          <div className="ts-sub-2">Dispute Resolution</div>
          <p>
            If the Buyer and the Seller are in a disagreement over a trade, either party can initiate a dispute, if and when they are allowed to do so by these Terms of Service. Disputes are to be resolved by the peer2punt.com support staff. By submitting a dispute, you create, sign and initiate a transaction of release of Bet from the Escrow Address.
          </p>
          <p>
            CodeFlame Ltd support resolves disputes with due diligence by carefully inspecting and considering the Terms of Trade, evidence of payment, Users’ chat messages, Users’ reputation, other data submitted to us by Users, and data collected on our own.
          </p>
          <p>
            CodeFlame Ltd can take the Buyer’s side if any of the following criteria have been met (this list is not explicit):
          </p>
          <ul>
            <li>
              The Seller does not respond in a time prescribed by CodeFlame Ltd and/or does not cooperate with CodeFlame Ltd in order to resolve the dispute;
            </li>
            <li>
              The Buyer supplies sufficient evidence (i.e. payment account statements with the transaction etc.) proving he or she has sent and/or transferred funds to the Seller;
            </li>
            <li>
              The Seller uses a third party and/or payment account that is not registered in the Seller’s name to provide, and/or attempt to provide, and/or to receive funds;
            </li>
            <li>
              The Seller does not provide an account statement for a period, in order to trace if the payment has been received.
            </li>
          </ul>
          <p>
            This will lead to the following result: CodeFlame Ltd will sign Buyer’s transaction in accordance with which the locked Bet shall be transferred from the Escrow Address to the Buyer. In this event, the status of the deal will be - “Resolved”.
          </p>
          <p>
            CodeFlame Ltd can take the Seller’s side, when any of the following criteria have been met (the list is not explicit):
          </p>
          <ul>
            <li>
              The Buyer does not respond in a time prescribed by CodeFlame Ltd and/or does not cooperate with CodeFlame Ltd in order to resolve the dispute;
            </li>
            <li>
              The payment made by the Buyer was not in accordance with the Terms of Trade;
            </li>
            <li>
              A payment provider has frozen/stopped/held the payment made by the Buyer;
            </li>
            <li>
              The payment was not made by the Buyer, and/or only a partial payment was made;
            </li>
            <li>
              The Buyer uses a third party and/or payment account that is not registered in the Buyer’s name to provide, and/or attempt to provide, and/or to send funds;
            </li>
            <li>
              The Buyer does not provide an account statement with the transaction in dispute, and the Seller provides an account statement for the period that the payment was not received.
            </li>
          </ul>
          <p>
            This will lead to the following result: CodeFlame Ltd will sign Seller’s transaction in accordance with which the locked Bet shall be returned from the Escrow Address to the Seller. In this event, the status of the deal will be - “Resolved”.
          </p>
          <p>
            In case both the Buyer and the Seller provide equally convincing evidence, CodeFlame Ltd will resolve the dispute in favor of the Party that provides the strongest evidence.
          </p>
          <p>
            Both Parties are required to provide accurate, unquestionable and complete evidence, and/or documents that confirm correctness regarding the dispute case of the User. Each User must be able to provide explicit and full information about the transaction and/or payment in question and should cooperate with CodeFlame Ltd in order to resolve the dispute. If CodeFlame Ltd requests any additional evidence (documents) then such should be provided by the User, otherwise the dispute can be resolved in favor of the other party.
          </p>
          <p>
            Any payment orders, account statements and slips must be with the complete payment information.
          </p>
          <p>
            CodeFlame Ltd draws the attention of the Users, peer2punt.com support staff does not resolve disputes with the payment method "In person". In case the Buyer and Seller are in a disagreement over a trade with this payment method, peer2punt.com will only sign a transaction for the locked Bet to be returned to the Seller if both Parties explicitly and clearly express their Agreement for the trade, and they want CodeFlame Ltd to take the Seller’s side. Otherwise, the Buyer shall only receive the locked Bet if the Seller signs the transaction for the locked Bet to be released to the Buyer.
          </p>
          <p>
            Any party that knowingly submits fraudulent, false, and/or inaccurate documents and/or information, and/or forcefully tries to control the outcome of a disputed trade, and/or makes false claims, and/or obstructs the dispute resolution process in any way, including but not limited to, not responding, not providing information, files and/or documents to customer support within the established timeframe, and/or violates any rule of the dispute resolution process is deemed to be in violation of these Terms of Service which may be grounds for losing a dispute and/or the User Account to be suspended and/or terminated.
          </p>
          <p>
            Dispute resolution rules are located at this link&nbsp;
            <Link
              to="/dispute-rules"
              onClick={(e) =>
                props.onClick(
                  handleLink(
                    e,
                    "/dispute-rules",
                    "Dispute Rules",
                    "Dispute-Rules"
                  )
                )
              }
            >
              https://peer2punt.com/dispute-rules
            </Link>
            . Both Parties must strictly adhere to all stated rules. If there is any conflict between the Terms and the Dispute resolution rules, the Dispute resolution rules prevail.
          </p>
          <p>
            You may request a review if you were a party of a dispute in any way, and you believe Peer2Punt did not resolve the dispute according to these Terms of Service. To do so, you must notify us by contacting customer support and request a review within 120 days after the dispute resolution.
          </p>
          <div className="ts-sub">API</div>
          <div className="ts-sub-2">Access to API</div>
          <p>
            To access the full API, you have to be a registered User of Peer2Punt with enabled API access in the User Account settings. After enabling the API, you will be given an API key, which will be required to access the API. You may not make your API key available to others, this includes by embedding it in open source projects, and only you may access the API with the key provided to you. You will not misrepresent and/or mask your identity and/or your key when accessing and/or using the API.
          </p>
          <p>
            We reserve the right to revoke your access to the API without prior notice if we determine your use violates the Terms, and/or is otherwise objectionable as determined by our sole discretion.
          </p>
          <div className="ts-sub-2">Use of API</div>
          <p>
            If you make your website/application usable by others, you must maintain a User Agreement and a legally compliant privacy policy for your website/application that is prominently identified and/or located where Users download and/or access your website/application. You must immediately notify us in writing of any breach of your User Agreement and/or privacy policy that impacts, and/or may impact, customers and/or Users of our products and/or services. You will only use the API in connection with legally permissible activities, and as described in the Terms.
          </p>
          <p>
            You will use commercially reasonable efforts to protect User information collected by your API Client, including personally identifiable information ("PII"), from unauthorized access and/or use, and will promptly report to your Users any unauthorized access and/or use of such information to the extent required by applicable law.
          </p>
          <p>
            You will comply with all applicable laws and regulations including those applying to PII. You will provide and adhere to a privacy policy for your API Client that clearly and accurately describes to Users of your API Client what User information you collect and how you use and share such information (including for advertising) with third parties.
          </p>
          <p>
            Peer2Punt sets and enforces limitations on the use of the API. You will not circumvent, and/or attempt to circumvent, such limitations as they apply to the API and/or as otherwise set forth in the Terms, except as approved in writing by us. We may limit the number and nature of network calls that you and/or your website/application may make. We may change such usage limits at any time with or without notice and may use any means to enforce its usage limitations and prevent overuse of the API.
          </p>
          <p>
            We may monitor your use of the API for reasons including to improve the API, to identify security issues, and to ensure compliance with the Terms. We reserve the sole right to determine whether or not your use of the API is acceptable, including the manner in which the API is integrated into your website/application. We may collect and use usage data and information related to your use of the API.
          </p>
          <p>
            Our review, testing, and/or approval of your products and/or services does not constitute any representation or acknowledgement by us that your website/application and/or any content therein comply with the Terms, any laws, rules, and/or regulations, nor does it constitute any acceptance by us of any responsibility or liability in connection to such products and/or services, and/or any content therein.
          </p>
          <p>
            If you do not comply with the Terms and/or if in our sole discretion we believe that you have attempted to exceed and/or circumvent any of the prohibited uses and/or the additional requirements and/or limitations described in the Terms, your ability to use the API may, without notice, be temporarily and/or permanently blocked and/or revoked.
          </p>
          <p>
            Unless permitted by applicable law or any Agreement(s) between you and us, you will not direct, encourage, or assist any other party to: (a) license, sublicense, sell, resell, transfer, assign, distribute or otherwise provide or make available to any other party the API; (b) modify or make derivative works based upon the API; (c) improperly use the API, including creating Internet “links” to any part of the API, “framing” or “mirroring” any part of the API on any other websites or systems, or “scraping” or otherwise improperly obtaining data from the API; (d) reverse engineer, decompile, modify, or disassemble the API; (e) send spam or otherwise duplicative or unsolicited messages with the API; or (f) use the API to (x) display any offensive content or any content for which you do not have the right to share with us or to display or (y) distribute unsolicited advertising or promotions, or initiate any other communication or contact with our Users or partners.
          </p>
          <p>
            In addition, you shall not direct, encourage, or assist any other party to access or use the API to: (i) design or develop a competitive or substantially similar product or service; (ii) copy or extract any features or functionality thereof; (iii) launch or cause to be launched on or in connection with the API a malicious automated program or script, including web spiders, crawlers, robots, indexers, bots, viruses or worms, or any program intended to overburden or hinder the operation or performance of the API; (iv) attempt to gain unauthorized access to the API or its related systems or networks; (v) include any underlying Peer2Punt platform or product with competitors in any aggregated view i.e. webpage, app, software, etc.; (vi) aggregate our data with competitors’ data; or (vii) parse or scrape any of our data; in each case other than as explicitly permitted by us in writing. You will not share with a third party (or enable a third party to use) any operational, technical or other data obtained through the use of the API in any manner that is competitive to us, including without limitation, in connection with any application, website or other product or service that also includes, features, endorses, or otherwise supports in any  way a third party that provides services competitive to our products and services.
          </p>
          <div className="ts-sub-2">Ownership</div>
          <p>
            CodeFlame Ltd owns all rights, title and interest, including without limitation all intellectual property rights and other rights in and to its software applications, including but not limited to the API, any intellectual property rights used in connection with the software applications, and other proprietary technology, including any data structures therein, accompanying documentation, and any updates and/or revisions to the foregoing. All rights not specifically conveyed are retained by us.
          </p>
          <p>
            Subject to your compliance with the Terms, CodeFlame Ltd hereby grants you a limited, revocable, non-exclusive, non-transferable, non-assignable license to access and use the API for the sole purposes of developing, testing, using, and maintaining an integration of your application with the API.
          </p>
          <p>
            We may produce and/or distribute incidental depictions, including screenshots, video, and/or other content from your API client, and/or may use your company and/or product name in the course of our worldwide promotion, marketing, and/or demonstration of the API you are using; you hereby grant us all necessary licenses, on a royalty-free basis, for these purposes.
          </p>
          <p>
            Subject to the rights granted to us and limitations herein, you reserve and retain sole and exclusive ownership of all rights, title, and interest in, to, and under (a) your extensions and applications, and (b) all modifications, corrections, repairs, translations, enhancements, and other derivative works and improvements of your extensions and applications, including all intellectual property rights arising therefrom or relating thereto (collectively, (a) and (b) are “Your Products”), to the extent Your Products do not infringe upon any of our intellectual property rights. You are solely responsible for all costs incurred by you in the creation and maintenance of Your Products.
          </p>
          <p>
            The API may contain content owned by a third party. We shall not be held responsible for any third party content. This content accessible through the API and our products may be subject to the intellectual property rights of third parties.
          </p>
          <div className="ts-sub-2">Term, Termination and Survival</div>
          <p>
            These Terms will apply for as long as you access and use the API. You may terminate these Terms at any time by giving us notice and ceasing to access and use the API or by disabling API access in your User Account settings. At our sole discretion, We may terminate these Terms at any time, including if it is determined that you have violated or attempted to violate the Terms. These Terms will terminate automatically in the event the API you use is no longer made available to you, if your account with Peer2Punt is terminated or expires, or if any representations you make herein are deemed or found to be untrue.
          </p>
          <p>
            Upon termination of these Terms, you will immediately stop using the API, cease all use of our intellectual property and delete any cached and/or stored content and any data (including any Confidential Information). Peer2Punt reserves the right to contact your end Users to notify them of the termination of your right to use the API and our services.
          </p>
          <div className="ts-sub-2">Indemnification</div>
          <p>
            You agree to indemnify and hold Peer2Punt and its directors, employees, representatives, agents, subsidiaries and affiliates harmless, from and against, any and all claims, demands, losses, liabilities, and expenses (including legal fees), arising out of or in connection with: (a) your use of the API or information obtained through your use of the API; (b) your breach or violation of the Terms or (c) your violation of the rights of any third party, including with respect to any third party content.
          </p>
          <div className="ts-sub-2">DISCLAIMER</div>
          <p>
            THE PEER2PUNT API IS PROVIDED “AS IS” AND “AS AVAILABLE”. PEER2PUNT DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. IN ADDITION, PEER2PUNT MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF THE API OR ANY INFORMATION REQUESTED OR OBTAINED THROUGH THE USE OF THE API, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE API, AND ANY INFORMATION REQUESTED OR OBTAINED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
          </p>
          <p>
            BEAR IN MIND THAT PEER2PUNT DOES NOT AND WILL NOT AT ANY TIME DEDUCT OR WITHHOLD OR REPORT ANY TAXES (INCOME, GST, VAT, STAMP-DUTY OR WHATSOEVER) AND YOU ARE PERSONALLY FULLY LIABLE TO BE COMPLIANT WITH ANY REPORTING OBLIGATIONS AND TAXES WHICH MIGHT BE REQUESTED FROM YOU FOR ANY OPERATIONS PERFORMED DURING THE USE OF SERVICES.
          </p>
          <div className="ts-sub-2">LIMITATIONS</div>
          <p>
            WHERE PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL PEER2PUNT OR ANY OF ITS SERVICE PROVIDERS, SUPPLIERS OR REPRESENTATIVES (INCLUDING DIRECTORS AND EMPLOYEES) BE LIABLE TO YOU OR ANY THIRD PARTY UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, FOR ANY: (A) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE OR PROFIT, OR LOSS OF DATA OR DIMINUTION IN VALUE, OR (B) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED OR PUNITIVE DAMAGES, ARISING FROM YOUR USE OF THE API, REGARDLESS OF WHETHER YOU OR SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. THE FOREGOING LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS OF USE IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. PEER2PUNT AGGREGATE LIABILITY UNDER THESE TERMS OF USE WILL NOT EXCEED ONE HUNDRED US DOLLARS ($100).
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
