import sentImg from "../assets/svg/sent.png";
import infoerror from "../assets/svg/info-error.png";
import infosuccess from "../assets/svg/svgexport-81.png";
import "../assets/css/confirm.css";
import React from "react";
import { Link } from "react-router-dom";

const ResetSent = (props) => {
  const forgotData = props.forgotData;
  const fpErrors = props.fpErrors;
  const fpEMSG = props.fpEMSG;
  const fpSEMSG = props.fpSEMSG;

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const resend = () => {
    const indexes = [];
    if (!forgotData[0]) indexes.push(0);
    if (!isValidEmail(forgotData[0])) indexes.push(1);
    if (indexes.length)
      return { setFPErrors: { indexes: indexes, bool: true } };

    return { forgot: true };
  };

  return (
    <>
      <div className="confirm-main">
        <div className="confirm-box">
          <div className="confirm-img">
            <img src={sentImg} alt="Sent" />
          </div>
          <div className="confirm-hd">Check your email</div>
          <div className="confirm-info">
            We've sent password reset instructions to
            <br />
            <strong>{forgotData[0]}</strong>
          </div>
          <div className="confirm-info">
            Just click on the link in that email to reset your
            <br />
            Peer2Punt account password.
            <br />
            <br />
            If you don't see it, you may need to
            <br />
            <strong>check your spam folder</strong>.
          </div>
          <div className="confirm-info">Still can't find the email?</div>
          <div className="confirm-action">
            <button
              className="btn btn-gold"
              onClick={(e) => props.onClick(resend())}
            >
              Resend Email
            </button>
            {fpErrors[4] ? (
              <>
                <br />
                <div className="field-error inline error">
                  <img src={infoerror} alt="Info" />
                  {fpSEMSG}
                </div>
              </>
            ) : (
              ""
            )}
            {fpErrors[5] ? (
              <>
                <br />
                <div className="field-error inline success">
                  <img src={infosuccess} alt="Info" />
                  {fpEMSG.fpmsg5}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="confirm-info">
            Need help? <Link to="mailto:support@peer2punt.com">Contact Us</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetSent;
