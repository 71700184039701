import infoerror from "../assets/svg/info-error.png";
import "../assets/css/accounts.css";
import React from "react";
//import $ from "jquery";

const ResetPass = (props) => {
  const resetData = props.resetData;
  const rpErrors = props.rpErrors;
  const rpEMSG = props.rpEMSG;
  const rpSEMSG = props.rpSEMSG;

  const setResetData = (index, val, select) => {
    const indexes = index !== 0 ? (index !== 1 ? [] : [2, 3]) : [0, 1];
    return {
      setResetData: {
        index: index,
        val: val,
        select: select,
      },
      setRPErrors: { indexes: indexes, bool: false },
    };
  };

  const isValidPass = (password) => {
    if (password.length < 8) return false;
    if (!password.match(/[a-z]/g)) return false;
    if (!password.match(/[A-Z]/g)) return false;
    if (!password.match(/[0-9]/g)) return false;
    if (!password.match(/[@.#$!%*? &]/g)) return false;
    return true;
  };

  const reset = () => {
    const indexes = [];
    if (!resetData[0]) indexes.push(0);
    if (!isValidPass(resetData[0])) indexes.push(1);
    if (!resetData[1]) indexes.push(4);
    if (!isValidPass(resetData[1])) indexes.push(3);
    if (resetData[0] !== resetData[1]) indexes.push(3);
    if (indexes.length)
      return { setRPErrors: { indexes: indexes, bool: true } };

    return { reset: true };
  };

  return (
    <>
      <div className="accounts-main">
        <div className="acc-box-1">
          <div className="acc-hd">Start predicting for free to get paid</div>
          <div className="acc-sub">No betting required.</div>
        </div>
        <div className="acc-box-2">
          <div className="acc-hd">Reset Your Password</div>
          <hr />
          <div className="acc-bd">
            <div className="acc-bd-item">
              <div className="acc-bdi title">New Password</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  rpErrors[0] || rpErrors[1] ? "error" : ""
                }`}
              >
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  onChange={(e) =>
                    props.onChange(setResetData(0, e.target.value))
                  }
                />
              </div>
              {rpErrors[0] || rpErrors[1] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!rpErrors[0] ? (
                    !rpErrors[1] ? (
                      ""
                    ) : (
                      <div>
                        {rpEMSG.rpmsg3}
                        <div className="pl-2">
                          {!resetData[0].match(/[a-z]/g) ? (
                            <div>
                              &bull; At least one lowercase alphabet [a-z]
                            </div>
                          ) : (
                            ""
                          )}
                          {!resetData[0].match(/[A-Z]/g) ? (
                            <div>
                              &bull; At least one uppercase alphabet [A-Z]
                            </div>
                          ) : (
                            ""
                          )}
                          {!resetData[0].match(/[0-9]/g) ? (
                            <div>&bull; At least one Numeric digit [0-9]</div>
                          ) : (
                            ""
                          )}
                          {!resetData[0].match(/[@.#$!%*? &]/g) ? (
                            <div>
                              &bull; At least one special character [@ $ . # ! %
                              * ? & ^]
                            </div>
                          ) : (
                            ""
                          )}
                          {resetData[0].length < 8 ||
                          resetData[0].length > 50 ? (
                            <div>
                              &bull; A total length between 8-50 characters
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )
                  ) : (
                    rpEMSG.rpmsg0
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="acc-bd-item">
              <div className="acc-bdi title">Confirm New Password</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  rpErrors[2] || rpErrors[3] ? "error" : ""
                }`}
              >
                <input
                  type="password"
                  name="password"
                  id="password2"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  onChange={(e) =>
                    props.onChange(setResetData(1, e.target.value))
                  }
                />
              </div>
              {rpErrors[2] || rpErrors[3] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!rpErrors[2] ? (
                    !rpErrors[3] ? (
                      ""
                    ) : (
                      <div>{rpEMSG.rpmsg3}</div>
                    )
                  ) : (
                    rpEMSG.rpmsg2
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <hr />
            <div className="acc-bd-item">
              <div className="acc-bdi action">
                <button
                  className="btn btn-gold"
                  onClick={(e) => props.onClick(reset())}
                >
                  Reset
                </button>
              </div>
              {rpErrors[4] ? (
                <div className="field-error error j-center">
                  <img src={infoerror} alt="Info" />
                  {rpSEMSG}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPass;
