import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/help.css";

const Help = (props) => {
  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="hp-main">
          <div className="hp-bf">
            Before trading read the&nbsp;
            <Link
              to="/security-guide"
              onClick={(e) =>
                props.onClick(
                  handleLink(
                    e,
                    "/security-guide",
                    "Security Guide",
                    "Security-Guide"
                  )
                )
              }
            >
              SECURITY GUIDE
            </Link>
          </div>
        <div className="hp-bd">
          <div className="hp-m-hd">Help guides</div>
          <div className="item" id="how-to-buy-bet">
            <div className="hp-hd">How to add an event</div>
            <p>To add an event, you must have an account with Peer2Punt.</p>
            <ol>
              <li>
                In the header section of the website, on the left side, click
                “Sports”.
              </li>
              <li>
                On the sports page of the website, just below the header section
                and on the right side, click “Add Event” to open a new window
                titled “Add Event”.
              </li>
              <li>
                Paste or enter the URL link of an event you copied from a
                supported livescore platform, such as: SofaScore, BeSoccer, and
                FlashScore, into the "Event URL" field. On the right side of the
                URL field, Click "Fetch" to fetch the event information into
                Peer2Punt. You may check to see if the information received
                are consistent with the information on the source, and make corrections where
                necessary before you proceed.
              </li>
              <li>
                You may provide the event information manually (without a URL
                link). To add an event manually, you must:
              </li>
              <ul>
                <li>
                  Select the "Event Type", such as Football, Basketball or Golf;
                </li>
                <li>
                  Select the "Event Country", such as England, Spain or
                  Brazil
                </li>
                <li>
                  Select the "Event Devision", such as Premier League, Serie A
                  or Ligue 1
                </li>
                <li>
                  Select the "Event Team 1/Home Team", such as Arsenal, PSG or
                  Real Madrid
                </li>
                <li>
                  Select the "Event Team 2/Away Team", such as Chelsea, Metz or
                  Celta Vigo
                </li>
                <li>Set the "Event Date"</li>
                <li>Set the "Event Time"</li>
              </ul>
              <li>
                At the buttom of the “Add Event” window, click “Add New Event”
                to submit the event and proceed to the “Add Punt” window. Adding
                an event with similar information as an already existing event
                is not possible.
              </li>
              <li>Note that:</li>
              <ul>
                <li>
                  Only you can be the first punter of an event you added to
                  Peer2Punt.
                </li>
                <li>
                  Only you can delete an event you added to Peer2Punt, given
                  that the event has not been punted by another user, and the
                  status of the event has not changed to “Ongoing”. Deleting an
                  event also deletes your punt for that event.
                </li>
              </ul>
            </ol>
            <br />
            <div className="hp-hd">How to punt an event</div>
            <p>To punt an event, you must have an account with Peer2Punt.</p>
            <ol>
              <li>
                In the header section of the website, on the left side, click
                “Sports”.
              </li>
              <li>
                On the sports page of the website, click on the Event you want
                to punt and wait for the event to load.
              </li>
              <li>
                Choose any punt option of your choice by navigating through the
                punt types, then click on an available punt option. This
                will open a “Punt confrimation” window, on which you may confirm your choice to add the clicked
                punt option to the event. The confirmation window also displays
                more information about the punt option you clicked, such as:
              </li>
              <ul>
                <li>
                  <b>Total punts</b> - This is the total punts that have been
                  added on the selected event, each by a different punter.
                </li>
                <li><b>Potential Up Rating</b> - This is the amount of Peer2Punt Rating that you will gain if your punt is correct at the end of the selected event. The maximum Peer2Punt Rating per event is 5. </li>
                <li><b>Potential Down Rating</b> - This is the amount of Peer2Punt Rating that you will lose if your punt is incorrect at the end of the selected event. The minimum Peer2Punt Rating per event is 0.</li>
                <li><b>Punt type</b> - This is a punt option group, such as: 3-Way, Double Chance or Correct Score.</li>
                <li><b>Punt description</b> - This is the description of your chosen punt option.</li>
              </ul>
              <li>Click “Add” to confirm your choice to add the clicked punt to the event, or click “Close” to close the confirmation window.</li>
              <li>Note that, for each event:</li>
              <ul>
                <li>
                  The amount of potential Up Rating decreases, while the amount of potential Down Rating increases as more punters punt that event;
                </li>
                <li>Only the first punter can gain the maximum Up Rating (5), if their punt was correct after the event is settled;</li>
                <li>The first punter will lose the minimum Down Rating (0), if their punt was incorrect after the event is settled.</li>
              </ul>
            </ol>
            <br />
            <div className="hp-hd">How to buy Bet</div>
            <p>To buy Bet, you must have an account with Peer2Punt.</p>
            <ol>
              <li>
                In the header section of the website, on the left side, click
                “Buy Bet”.
              </li>
              <li>
                Use filters to sort the offers according to your preferences,
                such as: country, currency, payment type, payment method, and
                amount (all fields are optional).
              </li>
              <li>In the offer list below, click the offer you prefer.</li>
              <li>
                Enter the minimum Betting Odds that you want to buy (the price
                is generated automatically), or the amount of money that you
                wish to pay for a Bet (the minimum Betting odds is generated
                automatically). Then you may select your preferred bookmaker and
                select your preferred payment method (some offers have more than
                one payment method). Also, you have the option to leave a
                comment for your counterparty.
              </li>
              <li>
                After the contract has been created, press the “Generate escrow”
                button and enter your payment password that generates a unique
                escrow address. Then wait until the seller sends Bet into the
                multisig escrow.
              </li>
              <li>
                When you see that Bet is locked in escrow and the contract
                status has changed to "In progress", it is not yet safe to pay
                the seller until the Bet has been verified by Peer2Punt. Click
                the verify button to instruct Peer2Punt to verify the Bet in
                escrow. This usually takes less than 5 minutes.
              </li>
              <li>
                When you see that the Bet in escrow has been verified and the
                contract status has changed to "Bet verified", it is safe to pay
                the seller by sending money according to the payment details the
                seller specified. After you have sent the payment, click the
                button "I've sent the payment".
              </li>
              <li>
                When the seller verifies the funds have arrived, the Seller then
                releases the Bet from escrow. All sports and punts contained in
                the Bet are directly repunted into your account except if you
                had previously punted any of those sports. You also receive the
                bonus Peer2Punt Rating specified in the offer. The bonus
                Peer2Punt Rating expires after 7 days.
              </li>
              <li>Your contract is now complete!</li>
            </ol>
            <div className="hp-sub">
              Pay special attention to the following, on the offer and contract
              pages:
            </div>
            <ul>
              <li>
                IMPORTANT: Never send the payment until the Bet is safely locked
                in escrow and the Bet has been verified by Peer2Punt!
              </li>
              <li>
                Note the "Payment window" - which is the time within which you
                are obligated to complete the payment and finish the contract.
              </li>
              <li>
                Note the bonus Peer2Punt Rating on the offer - this is the
                amount of Peer2Punt Rating that the seller has accumulated
                within 30 days (some offers have more bonus Peer2Punt Rating
                than others). You receive the seller's accumulated Peer2Punt
                Rating as bonus when you complete a contract.
              </li>
              <li>
                Don’t forget, you can always contact your counterparty in the
                contract’s chat.
              </li>
              <li>
                If the contract status has changed to "Paid" and you’ve sent the
                funds, but your counterparty does not want to release the Bet –
                you can start a dispute after the “Payment window” expires.
              </li>
            </ul>
          </div>
          <div className="item" id="how-to-sell-bet">
            <br />
            <div className="hp-hd">How to sell Bet</div>
            <p>To sell Bet you must have an account with Peer2Punt.</p>
            <ol>
              <li>
                In the header section of the website, on the left side, click
                ‘Sell Bet'.
              </li>
              <li>
                Use filters to sort the offers according to your preferences,
                such as: country, currency, payment type, payment method, and
                amount (all fields are optional).
              </li>
              <li>In the offer list below, click the offer you prefer.</li>
              <li>
                Enter the minimum Betting Odds that you want to sell (the price
                is generated automatically), or the amount of money that you
                wish to receive for your Bet (the minimum Betting odds is
                generated automatically). Next, you may select your preferred
                bookmaker and select your preferred payment method (some offers
                have more than one payment method). You also have the option to
                leave a comment for your counterparty.
              </li>
              <li>
                After the contract has been created, click the “Generate escrow”
                button and enter your payment password, this generates a unique
                escrow address, then wait for the seller to do the same.
              </li>
              <li>
                After the contract has been created, you send into escrow the
                Bets with no less than the minimum amount of Betting Odds
                specified in your contract, then click “I’ve sent the Bets”.
              </li>
              <li>
                When the Bet is locked in escrow, wait until Peer2Punt verifies
                your Bet (This usually takes 5 minutes), then wait until the
                buyer sends you the payment according to your contract’s payment
                method.
              </li>
              <li>
                When the contract status has changed to "Paid", it means the
                buyer has sent you the payment. You now need to make sure the
                funds actually arrived. After you verify you received the
                payment in full, click the "Release Bet" button, and enter your
                payment password to release the Bet to the buyer.
              </li>
              <li>Your contract is now complete.</li>
            </ol>
            <div className="hp-sub">
              Pay special attention to the following on the offer and contract
              pages:
            </div>
            <ul>
              <li>
                IMPORTANT: Never release the Bet from escrow unless you have
                received the payment from the buyer!
              </li>
              <li>
                Don’t forget, you can always contact your counterparty in the
                contract’s chat.
              </li>
              <li>
                If you have sent the Bet to escrow, but have not received the
                payment from the buyer (and the contract status has not been
                marked “Paid”) you are able to cancel the contract and release
                your Bet as soon as the payment window expires.
              </li>
            </ul>
          </div>
          <div className="item" id="title-security">
            <br />
            <div className="hp-hd">Why is trading on Peer2Punt secure?</div>
            <br />
            <div className="hp-sub" id="multisig-escrow">
              Multisig escrow
            </div>
            <p>
              Peer2Punt ensures every trader’s safety by providing unique
              multisig escrow for every contract created on our trading
              platform. During the trade, the Bets remain locked in the multisig
              escrow (P2P) address on our network.
            </p>
            <p>
              In order to release the Bet from escrow, two (out of three) keys
              are needed - one belongs to the seller, one belongs to the buyer,
              and the other belongs to Peer2Punt. This system ensures that both
              buyer and seller are protected from scams, since no transaction
              can happen without a signature from two parties.
            </p>
            <p>
              The seller is not able to refund Bet from escrow without the
              approval of a second party, and the buyer is not able to receive
              the Bet unless the contract is successfully completed. Peer2Punt
              uses its key if there is a dispute.
            </p>
            <br />
            <div className="hp-sub" id="reputation">
              Reputation system
            </div>
            <p>
              Every active trader on Peer2Punt has a reputation and public
              trading history, so before you engage in a contract – you can
              protect yourself by understanding the information available about
              your counterparty. You can read more about the reputation system
              in the "
              <Link
                to="/faq#what-is-the-rating-system"
                onClick={(e) =>
                  props.onClick(
                    handleLink(
                      e,
                      "/faq#what-is-the-rating-system",
                      "FAQ",
                      "faq"
                    )
                  )
                }
              >
                Ratings &amp; Reviews
              </Link>
              " guide.
            </p>
            <br />
            <div className="hp-sub" id="limits">
              Limits system
            </div>
            <p>
              Every user has a limit level that increases after successfully
              completing contracts. We apply limits to users for the sake of
              improving each user's overall experience, safety, and comfort
              level when using our trading platform.
            </p>
            <p>
              Peer2Punt offers many features, and some of them could potentially
              be targeted by any of the well known spam &amp; scam cases in the
              crypto-community, so we have taken the necessary steps to reduce
              this possibility so that unfair users cannot harm you. Also, user
              limits are a safety measure to protect users.
            </p>
            <p>
              You can find more information about limitations in the "
              <Link
                to="/faq#why-are-limits-need"
                onClick={(e) =>
                  props.onClick(
                    handleLink(e, "/faq#why-are-limits-need", "FAQ", "faq")
                  )
                }
              >
                Limits
              </Link>
              " guide.
            </p>
            <br />
            <div className="hp-sub" id="2fa">
              Two-Factor Authentication
            </div>
            <p>
              The Peer2Punt trading platform gives you the option to use an
              advanced level of security - Two-Factor authentication (2FA).
            </p>
            <p>
              In addition to your email address and password, if you have 2FA
              enabled, in order to log in, you are required to enter a 6-digit
              code, this code is generated in the application (e.g. Authy, or
              Google Authenticator) on your device. This makes it impossible to
              log into your account without having access to your 2FA code.
            </p>
            <p>
              More information is available in the "
              <Link
                to="/faq#what-is-2fa"
                onClick={(e) =>
                  props.onClick(handleLink(e, "/faq#what-is-2fa", "FAQ", "faq"))
                }
              >
                What is Two-Factor Authentication?
              </Link>
              " section.
            </p>
            <br />
            <div className="hp-sub" id="trusted-devices">
              Trusted devices
            </div>
            <p>
              The trusted devices feature at Peer2Punt is an additional level of
              security. After turning it on, every time a new device attempts to
              access your account, you are immediately asked to confirm it
              through your email. Otherwise your account cannot be accessed from
              the new device.
            </p>
            <p>
              More information is available in the "
              <Link
                to="/faq#trusted-devices"
                onClick={(e) =>
                  props.onClick(
                    handleLink(e, "/faq#trusted-devices", "FAQ", "faq")
                  )
                }
              >
                What are Trusted Devices?
              </Link>
              " section.
            </p>
            <br />
            <div className="hp-sub" id="dispute-system">
              Dispute resolution system
            </div>
            <p>
              Whenever there is a problem during a contract, both parties are
              able to start a dispute if they cannot resolve the issue on their
              own. Once a dispute has been started, the support manager will
              study the case, and give you further instructions in order to
              resolve the dispute.
            </p>
          </div>
          <div className="item" id="title-multisig-escrow">
            <br />
            <div className="hp-hd">Information We Collect</div>
            <br />
            <div className="hp-sub" id="what-is-multisig">
              What is multisig escrow?
            </div>
            <p>
              Escrow is a P2P Bet multisignature address, which requires n out
              of m keys to sign the transaction. Peer2Punt requires 2-out-of-3
              keys to sign the release transaction. Everytime a contract is
              created between two parties, Peer2Punt generates a unique multisig
              escrow Bet address. Then the seller sends Bet to this escrow, and
              while the Bet is locked in escrow, the buyer sends fiat to the
              seller. Then the seller releases the Bet from escrow using his
              payment password, and the buyer receives the Bet into his
              Peer2Punt account.
            </p>
            <br />
            <div className="hp-sub" id="how-keys-generated">
              How are the keys to escrow generated and stored?
            </div>
            <p>
              Peer2Punt generates the private keys for the contract’s buyer and
              seller on the client side (in the browser, with javascript). These
              private keys are used to create the escrow address and to release
              the Bets from that escrow address.
            </p>
            <p>
              A user’s private keys are created using both a random number
              generator and a user-created payment password. They are then
              encrypted with the same password and sent to the Peer2Punt server
              encrypted. Peer2Punt cannot decrypt its users’ private keys
              without knowing the payment passwords — and payment passwords are
              not known to Peer2Punt and are not stored anywhere (not even on
              the client side!). This means that Peer2Punt doesn’t store any of
              its users private keys and cannot release Bets from any particular
              contract’s escrow address without the buyer's or seller’s
              approval.
            </p>
            <p>
              An approval, in this case, means sending the encrypted private key
              back from the server side to the client side (user’s browsers),
              prompting the user to enter their payment password to decrypt the
              key, and then partially signing the release transaction. Only
              after the transaction is partially signed with the user’s private
              key, it is sent back to the server where Peer2Punt signs it with
              its own key and broadcasts it.
            </p>
            <br />
            <div className="hp-sub" id="when-multisig-used">
              When are escrow keys needed?
            </div>
            <p>Escrow keys are required in the following situations:</p>
            <ul>
              <li>
                <strong>Contract is cancelled.</strong>
                &nbsp;In case the contract has been cancelled, and the Bet has
                already been sent to escrow, the seller can return the Bet back
                to himself by entering his payment password - Peer2Punt will
                automatically sign the transaction with it’s key. However, the
                buyer would not be able to make a release from the escrow - as
                Peer2Punt will not sign such a transaction.
              </li>
              <li>
                <strong>Contract is completed.</strong>
                &nbsp;If everything goes well in the contract, the seller
                releases the Bet from escrow to the buyer’s account by entering
                his payment password - and Peer2Punt will automatically sign the
                transaction with it’s key. However, the buyer alone would not be
                able to make a release from the escrow - as Peer2Punt will not
                sign such a transaction.
              </li>
              <li>
                <strong>
                  Contract is disputed and the administrator has not taken sides
                  yet.
                </strong>
                &nbsp;In this case, the parties still have the option to resolve
                the issue on their own. The seller is able to release the Bet
                from the escrow to the buyer’s account by entering the payment
                password - Peer2Punt will automatically sign the transaction
                with it’s key. However, neither buyer nor seller will be able to
                release the Bet from escrow to themselves - Peer2Punt will not
                sign such a transaction.
              </li>
              <li>
                <strong>
                  Dispute is resolved in favor of one of the parties.
                </strong>
                &nbsp;The winning party can release the Bet from escrow to his
                own account by entering the payment password - Peer2Punt will
                automatically sign the transaction with it’s key. However, the
                other party will not be able to release the Bet from escrow - as
                Peer2Punt will not sign such a transaction.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
