import infoerror from "../assets/svg/info-error.png";
import "../assets/css/accounts.css";
import React from "react";
import { Link } from "react-router-dom";

const Confirmation = (props) => {
  const confData = props.confData;
  const cfErrors = props.cfErrors;
  const cfEMSG = props.cfEMSG;
  const cfSEMSG = props.cfSEMSG;

  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  const setFPData = (index, val, select) => {
    const indexes = index !== 0 ? [] : [0, 1, 2, 3];
    return {
      setConfData: {
        index: index,
        val: val,
        select: select,
      },
      setcfErrors: { indexes: indexes, bool: false },
    };
  };

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const conf = () => {
    const indexes = [];
    if (!confData[0]) indexes.push(0);
    if (!isValidEmail(confData[0])) indexes.push(1);
    if (indexes.length)
      return { setcfErrors: { indexes: indexes, bool: true } };

    return { conf: true };
  };

  const goToLogin = (e) => {
    e.preventDefault();
    let pState = {
      title: "Login",
      newUrl: "/accounts/login",
      rto: "Login",
      rid: null,
    };
    return { pState: pState };
  };

  const goToSignup = (e) => {
    e.preventDefault();
    let pState = {
      title: "Get started",
      newUrl: "/accounts/signup",
      rto: "Sign-Up",
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="accounts-main">
        <div className="acc-box-1">
          <div className="acc-hd">Start predicting for free to get paid</div>
          <div className="acc-sub">No betting required.</div>
        </div>
        <div className="acc-box-2">
          <div className="acc-hd">Resend confirmation instructions</div>
          <hr />
          <div className="acc-bd">
            <div className="acc-bd-item">
              <div className="acc-bdi title">Email</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  cfErrors[0] || cfErrors[1] || cfErrors[2] || cfErrors[3]
                    ? "error"
                    : ""
                }`}
              >
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  defaultValue={confData[0]}
                  onChange={(e) => props.onChange(setFPData(0, e.target.value))}
                />
              </div>
              {cfErrors[0] || cfErrors[1] || cfErrors[2] || cfErrors[3] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!cfErrors[0]
                    ? !cfErrors[1]
                      ? !cfErrors[2]
                        ? !cfErrors[3]
                          ? ""
                          : cfEMSG.cfmsg3
                        : cfEMSG.cfmsg2
                      : cfEMSG.cfmsg1
                    : cfEMSG.cfmsg0}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="acc-bd-item py-3">
              <div className="acc-bdi action">
                <button
                  className="btn btn-gold"
                  onClick={(e) => props.onClick(conf())}
                >
                  Resend instructions
                </button>
                <br />
                {cfErrors[4] ? (
                  <div className="field-error inline error">
                    <img src={infoerror} alt="Info" />
                    {cfSEMSG}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <hr />
            <div className="su-box">
              <Link
                to="/accounts/login"
                onClick={(e) => props.onClick(goToLogin(e))}
              >
                Log In
              </Link>
              &nbsp; &nbsp; &nbsp;
              <Link
                title="Get Started"
                to="/accounts/signup"
                onClick={(e) => props.onClick(goToSignup(e))}
              >
                Sign up
              </Link>
            </div>
            <hr />
            <div className="inst-box">
              <Link
                title="Forgot your password?"
                to="/accounts/forgot"
                onClick={(e) =>
                  props.onClick(
                    handleLink(
                      e,
                      "/accounts/forgot",
                      "Forgot Password",
                      "Forgot"
                    )
                  )
                }
              >
                Forgot your password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
