import "../../assets/css/ov0.css";
import React from "react";

const Ov0 = (props) => {
  const rb = props.rb;
  const toggleRB = () => {
    if(rb === "off") return null;
    return { setRB: true, setOV0: true };
  };

  return <div className="overlay ov-0" onClick={() => props.onClick(toggleRB())}></div>;
};

export default Ov0;
