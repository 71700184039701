import asc from "../assets/svg/svgexport-15.png";
import desc from "../assets/svg/svgexport-6.png";
import searchIcon from "../assets/svg/svgexport-93.png";
import close2 from "../assets/svg/close-2.png";
import infoerror from "../assets/svg/info-error.png";
import "../assets/css/accounts.css";
import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

const SignUp = (props) => {
  const tzData = props.tzData;
  const signUpData = props.signUpData;
  const tz = signUpData[2];
  const tzVal = tzData ? tzData.timezones[tz] : "";
  const slt = props.slt;
  const search = props.search;
  const suErrors = props.suErrors;
  const suEMSG = props.suEMSG;
  const suSEMSG = props.suSEMSG;

  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  const toggleSLT = (data) => {
    return { setSLT: data, setOVSLT: true };
  };

  const setSearch = (data, reset) => {
    if (reset) $(".options-search input").val("");
    data = data.length > 32 ? data.substring(0, 32) : data;
    return { setSearch: { data: data } };
  };

  const setSUData = (index, val, select) => {
    const indexes =
      index !== 0
        ? index !== 1
          ? index !== 2
            ? index !== 3
              ? index !== 4
                ? index !== 6
                  ? []
                  : [11]
                : [9, 10]
              : [7, 8]
            : [6]
          : [3, 4, 5]
        : [0, 1, 2];
    return {
      setSignUpData: {
        index: index,
        val: val,
        select: select,
      },
      setSUErrors: { indexes: indexes, bool: false },
    };
  };

  const tzOptions = () => {
    const nodata = (
      <div className="acc-bdi field d-flex pr-1">
        <div className="select disabled">
          <div className="select-val">-</div>
          <div className="select-pointer">
            <img src={desc} alt="Desc" />
          </div>
        </div>
      </div>
    );
    if (!tzData) return nodata;
    const allTimeZones = [];
    const total = tzData.total;
    const major = parseInt(tzData.major);
    const timezones = tzData.timezones;
    if (!total) return nodata;
    allTimeZones.push(
      <div key="sch1" className="options-search field">
        <span>
          <img src={searchIcon} alt="Search" />
        </span>
        <span>
          <input
            type="text"
            maxLength="32"
            placeholder="Search"
            autoComplete="false"
            onChange={(e) => props.onChange(setSearch(e.target.value))}
          />
          <button
            type="button"
            className="btn"
            onClick={(e) => props.onClick(setSearch("", true))}
          >
            <img src={close2} alt="Search" />
          </button>
        </span>
      </div>
    );
    allTimeZones.push(
      <button
        key="tz0"
        className={`btn btn-special-alt-3 option-val ${
          !signUpData[2] ? "active" : ""
        }`}
        onClick={(e) => props.onClick(setSUData(2, "", true))}
      >
        Choose a timezone
      </button>
    );
    let i = 0;
    for (let [key, val] of Object.entries(timezones)) {
      if (i === major) {
        allTimeZones.push(
          <span key="tzgap2" className="option-val gap">
            <hr />
          </span>
        );
      }
      i++;
      let tzutc = val.tzutc,
        tzname = val.tzname;
      if (search) {
        let search2 = search.replace("+", ""),
          regex = new RegExp(search2, "i");
        if (tzutc.search(regex) < 0) {
          if (tzname.search(regex) < 0) continue;
        }
      }
      allTimeZones.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            signUpData[2] === key ? "active" : ""
          }`}
          onClick={() => props.onClick(setSUData(2, key, true))}
        >
          (UTC {tzutc}) {tzname}
        </button>
      );
    }

    return (
      <>
        <div
          className={`acc-bdi field d-flex pr-1 ${suErrors[6] ? "error" : ""}`}
        >
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("timezone"))}
          >
            <div className="select-val">
              {tzVal
                ? `(UTC ${tzVal.tzutc}) ${tzVal.tzname}`
                : "Choose a timezone"}
            </div>
            <div className="select-pointer">
              <img src={slt === "timezone" ? asc : desc} alt="Desc" />
            </div>
          </div>
        </div>
        {slt === "timezone" ? (
          <div className="options">{allTimeZones}</div>
        ) : (
          ""
        )}
        {suErrors[6] ? (
          <div className="field-error error">
            <img src={infoerror} alt="Info" />
            {suEMSG.sumsg6}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const isValidNick = (nickname) => {
    if (nickname.length < 3 || nickname.length > 12) return false;
    const pattern = /^[a-z]([a-zA-Z0-9_.])+$/;
    return pattern.exec(nickname);
  };

  const isValidPass = (password) => {
    /*const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!~',`";/>\-_<+%=|*? &]{8,40}$/;
    return pattern.test(password);*/
    if (password.length < 8) return false;
    if (!password.match(/[a-z]/g)) return false;
    if (!password.match(/[A-Z]/g)) return false;
    if (!password.match(/[0-9]/g)) return false;
    if (!password.match(/[@.#$!%*? &]/g)) return false;
    return true;
  };

  const signUp = () => {
    const indexes = [];
    if (!signUpData[0]) indexes.push(0);
    if (!isValidEmail(signUpData[0])) indexes.push(1);
    if (!signUpData[1]) indexes.push(3);
    if (!isValidNick(signUpData[1])) indexes.push(4);
    if (!signUpData[2]) indexes.push(6);
    if (!signUpData[3]) indexes.push(7);
    if (!isValidPass(signUpData[3])) indexes.push(8);
    if (!signUpData[4]) indexes.push(9);
    if (signUpData[4] !== signUpData[3]) indexes.push(10);
    if (!signUpData[6]) indexes.push(11);
    if (indexes.length)
      return { setSUErrors: { indexes: indexes, bool: true } };

    return { signUP: true };
  };

  return (
    <>
      <div className="accounts-main">
        <div className="acc-box-1">
          <div className="acc-hd">Start predicting for free to get paid</div>
          <div className="acc-sub">No betting required.</div>
        </div>
        <div className="acc-box-2">
          <div className="acc-hd">Sign Up</div>
          <hr />
          <div className="acc-bd">
            <div className="acc-bd-item">
              <div className="acc-bdi title">Email</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  suErrors[0] || suErrors[1] || suErrors[2] ? "error" : ""
                }`}
              >
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  defaultValue={signUpData[0]}
                  onChange={(e) => props.onChange(setSUData(0, e.target.value))}
                />
              </div>
              {suErrors[0] || suErrors[1] || suErrors[2] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!suErrors[0]
                    ? !suErrors[1]
                      ? !suErrors[2]
                        ? ""
                        : suEMSG.sumsg2
                      : suEMSG.sumsg1
                    : suEMSG.sumsg0}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="acc-bd-item">
              <div className="acc-bdi title">Nickname</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  suErrors[3] || suErrors[4] || suErrors[5] ? "error" : ""
                }`}
              >
                <input
                  type="text"
                  name="nickname"
                  id="nickname"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  defaultValue={signUpData[1]}
                  onChange={(e) => props.onChange(setSUData(1, e.target.value))}
                />
              </div>
              {suErrors[3] || suErrors[4] || suErrors[5] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!suErrors[3] ? (
                    !suErrors[4] ? (
                      !suErrors[5] ? (
                        ""
                      ) : (
                        suEMSG.sumsg5
                      )
                    ) : (
                      <div>
                        {suEMSG.sumsg4}
                        <div className="pl-2">
                          {signUpData[1].length < 3 ||
                          signUpData[1].length > 12 ? (
                            <div>
                              &bull; Nickname must be between 3 to 12 characters
                            </div>
                          ) : (
                            ""
                          )}
                          {!signUpData[1].match(/^[a-z]([a-zA-Z0-9_.])+$/) ? (
                            <div>
                              &bull; Nickname must start with a lowercase
                              alphabet (a-z)
                            </div>
                          ) : (
                            ""
                          )}
                          {(/[@.#$!%*? &]/g).test(signUpData[1]) ? (
                            <div>
                              &bull; Nickname may only contain:
                              <div className="pl-2">
                                &bull; Uppercase alphabets (A-Z)
                                <br />
                                &bull; numbers (0-9)
                                <br />
                                &bull; dots (.)
                                <br />
                                &bull; underscores (_)
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )
                  ) : (
                    suEMSG.sumsg3
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="acc-bdi info">
                This is the name other users of the site will identify you with
              </div>
            </div>
            <div className="acc-bd-item">
              <div className="acc-bdi title">Timezone</div>
              {tzOptions()}
            </div>
            <div className="acc-bd-item">
              <div className="acc-bdi title">Password</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  suErrors[7] || suErrors[8] ? "error" : ""
                }`}
              >
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  defaultValue={signUpData[3]}
                  onChange={(e) => props.onChange(setSUData(3, e.target.value))}
                />
              </div>
              {suErrors[7] || suErrors[8] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!suErrors[7] ? (
                    !suErrors[8] ? (
                      ""
                    ) : (
                      <div>
                        {suEMSG.sumsg8}
                        <div className="pl-2">
                          {!signUpData[3].match(/[a-z]/g) ? (
                            <div>
                              &bull; At least one lowercase alphabet [a-z]
                            </div>
                          ) : (
                            ""
                          )}
                          {!signUpData[3].match(/[A-Z]/g) ? (
                            <div>
                              &bull; At least one uppercase alphabet [A-Z]
                            </div>
                          ) : (
                            ""
                          )}
                          {!signUpData[3].match(/[0-9]/g) ? (
                            <div>&bull; At least one Numeric digit [0-9]</div>
                          ) : (
                            ""
                          )}
                          {!signUpData[3].match(/[@.#$!%*? &]/g) ? (
                            <div>
                              &bull; At least one special character [@ $ . # ! %
                              * ? & ^]
                            </div>
                          ) : (
                            ""
                          )}
                          {signUpData[3].length < 8 ||
                          signUpData[3].length > 50 ? (
                            <div>
                              &bull; A total length between 8-50 characters
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )
                  ) : (
                    suEMSG.sumsg7
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="acc-bd-item">
              <div className="acc-bdi title">Confirm password</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  suErrors[9] || suErrors[10] ? "error" : ""
                }`}
              >
                <input
                  type="password"
                  name="password2"
                  id="password2"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  defaultValue={signUpData[4]}
                  onChange={(e) => props.onChange(setSUData(4, e.target.value))}
                />
              </div>
              {suErrors[9] || suErrors[10] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!suErrors[9]
                    ? !suErrors[10]
                      ? ""
                      : suEMSG.sumsg10
                    : suEMSG.sumsg9}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="acc-bd-item">
              <div className="acc-bdi title">Referral code</div>
              <div className="acc-bdi field d-flex px-0">
                <input
                  type="text"
                  name="refcode"
                  id="refcode"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  defaultValue={signUpData[5]}
                  onChange={(e) => props.onChange(setSUData(5, e.target.value))}
                />
              </div>
              <div className="acc-bdi info">
                If you have a referral code, you can enter it here
              </div>
            </div>
            <hr />
            <div className="acc-bd-item">
              <div className="acc-bdi checkbox">
                <label htmlFor="regaccept">
                  <input
                    type="checkbox"
                    name="regaccept"
                    id="regaccept"
                    autoComplete="off"
                    defaultChecked={signUpData[6]}
                    onChange={(e) =>
                      props.onChange(setSUData(6, e.target.checked))
                    }
                    required
                  />
                  <span>
                    I agree to the{" "}
                    <Link
                      to="/terms-and-conditions"
                      onClick={(e) =>
                        props.onClick(
                          handleLink(
                            e,
                            "/terms-and-conditions",
                            "Terms and Conditions",
                            "Terms-and-Conditions"
                          )
                        )
                      }
                    >
                      Terms and Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="/privacy-policy"
                      onClick={(e) =>
                        props.onClick(
                          handleLink(
                            e,
                            "/privacy-policy",
                            "Privacy Policy",
                            "Privacy"
                          )
                        )
                      }
                    >
                      Privacy Policy
                    </Link>
                  </span>
                </label>
              </div>
              {suErrors[11] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {suEMSG.sumsg11}
                </div>
              ) : (
                ""
              )}
            </div>
            <hr />
            <div className="acc-bd-item">
              <div className="acc-bdi action">
                <button
                  className="btn btn-gold"
                  onClick={(e) => props.onClick(signUp())}
                >
                  Sign up
                </button>
              </div>
              {suErrors[12] ? (
                <div className="field-error error j-center">
                  <img src={infoerror} alt="Info" />
                  {suSEMSG}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
