import securedImg from "../assets/svg/secured.png";
import "../assets/css/confirm.css";
import React from "react";
import { Link } from "react-router-dom";

const Reset = (props) => {
  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="confirm-main">
        <div className="confirm-box">
          <div className="confirm-img">
            <img src={securedImg} alt="Secured" />
          </div>
          <div className="confirm-hd">Password Changed</div>
          <div className="confirm-info">
            Your password was changed successfully.
          </div>
          <div className="confirm-action">
            <button
              className="btn btn-gold"
              onClick={(e) =>
                props.onClick(
                  handleLink(e, "/accounts/login", "Login", "Login")
                )
              }
            >
              Login to my account
            </button>
          </div>
          <div className="confirm-info">
            Need help? <Link to="mailto:support@peer2punt.com">Contact Us</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
