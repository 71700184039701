import React from "react";
import Events from "./MidDiv/Events";
import EVFreePT from "./MidDiv/EVFreePT";
import EVTopPT from "./MidDiv/EVTopPT";
import TopBox from "./MidDiv/TopBox";

const MidDiv = (props) => {
  const mView = props.mView;
  const evData = props.evData;

  const handleDateClick = (view) => {
    let setmView = view;
    return { setmView: setmView };
  };
  return (
    <>
      <div className="middiv m-show">
        <div className="plt">
          <div className="plt1 m-show">
            <div className="mini-nav">
              <span className="navsw">
                <button
                  id="plv1"
                  className={`sw hbtn alt${
                    mView === "Matches" ? " active" : ""
                  }`}
                  onClick={() => props.onClick(handleDateClick("Matches"))}
                  disabled={evData ? false : true}
                >
                  Matches
                </button>
                <button
                  id="plv2"
                  className={`sw hbtn alt${mView === "Top" ? " active" : ""}`}
                  onClick={() => props.onClick(handleDateClick("Top"))}
                  disabled={evData ? false : true}
                >
                  Top punts
                </button>
                <button
                  id="plv3"
                  className={`sw hbtn alt${mView === "Free" ? " active" : ""}`}
                  onClick={() => props.onClick(handleDateClick("Free"))}
                  disabled={evData ? false : true}
                >
                  Free punts
                </button>
                <button
                  id="plv4"
                  className={`sw hbtn alt btn-gold${
                    mView === "Markets" ? " active" : ""
                  }`}
                  onClick={() => props.onClick(handleDateClick("Markets"))}
                  disabled={evData ? false : true}
                >
                  Punt Markets
                  <div>
                    <span className="new">New</span>
                  </div>
                </button>
              </span>
            </div>
            {mView === "Top" ? (
              <EVTopPT
                dd={props.dd}
                eView={props.eView}
                evTPPTData={props.evTPPTData}
                onClick={(value) => props.onClick(value)}
              />
            ) : mView === "Free" ? (
              <EVFreePT
                dd={props.dd}
                eView={props.eView}
                evFRPTData={props.evFRPTData}
                onClick={(value) => props.onClick(value)}
              />
            ) : (
              <Events
                dd={props.dd}
                eView={props.eView}
                evData={props.evData}
                nevData={props.nevData}
                onClick={(value) => props.onClick(value)}
              />
            )}
          </div>
          <div className="plt2">
            <div className="inner">
              <TopBox
                offset={props.offset}
                dd={props.dd}
                evData={props.evData}
                onClick={(value) => props.onClick(value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MidDiv;
