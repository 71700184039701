import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/privacy-policy.css";

const PrivacyPolicy = (props) => {
  //const handleLink = (e, url, title, rto) => {
  // e.preventDefault();
  //let pState = {
  //title: title,
  //newUrl: url,
  //rto: rto,
  //rid: null,
  //};
  //return { pState: pState };
  //};

  return (
    <>
      <div className="pp-main">
        <div className="pp-bd">
          <div className="pp-m-hd">Privacy policy</div>
          <div className="pp-lu">Last updated: March 13th, 2024</div>
          <div className="pp-hd">INTRODUCTION</div>
          <p>
            Our Privacy Policy explains how your personal data is collected,
            stored, used, removed and disclosed by CodeFlame Ltd. ("CodeFlame
            Ltd", "we" or "us"). The rules of this Privacy Policy apply to
            information we collect when you use Peer2Punt platform operated by
            CodeFlame Ltd (“Peer2Punt” or "Platform").
          </p>
          <p>
            Please note that any provision of this Privacy Policy may be revised
            and reissued unilaterally by us without notice at any time. You
            should visit this page regularly to review the current version of
            the Privacy Policy, since your continued use of the Platform will be
            deemed as irrevocable acceptance of any revisions.
          </p>
          <p>
            By using the Platform, you irrevocably express consent for us to
            collect, store and use the information you share with us as well as
            the other information mentioned in this Privacy Policy. Should you
            not accept this Privacy Policy, or any single rule or rules of it,
            you shall immediately stop using our Platform and delete your
            account.
          </p>
          <div className="pp-hd">Information We Collect</div>
          <p>
            Peer2Punt must receive or collect some information to operate,
            provide, improve, understand, customize, support, and market our
            Platform, including when you visit, access, register or use our
            Platform. The types of information we receive and collect depend on
            how you use our Platform.
          </p>
          <div className="pp-sub">
            Information you provide us when using CodeFlame Ltd Platform
          </div>
          <ul>
            <li>
              <b>Account Information.</b> You provide your email and basic
              information (including a nickname and timezone) to create an
              account on peer2punt.com. After account creation, you may also add
              other information to your account, such as a profile picture and
              information about yourself.
            </li>
            <li>
              <b>Event Information.</b> Event information includes information
              relating to sporting events, such as the Event Type, the Event
              Country, the Event Division, the Event Team 1 / Home Team, the
              Event Team 2 / Away Team, the Event Date and the Event Time.
            </li>
            <li>
              <b>Prediction Information.</b> You choose a Punt Option from a
              list of popular betting options.
            </li>
            <li>
              <b>Trading Information.</b> Trading information includes
              information relating to your trades, created advertisements, the
              value of your trades in terms of both cryptocurrency and fiat,
              Bitcoin addresses, the price of the cryptocurrency, the currency
              used, the timestamps that the trades took place, the chat logs of
              the trade, nicknames of both buyer and seller, the payment method,
              a record of initiated trades, trade IDs, merchant invoice
              information (if exists) and other information (if exists).
            </li>
            <li>
              <b>Communication Information.</b> Communication information
              relates to communications. Any communication you have with us may
              be stored and analyzed, this includes information created through
              using our Platform, including all communications during a trade
              with other users and file attachments, communications with
              customer support, which may happen through support tickets,
              emails, or the dispute resolution process, or any other
              communication channel, including all messages and communications
              with our customer support.
            </li>
            <li>
              <b>Information relating to user identity and payment details.</b>
              &nbsp;In case of a dispute, you will be obliged to provide us with your
              ID and account statements etc., which may be required for dispute
              resolution. The user is also free to verify his or her self on an
              entirely voluntary basis. The term ID may include: full name, date
              of birth, country (country of residence and citizenship), and
              other information. Mandatory ID verification may also take place
              if we suspect abuse of our Platform such as criminal activity,
              money laundering or fraud.
            </li>
          </ul>
          <div className="pp-sub">
            Automatically collected information during the use of CodeFlame Ltd
            Platform
          </div>
          <ul>
            <li>
              <b>Log records and usage information.</b> We may collect
              information about your activity when using our Platform, including
              diagnostics, performance related statistics, and Service related
              information. Including when you registered to use our Platform,
              when you most recently updated your information, when you last
              used our Platform, and which features you use, such as offer
              creation, API, or engaging in contracts. This also includes
              information relating to your activity, how you use our Platform,
              the time, frequency, and duration you use our Platform, how you
              interact with others while using our Platform, the settings you
              use when using our Platform, log files, performance logs,
              diagnostics (such as crashes and website usage statistics) and
              other reports.
            </li>
            <li>
              <b>Connection Information (Device And Location).</b> We may
              collect device information, and other information relating to your
              connection when you access and use our Platform, such as your IP
              and MAC address and related information, browser information,
              operating system information, and hardware model etc.
            </li>
          </ul>
          <div className="pp-sub">Third-Party Information</div>
          <ul>
            <li>
              <b>Information Others Have Provided About You.</b> Other users or
              businesses may provide us with information about you, such as your
              nickname, email, and other information. Users may also inform us
              that you use our Platform. All users and businesses are required
              to have legal rights to use, collect, and share your information
              before sending your information to us.
            </li>
            <li>
              <b>Third Parties.</b> Third party service operators may work with
              us, and we may receive information about you to improve our
              operations, and to help us provide our Platform, customization,
              understanding, as well as to provide support, research and conduct
              surveys, market our Platform, and to understand how people use our
              Platform, the distribution of our Platform, and to provide
              infrastructure, delivery, and other systems.
            </li>
          </ul>
          <div className="pp-hd">How We Use Information</div>
          <p>
            The following explains how we provide and support our Platform, and
            use information originating from the choices you make:
          </p>
          <ul>
            <li>
              <b>Platform.</b> In order to operate our Platform, provide
              customer support, improvements, fixes, and the customization of
              our Platform, we use information that we have access to. We use
              information about how people use our Platform in order to analyze,
              evaluate, engage in troubleshooting, and to improve our Platform,
              as well as for the development, improvement, research, and testing
              of new features and Platform.
            </li>
            <li>
              <b>Security.</b> We investigate all suspicious activity and
              violations of our terms, to be certain our Platform is being used
              legally. We verify our users activity and combat conduct that may
              be deemed harmful. We detect and prevent spam, and any other bad
              experiences, to promote safety and security both when using and
              when not using our Platform.
            </li>
            <li>
              <b>Communications.</b> We use information to communicate with you
              regarding our Platform, to send you marketing communications, and
              to inform you of significant changes or innovations in our
              Platform, and to send you notifications, including but not limited
              to contract status updates, new messages from contracts you
              participate in, and any changes to your account. When you contact
              us, we use your information to respond to you.
            </li>
            <li>
              <b>Location.</b> In order to improve and uniquely tailor our
              Platform to you, we use location related information such as where
              you live and your current location, for example, for the purpose
              of automatically filtering the offer list. IP addresses and other
              location related information may also be used.
            </li>
            <li>
              <b>Promotion.</b> For the purpose of promoting our platform, we
              may use your offer’s information.
            </li>
          </ul>
          <div className="pp-hd">Information You Share With Us</div>
          <p>
            We share the information that you provide by using and communicating
            through our Platform, to improve our Platform, as well as to
            understand, support, customize, provide, operate, and market our
            Platform.
          </p>
          <ul>
            <li>
              <b>Public Information.</b> Can be seen by everyone, even if they
              don't have an account, inside and outside of our Platform. This
              includes the advertisement of your offer in the list, as well as
              your profile and trading information. You may manage your profile
              settings to make only specific information visible to others, as
              well as make your advertisements private or not visible to others.
              Also, anybody can see whether or not you are actively using our
              Platform, and the status of your account. By posting a public
              offer you automatically grant, represent and warrant that you have
              the right to grant to us an irrevocable, perpetual, exclusive,
              fully paid, worldwide license to use, copy, perform, display, and
              distribute such an offer and to prepare derivative works of, or
              incorporate it into other works, such personal data.
            </li>
            <li>
              <b>Counterparties.</b> People you trade with may store and share
              your information (such as your contact details, messages, or chat
              attachments) with others that may or may not use our Platform.
            </li>
            <li>
              <b>Third Party Sharing:</b> We do not sell your information, and
              never will. We do partner with third parties to help use, provide
              and improve our Platform, which allows us to operate globally. We
              strictly limit how our partners use and disclose the data we
              provide. The following are the types of third parties we share
              information with:
            </li>
            <ol>
              <li>
                When monitoring for and fixing crashes, we use "Sentry" service,
                your IP address may be included in the error messages.
              </li>
              <li>
                "Google Analytics" service is used for behavioral statistics.
                Your IP address may be included in the data we provide to Google
                Analytics, which is used by Google Analytics to understand how
                you use our Service.
              </li>
              <li>We use "AWS" service for storing your personal data.</li>
              <li>
                For communications we use "ProtonMail". We may share data with
                Protonmail that includes your email and nickname.
              </li>
              <li>
                We use "Twitter" service for promotions. We may provide Twitter
                with data such as your offer information;
              </li>
              <li>
                We use “SendGrid” to send critical notifications and product
                updates regarding our Platform, informative emails,
                announcements and marketing emails.
              </li>
            </ol>
          </ul>
          <p>
            We may provide anonymous data such as usage statistics about the
            users of our Platform to share our traffic patterns, sales,
            customers, and other information to potential partners, investors,
            marketers, other reputable third parties, and for other legal
            purposes, however none of these statistics will contain your
            personal data.
          </p>
          <p>
            We will never share Your IP and/or MAC address and/or any other
            sensitive information with anyone (even You) if we believe that it
            can harm our customers or if there is no legal obligation for us to
            do so.
          </p>
          <div className="pp-hd">
            Our Legal Basis For Processing Information
          </div>
          <p>We process the information we have as described below:</p>
          <ul>
            <li>As required in fulfilling our Terms of Service;</li>
            <li>
              Consistent with your consent which you can withdraw at any time;
            </li>
            <li>As required to be in compliance with our legal obligations;</li>
            <li>
              As it may be necessary to protect your (and others’) interests;
            </li>
            <li>As for the benefit of the public interest;</li>
            <li>
              As required to benefit the interests of yourself and others,
              fulfilling our goal of providing a safe, innovative, and
              profitable Service for our users and partners, barring these
              interests being superseded by your rights, freedoms, and personal
              interests that mandate the utmost protection of your personal
              data.
            </li>
          </ul>
          <div className="pp-hd">Your rights</div>
          <p>
            In this Section, we summarise the rights you have regarding your
            personal data.
          </p>
          <p>You have the following rights regarding your personal data:</p>
          <ul>
            <li>
              A right to object to us processing your personal data, citing
              personal reasons; however, understand that we may still process
              your personal data if we have lawful grounds to do so, but only if
              our interests in processing your personal data are not overridden
              by your rights, interests, or freedoms;
            </li>
            <li>
              A right to access the personal data of yours that we hold. You may
              also receive specific personal data in a machine readable format
              such as .pdf or .csv;
            </li>
            <li>
              A right to transfer your data, if we are in legal accordance of
              processing your personal data and you consent, and the processing
              is executed automatically, you are within your rights to be sent
              your personal data from us in a structured, regularly used machine
              readable format, only if this would not negatively affect the
              freedoms and rights of others;
            </li>
            <li>A right to rectify any inaccuracies in your personal data;</li>
            <li>
              A right to have specific parts of your personal data erased when
              it is no longer required for us to process it, if you have
              withdrawn your consent, objected, if your personal data has been
              unlawfully processed, and/or if erasing your personal data is a
              requirement in accordance with a legal requirement;
            </li>
            <li>
              A right to be made aware of the logic we use to make automated
              decisions about you, upon request;
            </li>
            <li>
              A right to withdraw consent (if previously given) for processing
              your personal data. Please note that withdrawing consent does not
              affect the lawfulness of processing your personal data before the
              withdrawal. You may withdraw your consent by emailing&nbsp;
              <Link to="mailto:dpo@peer2punt.com" title="">
                dpo@peer2punt.com
              </Link>
              ;
            </li>
            <li>
              A right to contact your national data protection regulator if you
              are unaware of your rights, or are concerned about how your
              personal data is processed;
            </li>
            <li>
              A right to file a complaint with the national data protection
              regulator in your country;
            </li>
            <li>
              A right to exercise your rights by emailing us at&nbsp;
              <Link to="mailto:dpo@peer2punt.com" title="">
                dpo@peer2punt.com
              </Link>
              . We will try to accommodate any request you make in an effort to
              respect your rights, but please be aware that your rights may not
              be absolute rights. This means that we are within our rights to
              refuse your request or may only be able to partially comply;
            </li>
          </ul>
          <p>
            If you make a request, in respect of your rights, we may ask for
            clarification about your request. If we believe your requests are
            unreasonable or repetitive, we do have the right to not respond.
          </p>
          <div className="pp-hd">Storing and Deleting Personal Data</div>
          <p>
            We keep your personal data for however long is necessary to provide
            you with our Platform. We store your personal data for up to 5 years
            following the deletion of your Account (if applicable) or from the
            last contact we receive from you. We will delete your personal data
            when we are not required to process your data. Please be aware that
            legal and regulatory requirements may require us to store your
            personal data for a longer period, pursuant to any applicable
            statutory limitation period.
          </p>
          <div className="pp-hd">
            How We Transfer Data While Operating Global Platform
          </div>
          <p>
            CodeFlame Ltd. information is shared globally, internally within the
            company, as well as externally with our partners, and with everyone
            who communicates according to this Privacy Policy. CodeFlame Ltd
            transfers, transmits, stores, and processes information mostly
            within the European Economic Area, and other countries outside of
            your country of residence for the purposes described in this Privacy
            Policy. Transferring data in this manner is required in order to
            provide you with our Platform as described in our Terms of Services.
            The European Commission approves the standard contract clauses we
            use, and we may be reliant upon the European Commission's&nbsp;
            <Link
              target="_blank"
              to="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
            >
              adequacy decisions
            </Link>
            &nbsp;about certain countries, as applicable, for data transfers
            from the European Economic Area to the United States and other
            countries.
          </p>
          <div className="pp-hd">Cookie policy</div>
          <div className="pp-sub">What Are Cookies?</div>
          <p>
            It’s common for most professional websites to use cookies, as our
            site does. Cookies are very small files that are temporarily, or
            permanently, downloaded to your computer, for the sole purpose of
            improving your experience as a user of the website. Here we describe
            the purpose of a cookie, which is the information they gather, we
            also explain how we use this information and why we sometimes need
            to store these cookies. We also explain how you can prevent these
            cookies from being stored, however this may limit site functionality
            or render it unusable.
          </p>
          <p>
            For more information on cookies you can see the&nbsp;
            <Link
              target="_blank"
              to="https://en.wikipedia.org/wiki/HTTP_cookie"
            >
              Wikipedia article on HTTP Cookies
            </Link>
            .
          </p>
          <div className="pp-sub">Turning Off Cookies</div>
          <p>
            You can block cookies by changing the settings on your browser
            (typically located under the privacy settings in your browser).
            Please be aware that disabling cookies will affect the functionality
            of our website, and many others that you visit. Disabling cookies
            usually results in limiting the functionality and features of
            websites. Therefore, we recommend that you do not disable cookies.
          </p>
          <div className="pp-sub">Cookies We Issue</div>
          <p>
            Once you create an account with us, we will use cookies for
            administration purposes such as managing the signup process, as well
            as for preventing the abuse &amp; misuse of our Platform. Cookies
            will usually be deleted when you log out, however, in some cases
            they remain afterwards, for example, to remember your site
            preferences for the next time you log in. In other words, once you
            are logged in, we use cookies to remember your site preferences.
            Furthermore, cookies prevent you from having to log in every time
            you visit a new page. These cookies are usually deleted when you log
            out to ensure you only have access to restricted features and areas
            when you are logged in. In order to provide you with a great
            experience on our website, we give you the ability to set your own
            preferences for how our site runs, whenever you use it. We set
            cookies so your customizations and preferences are remembered
            whenever you use a particular page.
          </p>
          <div className="pp-sub">Third-Party Cookies</div>
          <p>
            Sometimes we also use cookies from trusted third parties. For now,
            the third parties are all part of Google company’s services, such
            as:
          </p>
          <ul>
            <li>Google Analytics,</li>
            <li>Youtube,</li>
            <li>reCAPTCHA.</li>
          </ul>
          <p>
            For more information on Google Analytics cookies, you can see&nbsp;
            <Link target="_blank" to="https://www.google.com/analytics/">
              the official Google Analytics page
            </Link>
            .
          </p>
          <p>
            For more information on reCAPTCHA cookies, you can see the&nbsp;
            <Link
              target="_blank"
              to="https://termsfeed.com/blog/privacy-policy-recaptcha/"
            >
              Privacy Policy for reCAPTCHA page
            </Link>
            .
          </p>
          <p>
            For more information on Google cookies, you can see&nbsp;
            <Link
              target="_blank"
              to="https://www.google.com/policies/technologies/types/"
            >
              types of cookies used by Google page
            </Link>
            .
          </p>
          <div className="pp-sub">Contact Information</div>
          <p>
            CodeFlame Ltd. is the data controller responsible for your personal
            data. If you have any questions or requests related to our policies
            you may contact our data protection office through email:&nbsp;
            <Link to="mailto:dpo@peer2punt.com" title="">
              dpo@peer2punt.com
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
