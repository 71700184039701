import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/dispute-rules.css";

const DisputeRules = (props) => {
  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };
  return (
    <>
      <div className="dr-main">
        <div className="dr-bf">
          Before trading read the&nbsp;
          <Link
            to="/security-guide"
            onClick={(e) =>
              props.onClick(
                handleLink(
                  e,
                  "/security-guide",
                  "Security Guide",
                  "Security-Guide"
                )
              )
            }
          >
            SECURITY GUIDE
          </Link>
        </div>
        <div className="dr-bd">
          <div className="dr-m-hd">Dispute Rules</div>
          <p>Hello,</p>
          <br />
          <p>
            Before customer support contacts you directly, we would like to
            inform you about the overall process, specifically the next steps
            and established timeframes for actions in a dispute. Since the
            dispute has just started, please be patient and allow our customer
            support to contact both of you within the next 12 hours. You will
            receive a message from customer support in the contract’s chat, and
            also through email.
          </p>
          <div className="dr-hd">
            To reduce dispute time, we kindly ask you to follow these steps:
          </div>
          <ul>
            <li>
              Send a detailed description of the situation to&nbsp;
              <Link to="mailto:dispute@peer2punt.com" title="">
                dispute@peer2punt.com
              </Link>
              &nbsp;(YOU MUST DO THIS WITHIN 10 HOURS AFTER THE DISPUTE HAS STARTED).
            </li>
            <li>
              We use TLSNOTARY browser extension - we ask you to download it
              from&nbsp;
              <Link target="_blank" to="https://tlsnotary.org/pagesigner.html">
                https://tlsnotary.org/pagesigner.html
              </Link>&nbsp;
              (click on the browser you use, either Chrome or Firefox) and you
              can watch the “Quick Start Video” on the same page to learn how it
              works.
            </li>
            <li>
              Prepare your ID/Passport/Drivers License and payment account
              statement - customer support may ask for this.
            </li>
          </ul>
          <div className="dr-hd">Dispute Rules and Timeframes:</div>
          <ul>
            <li>
              Everytime there is a message in the chat from customer support -
              you will get an email.
            </li>
            <li>
              The maximum dispute duration time is 60 days (the dispute may
              stretch over this time only if the payment is delayed or if
              customer support decides that additional time is required for
              dispute resolution).
            </li>
            <li>
              Customer support needs you to respond within 10 hours of receiving
              a trader's message.
            </li>
            <li>
              You must respond, and provide any information that the customer
              support manager finds appropriate, in order to resolve the dispute
              within the established timeframe.
            </li>
            <li>
              You have a maximum of 10 hours to respond to a message from
              customer support.
            </li>
            <li>
              You have a maximum of 10 hours to provide information, files and
              documents asked by customer support.
            </li>
            <li>
              If you do not answer within this timeframe, you will be granted an
              additional 10 hours, however you will not be able to make any new
              offers or contracts during this additional 10 hour period.
            </li>
            <li>
              Also, these timeframes can be extended by us if you do not have an
              opportunity to provide all of the necessary information, files or
              documents. For example, 3rd party payment service working hours
              may cause a delay, but in this case, you have to inform us about
              the reason and let us know when you will be able to provide all of
              the necessary information.
            </li>
            <li>Customer support has the final word.</li>
          </ul>
          <div className="dr-hd">
            During a dispute, the following is strictly forbidden:
          </div>
          <ul>
            <li>To have a verbal conflict with each other.</li>
            <li>To provide false and/or fake documents and/or information.</li>
            <li>To spam the chat.</li>
            <li>To insult and/or bully.</li>
            <li>To argue with customer support.</li>
            <li>
              To not respond and/or to not provide information, files and/or
              documents to customer support within the established timeframe, as
              this will be considered interfering with resolving a problem, and
              you may lose the dispute.
            </li>
          </ul>
          <p>
            If you have any questions regarding a dispute, please contact&nbsp;
            <Link to="mailto:dispute@peer2punt.com" title="">
              dispute@peer2punt.com
            </Link>
            &nbsp;and enter the subject as “Questions regarding dispute”.
          </p>
        </div>
      </div>
    </>
  );
};

export default DisputeRules;
