import "../../assets/css/ovslt.css";
import React from "react";

const OvSLT = (props) => {
  const toggleSLT = () => {
    return { setSLT: "none", setOVSLT: true };
  };

  return <div className="overlay ov-slt" onClick={() => props.onClick(toggleSLT())}></div>;
};

export default OvSLT;
