import React from "react";
import LeftDiv from "./Main/LeftDiv";
import MidDiv from "./Main/MidDiv";

const Main = (props) => {
  return (
    <>
      <div className="main-box">
        <LeftDiv
          dd={props.dd}
          sDate={props.sDate}
          ccData={props.ccData}
          sCountry={props.sCountry}
          onClick={(value) => props.onClick(value)}
        />
        <MidDiv
          offset={props.offset}
          dd={props.dd}
          mView={props.mView}
          eView={props.eView}
          evData={props.evData}
          evFRPTData={props.evFRPTData}
          evTPPTData={props.evTPPTData}
          nevData={props.nevData}
          onClick={(value) => props.onClick(value)}
        />
      </div>
    </>
  );
};

export default Main;
