import React from "react";
import { Routes, Route, Link } from "react-router-dom";

const EVTopPT = (props) => {
  const maxEV = 20;
  const eView = props.eView;
  const evTPPTData = props.evTPPTData;
  // const nevTPPTData = props.nevTPPTData;

  const handleDateClick = (view) => {
    let seteView = view;
    return { seteView: seteView };
  };

  const plchldr = () => {
    const allPlchdr = [];
    for (let i = 1; i <= maxEV; i++) {
      allPlchdr.push(
        <div className="plte plchldr" key={`evplchldr${i}`}>
          <div className="ecclg mt-1 mb-1">
            <div className="flag mr-1">
              <span className="animated-gradient"></span>
            </div>
            <div className="cclg">
              <div className="ecc">
                <span className="animated-gradient"></span>
              </div>
              <div className="elg">
                <span className="animated-gradient"></span>
              </div>
            </div>
          </div>
          <div className="einf">
            <div className="edt">
              <span className="animated-gradient"></span>
            </div>
            <div className="efx">
              <div className="ehm">
                <span className="animated-gradient"></span>
              </div>
              <div className="eaw">
                <span className="animated-gradient"></span>
              </div>
            </div>
            <div className="esc">
              <div className="hsc">
                <span className="animated-gradient"></span>
              </div>
              <div className="asc">
                <span className="animated-gradient"></span>
              </div>
            </div>
            <div className="ert">
              <div className="eur">
                <span className="animated-gradient"></span>
              </div>
            </div>
            <div className="eop">
              <span className="animated-gradient"></span>
              <span className="animated-gradient"></span>
              <span className="animated-gradient"></span>
            </div>
            <div className="ert">
              <div className="edr">
                <span className="animated-gradient"></span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return allPlchdr;
  };

  const punts = () => {
    const allPunts = [];
    const cclg = [];
    if (!evTPPTData || !evTPPTData.punts)
      return <div className="noev">No Event(s)</div>;
    const punts = evTPPTData.punts;
    for (let [key, val] of Object.entries(punts)) {
      let ecc = val.ecc,
        ecc2 = val.ecc2,
        ecclg = val.ecclg,
        elg = val.elg,
        evst = val.evst,
        ptdt = val.ptdt,
        ptusn = val.ptusn,
        ptucc = val.ptucc,
        ptfx = val.ptfx,
        ptur = val.ptur,
        ptdr = val.ptdr,
        opnm = val.opnm,
        optp = val.optp,
        opdsc = val.opdsc,
        ptst = val.ptst,
        subscribe = val.subscribe,
        started = val.started,
        punted = val.punted,
        uslk = `/football/punter/${ptusn}`;
      if (evTPPTData.day === "Today" && eView === "Upcoming" && started)
        continue;
      allPunts.push(
        <div key={key} id={key} className={`plte`}>
          {!cclg.includes(ecclg) ? (
            <div className="ecclg">
              <div className="flag">
                <img
                  src={require(`../../../assets/countries/${ecc2}.png`)}
                  alt={ecc}
                />
              </div>
              <div className="cclg">
                <div className="ecc">{ecc}</div>
                <div className="elg">{elg}</div>
              </div>
              <div className="etxt d-none"></div>
            </div>
          ) : (
            ""
          )}
          <div className="nwpb d-none">
            <div className="ptdt"></div>
            <Link to="" title="Punter" className="ptdtl">
              <div className="ptusr"></div>
              <div className="ptfx"></div>
            </Link>
            <div className="ptrt">
              <div className="ptur"></div>
            </div>
            <div className="eop eop3">
              <div className="sltinf">
                <div className="slt-punt"></div>
                <div className="slt-count"></div>
              </div>
              <button className="btn btn-special ptrpt" disabled>
                <div className="inner">
                  <div className="eopn">
                    <img
                      src={require(`../../../assets/icons/rpt-icon.png`)}
                      alt="Repunt"
                    />
                  </div>
                  <div className="eoprc">Repunt</div>
                </div>
              </button>
            </div>
            <div className="ptrt">
              <div className="ptdr"></div>
            </div>
          </div>
          <div className="tppb">
            <div className="ptdt">{ptdt}</div>
            <Link to={uslk} title={ptusn} className="ptdtl">
              <div className="ptusr">
                <img
                  src={require(`../../../assets/icons/icon-1.png`)}
                  alt="User Icon"
                />
                <span>
                  {ptusn}&nbsp;
                  <span className={`flag-icon flag-icon-${ptucc}`}></span>
                </span>
              </div>
              <div className="ptfx">{ptfx}</div>
            </Link>
            <div className="ptrt">
              <div className="ptur">
                <span
                  className={
                    !started ? "green" : ptst === "1" ? "green" : "gray"
                  }
                >
                  {ptur}
                  <br />
                  <img
                    src={
                      !started
                        ? require("../../../assets/image/star-icon-green.png")
                        : ptst === "1"
                        ? require("../../../assets/image/star-icon-green.png")
                        : require("../../../assets/image/star-icon-gray.png")
                    }
                    alt="Up Rating"
                  />
                </span>
              </div>
            </div>
            <div className="eop eop3">
              <div className="sltinf">
                <div className="slt-punt">
                  {subscribe ? (
                    <span
                      className={started ? "expired" : "unlocked"}
                      title={opdsc ? opdsc : opnm}
                    >
                      {opnm}
                    </span>
                  ) : (
                    <span
                      className={started ? "expired" : "locked"}
                      title="Locked"
                    >
                      <i className="fa fa-lock small mr-1"></i>
                    </span>
                  )}
                </div>
                <div className="slt-count">{subscribe ? optp : "Locked"}</div>
              </div>
              <button
                className="btn btn-special ptrpt"
                disabled={evst >= 3 || punted || started ? true : false}
              >
                <div className="inner">
                  <div className="eopn">
                    <img
                      src={require(`../../../assets/icons/rpt-icon.png`)}
                      alt="Repunt"
                    />
                  </div>
                  <div className="eoprc">Repunt</div>
                </div>
              </button>
            </div>
            <div className="ptrt">
              <div className="ptdr">
                <span
                  className={!started ? "red" : ptst === "2" ? "red" : "gray"}
                >
                  {ptdr}
                  <br />
                  <img
                    src={
                      !started
                        ? require("../../../assets/image/star-icon-red.png")
                        : ptst === "2"
                        ? require("../../../assets/image/star-icon-red.png")
                        : require("../../../assets/image/star-icon-gray2.png")
                    }
                    alt="Down Rating"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      );
      if (!cclg.includes(ecclg)) cclg.push(ecclg);
    }

    if (allPunts.length === 0) return <div className="noev">No Punt(s)</div>;
    return allPunts;
  };

  return (
    <>
      <div className="elt1">
        <div className="plth">
          <span id="evdd">
            {!evTPPTData ? (
              <span className="animated-gradient"></span>
            ) : (
              evTPPTData.day
            )}
          </span>
          <span className="pltsw">
            <button
              id="evv1"
              className={`sw hbtn alt${eView === "Upcoming" ? " active" : ""}`}
              onClick={() => props.onClick(handleDateClick("Upcoming"))}
              disabled={
                evTPPTData ? (evTPPTData.day === "Today" ? false : true) : true
              }
            >
              Upcoming
            </button>
            <button
              id="evv2"
              className={`sw hbtn alt${eView === "All" ? " active" : ""}`}
              onClick={() => props.onClick(handleDateClick("All"))}
              disabled={
                evTPPTData ? (evTPPTData.day === "Today" ? false : false) : true
              }
            >
              All
            </button>
          </span>
        </div>
        <div className="cm-pd">{!evTPPTData ? plchldr() : punts()}</div>
      </div>
      <Routes>
        <Route path="/" element="" />
      </Routes>
    </>
  );
};

export default EVTopPT;
