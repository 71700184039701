import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/terms-and-conditions.css";

const TermsAndConditions = (props) => {
  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="tc-main">
        <div className="tc-bd">
          <div className="tc-m-hd">Terms and Conditions</div>
          <div className="tc-wc">
            Welcome to Peer2Punt - Thank you for visiting.
          </div>
          <div className="tc-lu">Last updated: March 12th, 2024</div>
          <div className="tc-hd">INTRODUCTION</div>
          <p>
            This is an agreement between you or the entity that you represent
            (“You” or “Your” or “User”) and CodeFlame Ltd (“CodeFlame Ltd” or
            "us" or "our" or "we") governing your use of CodeFlame Ltd Services
            ("Service" or "Services").
          </p>
          <p>
            This Agreement (the “Agreement”) consists of the following General
            Terms of Service (hereinafter “General Terms”) and the Terms of
            Service, such as&nbsp;
            <Link
              to="/terms-and-conditions"
              onClick={(e) =>
                props.onClick(
                  handleLink(
                    e,
                    "/terms-and-conditions",
                    "Terms and Conditions",
                    "Terms-and-Conditions"
                  )
                )
              }
            >
              Peer2Punt
            </Link>
            &nbsp; and other, that are specific to the use of individual
            Services (hereinafter “Service Specific Terms”), and constitutes an
            agreement between you and CodeFlame Ltd.
          </p>
          <p>
            The General Terms regulate the common provision of Services by
            CodeFlame Ltd to you ("you" or "your" or “User”) as an individual.
            Please read these General Terms carefully before using the Services,
            because they affect your legal rights and obligations.
          </p>
          <p>
            The General Terms and Service Specific Terms are collectively
            referred to as the “Terms”. In the event of a conflict between the
            General Terms and Service Specific Terms, the Service Specific Terms
            shall prevail.
          </p>
          <p>
            By using CodeFlame Ltd Services, you further represent and warrant
            that:
          </p>
          <ul>
            <li>you are at least 18 (eighteen) years old;</li>
            <li>
              you have will, full legal power, authority and capacity to enter
              into the Agreement;
            </li>
            <li>
              you agree to be bound by these Terms of Service, including by
              their future amendments if any;
            </li>
            <li>
              you are acting in compliance with the legislation of the
              jurisdiction you operate in, and with any other applicable
              legislation;
            </li>
            <li>
              in accordance with any law you have to comply with, it is not
              forbidden to use our Services;
            </li>
            <li>
              you will not use our Services for any purpose which is unlawful or
              prohibited by the Agreement;
            </li>
            <li>
              you have not previously been suspended or removed from using our
              Services;
            </li>
            <li>
              you have read, understand and completely agree to these Terms of
              Service as a whole, and to each of the Terms of Service provisions
              separately;
            </li>
            <li>
              all of your previous representations were made responsibly and you
              have fully understood the meaning of all sentences and separate
              words;
            </li>
          </ul>
          <p>
            Your use of the Services will be governed by the Terms, along with
            the peer2punt.com&nbsp;
            <Link
              to="/privacy-policy"
              onClick={(e) =>
                props.onClick(
                  handleLink(
                    e,
                    "/privacy-policy",
                    "Privacy Policy",
                    "Privacy-Policy"
                  )
                )
              }
            >
              Privacy Policy
            </Link>
            .
          </p>
          <div className="ts-hd">DEFINITIONS</div>
          <p>
            Agreement – agreement regarding the provision of Services concluded
            between CodeFlame Ltd as a service provider, and a User as a service
            consumer. These Terms of Service constitute the rules of the
            Agreement. The Agreement is deemed to have concluded between
            CodeFlame Ltd and a User, if a User has actually started to use the
            Services (finished the sign up process);
          </p>
          <p>
            peer2punt.com – Internet website of CodeFlame Ltd authentication
            system, where Users can:
          </p>
          <ul>
            <li>
              Create a common User Account that allows users to access all of
              the main functions of CodeFlame Ltd Services;
            </li>
            <li>Manage their cross platform User Account.</li>
          </ul>
          <p>General Terms – these terms of service;</p>
          <p>
            CodeFlame Ltd – limited liability company incorporated under the
            laws of Marshall Islands, with business number 84756;
          </p>
          <p>
            Services – all services which are provided by CodeFlame Ltd to the
            User under the rules of the Agreement through the peer2punt.com
            domain:&nbsp;
            <Link target="_blank" to="/welcome">peer2punt.com</Link>;
          </p>
          <p>
            Service Specific Terms – terms of service, specific to the use of
            individual Services;
          </p>
          <p>
            User – an individual who has an account (record) created within the
            peer2punt.com authentication system and who is actually using
            CodeFlame Ltd Services. Any User is deemed to have entered into the
            Agreement;
          </p>
          <p>
            User Account – a common account (record) created within the
            peer2punt.com authentication system and associated to the specific
            User which allows this User to access all CodeFlame Ltd Services;
          </p>
          <br />
          <div className="tc-hd">USING OUR SERVICES</div>
          <br />
          <div className="tc-sub">Registration and User Accounts</div>
          <p>
            Before access to the Services is granted to you, you need to create
            a User Account with the CodeFlame Ltd authentication system. During
            the account creation (sign up) process you will be asked to provide
            certain registration details, such as your email, nickname,
            password, etc. The list of mandatory information to be provided may
            be changed from time to time by CodeFlame Ltd unilaterally.
          </p>
          <p>
            All of the information you provide during the account creation
            process must be true, accurate, current, and complete. If we at
            CodeFlame Ltd believe that the provided information is not true,
            accurate, current, and/or complete, we have the right to refuse you
            access to our Services, and/or any of its resources, and/or to
            terminate and/or suspend your account without any explanations
            and/or refunds. We are limiting account creation attempts to one per
            24 hours.
          </p>
          <p>
            After the account is created, your e-mail must be verified. Email
            verification instructions will be sent to the Email you specified
            during the sign up process shortly after registration has been
            completed.
          </p>
          <p>
            After your email is verified you will be able to log into your
            account and access the Services, and all of the information from
            every CodeFlame Ltd Service will be displayed on your single profile
            page.
          </p>
          <p>
            While using the Services you may only act on your own behalf. Your
            account cannot be used to act as an intermediary or broker for any
            person or entity other than yourself unless specifically allowed by
            an additional agreement with CodeFlame Ltd.
          </p>
          <p>
            You may only use your own account to use CodeFlame Ltd Services. You
            may only use one User Account at a time for using Services unless
            specifically allowed by CodeFlame Ltd. You have to maintain adequate
            security and control of any and all IDs, passwords, personal
            identification numbers (PINs), and any other codes that you use to
            access Services, as well as all passwords you use to access your
            email address associated with your User Account. You are not allowed
            to sell, borrow, share or otherwise make available your account or
            any detail necessary to access your account to people or entities
            other than yourself. We may terminate or suspend your account if we
            discover that you have violated the Terms of Use.
          </p>
          <p>
            Your account must not contain misleading, inaccurate or fraudulent
            information, including but not limited to having a fake phone
            number, creating fake reputation information for your account,
            faking your residence state or providing fraudulent identification
            documents.
          </p>
          <div className="tc-sub">Identification and Verification</div>
          <p>
            If we are obliged to do so by the law or in accordance with our
            internal policy, we may at any time introduce or amend mandatory
            identification / verification procedures and require you to complete
            our identification and/or verification and may also require you to
            submit identification documents to CodeFlame Ltd. Failing to
            complete identification and/or verification will be considered a
            violation of the Agreement and may lead to the suspension and/or
            termination of your account.
          </p>
          <div className="tc-sub">Types of Accounts</div>
          <p>
            Currently, there are two types of accounts - regular account and
            verified account.
          </p>
          <p>
            “Regular” type of account means that a User who owns this account
            has only performed standard registration within the CodeFlame Ltd
            authentication system.
          </p>
          <p>
            "Verified" type of account means that a User who owns this account
            has performed the standard registration within the CodeFlame Ltd
            authentication system, and has also entered into an additional
            agreement with CodeFlame Ltd, and has provided all requested
            identification documents and information and his identity has been
            verified.
          </p>
          <div className="tc-sub">
            Limitation of Access, Suspension and Termination
          </div>
          <p>
            We may terminate, suspend, or limit your access to your User Account
            or to the Services we offer at our sole discretion, if:
          </p>
          <ul>
            <li>
              you violate (or have violated at any previous point in time) these
              General Terms and/or any agreement you enter into with CodeFlame
              Ltd;
            </li>
            <li>
              we have a reason to believe that your use of the Services violates
              any applicable law and/or regulations;
            </li>
            <li>
              we have a reason to believe that during the registration, and/or
              verification, and/or at any other point in time, you have provided
              us with false, inaccurate, incomplete, misleading and/or
              fraudulent information;
            </li>
            <li>
              you have not provided any information / document requested by us
              within a specified deadline and/or the documents provided are fake
              and/or misleading;
            </li>
            <li>
              you failed and/or refused and/or delayed to take actions
              prescribed by the decision of CodeFlame Ltd and/or refused to
              cooperate regarding the dispute you were involved in;
            </li>
            <li>
              you have performed an attempt (either successful or unsuccessful)
              of unauthorized access to any part or component of the Services,
              to bypass and/or break any security mechanism of the Services
              and/or use the Services in any other manner that poses a security
              risk to us and/or to any other User;
            </li>
            <li>
              you have performed an attempt (either successful or unsuccessful)
              to interrupt Services and/or damage CodeFlame Ltd owned software
              and/or hardware;
            </li>
            <li>
              we are required to do so by any applicable law and/or order issued
              by the state authority;
            </li>
            <li>you are unreasonably inactive during contracts;</li>
            <li>
              you have not finished the sign up and/or verification process;
            </li>
            <li>you have not used your account for more than 24 months;</li>
            <li>you have been reported by one or more users as a scammer.</li>
          </ul>
          <div className="tc-hd">INTELLECTUAL PROPERTY RIGHTS</div>
          <p>
            You acknowledge and agree that all intellectual property rights
            (inter alia copyright, trademarks and/or others) related to the
            peer2punt.com site and Services are owned by CodeFlame Ltd and/or
            our licensors. You are permitted to use the site and our services
            only as expressly authorized by us. We reserve all rights, not
            expressly granted, in and to this site, the services, and the
            content on this site.
          </p>
          <div className="tc-hd">
            DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
          </div>
          <p>
            You expressly agree that the use of CodeFlame Ltd Services is at
            your sole risk.
          </p>
          <p>
            ALL CODEFLAME LTD SERVICES ARE PROVIDED FOR YOUR USE ON AN “AS IS”
            AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATION OR ENDORSEMENT.
          </p>
          <p>We reserve the right to:</p>
          <ul>
            <li>
              modify any part of the CodeFlame Ltd Services and/or any software
              associated with them at any time at our sole discretion and
              without any notice;
            </li>
            <li>
              modify, alter, and/or discontinue any aspect of the Services at
              any time at our sole discretion and without any notice;
            </li>
          </ul>
          <p>
            YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
            YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION
            WITH THE SERVICES IS TO STOP USING THE SERVICES.
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, CODEFLAME LTD
            FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY
            KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING ANY IMPLIED
            WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, CODEFLAME LTD
            FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES THAT SERVICES WILL MEET
            YOUR REQUIREMENTS OR WILL BE UNINTERRUPTED, TIMELY, SECURE OR
            ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE
            SERVER WHICH MAKES IT AVAILABLE ARE FREE OF VIRUSES OR BUGS OR ARE
            FULLY FUNCTIONAL, ACCURATE, OR RELIABLE. WE WILL NOT BE RESPONSIBLE
            OR LIABLE TO YOU FOR ANY LOSS, LOST PROFITS, DESTRUCTION OR
            ALTERATION OF CONTENT OR MATERIAL AS A RESULT OF UPLOADING TO OR
            DOWNLOADING FROM THE SITE. CODEFLAME LTD DOES NOT GUARANTEE THE
            POSSIBILITY OF BEING ABLE TO USE THE SERVICES DUE TO CIRCUMSTANCES
            INDEPENDENT OF CODEFLAME LTD. SUCH AS CYBER ATTACK OR SERVER
            FAILURE.
          </p>
          <p>
            To the fullest extent permitted by law, in no event will CodeFlame
            Ltd, its officers, shareholders, employees, agents, directors,
            subsidiaries, affiliates, successors, assignors, suppliers, or
            licensors be liable for:
          </p>
          <ul>
            <li>
              any economic losses (including but not limited to, loss of
              revenues, profits, contracts, business or anticipated savings); or
            </li>
            <li>
              any indirect, special, incidental, punitive, exemplary, or
              consequential damages; or
            </li>
            <li>any loss of data, goodwill or reputation.</li>
          </ul>
          <p>
            To the maximum extent permitted by applicable law, the aggregate
            liability for all claims relating to CodeFlame Ltd Services in no
            event shall be more than the amounts paid by you to CodeFlame Ltd
            during the prior twelve months in question.
          </p>
          <p>
            CodeFlame Ltd will not be responsible for any loss or damage that
            could result from interception by third parties of any information
            made available to you via this site. Although the information
            provided to you on this site is obtained or compiled from sources we
            believe to be reliable, CodeFlame Ltd cannot and does not guarantee
            the accuracy, validity, timeliness or completeness of any
            information or data made available to you for any particular
            purpose.
          </p>
          <div className="tc-hd">INDEMNIFICATION FOR THIRD PARTY CLAIMS</div>
          <p>
            You agree to fully indemnify CodeFlame Ltd against, and hold
            harmless from, any and all claims, liabilities, losses, damages,
            costs, expenses, interest, awards, judgments, legal actions and
            penalties (including reasonable fees for outside counsel, lawyers,
            accountants and other outside consultants) suffered or incurred as a
            result of a third party claim against CodeFlame Ltd if such claim
            will be caused by your use of the Services (or use by any other
            person accessing the Services using your User Account) or your
            violation of any law or rights of any third party.
          </p>
          <div className="tc-hd">TRANSFER OF RIGHTS AND ASSIGNMENT</div>
          <p>
            CodeFlame Ltd is entitled to transfer or assign its rights and
            obligations under this Agreement to any person either natural or
            legal, at any time without notice to you. However, such transfer or
            assignment may not affect your rights or our obligations under this
            Agreement.
          </p>
          <p>
            At the same time, you may not transfer or assign any of your rights
            and / or obligations under this Agreement to any other person
            without our expressed written acceptance.
          </p>
          <div className="tc-hd">GENERAL PROVISIONS</div>
          <p>
            The client acknowledges that the official language of CodeFlame Ltd
            is the English language. In case of a conflict between the English
            language and any other language of our Services – English language
            shall prevail.
          </p>
          <p>
            This Agreement constitutes the entire agreement and understanding
            between you and the CodeFlame Ltd parties with respect to the
            subject matter of this Agreement and completely supersedes all prior
            oral and/or written agreements and understandings between you and
            CodeFlame Ltd before this Agreement with respect to the subject
            matter hereof.
          </p>
          <p>
            You hereby acknowledge and represent that you have not relied on any
            representation, assertion, guarantee, or other assurance, except
            those set out in this Agreement, made by or on behalf of any other
            party prior to the execution of this Agreement.
          </p>
          <p>
            You hereby certify that you have understood every single rule of the
            Agreement.
          </p>
          <p>
            If any provision of this Agreement is held invalid, the invalidity
            shall not affect other provisions of the Agreement which can be put
            into effect without the invalid provisions, and to this end, the
            provisions of this Agreement are declared to be severable.
          </p>
          <p>
            If you communicate with CodeFlame Ltd by email, you should note that
            the security of Internet email is uncertain. By sending sensitive or
            confidential email messages which are not encrypted you accept the
            risks of such uncertainty and the possible lack of confidentiality
            over the Internet. The Internet is not 100% safe and someone may be
            able to intercept and read your details.
          </p>
          <p>
            This site and the information, tools and material contained in it
            are not directed to, or intended for distribution to, or use by, any
            person or entity who is a citizen or resident of, or located in, any
            jurisdiction where such distribution, publication, availability or
            use would be contrary to law or regulation which would subject
            CodeFlame Ltd or its affiliates to any registration or licensing
            requirement within such jurisdiction.
          </p>
          <p>
            You agree that this Agreement and any dispute between you and us
            shall be governed in all respects by the laws of the United Kingdom,
            without regard to their choice-of-law provisions, and excluding the
            application of the 1980 U.N. Convention on Contracts for the
            International Sale of Goods. Except if prohibited, and not limited
            to, you agree that all disputes, claims and proceedings arising out
            of, or relating to the services, shall be resolved by the competent
            courts of London, United Kingdom. However, we shall always have the
            right to take legal proceedings in the court of competent
            jurisdiction of your domicile.
          </p>
          <p>
            We each agree that any dispute resolution proceedings will be
            conducted only on an individual basis and not in a class,
            consolidated or representative action. If for any reason a claim
            proceeds in court, we each waive any right to a jury trial. We also
            both agree that you or we may bring suit in court to enjoin
            infringement or other misuse of intellectual property rights.
          </p>
          <p>
            You will attempt to resolve all disputes with respect to the
            Services through communication with our customer service. If parties
            are unable to resolve the dispute, You must use the dispute
            resolution mechanism discussed above.
          </p>
          <p>
            To the fullest extent permitted by applicable law, you agree that
            any claim against CodeFlame Ltd must be commenced within one (1)
            year after the date, the party asserting the claim first knows or
            reasonably should know of the act, omission, or default giving rise
            to the claim; and there shall be no right to any remedy for any
            claim not asserted within that time period.
          </p>
          <div className="tc-hd">PRIVACY</div>
          <p>
            Any personal information you provide us will be treated as
            confidential and shared only within the Company, its affiliates and
            its business partners and will not be disclosed to any third party
            except under any regulatory or legal proceedings. Web site tracking
            systems may also collect data detailing the pages you have accessed,
            how you discovered our Services, the frequency of visits and so on.
            The information we obtain is used to improve the content of our
            Services and may be used by us to contact you, by any appropriate
            means, and to provide you with any information we believe may be
            useful to you.
          </p>
          <div className="tc-hd">HOW TO CONTACT US</div>
          <p>
            You can contact us at any time by sending the respective question,
            notice or request to our support team. We will always notify you
            about the delivery of your letter. In the event you have not
            received respective notification, please consider your letter
            undelivered and send it to us one more time. Please check your
            spam-filter settings.
          </p>
          <p>List of e-mails through which you can receive messages from us:</p>
          <ul>
            <li>
              <Link to="mailto:support@peer2punt.com" title="">
                support@peer2punt.com
              </Link>
            </li>
            <li>
              <Link to="mailto:administrator@peer2punt.com" title="">
                administrator@peer2punt.com
              </Link>
            </li>
            <li>
              <Link to="mailto:dispute@peer2punt.com" title="">
                dispute@peer2punt.com
              </Link>
            </li>
            <li>
              <Link to="mailto:no-reply@peer2punt.com" title="">
                no-reply@peer2punt.com
              </Link>
            </li>
            <li>
              <Link to="mailto:dpo@peer2punt.com" title="">
                dpo@peer2punt.com
              </Link>
            </li>
          </ul>
          <p>
            By using the Services, you agree that any notice, disclosure,
            agreement or other communication that we send to you electronically
            to the email address you have defined in your account settings will
            satisfy any legal communication requirements, including but not
            limited to, that such communications be in writing. You should
            maintain copies of electronic communications from us by printing a
            paper copy or saving an electronic copy.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
