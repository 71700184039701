import React from "react";
import { Link } from "react-router-dom";
import asc from "../assets/svg/svgexport-15.png";
import desc from "../assets/svg/svgexport-6.png";
import add from "../assets/svg/add.png";
import close from "../assets/svg/close.png";
import checked from "../assets/svg/svgexport-75.png";
import switchON from "../assets/svg/switch-on.png";
import switchOFF from "../assets/svg/switch-off.png";
import searchIcon from "../assets/svg/svgexport-93.png";
import infoerror from "../assets/svg/info-error.png";
import close2 from "../assets/svg/close-2.png";
import "../assets/css/create-offer.css";
import $ from "jquery";

const CreateSellOffer = (props) => {
  const uData = props.uData;
  const cyData = props.cyData;
  const cy = props.cy;
  const cyVal = cyData ? cyData.currencies[cy] : "";
  const pmData = props.pmData;
  const pm = props.pm;
  const pmVal = pm !== "pmAll" && pmData ? pmData.paymethods[pm] : "";
  const pmtData = props.pmtData;
  const pmt = props.pmt;
  const pmtVal = pmt !== "pmtAll" && pmtData ? pmtData.paytypes[pmt] : "";
  const pcData = props.pcData;
  const ohData = props.ohData;
  const pwData = props.pwData;
  const bmData = props.bmData;
  const opData = props.opData;
  const ulData = props.ulData;
  const ul = uData ? uData.total ? uData.user.ulv : 1 : 1;
  const oh = props.oh;
  const oh1 = oh[0];
  const oh2 = oh[1];
  const oh1Val = oh1 && ohData ? ohData.hours[oh1] : "";
  const oh2Val = oh2 && ohData ? ohData.hours[oh2] : "";
  const slt = props.slt;
  const odt = props.odt;
  const opMin = props.opMin;
  const opMax = props.opMax;
  const pc = props.pc;
  const pcVal = pc && pcData ? pcData.percentages[pc] : "";
  const myPM = props.myPM;
  const myPMData = props.myPMData;
  const crsErrors = props.crsErrors;
  const crsEMSG = props.crsEMSG;
  const crsSettings = props.crsSettings;
  const pw = crsSettings[2];
  const pwVal = pw && pwData ? pwData.paywindows[pw] : "";
  const bm = crsSettings[3];
  const bmVal = bm && bmData ? bmData.bookmakers[bm] : "";
  const crsTitle = props.crsTitle;
  const crsDesc = props.crsDesc;
  const ngnUSD = cyData ? cyData.ngnusd : 1220;
  const search = props.search;
  const uRating = uData ? uData.total ? uData.user.urt : 0 : 0;
  const ullNum = "ull" + ul;
  const ullVal = ulData ? ulData.levels[ullNum] : "";
  const ullIdeal = ullVal ? parseInt(ullVal.ullideal) : 1000;
  const urMaxPercent = Math.ceil((uRating / ullIdeal) * 100);
  const urPercent = urMaxPercent >= 100 ? 100 : urMaxPercent;

  const ult3l1maxid = ulData ? ulData.limits.ult3.ull1.ulmax : "pc1";
  const ult3l2maxid = ulData ? ulData.limits.ult3.ull2.ulmax : "pc1";
  const ult3l3maxid = ulData ? ulData.limits.ult3.ull3.ulmax : "pc1";
  const pcMax =
    parseInt(ul) < 3
      ? parseInt(ul) === 2
        ? ult3l2maxid
        : ult3l1maxid
      : ult3l3maxid;
  const pcMaxVal = pcData ? pcData.percentages[pcMax] : "";
  const pcMaxPrice = pcMaxVal ? pcMaxVal.pcprice : 0;
  const urMaxPrice = (urPercent * parseInt(pcMaxPrice)) / 100;

  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  const toggleSLT = (data) => {
    return { setSLT: data, setOVSLT: true };
  };

  const setSearch = (data, reset) => {
    if (reset) $(".options-search input").val("");
    data = data.length > 32 ? data.substring(0, 32) : data;
    return { setSearch: { data: data } };
  };

  const setCY = (data) => {
    return {
      setCY: data,
      setSLT: "none",
      setOVSLT: true,
      setCRSErrors: { indexes: [0], bool: false },
    };
  };

  const cyOptions = () => {
    const nodata = (
      <div className="c-b-item field pr-1">
        <div className="select disabled">
          <div className="select-val">-</div>
          <div className="select-pointer">
            <img src={desc} alt="Drop" />
          </div>
        </div>
      </div>
    );
    if (!cyData) return nodata;
    const allCurrencies = [];
    const total = cyData.total;
    const major = parseInt(cyData.major);
    const currencies = cyData.currencies;
    if (!total) return nodata;
    allCurrencies.push(
      <div key="sch1" className="options-search field">
        <span>
          <img src={searchIcon} alt="Search" />
        </span>
        <span>
          <input
            type="text"
            maxLength="32"
            placeholder="Search"
            autoComplete="false"
            onChange={(e) => props.onChange(setSearch(e.target.value))}
          />
          <button
            type="button"
            className="btn"
            onClick={(e) => props.onClick(setSearch("", true))}
          >
            <img src={close2} alt="Search" />
          </button>
        </span>
      </div>
    );
    let i = 0;
    for (let [key, val] of Object.entries(currencies)) {
      if (i === major) {
        allCurrencies.push(
          <span key="cygap2" className="option-val gap">
            <hr />
          </span>
        );
      }
      i++;
      let cycode = val.cycode;
      if (search) {
        let regex = new RegExp(search, "i");
        if (cycode.search(regex) < 0) continue;
      }
      allCurrencies.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            cy === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(setCY(key))}
        >
          {cycode}
        </button>
      );
    }

    return (
      <>
        <div className={`c-b-item field pr-1 ${crsErrors[0] ? "error" : ""}`}>
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("currency"))}
          >
            <div className="select-val">{cyVal ? cyVal.cycode : "-"}</div>
            <div className="select-pointer">
              <img src={slt === "currency" ? asc : desc} alt="Drop" />
            </div>
          </div>
        </div>
        {crsErrors[0] ? (
          <div>
            <div className="field-error error d-inline-flex">
              <img src={infoerror} alt="Info" />
              {crsEMSG.crsmsg0}
            </div>
          </div>
        ) : (
          ""
        )}
        {slt === "currency" ? (
          <div className="options">{allCurrencies}</div>
        ) : (
          ""
        )}
      </>
    );
  };

  const setODType = (data) => {
    return { setODType: data };
  };

  const setOPMin = (data) => {
    return {
      setOPMin: data,
      setOPMax: data,
      setSLT: "none",
      setOVSLT: true,
      setCRSErrors: { indexes: [1], bool: false },
    };
  };

  const setOPMax = (data) => {
    return {
      setOPMax: data,
      setSLT: "none",
      setOVSLT: true,
      setCRSErrors: { indexes: [2], bool: false },
    };
  };

  const getUSD = (amount) => {
    if (!cyData || !pcData) return "-";
    let cyUSD = cyVal.cyusd,
      pcprice = pcVal.pcprice,
      price = (parseInt(pcprice) / 100) * amount + parseFloat(amount),
      usdAmount = price / ngnUSD,
      newAmount = cyUSD * usdAmount;
    newAmount = parseFloat(newAmount.toFixed(2));
    return newAmount.toLocaleString("en-GB", 2);
  };

  const opMinVal = opData ? opData.prices[opMin] : "";
  const opMinPrice = opMinVal ? opMinVal.price : "";
  const minPrice = opMinPrice ? getUSD(opMinPrice) : "-";
  const opMaxVal = opData ? opData.prices[opMax] : "";
  const opMaxPrice = opMaxVal ? opMaxVal.price : "";
  const maxPrice = opMaxPrice ? getUSD(opMaxPrice) : "-";

  const ult4l1maxid = ulData ? ulData.limits.ult4.ull1.ulmax : "op1";
  const ult4l2maxid = ulData ? ulData.limits.ult4.ull2.ulmax : "op1";
  const ult4l3maxid = ulData ? ulData.limits.ult4.ull3.ulmax : "op1";
  const ulMaxOP =
    parseInt(ul) < 3
      ? parseInt(ul) === 2
        ? ult4l2maxid
        : ult4l1maxid
      : ult4l3maxid;

  const opOptions = () => {
    const nodata = (
      <div className="c-b-group-1">
        <div className="c-b-item field">
          <div className="select">
            <div className="select-val">-</div>
            <div className="select-pointer">
              <img src={desc} alt="Drop" />
            </div>
          </div>
          <span className="mi-currency">&nbsp;Odds</span>
          <span className="cag">- {cyVal ? cyVal.cycode : ""}</span>
        </div>
        {odt === "range" ? (
          <>
            &#8212;
            <div className="c-b-item field">
              <div className="select">
                <div className="select-val">-</div>
                <div className="select-pointer">
                  <img src={desc} alt="Drop" />
                </div>
              </div>
              <span className="mi-currency">&nbsp;Odds</span>
              <span className="cag">- {cyVal ? cyVal.cycode : ""}</span>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
    if (!opData) return nodata;
    const allMinOdds = [];
    const allMaxOdds = [];
    const total = opData.total;
    const prices = opData.prices;
    if (!total) return nodata;
    for (let [key, val] of Object.entries(prices)) {
      let opid = key.substring(2),
        min = val.min,
        max = val.max,
        opMinid = opMin.substring(2),
        ulMaxOPID = ulMaxOP.substring(2);
      if (parseInt(opid) > parseInt(ulMaxOPID)) continue;
      allMinOdds.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            opMin === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(setOPMin(key))}
        >
          {min}
        </button>
      );
      if (parseInt(opid) < parseInt(opMinid)) continue;
      allMaxOdds.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            opMax === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(setOPMax(key))}
        >
          {max}
        </button>
      );
    }

    return (
      <>
        <div className="c-b-group-1">
          <div>
            <div
              className={`c-b-item field d-flex ${crsErrors[1] ? "error" : ""}`}
            >
              <div
                className="select"
                onClick={(e) => props.onClick(toggleSLT("minodd"))}
              >
                <div className="select-val">
                  {opMinVal ? opMinVal.min : "-"}
                </div>
                <div className="select-pointer">
                  <img src={slt === "minodd" ? asc : desc} alt="Drop" />
                </div>
              </div>
              <span className="mi-currency">&nbsp;Odds</span>
              <span className="cag">
                {minPrice} {cyVal ? cyVal.cycode : ""}
              </span>
            </div>
            {slt === "minodd" ? (
              <div className="options">{allMinOdds}</div>
            ) : (
              ""
            )}
          </div>
          {odt === "range" ? (
            <>
              &#8212;
              <div>
                <div
                  className={`c-b-item field d-flex ${
                    crsErrors[2] ? "error" : ""
                  }`}
                >
                  <div
                    className="select"
                    onClick={(e) => props.onClick(toggleSLT("maxodd"))}
                  >
                    <div className="select-val">
                      {opMaxVal ? opMaxVal.max : "-"}
                    </div>
                    <div className="select-pointer">
                      <img src={slt === "maxodd" ? asc : desc} alt="Drop" />
                    </div>
                  </div>
                  <span className="mi-currency">&nbsp;Odds</span>
                  <span className="cag">
                    {maxPrice} {cyVal ? cyVal.cycode : ""}
                  </span>
                </div>
                {slt === "maxodd" ? (
                  <div className="options">{allMaxOdds}</div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {crsErrors[1] ? (
          <div className="field-error error d-inline-flex mr-1">
            <img src={infoerror} alt="Info" />
            {crsEMSG.crsmsg1}
          </div>
        ) : (
          ""
        )}
        {odt === "range" && crsErrors[2] ? (
          <div className="field-error error d-inline-flex">
            <img src={infoerror} alt="Info" />
            {crsEMSG.crsmsg2}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const setPC = (data) => {
    return {
      setPC: data,
      setSLT: "none",
      setOVSLT: true,
      setCRSErrors: { indexes: [3], bool: false },
    };
  };

  const pcOptions = () => {
    const nodata = (
      <div className="c-b-item field pr-1">
        <div className="select disabled">
          <div className="select-val">-</div>
          <div className="select-pointer">
            <img src={desc} alt="Drop" />
          </div>
        </div>
      </div>
    );
    if (!pcData) return nodata;
    const allPercentages = [];
    const total = pcData.total;
    const percentages = pcData.percentages;
    if (!total) return nodata;
    for (let [key, val] of Object.entries(percentages)) {
      let pcprice = val.pcprice;
      if(parseFloat(pcprice) > parseFloat(urMaxPrice)) continue;
      allPercentages.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            pc === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(setPC(key))}
        >
          +{pcprice}
        </button>
      );
    }

    return (
      <>
        <div className={`c-b-item field ${crsErrors[3] ? "error" : ""}`}>
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("price"))}
          >
            <div className="select-val">+{pcVal ? pcVal.pcprice : "-"}</div>
            <div className="select-pointer">
              <img src={slt === "price" ? asc : desc} alt="Drop" />
            </div>
          </div>
          <span className="mi-currency">&nbsp;%</span>
        </div>
        {crsErrors[3] ? (
          <div>
            <div className="field-error error d-inline-flex">
              <img src={infoerror} alt="Info" />
              {crsEMSG.crsmsg3}
            </div>
          </div>
        ) : (
          ""
        )}
        {slt === "price" ? <div className="options">{allPercentages}</div> : ""}
      </>
    );
  };

  const setPMT = (pmtid) => {
    return { setPMT: pmtid, setPM: "pmAll", setSLT: "none", setOVSLT: true };
  };

  const pmtOptions = (j) => {
    const nodata = (
      <div>
        <div className="c-b-item field d-flex pr-1">
          <div className="select disabled">
            <div className="select-val">-</div>
            <div className="select-pointer">
              <img src={desc} alt="Drop" />
            </div>
          </div>
        </div>
      </div>
    );
    if (!pmtData) return nodata;
    const allPayTypes = [];
    const total = pmtData.total;
    const paytypes = pmtData.paytypes;
    if (!total) return nodata;
    allPayTypes.push(
      <button
        key={`pmt0`}
        className={`btn btn-special-alt-3 option-val ${
          pmt === "pmtAll" ? "active" : ""
        }`}
        onClick={(e) => props.onClick(setPMT("pmtAll"))}
      >
        All payment types
      </button>
    );
    for (let [key, val] of Object.entries(paytypes)) {
      let pmtname = val.pmtname;
      allPayTypes.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            pmt === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(setPMT(key))}
        >
          {pmtname}
        </button>
      );
    }

    return (
      <div>
        <div className="c-b-item field d-flex pr-1">
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("paytype"))}
          >
            <div className="select-val">
              {pmt !== "pmtAll"
                ? pmtVal
                  ? pmtVal.pmtname
                  : "-"
                : "All payment types"}
            </div>
            <div className="select-pointer">
              <img src={slt === "paytype" ? asc : desc} alt="Drop" />
            </div>
          </div>
        </div>
        {slt === "paytype" ? <div className="options">{allPayTypes}</div> : ""}
      </div>
    );
  };

  const setPM = (pmid, pmtid) => {
    return {
      setPM: pmid,
      setPMT: pmtid,
      setSLT: "none",
      setOVSLT: true,
    };
  };

  const pmOptions = () => {
    const nodata = (
      <div>
        <div className="c-b-item field d-flex pr-1">
          <div className="select disabled">
            <div className="select-val">-</div>
            <div className="select-pointer">
              <img src={desc} alt="Drop" />
            </div>
          </div>
        </div>
      </div>
    );
    if (!pmData) return nodata;
    const allPayMethods = [];
    const total = pmData.total;
    const major = parseInt(pmData.major);
    const paymethods = pmData.paymethods;
    if (!total) return nodata;
    allPayMethods.push(
      <button
        key={`pm0`}
        className={`btn btn-special-alt-3 option-val ${
          pm === "pmAll" ? "active" : ""
        }`}
        onClick={(e) => props.onClick(setPM("pmAll"))}
      >
        Choose a method
      </button>
    );
    allPayMethods.push(
      <span key="pmgap1" className="option-val gap">
        <hr />
      </span>
    );
    allPayMethods.push(
      <div key="sch1" className="options-search field">
        <span>
          <img src={searchIcon} alt="Search" />
        </span>
        <span>
          <input
            type="text"
            maxLength="32"
            placeholder="Search"
            autoComplete="false"
            onChange={(e) => props.onChange(setSearch(e.target.value))}
          />
          <button
            type="button"
            className="btn"
            onClick={(e) => props.onClick(setSearch("", true))}
          >
            <img src={close2} alt="Search" />
          </button>
        </span>
      </div>
    );
    let i = 0;
    for (let [key, val] of Object.entries(paymethods)) {
      if (i === major) {
        allPayMethods.push(
          <span key="pmgap2" className="option-val gap">
            <hr />
          </span>
        );
      }
      i++;
      let pmname = val.pmname,
        pmtype = val.pmtype;
      if (pmt !== "pmtAll") {
        if (pmt !== pmtype) continue;
      }
      if (myPM.includes(key)) continue;
      if (search) {
        let regex = new RegExp(search, "i");
        if (pmname.search(regex) < 0) continue;
      }
      allPayMethods.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            pm === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(setPM(key, pmtype))}
        >
          {pmname}
        </button>
      );
    }

    return (
      <div>
        <div
          className={`c-b-item field d-flex pr-1 ${
            crsErrors[4] ? "error" : ""
          }`}
        >
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("paymethod"))}
          >
            <div
              className={`select-val ${
                !myPM.length && pm === "pmAll" ? "golden" : ""
              }`}
            >
              {pm !== "pmAll"
                ? pmVal
                  ? pmVal.pmname
                  : "-"
                : "Choose a method"}
            </div>
            <div className="select-pointer">
              <img src={slt === "paymethod" ? asc : desc} alt="Drop" />
            </div>
          </div>
        </div>
        {slt === "paymethod" ? (
          <div className="options">{allPayMethods}</div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const addPM = () => {
    myPM.push(pm);
    myPMData.push({ id: pm, pm: pmVal.pmname, pmt: pmtVal.pmtname });
    return {
      addPM: myPM,
      addPMData: myPMData,
      setPMT: "pmtAll",
      setPM: "pmAll",
      setCRSErrors: { indexes: [4], bool: false },
    };
  };

  const removePM = (index) => {
    let indexes = [5, 6, 7, 8, 9, 10, 11];
    return {
      removePM: { index: index },
      setPMT: "pmtAll",
      setPM: "pmAll",
      setCRSErrors: { indexes: indexes, bool: false },
    };
  };

  const myPMs = () => {
    return myPMData.map((val, index) => (
      <div key={`pay${index}`} className="crt-body payment">
        <div className="c-b-hd">
          <span>Payment method {index + 1}</span>
        </div>
        <div className="c-b-group-2">
          <div>
            <div className="c-b-item field d-flex pr-1">
              <div className="select disabled">
                <div className="select-val">{val.pmt}</div>
                <div className="select-pointer">
                  <img src={desc} alt="Drop" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`c-b-item field d-flex pr-1 ${
                crsErrors[index + 5] ? "error" : ""
              }`}
            >
              <div className="select disabled">
                <div className="select-val">{val.pm}</div>
                <div className="select-pointer">
                  <img src={desc} alt="Drop" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              className="btn btn-red-alt-2 newpm"
              title={`Remove payment method ${index + 1}:  ${val.pm}`}
              onClick={(e) => props.onClick(removePM(index))}
            >
              <span>
                <img src={close} alt="Close" />
              </span>
              <span className="btn-tag">
                &nbsp;<span>Remove</span>
              </span>
            </button>
          </div>
        </div>
        {crsErrors[index + 5] ? (
          <div>
            <div className="field-error error">
              <img src={infoerror} alt="Info" />
              {index + 5 < 11
                ? index + 5 < 10
                  ? index + 5 < 9
                    ? index + 5 < 8
                      ? index + 5 < 7
                        ? index + 5 < 6
                          ? crsEMSG.crsmsg5
                          : crsEMSG.crsmsg6
                        : crsEMSG.crsmsg7
                      : crsEMSG.crsmsg8
                    : crsEMSG.crsmsg9
                  : crsEMSG.crsmsg10
                : crsEMSG.crsmsg11}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    ));
  };

  const myPMs2 = () => {
    return myPMData.map((val, index) => (
      <span key={`pay-${index}`} className="offer-method">
        {val.pm}
      </span>
    ));
  };

  const changeSetting = (index, val, select) => {
    let indexes =
      index !== 3 ? (index !== 2 ? (index !== 0 ? [] : [12]) : [13]) : [14];
    return {
      setCRSSettings: {
        index: index,
        val: val,
        select: select,
      },
      setCRSErrors: { indexes: indexes, bool: false },
    };
  };

  const setOH = (index, val, val2 = "") => {
    return {
      setOH: { index: index, val: val, val2: val2 },
      setSLT: "none",
      setOVSLT: true,
      setCRSErrors: { indexes: [12], bool: false },
    };
  };

  const ohRange1 = () => {
    const nodata = (
      <div>
        <div className="c-b-item field d-flex pr-1">
          <div className="select disabled">
            <div className="select-val">00:00</div>
            <div className="select-pointer">
              <img src={desc} alt="Drop" />
            </div>
          </div>
        </div>
      </div>
    );
    if (!ohData) return nodata;
    if (crsSettings[0]) return nodata;
    const allHours = [];
    const total = ohData.total;
    const hours = ohData.hours;
    if (!total) return nodata;
    for (let [key, val] of Object.entries(hours)) {
      let ohid = key.substring(2),
        oh_2 = parseInt(ohid) + 1,
        hour = val.hour;
      allHours.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            oh1 === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(setOH(0, key, `oh${oh_2}`))}
        >
          {hour}
        </button>
      );
    }

    return (
      <div>
        <div
          className={`c-b-item field d-flex pr-1 ${
            crsErrors[12] ? "error" : ""
          }`}
        >
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("availfrom"))}
          >
            <div className="select-val">{oh1Val ? oh1Val.hour : "--"}</div>
            <div className="select-pointer">
              <img src={slt === "availfrom" ? asc : desc} alt="Drop" />
            </div>
          </div>
        </div>
        {slt === "availfrom" ? <div className="options">{allHours}</div> : ""}
      </div>
    );
  };

  const ohRange2 = () => {
    const nodata = (
      <div>
        <div className="c-b-item field d-flex pr-1">
          <div className="select disabled">
            <div className="select-val">00:00</div>
            <div className="select-pointer">
              <img src={desc} alt="Drop" />
            </div>
          </div>
        </div>
      </div>
    );
    if (!ohData) return nodata;
    if (crsSettings[0]) return nodata;
    const allHours = [];
    const total = ohData.total;
    const hours = ohData.hours;
    if (!total) return nodata;
    for (let [key, val] of Object.entries(hours)) {
      let ohid = key.substring(2),
        hour = val.hour,
        oh1id = oh1.substring(2);
      if (parseInt(ohid) <= parseInt(oh1id)) continue;
      allHours.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            oh2 === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(setOH(1, key))}
        >
          {hour}
        </button>
      );
    }

    return (
      <div>
        <div
          className={`c-b-item field d-flex pr-1 ${
            crsErrors[12] ? "error" : ""
          }`}
        >
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("availto"))}
          >
            <div className="select-val">{oh2Val ? oh2Val.hour : "--"}</div>
            <div className="select-pointer">
              <img src={slt === "availto" ? asc : desc} alt="Drop" />
            </div>
          </div>
        </div>
        {slt === "availto" ? <div className="options">{allHours}</div> : ""}
      </div>
    );
  };

  const pwOptions = () => {
    const nodata = (
      <div className="c-b-item field pr-1">
        <div className="select disabled">
          <div className="select-val">-</div>
          <div className="select-pointer">
            <img src={desc} alt="Drop" />
          </div>
        </div>
      </div>
    );
    if (!pwData) return nodata;
    const allMins = [];
    const total = pwData.total;
    const paywindows = pwData.paywindows;
    if (!total) return nodata;
    for (let [key, val] of Object.entries(paywindows)) {
      let pwminute = val.pwminute;
      allMins.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            crsSettings[2] === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(changeSetting(2, key, true))}
        >
          {pwminute}
        </button>
      );
    }

    return (
      <>
        <div className={`c-b-item field ${crsErrors[13] ? "error" : ""}`}>
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("paywindow"))}
          >
            <div className="select-val">{pwVal ? pwVal.pwminute : "-"}</div>
            <div className="select-pointer">
              <img src={slt === "paywindow" ? asc : desc} alt="Drop" />
            </div>
          </div>
          <span className="mi-currency">&nbsp;Min</span>
        </div>
        {crsErrors[13] ? (
          <div>
            <div className="field-error error d-inline-flex">
              <img src={infoerror} alt="Info" />
              {crsEMSG.crsmsg13}
            </div>
          </div>
        ) : (
          ""
        )}
        {slt === "paywindow" ? <div className="options">{allMins}</div> : ""}
      </>
    );
  };

  const bmOptions = () => {
    const nodata = (
      <div className="c-b-item field pr-1">
        <div className="select disabled">
          <div className="select-val">-</div>
          <div className="select-pointer">
            <img src={desc} alt="Drop" />
          </div>
        </div>
      </div>
    );
    if (!bmData) return nodata;
    const allBM = [];
    const total = bmData.total;
    const bookmakers = bmData.bookmakers;
    if (!total) return nodata;
    allBM.push(
      <div key="sch1" className="options-search field">
        <span>
          <img src={searchIcon} alt="Search" />
        </span>
        <span>
          <input
            type="text"
            maxLength="32"
            placeholder="Search"
            autoComplete="false"
            onChange={(e) => props.onChange(setSearch(e.target.value))}
          />
          <button
            type="button"
            className="btn"
            onClick={(e) => props.onClick(setSearch("", true))}
          >
            <img src={close2} alt="Search" />
          </button>
        </span>
      </div>
    );
    for (let [key, val] of Object.entries(bookmakers)) {
      let bmname = val.bmname;
      if (search) {
        let regex = new RegExp(search, "i");
        if (bmname.search(regex) < 0) continue;
      }
      allBM.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            crsSettings[3] === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(changeSetting(3, key, true))}
        >
          {bmname}
        </button>
      );
    }

    return (
      <>
        <div className={`c-b-item field pr-1 ${crsErrors[14] ? "error" : ""}`}>
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("bookmaker"))}
          >
            <div className="select-val">{bmVal ? bmVal.bmname : "-"}</div>
            <div className="select-pointer">
              <img src={slt === "bookmaker" ? asc : desc} alt="Drop" />
            </div>
          </div>
        </div>
        {crsErrors[14] ? (
          <div>
            <div className="field-error error d-inline-flex">
              <img src={infoerror} alt="Info" />
              {crsEMSG.crsmsg14}
            </div>
          </div>
        ) : (
          ""
        )}
        {slt === "bookmaker" ? <div className="options">{allBM}</div> : ""}
      </>
    );
  };

  const setCRSTitle = (data) => {
    data = data.length > 100 ? data.substring(0, 100) : data;
    return {
      setCRSTitle: { data: data },
      setCRSErrors: { indexes: [15], bool: false },
    };
  };

  const setCRSDesc = (data) => {
    data = data.length > 5000 ? data.substring(0, 5000) : data;
    return {
      setCRSDesc: { data: data },
      setCRSErrors: { indexes: [16], bool: false },
    };
  };

  const createSell = () => {
    return { createSell: true };
  };

  return (
    <>
      <div className="new-design">
        New design release! Better trading experience. Enjoy! &#10084;
      </div>
      <div className="crtoffer-main">
        <div className="crtoffer-hd">Create New Offer</div>
        <div className="crtoffer-body">
          <div className="crtoffer-header">
            <button className="btn btn-gold-alt header-item">
              I want to buy Bet
            </button>
            <button className="btn btn-gold-alt header-item active">
              I want to sell Bet
            </button>
          </div>
          <div className="crtoffer-box">
            <div className="co-box-1">
              <div className="crt-box-item">
                <div className="crt-title">
                  <span className="c-t-item">Currency</span>
                </div>
                <div className="crt-body">{cyOptions()}</div>
              </div>
              <div className="crt-box-item">
                <div className="crt-title">
                  <span className="c-t-item">
                    Amount of odds you want to sell
                  </span>
                  <span className="c-t-item ctg p-1">
                    <button
                      className={`btn btn-gold-alt ${
                        odt === "fixed" ? "active" : ""
                      }`}
                      onClick={(e) => props.onClick(setODType("fixed"))}
                    >
                      Fixed
                    </button>
                    <button
                      className={`btn btn-gold-alt ${
                        odt === "range" ? "active" : ""
                      }`}
                      onClick={(e) => props.onClick(setODType("range"))}
                    >
                      Range
                    </button>
                  </span>
                </div>
                <div className="crt-body">{opOptions()}</div>
              </div>
              <div className="crt-box-item">
                <div className="crt-title">
                  <span className="c-t-item">Price</span>
                </div>
                <div className="c-b-info">
                  Your current price limit is +{urMaxPrice}%.
                  {parseFloat(urMaxPrice) < parseFloat(pcMaxPrice)
                    ? " Gain more Peer2Punt Ratings to increase your price limit up to +" +
                      pcMaxPrice +
                      "%."
                    : ""}
                  &nbsp;
                  <Link
                    to="/faq/limits"
                    onClick={(e) =>
                      props.onClick(handleLink(e, "/faq", "FAQ", "Faq"))
                    }
                  >
                    Learn more about limits
                  </Link>
                </div>
                <div className="crt-body">{pcOptions()}</div>
              </div>
              <div className="crt-box-item">
                <div className="crt-title">
                  <span className="c-t-item">Your payment methods</span>
                </div>
                {myPMs()}
                {myPM.length < 7 ? (
                  <div className="crt-body payment">
                    <div className="c-b-hd">
                      <span>Add payment method</span>
                    </div>
                    <div className="c-b-group-2">
                      {pmtOptions()}
                      {pmOptions()}
                      <div className="pm-action">
                        {myPM.length < 7 ? (
                          pm === "pmAll" ? (
                            <button
                              className="btn btn-gold-alt-2 newpm"
                              disabled
                            >
                              <span>
                                <img src={add} alt="Add" />
                              </span>
                              <span className="btn-tag">
                                &nbsp;<span>Add</span>
                              </span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-gold-alt-2 newpm"
                              title="Add payment method"
                              onClick={(e) => props.onClick(addPM())}
                            >
                              <span>
                                <img src={add} alt="Add" />
                              </span>
                              <span className="btn-tag">
                                &nbsp;<span>Add</span>
                              </span>
                            </button>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {crsErrors[4] ? (
                      <div>
                        <div className="field-error error">
                          <img src={infoerror} alt="Info" />
                          {crsEMSG.crsmsg4}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="crt-box-item desktop-box nobb">
                <div className="c-b-group-6">
                  <div className="c-b-switch">
                    {!crsSettings[4] ? (
                      <button
                        className="switch-icon btn"
                        onClick={(e) => props.onClick(changeSetting(4, true))}
                      >
                        <img src={switchOFF} alt="Switch" />
                      </button>
                    ) : (
                      <button
                        className="switch-icon btn"
                        onClick={(e) => props.onClick(changeSetting(4, false))}
                      >
                        <img src={switchON} alt="Switch" />
                      </button>
                    )}
                  </div>
                  <div className="crt-box-item nobb">
                    <div className="c-b-tt">Enable offer after creation</div>
                    <div className="c-b-info">
                      Your offer will be active and available to other users.
                      Disable to save as a draft.
                    </div>
                  </div>
                </div>
                <div className="c-b-group-6">
                  <div className="c-b-switch">
                    {!crsSettings[4] ? (
                      !crsSettings[5] ? (
                        <button
                          key="enb1"
                          className="switch-icon btn"
                          onClick={(e) => props.onClick(changeSetting(5, true))}
                        >
                          <img src={switchOFF} alt="Switch" />
                        </button>
                      ) : (
                        <button
                          key="enb2"
                          className="switch-icon btn"
                          onClick={(e) =>
                            props.onClick(changeSetting(5, false))
                          }
                        >
                          <img src={switchON} alt="Switch" />
                        </button>
                      )
                    ) : (
                      <button key="enb3" className="switch-icon btn" disabled>
                        <img src={switchOFF} alt="Switch" />
                      </button>
                    )}
                  </div>
                  <div className="crt-box-item nobb">
                    <div className="c-b-tt">Make offer private</div>
                    <div className="c-b-info">
                      Your offer will only be accessible by the link and not
                      displayed in the public offer.
                    </div>
                  </div>
                </div>
                <div className="c-b-group-7">
                  {!myPM.length ? (
                    <button className="btn btn-gold newpm" disabled>
                      Create offer to sell Bet
                    </button>
                  ) : (
                    <button
                      className="btn btn-gold newpm"
                      onClick={(e) => props.onClick(createSell())}
                    >
                      Create offer to sell Bet
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="co-box-2">
              <div className="crt-box-item">
                <div className="c-b-tt">Available hours</div>
                <div className="c-b-info">
                  Define the timeframes for an offer to be active. An offer is
                  automatically disabled if you have not been online for 10
                  days.
                </div>
                <div className={`c-b-group-3 ${crsErrors[12] ? "mb-1" : ""}`}>
                  <div className="c-b-item">
                    {!crsSettings[0] ? (
                      <button
                        className="checkmark-icon btn"
                        onClick={(e) => props.onClick(changeSetting(0, true))}
                      ></button>
                    ) : (
                      <button
                        className="checkmark-icon btn checked"
                        onClick={(e) => props.onClick(changeSetting(0, false))}
                      >
                        <img src={checked} alt="Checked" />
                      </button>
                    )}
                  </div>
                  {!crsSettings[0] ? (
                    <div
                      className="c-b-item"
                      onClick={(e) => props.onClick(changeSetting(0, true))}
                    >
                      24 hours access
                    </div>
                  ) : (
                    <div
                      className="c-b-item"
                      onClick={(e) => props.onClick(changeSetting(0, false))}
                    >
                      24 hours access
                    </div>
                  )}
                  {ohRange1()}
                  &#8212;
                  {ohRange2()}
                </div>
                {crsErrors[12] ? (
                  <div className="field-error error mb-3">
                    <img src={infoerror} alt="Info" />
                    {crsEMSG.crsmsg12}
                  </div>
                ) : (
                  ""
                )}
                <div className="c-b-group-4">
                  <div className="c-b-item">
                    {!crsSettings[1] ? (
                      <button
                        className="checkmark-icon btn"
                        onClick={(e) => props.onClick(changeSetting(1, true))}
                      ></button>
                    ) : (
                      <button
                        className="checkmark-icon btn checked"
                        onClick={(e) => props.onClick(changeSetting(1, false))}
                      >
                        <img src={checked} alt="Checked" />
                      </button>
                    )}
                  </div>
                  {!crsSettings[1] ? (
                    <div
                      className="c-b-item"
                      onClick={(e) => props.onClick(changeSetting(1, true))}
                    >
                      Workdays only.{" "}
                      <span className="item-info">
                        Offer will be disabled on Saturdays and Sundays.
                      </span>
                    </div>
                  ) : (
                    <div
                      className="c-b-item"
                      onClick={(e) => props.onClick(changeSetting(1, false))}
                    >
                      Workdays only.{" "}
                      <span className="item-info">
                        Offer will be disabled on Saturdays and Sundays.
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="crt-box-item">
                <div className="c-b-tt">Payment window</div>
                <div className="c-b-info">
                  The time within which the Seller must receive the payment from
                  the Seller. When the time runs out, a dispute can be started.
                </div>
                {pwOptions()}
              </div>
              <div className="crt-box-item">
                <div className="c-b-tt">Preferred bookmaker</div>
                <div className="c-b-info">
                  Choose the bookmaker whose odds will be used for the Bet.
                </div>
                <div className="c-b-item">{bmOptions()}</div>
              </div>
              <div className="crt-box-item">
                <div className="c-b-tt">Offer description</div>
                <div className="c-b-item mb-1">Title</div>
                <div
                  className={`c-b-item field d-flex ${
                    crsErrors[15] ? "error" : ""
                  }`}
                >
                  <input
                    type="text"
                    name="offertitle"
                    id="offertitle"
                    maxLength="100"
                    autoComplete="false"
                    defaultValue={crsTitle}
                    onChange={(e) =>
                      props.onChange(setCRSTitle(e.target.value))
                    }
                  />
                </div>
                {crsErrors[15] ? (
                  <div className="field-error error">
                    <img src={infoerror} alt="Info" />
                    {crsEMSG.crsmsg15}
                  </div>
                ) : (
                  ""
                )}
                <div className="c-b-note dual">
                  <span>Displayed in offer list</span>
                  <span>{crsTitle ? crsTitle.length : "0"}/100</span>
                </div>
                <div className="c-b-item mb-1">Description</div>
                <div
                  className={`c-b-item field d-flex auto-h ${
                    crsErrors[16] ? "error" : ""
                  }`}
                >
                  <textarea
                    name="offerdesc"
                    id="offerdesc"
                    maxLength="5000"
                    rows="4"
                    defaultValue={crsDesc}
                    onChange={(e) => props.onChange(setCRSDesc(e.target.value))}
                  ></textarea>
                </div>
                {crsErrors[16] ? (
                  <div className="field-error error">
                    <img src={infoerror} alt="Info" />
                    {crsEMSG.crsmsg16}
                  </div>
                ) : (
                  ""
                )}
                <div className="c-b-note">
                  <span>{crsDesc ? crsDesc.length : 0}/5000</span>
                </div>
              </div>
              <div className="crt-box-item nobb">
                <div className="co-type-hd">Offer to sell Bet</div>
                <div className="c-b-group-5">
                  <span className="item-info">Price</span>
                  <span className="item-data">
                    +{pcVal ? pcVal.pcprice : "-"}%
                  </span>
                </div>
                <div className="c-b-group-5">
                  <span className="item-info">Amount</span>
                  <span className="item-data">
                    <div className="mb-1">
                      <span>{opMinVal ? opMinVal.min : "-"} Odds</span>
                      {odt === "range" ? (
                        <span>
                          &nbsp;&nbsp;&#8212;&nbsp;&nbsp;
                          {opMaxVal ? opMaxVal.max : "-"} Odds
                        </span>
                      ) : (
                        ""
                      )}{" "}
                    </div>
                    <div className="mb-1 golden">
                      <span>
                        {minPrice} {cyVal ? cyVal.cycode : ""}
                      </span>
                      {odt === "range" ? (
                        <span>
                          &nbsp;&nbsp;&#8212;&nbsp;&nbsp;{maxPrice}{" "}
                          {cyVal ? cyVal.cycode : ""}
                        </span>
                      ) : (
                        ""
                      )}{" "}
                    </div>
                  </span>
                </div>
                <div className="c-b-group-5 offer-methods">
                  <span className="item-info">Payment methods</span>
                  <span className="item-data">{myPMs2()}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="crtoffer-box mobile-box">
            <div className="co-box-1">
              <div className="c-b-group-6">
                <div className="c-b-switch">
                  {!crsSettings[4] ? (
                    <button
                      className="switch-icon btn"
                      onClick={(e) => props.onClick(changeSetting(4, true))}
                    >
                      <img src={switchOFF} alt="Switch" />
                    </button>
                  ) : (
                    <button
                      className="switch-icon btn"
                      onClick={(e) => props.onClick(changeSetting(4, false))}
                    >
                      <img src={switchON} alt="Switch" />
                    </button>
                  )}
                </div>
                <div className="crt-box-item nobb">
                  <div className="c-b-tt">Enable offer after creation</div>
                  <div className="c-b-info">
                    Your offer will be active and available to other users.
                    Disable to save as a draft.
                  </div>
                </div>
              </div>
              <div className="c-b-group-6">
                <div className="c-b-switch">
                  {!crsSettings[4] ? (
                    !crsSettings[5] ? (
                      <button
                        key="enb1"
                        className="switch-icon btn"
                        onClick={(e) => props.onClick(changeSetting(5, true))}
                      >
                        <img src={switchOFF} alt="Switch" />
                      </button>
                    ) : (
                      <button
                        key="enb2"
                        className="switch-icon btn"
                        onClick={(e) => props.onClick(changeSetting(5, false))}
                      >
                        <img src={switchON} alt="Switch" />
                      </button>
                    )
                  ) : (
                    <button key="enb3" className="switch-icon btn" disabled>
                      <img src={switchOFF} alt="Switch" />
                    </button>
                  )}
                </div>
                <div className="crt-box-item nobb">
                  <div className="c-b-tt">Make offer private</div>
                  <div className="c-b-info">
                    Your offer will only be accessible by the link and not
                    displayed in the public offer.
                  </div>
                </div>
              </div>
              <div className="c-b-group-7">
                {!myPM.length ? (
                  <button className="btn btn-gold newpm" disabled>
                    Create offer to sell Bet
                  </button>
                ) : (
                  <button
                    className="btn btn-gold newpm"
                    onClick={(e) => props.onClick(createSell())}
                  >
                    Create offer to sell Bet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSellOffer;
