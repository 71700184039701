import React from "react";
import { Routes, Route, Link } from "react-router-dom";

const Events = (props) => {
  const maxEV = 20;
  const eView = props.eView;
  const evData = props.evData;
  const nevData = props.nevData;

  const handleDateClick = (view) => {
    let seteView = view;
    return { seteView: seteView };
  };

  const plchldr = () => {
    const allPlchdr = [];
    for (let i = 1; i <= maxEV; i++) {
      allPlchdr.push(
        <div className="plte plchldr" key={`evplchldr${i}`}>
          <div className="ecclg mt-1 mb-1">
            <div className="flag mr-1">
              <span className="animated-gradient"></span>
            </div>
            <div className="cclg">
              <div className="ecc">
                <span className="animated-gradient"></span>
              </div>
              <div className="elg">
                <span className="animated-gradient"></span>
              </div>
            </div>
          </div>
          <div className="einf">
            <div className="edt">
              <span className="animated-gradient"></span>
            </div>
            <div className="efx">
              <div className="ehm">
                <span className="animated-gradient"></span>
              </div>
              <div className="eaw">
                <span className="animated-gradient"></span>
              </div>
            </div>
            <div className="esc">
              <div className="hsc">
                <span className="animated-gradient"></span>
              </div>
              <div className="asc">
                <span className="animated-gradient"></span>
              </div>
            </div>
            <div className="ert">
              <div className="eur">
                <span className="animated-gradient"></span>
              </div>
            </div>
            <div className="eop">
              <span className="animated-gradient"></span>
              <span className="animated-gradient"></span>
              <span className="animated-gradient"></span>
            </div>
            <div className="ert">
              <div className="edr">
                <span className="animated-gradient"></span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return allPlchdr;
  };

  const plchldr2 = () => {
    const allPlchdr = [];
    for (let i = 1; i <= maxEV; i++) {
      allPlchdr.push(
        <div className="plte plchldr" key={`nevplchldr${i}`}>
          <div className="ecclg mt-1 mb-1">
            <div className="flag mr-1">
              <span className="animated-gradient"></span>
            </div>
            <div className="cclg">
              <div className="ecc">
                <span className="animated-gradient"></span>
              </div>
              <div className="elg">
                <span className="animated-gradient"></span>
              </div>
            </div>
          </div>
          <div className="einf">
            <div className="edt">
              <span className="animated-gradient"></span>
            </div>
            <div className="efx">
              <div className="ehm">
                <span className="animated-gradient"></span>
              </div>
              <div className="eaw">
                <span className="animated-gradient"></span>
              </div>
            </div>
            <div className="esc">
              <div className="hsc">
                <span className="animated-gradient"></span>
              </div>
              <div className="asc">
                <span className="animated-gradient"></span>
              </div>
            </div>
            <div className="ert">
              <div className="eur">
                <span className="animated-gradient"></span>
              </div>
            </div>
            <div className="eop">
              <span className="animated-gradient"></span>
              <span className="animated-gradient"></span>
              <span className="animated-gradient"></span>
            </div>
            <div className="ert">
              <div className="edr">
                <span className="animated-gradient"></span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="elt2">
        <div className="plth">
          <span id="nevdd">
            <span className="animated-gradient"></span>
          </span>
        </div>
        <div className="cm-pd">{allPlchdr}</div>
      </div>
    );
  };

  const events = () => {
    const allEvents = [];
    const cclg = [];
    if (!evData || !evData.total)
      return <div className="noev">No Event(s)</div>;
    const events = evData.events;
    for (let [key, val] of Object.entries(events)) {
      let evna = val.evna,
        evtt = val.evtt,
        ecclg = val.ecclg,
        eci = val.eci,
        ecc = val.ecc,
        ecc2 = val.ecc2,
        elg = val.elg,
        edt = val.edt,
        etm = val.etm,
        ehm = val.ehm,
        eaw = val.eaw,
        hsc = val.hsc,
        asc = val.asc,
        evst = val.evst,
        started = val.started,
        punted = val.punted,
        eur = val.eur,
        edr = val.edr,
        mpo = val.mpo,
        to = `/football/event/${evna}`;
      if (evData.day === "Today" && eView === "Upcoming" && started) continue;
      allEvents.push(
        <div key={key} id={key} className={`plte ${eci}`}>
          {!cclg.includes(ecclg) ? (
            <div className="ecclg">
              <div className="flag">
                <img
                  src={require(`../../../assets/countries/${ecc2}.png`)}
                  alt={ecc}
                />
              </div>
              <div className="cclg">
                <div className="ecc">{ecc}</div>
                <div className="elg">{elg}</div>
              </div>
              <div className="etxt">
                <span>&nbsp;</span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="nwpb d-none">
            <div className="ptdt"></div>
            <Link
              to=""
              title="Punter"
              className="ptdtl"
              data-pti="<?php echo $rpt; ?>"
            >
              <div className="ptusr"></div>
              <div className="ptfx"></div>
            </Link>
            <div className="ptrt">
              <div className="ptur"></div>
            </div>
            <div className="eop eop3">
              <div className="sltinf">
                <div className="slt-punt"></div>
                <div className="slt-count"></div>
              </div>
              <button
                className="btn btn-special ptrpt"
                data-rpt="<?php echo $rpt; ?>"
                data-pid="<?php echo $evi; ?>"
                disabled
              >
                <div className="inner">
                  <div className="eopn">
                    <img
                      src={require(`../../../assets/icons/rpt-icon.png`)}
                      alt="Repunt"
                    />
                  </div>
                  <div className="eoprc">Repunt</div>
                </div>
              </button>
            </div>
            <div className="ptrt">
              <div className="ptdr"></div>
            </div>
          </div>
          <div className="einf">
            <div className="edt">
              <span>
                {evData.day === "Today" ? (
                  etm
                ) : (
                  <span>
                    {edt}
                    <br />
                    {etm}
                  </span>
                )}
                {evst > 3 ? (
                  evst === "4" ? (
                    <>
                      <br />
                      <span className="end">Postponed</span>
                    </>
                  ) : (
                    <>
                      <br />
                      <span className="end">Suspended</span>
                    </>
                  )
                ) : evst === "3" ? (
                  <>
                    <br />
                    <span>FT</span>
                  </>
                ) : (
                  ""
                )}
              </span>
            </div>
            <Link to={to} className="btn efx" title={evtt}>
              <div className="ehm">{ehm}</div>
              <div className="eaw">{eaw}</div>
            </Link>
            <div className="esc">
              <div className="hsc">{hsc}</div>
              <div className="asc">{asc}</div>
            </div>
            <div className="ert">
              <div className="eur">
                <span className={started ? "gray" : "green"}>
                  {eur}
                  <br />
                  <img
                    src={
                      started
                        ? require("../../../assets/image/star-icon-gray.png")
                        : require("../../../assets/image/star-icon-green.png")
                    }
                    alt="Up Rating"
                  />
                </span>
              </div>
            </div>
            <div className="eop eop1">
              <button
                className="btn btn-special hmpt"
                title="Home Win"
                data-pt="17"
                disabled={evst >= 3 || punted || started ? true : false}
              >
                <div className="inner">
                  <div className="eopn">1</div>
                  <div className="eopc">Home</div>
                </div>
              </button>
              <button
                className="btn btn-special drpt"
                title="Draw"
                data-pt="18"
                disabled={evst >= 3 || punted || started ? true : false}
              >
                <div className="inner">
                  <div className="eopn">X</div>
                  <div className="eopc">Draw</div>
                </div>
              </button>
              <button
                className="btn btn-special awpt"
                title="Away Win"
                data-pt="19"
                disabled={evst >= 3 || punted || started ? true : false}
              >
                <div className="inner">
                  <div className="eopn">2</div>
                  <div className="eopc">Away</div>
                </div>
              </button>
            </div>
            <div className="eop eop2 d-none">
              <div className="sltinf">
                <div className="slt-punt">{mpo}</div>
                <div className="slt-count">
                  <span className="animated-gradient"></span>
                </div>
              </div>
              <button className="btn btn-special ptrpt" disabled>
                <div className="inner">
                  <div className="eopn">
                    <img
                      src={require(`../../../assets/icons/rpt-icon.png`)}
                      alt="Repunt"
                    />
                  </div>
                  <div className="eoprc">Repunt</div>
                </div>
              </button>
            </div>
            <div className="ert">
              <div className="edr">
                <span className={started ? "gray" : "red"}>
                  {edr}
                  <br />
                  <img
                    src={
                      started
                        ? require("../../../assets/image/star-icon-gray2.png")
                        : require("../../../assets/image/star-icon-red.png")
                    }
                    alt="Down Rating"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      );
      if (!cclg.includes(ecclg)) cclg.push(ecclg);
    }

    if (allEvents.length === 0) return <div className="noev">No Event(s)</div>;
    return allEvents;
  };

  const showNev = () => {
    if (!evData) return "";
    if (evData.day !== "Today") return "";
    if (!nevData) return "";
    if (!nevData.total)
      return (
        <div className="elt2">
          <div className="plth">
            <span id="nevdd">{nevData.day}</span>
          </div>
          <div className="cm-pd">
            <div className="noev">No Event(s)</div>
          </div>
        </div>
      );
    const allEvents = [];
    const events = nevData.events;
    for (let [key, val] of Object.entries(events)) {
      let evna = val.evna,
        evtt = val.evtt,
        ecclg = val.ecclg,
        eci = val.eci,
        ecc = val.ecc,
        ecc2 = val.ecc2,
        elg = val.elg,
        edt = val.edt,
        etm = val.etm,
        ehm = val.ehm,
        eaw = val.eaw,
        hsc = val.hsc,
        asc = val.asc,
        evst = val.evst,
        started = val.started,
        punted = val.punted,
        eur = val.eur,
        edr = val.edr,
        mpo = val.mpo,
        to = `/football/event/${evna}`;
      allEvents.push(
        <div key={key} id={key} className={`plte ${eci}`}>
          {ecclg ? (
            <div className="ecclg">
              <div className="flag">
                <img
                  src={require(`../../../assets/countries/${ecc2}.png`)}
                  alt={ecc}
                />
              </div>
              <div className="cclg">
                <div className="ecc">{ecc}</div>
                <div className="elg">{elg}</div>
              </div>
              <div className="etxt d-none">
                <span>
                  Locked punts are not counted until matches are settled
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="nwpb d-none">
            <div className="ptdt"></div>
            <Link to="" title="Punter" className="ptdtl">
              <div className="ptusr"></div>
              <div className="ptfx"></div>
            </Link>
            <div className="ptrt">
              <div className="ptur"></div>
            </div>
            <div className="eop eop3">
              <div className="sltinf">
                <div className="slt-punt"></div>
                <div className="slt-count"></div>
              </div>
              <button className="btn btn-special ptrpt" disabled>
                <div className="inner">
                  <div className="eopn">
                    <img
                      src={require(`../../../assets/icons/rpt-icon.png`)}
                      alt="Repunt"
                    />
                  </div>
                  <div className="eoprc">Repunt</div>
                </div>
              </button>
            </div>
            <div className="ptrt">
              <div className="ptdr"></div>
            </div>
          </div>
          <div className="einf">
            <div className="edt">
              <span>
                {nevData.day === "Today" ? (
                  etm
                ) : (
                  <span>
                    {edt}
                    <br />
                    {etm}
                  </span>
                )}
                {evst > 3 ? (
                  evst === "4" ? (
                    <>
                      <br />
                      <span className="end">Postponed</span>
                    </>
                  ) : (
                    <>
                      <br />
                      <span className="end">Suspended</span>
                    </>
                  )
                ) : evst === "3" ? (
                  <>
                    <br />
                    <span>FT</span>
                  </>
                ) : (
                  ""
                )}
              </span>
            </div>
            <Link to={to} className="btn efx" title={evtt}>
              <div className="ehm">{ehm}</div>
              <div className="eaw">{eaw}</div>
            </Link>
            <div className="esc">
              <div className="hsc">{hsc}</div>
              <div className="asc">{asc}</div>
            </div>
            <div className="ert">
              <div className="eur">
                <span className={started ? "gray" : "green"}>
                  {eur}
                  <br />
                  <img
                    src={
                      started
                        ? require("../../../assets/image/star-icon-gray.png")
                        : require("../../../assets/image/star-icon-green.png")
                    }
                    alt="Up Rating"
                  />
                </span>
              </div>
            </div>
            <div className="eop eop1">
              <button
                className="btn btn-special hmpt"
                title="Home Win"
                data-pt="17"
                disabled={evst >= 3 || punted || started ? true : false}
              >
                <div className="inner">
                  <div className="eopn">1</div>
                  <div className="eopc">Home</div>
                </div>
              </button>
              <button
                className="btn btn-special drpt"
                title="Draw"
                data-pt="18"
                disabled={evst >= 3 || punted || started ? true : false}
              >
                <div className="inner">
                  <div className="eopn">X</div>
                  <div className="eopc">Draw</div>
                </div>
              </button>
              <button
                className="btn btn-special awpt"
                title="Away Win"
                data-pt="19"
                disabled={evst >= 3 || punted || started ? true : false}
              >
                <div className="inner">
                  <div className="eopn">2</div>
                  <div className="eopc">Away</div>
                </div>
              </button>
            </div>
            <div className="eop eop2 d-none">
              <div className="sltinf">
                <div className="slt-punt">{mpo}</div>
                <div className="slt-count">
                  <span className="animated-gradient"></span>
                </div>
              </div>
              <button className="btn btn-special ptrpt" disabled>
                <div className="inner">
                  <div className="eopn">
                    <img
                      src={require(`../../../assets/icons/rpt-icon.png`)}
                      alt="Repunt"
                    />
                  </div>
                  <div className="eoprc">Repunt</div>
                </div>
              </button>
            </div>
            <div className="ert">
              <div className="edr">
                <span className={started ? "gray" : "red"}>
                  {edr}
                  <br />
                  <img
                    src={
                      started
                        ? require("../../../assets/image/star-icon-gray2.png")
                        : require("../../../assets/image/star-icon-red.png")
                    }
                    alt="Down Rating"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="elt2">
        <div className="plth">
          <span id="nevdd">{nevData.day}</span>
        </div>
        <div className="cm-pd">
          {allEvents.length === 0 ? (
            <div className="noev">No Event(s)</div>
          ) : (
            allEvents
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="elt1">
        <div className="plth">
          <span id="evdd">
            {!evData ? <span className="animated-gradient"></span> : evData.day}
          </span>
          <span className="pltsw">
            <button
              id="evv1"
              className={`sw hbtn alt${eView === "Upcoming" ? " active" : ""}`}
              onClick={() => props.onClick(handleDateClick("Upcoming"))}
              disabled={evData ? (evData.day === "Today" ? false : true) : true}
            >
              Upcoming
            </button>
            <button
              id="evv2"
              className={`sw hbtn alt${eView === "All" ? " active" : ""}`}
              onClick={() => props.onClick(handleDateClick("All"))}
              disabled={
                evData ? (evData.day === "Today" ? false : false) : true
              }
            >
              All
            </button>
          </span>
        </div>
        <div className="cm-pd">{!evData ? plchldr() : events()}</div>
      </div>
      {evData && evData.day === "Today"
        ? !nevData
          ? plchldr2()
          : showNev()
        : showNev()}
      <Routes>
        <Route path="/" element="" />
      </Routes>
    </>
  );
};

export default Events;
