import infoerror from "../assets/svg/info-error.png";
import "../assets/css/accounts.css";
import React from "react";
import { Link } from "react-router-dom";
//import $ from "jquery";

const LogIn = (props) => {
  const logInData = props.logInData;
  const liErrors = props.liErrors;
  const liEMSG = props.liEMSG;
  const liSEMSG = props.liSEMSG;

  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  const setLIData = (index, val, select) => {
    const indexes = index !== 0 ? (index !== 1 ? [] : [3, 4]) : [0, 1, 2];
    return {
      setLogInData: {
        index: index,
        val: val,
        select: select,
      },
      setLIErrors: { indexes: indexes, bool: false },
    };
  };

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const logIn = () => {
    const indexes = [];
    if (!logInData[0]) indexes.push(0);
    if (!isValidEmail(logInData[0])) indexes.push(1);
    if (!logInData[1]) indexes.push(3);
    if (indexes.length)
      return { setLIErrors: { indexes: indexes, bool: true } };

    return { logIn: true };
  };

  const goToSignup = (e) => {
    e.preventDefault();
    let pState = {
      title: "Get started",
      newUrl: "/accounts/signup",
      rto: "Sign-Up",
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="accounts-main">
        <div className="acc-box-1">
          <div className="acc-hd">Start predicting for free to get paid</div>
          <div className="acc-sub">No betting required.</div>
        </div>
        <div className="acc-box-2">
          <div className="acc-hd">Login</div>
          <hr />
          <div className="acc-bd">
            <div className="acc-bd-item">
              <div className="acc-bdi title">Email</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  liErrors[0] || liErrors[1] || liErrors[2] || liErrors[3] ? "error" : ""
                }`}
              >
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  onChange={(e) => props.onChange(setLIData(0, e.target.value))}
                />
              </div>
              {liErrors[0] || liErrors[1] || liErrors[2] || liErrors[3] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!liErrors[0]
                    ? !liErrors[1]
                      ? !liErrors[2]
                        ? !liErrors[3]
                          ? ""
                          : liEMSG.limsg3
                        : liEMSG.limsg2
                      : liEMSG.limsg1
                    : liEMSG.limsg0}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="acc-bd-item">
              <div className="acc-bdi title">Password</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  liErrors[4] || liErrors[5] ? "error" : ""
                }`}
              >
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  onChange={(e) => props.onChange(setLIData(1, e.target.value))}
                />
              </div>
              <div className="acc-bdi info">
                <Link
                  to="/accounts/forgot"
                  onClick={(e) =>
                    props.onClick(
                      handleLink(
                        e,
                        "/accounts/forgot",
                        "Forgot Password",
                        "Forgot"
                      )
                    )
                  }
                >
                  Forgot your password?
                </Link>
              </div>
              {liErrors[4] || liErrors[5] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!liErrors[3] ? (
                    !liErrors[4] ? (
                      ""
                    ) : (
                      <div>{liEMSG.limsg5}</div>
                    )
                  ) : (
                    liEMSG.limsg4
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <hr />
            <div className="acc-bd-item">
              <div className="acc-bdi checkbox">
                <label htmlFor="remember">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    autoComplete="off"
                    onChange={(e) =>
                      props.onChange(setLIData(2, e.target.checked))
                    }
                  />
                  <span>Remember me</span>
                </label>
              </div>
            </div>
            <hr />
            <div className="acc-bd-item">
              <div className="acc-bdi action">
                <button
                  className="btn btn-gold"
                  onClick={(e) => props.onClick(logIn())}
                >
                  Log In
                </button>
              </div>
              {liErrors[5] ? (
                <div className="field-error error j-center">
                  <img src={infoerror} alt="Info" />
                  {liSEMSG}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="nu-box">
              <div className="nu-line"></div>
              <div className="nu-text">New user?</div>
              <div className="nu-line"></div>
            </div>
            <div className="su-box">
              <Link
                title="Get Started"
                to="/accounts/signup"
                onClick={(e) => props.onClick(goToSignup(e))}
              >
                Sign Up
              </Link>
            </div>
            <hr />
            <div className="inst-box">
              <Link
                title="Resend Confirmation"
                to="/accounts/confirmation"
                onClick={(e) =>
                  props.onClick(
                    handleLink(
                      e,
                      "/accounts/confirmation",
                      "Resend Confirmation",
                      "Confirm"
                    )
                  )
                }
              >
                Didn't receive confirmation instructions?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogIn;
