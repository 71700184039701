import React from "react";
import { Routes, Route, Link } from "react-router-dom";

const Countries = (props) => {
  const dd = props.dd;
  const ccData = props.ccData;
  const sCountry = props.sCountry;

  const handleDateClick = (e, url, ccid, ccname) => {
    e.preventDefault();
    let setsCountry = ccid;
    let pState = {
      title: ccname,
      newUrl: url,
      rto: "Country",
      rid: ccid,
    };
    return { pState: pState, setsCountry: setsCountry };
  };

  const plchldr = () => {
    const allPlchdr = [];
    for (let i = 1; i <= 20; i++) {
      allPlchdr.push(
        <div key={`ccplchldr${i}`} className="cc">
          <span className="cc-item">
            <span className="flag mr-1">
              <span className="animated-gradient"></span>
            </span>
            <span className="name">
              <span className="animated-gradient"></span>
            </span>
          </span>
          <span className="total">
            <span className="animated-gradient"></span>
          </span>
        </div>
      );
    }

    return allPlchdr;
  };

  const list = () => {
    const allCountries = [];
    const total = ccData.total;
    const countries = ccData.countries;
    if (!total) return;
    for (let [key, val] of Object.entries(countries)) {
      let ccname = val.ccname,
        ccname2 = val.ccname2,
        to = `/football/${dd}/${ccname2}`;
      allCountries.push(
        <Link
          to={to}
          key={key}
          id={key}
          className={`cc${sCountry === key ? " active" : ""}`}
          title={ccname}
          onClick={(e) => props.onClick(handleDateClick(e, to, key, ccname))}
        >
          <span className="cc-item">
            <span className="flag mr-1">
              <img
                src={require(`../../../assets/countries/${ccname2}.png`)}
                alt={ccname}
              />
            </span>
            <span className="name">{ccname}</span>
          </span>
          <span className="total">&#8226;</span>
        </Link>
      );
    }

    return allCountries;
  };

  return (
    <>
      <div className="countries">
        <div className="cch">Countries</div>
        <Link
          key="cc0"
          id="cc0"
          className={`cc${sCountry === "cc0" ? " active" : ""} m-avail`}
          title="All"
          to={`/football/${dd}/all`}
          onClick={(e) =>
            props.onClick(
              handleDateClick(e, `/football/${dd}/all`, "cc0", "All")
            )
          }
        >
          <span className="cc-item">
            <span className="flag mr-1">
              <img
                src={require("../../../assets/countries/all.png")}
                alt="All"
              />
            </span>
            <span className="name">All</span>
          </span>
          <span className="total">&#8226;</span>
        </Link>
        {!ccData ? plchldr() : list()}
      </div>
      <Routes>
        <Route path="/" element="" />
      </Routes>
    </>
  );
};

export default Countries;
