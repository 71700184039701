import sentImg from "../assets/svg/sent.png";
import infoerror from "../assets/svg/info-error.png";
import infosuccess from "../assets/svg/svgexport-81.png";
import "../assets/css/confirm.css";
import React from "react";
import { Link } from "react-router-dom";

const Resent = (props) => {
  const confData = props.confData;
  const cfErrors = props.cfErrors;
  const cfEMSG = props.cfEMSG;
  const cfSEMSG = props.cfSEMSG;

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const resend = () => {
    const indexes = [];
    if (!confData[0]) indexes.push(0);
    if (!isValidEmail(confData[0])) indexes.push(1);
    if (indexes.length)
      return { setFPErrors: { indexes: indexes, bool: true } };

    return { conf: true };
  };

  return (
    <>
      <div className="confirm-main">
        <div className="confirm-box">
          <div className="confirm-img">
            <img src={sentImg} alt="Sent" />
          </div>
          <div className="confirm-hd">Please confirm your email</div>
          <div className="confirm-info">
            We've sent confirmation instructions to
            <br />
            <strong>{confData[0]}</strong>
          </div>
          <div className="confirm-info">
            Just click on the link in that email to complete your signup.
            <br />
            If you don't see it, you may need to{" "}
            <strong>check your spam folder</strong>.
          </div>
          <div className="confirm-info">Still can't find the email?</div>
          <div className="confirm-action">
            <button
              className="btn btn-gold"
              onClick={(e) => props.onClick(resend())}
            >
              Resend Email
            </button>
            {cfErrors[4] ? (
              <>
                <br />
                <div className="field-error inline error">
                  <img src={infoerror} alt="Info" />
                  {cfSEMSG}
                </div>
              </>
            ) : (
              ""
            )}
            {cfErrors[5] ? (
              <>
                <br />
                <div className="field-error inline success">
                  <img src={infosuccess} alt="Info" />
                  {cfEMSG.cfmsg5}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="confirm-info">
            Need help? <Link to="mailto:support@peer2punt.com">Contact Us</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resent;
