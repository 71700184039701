import infoerror from "../assets/svg/info-error.png";
import "../assets/css/accounts.css";
import React from "react";
import { Link } from "react-router-dom";

const Forgot = (props) => {
  const forgotData = props.forgotData;
  const fpErrors = props.fpErrors;
  const fpEMSG = props.fpEMSG;
  const fpSEMSG = props.fpSEMSG;

  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  const setFPData = (index, val, select) => {
    const indexes = index !== 0 ? [] : [0, 1, 2, 3];
    return {
      setForgotData: {
        index: index,
        val: val,
        select: select,
      },
      setFPErrors: { indexes: indexes, bool: false },
    };
  };

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const forgot = () => {
    const indexes = [];
    if (!forgotData[0]) indexes.push(0);
    if (!isValidEmail(forgotData[0])) indexes.push(1);
    if (indexes.length)
      return { setFPErrors: { indexes: indexes, bool: true } };

    return { forgot: true };
  };

  const goToLogin = (e) => {
    e.preventDefault();
    let pState = {
      title: "Login",
      newUrl: "/accounts/login",
      rto: "Login",
      rid: null,
    };
    return { pState: pState };
  };

  const goToSignup = (e) => {
    e.preventDefault();
    let pState = {
      title: "Get started",
      newUrl: "/accounts/signup",
      rto: "Sign-Up",
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="accounts-main">
        <div className="acc-box-1">
          <div className="acc-hd">Start predicting for free to get paid</div>
          <div className="acc-sub">No betting required.</div>
        </div>
        <div className="acc-box-2">
          <div className="acc-hd">Forgot your password?</div>
          <hr />
          <div className="acc-bd">
            <div className="acc-bd-item">
              <div className="acc-bdi title">Email</div>
              <div
                className={`acc-bdi field d-flex px-0 ${
                  fpErrors[0] || fpErrors[1] || fpErrors[2] || fpErrors[3]
                    ? "error"
                    : ""
                }`}
              >
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="pl-2"
                  maxLength="50"
                  autoComplete="false"
                  defaultValue={forgotData[0]}
                  onChange={(e) => props.onChange(setFPData(0, e.target.value))}
                />
              </div>
              {fpErrors[0] || fpErrors[1] || fpErrors[2] || fpErrors[3] ? (
                <div className="field-error error">
                  <img src={infoerror} alt="Info" />
                  {!fpErrors[0]
                    ? !fpErrors[1]
                      ? !fpErrors[2]
                        ? !fpErrors[3]
                          ? ""
                          : fpEMSG.fpmsg3
                        : fpEMSG.fpmsg2
                      : fpEMSG.fpmsg1
                    : fpEMSG.fpmsg0}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="acc-bd-item py-3">
              <div className="acc-bdi action">
                <button
                  className="btn btn-gold"
                  onClick={(e) => props.onClick(forgot())}
                >
                  Send me reset password instructions
                </button>
                <br />
                {fpErrors[4] ? (
                  <div className="field-error inline error">
                    <img src={infoerror} alt="Info" />
                    {fpSEMSG}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <hr />
            <div className="su-box">
              <Link
                to="/accounts/login"
                onClick={(e) => props.onClick(goToLogin(e))}
              >
                Log In
              </Link>
              &nbsp; &nbsp; &nbsp;
              <Link
                title="Get Started"
                to="/accounts/signup"
                onClick={(e) => props.onClick(goToSignup(e))}
              >
                Sign up
              </Link>
            </div>
            <hr />
            <div className="inst-box">
              <Link
                title="Resend Confirmation"
                to="/accounts/confirmation"
                onClick={(e) =>
                  props.onClick(
                    handleLink(
                      e,
                      "/accounts/confirmation",
                      "Resend Confirmation",
                      "Confirm"
                    )
                  )
                }
              >
                Didn't receive confirmation instructions?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
