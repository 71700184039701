import React from "react";
import { Routes, Route, Link } from "react-router-dom";

const TopBox = (props) => {
  const evData = props.evData;
  const ftrdEV = evData ? evData.featured : null;

  const plchldr = () => {
    return (
      <div className="ftrd-body">
        <div className="ftrd-evt">
          <div className="fetm1">
            <div className="fetml">
              <span className="animated-gradient"></span>
            </div>
            <div className="fetmn">
              <span className="animated-gradient"></span>
            </div>
          </div>
          <div className="fesc">
            <div className="text-center">
              <span className="animated-gradient"></span>
              <span className="animated-gradient"></span>
            </div>
          </div>
          <div className="fetm2">
            <div className="fetml">
              <span className="animated-gradient"></span>
            </div>
            <div className="fetmn">
              <span className="animated-gradient"></span>
            </div>
          </div>
        </div>
        <div className="ftrd-opt">
          <div className="ftrd-lst">
            <div className="ftrd-txt">Who will win?</div>
            <div className="ftrd-rtg">
              <span className="animated-gradient"></span>
            </div>
          </div>
          <div className="feo">
            <div className="feo-box">
              <button
                className="btn btn-special hmpt"
                title="Home Win"
                data-pt="17"
                disabled
              >
                <div className="inner">
                  <div className="feon">1</div>
                  <div className="feoc">Home</div>
                </div>
              </button>
            </div>
            <div className="feo-box">
              <button
                className="btn btn-special drpt"
                title="Draw"
                data-pt="18"
                disabled
              >
                <div className="inner">
                  <div className="feon">X</div>
                  <div className="feoc">Draw</div>
                </div>
              </button>
            </div>
            <div className="feo-box">
              <button
                className="btn btn-special awpt"
                title="Away Win"
                data-pt="19"
                disabled
              >
                <div className="inner">
                  <div className="feon">2</div>
                  <div className="feoc">Away</div>
                </div>
              </button>
            </div>
          </div>
          <div className="ftrd-lst">
            <div className="ftrd-txt">
              <small>&nbsp;</small>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const featured = () => {
    // const total = ftrdEV.total;
    // const day = ftrdEV.day;
    const featured = ftrdEV;
    // const evi = featured.evi;
    const evst = featured.evst;
    const date = featured.date;
    const time = featured.time;
    const team1 = featured.team1;
    const logo1 = featured.logo1;
    const team2 = featured.team2;
    const logo2 = featured.logo2;
    const hsc = featured.hsc;
    const htHsc = featured.ht_hsc;
    const asc = featured.asc;
    const htAsc = featured.ht_asc;
    const rtg = featured.rtg;
    const punted = featured.punted;
    const started = featured.started;
    const result =
      evst === "3" ? (
        <div className="vs-score">
          <div className="ft">{hsc}</div>
          <div className="ht">{htHsc}</div> &#45;{" "}
          <div className="ht">{htAsc}</div>
          <div className="ft">{asc}</div>
        </div>
      ) : (
        <div className="vs-score">vs</div>
      );
    return (
      <div className="ftrd-body">
        <div className="ftrd-evt">
          <div className="fetm1">
            <div className="fetml">
              <img
                src={require(`../../../assets/teams/${logo1}`)}
                alt="Team 1"
              />
            </div>
            <div className="fetmn">{team1}</div>
          </div>
          <div className="fesc">
            <div className="text-center">
              {evst >= 3 ? (
                <div>
                  {result}
                  <div className="datetime">
                    <small>
                      <div>{time}</div>
                      <div>{date}</div>
                    </small>
                  </div>
                </div>
              ) : (
                <div className="datetime">
                  {time}
                  <br />
                  <small>{date}</small>
                </div>
              )}
            </div>
          </div>
          <div className="fetm2">
            <div className="fetml">
              <img
                src={require(`../../../assets/teams/${logo2}`)}
                alt="Team 2"
              />
            </div>
            <div className="fetmn">{team2}</div>
          </div>
        </div>
        <div className="ftrd-opt">
          <div className="ftrd-lst">
            <div className="ftrd-txt">Who will win?</div>
            <div className="ftrd-rtg">
              {started ? (
                <span className="gray">
                  {rtg}&nbsp;
                  <img
                    src={require(`../../../assets/image/star-icon-gray.png`)}
                    alt="Up Rating"
                  />
                </span>
              ) : (
                <span className="green">
                  {rtg}&nbsp;
                  <img
                    src={require(`../../../assets/image/star-icon-green.png`)}
                    alt="Up Rating"
                  />
                </span>
              )}
            </div>
          </div>
          <div className="feo">
            <div className="feo-box">
              <button
                className="btn btn-special hmpt"
                title="Home Win"
                disabled={started || punted ? true : false}
              >
                <div className="inner">
                  <div className="feon">1</div>
                  <div className="feoc">Home</div>
                </div>
              </button>
            </div>
            <div className="feo-box">
              <button
                className="btn btn-special drpt"
                title="Draw"
                disabled={started || punted ? true : false}
              >
                <div className="inner">
                  <div className="feon">X</div>
                  <div className="feoc">Draw</div>
                </div>
              </button>
            </div>
            <div className="feo-box">
              <button
                className="btn btn-special awpt"
                title="Away Win"
                disabled={started || punted ? true : false}
              >
                <div className="inner">
                  <div className="feon">2</div>
                  <div className="feoc">Away</div>
                </div>
              </button>
            </div>
          </div>
          <div className="ftrd-lst">
            <div className="ftrd-txt">
              <small>&nbsp;</small>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="items-box top-box">
        <div className="mini-nav"></div>
        <div className="ftrd-box">
          <div className="ftrd-head">Featured Match</div>
          {ftrdEV ? featured() : plchldr()}
        </div>
        <Link
          to="/submit"
          id="submitResult"
          className="mb-item submit-result"
        ></Link>
        <Link
          to="/invite"
          id="inviteFriend"
          className="mb-item invite-friend"
        ></Link>
        <div className="items-box top-box punts">
          <div className="items-box-header">
            <span>Today&#39;s top punts</span>
            <span>
              <button className="sw hbtn alt active">All</button>
            </span>
          </div>
          <div className="cm-pd">
            <div className="nopt">No Punt(s)</div>
          </div>
        </div>
        <div className="items-box top-box winners">
          <div className="items-box-header">
            <span>Yesterday&#39;s top punts</span>
            <span>
              <button className="sw hbtn alt active">All</button>
            </span>
          </div>
          <div className="cm-pd">
            <div className="nopt">No Punt(s)</div>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/" element="" />
        <Route path="/submit" element="" />
        <Route path="/invite" element="" />
      </Routes>
    </>
  );
};

export default TopBox;
