import React from "react";
import asc from "../../assets/svg/svgexport-15.png";
import desc from "../../assets/svg/svgexport-6.png";
import searchIcon from "../../assets/svg/svgexport-93.png";
import close2 from "../../assets/svg/close-2.png";
import $ from "jquery";

const BuyBet = (props) => {
  const cyData = props.cyData;
  const cy = props.cy;
  const cyVal = cyData ? cyData.currencies[cy] : "";
  const pmData = props.pmData;
  const pm = props.pm;
  const pmVal = pm !== "pmAll" && pmData ? pmData.paymethods[pm] : "";
  const od = props.od;
  const slt = props.slt;
  const search = props.search;

  const toggleSLT = (data) => {
    return { setSLT: data, setOVSLT: true };
  };

  const setSearch = (data, reset) => {
    if (reset) $(".options-search input").val("");
    data = data.length > 32 ? data.substring(0, 32) : data;
    return { setSearch: { data: data } };
  };

  const selectCY = (data) => {
    return { setCY: data, delay: false };
  };

  const setCY = (data) => {
    return { setCY: data, setSLT: "none", setOVSLT: true, delay: false };
  };

  const cyOptions = () => {
    const nodata = (
      <div className="mi-2-item field pr-1">
        <div className="select disabled">
          <div className="select-val">-</div>
          <div className="select-pointer">
            <img src={desc} alt="Desc" />
          </div>
        </div>
      </div>
    );
    if (!cyData) return nodata;
    const allCurrencies = [];
    const total = cyData.total;
    const major = parseInt(cyData.major);
    const currencies = cyData.currencies;
    if (!total) return nodata;
    allCurrencies.push(
      <div key="sch1" className="options-search field">
        <span>
          <img src={searchIcon} alt="Search" />
        </span>
        <span>
          <input
            type="text"
            maxLength="32"
            placeholder="Search"
            autoComplete="false"
            onChange={(e) => props.onChange(setSearch(e.target.value))}
          />
          <button
            type="button"
            className="btn"
            onClick={(e) => props.onClick(setSearch("", true))}
          >
            <img src={close2} alt="Search" />
          </button>
        </span>
      </div>
    );
    let i = 0;
    for (let [key, val] of Object.entries(currencies)) {
      if (i === major) {
        allCurrencies.push(
          <span key="cygap2" className="option-val gap">
            <hr />
          </span>
        );
      }
      i++;
      let cycode = val.cycode;
      if (search) {
        let regex = new RegExp(search, "i");
        if (cycode.search(regex) < 0) continue;
      }
      allCurrencies.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            cy === key ? "active" : ""
          }`}
          onClick={() => props.onClick(setCY(key))}
        >
          {cycode}
        </button>
      );
    }

    return (
      <div>
        <div className="mi-2-item field pr-1">
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("currency"))}
          >
            <div className="select-val">{cyVal ? cyVal.cycode : "-"}</div>
            <div className="select-pointer">
              <img src={slt === "currency" ? asc : desc} alt="Desc" />
            </div>
          </div>
        </div>
        {slt === "currency" ? (
          <div className="options">{allCurrencies}</div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const cyButtons = () => {
    const noData = (
      <>
        <button className="btn btn-special-2">-</button>
        <button className="btn btn-special-2">-</button>
        <button className="btn btn-special-2">-</button>
      </>
    );
    if (!cyData) return noData;
    const allCurrencies = [];
    const total = cyData.total;
    const currencies = cyData.currencies;
    if (!total) return noData;
    let i = 0;
    for (let [key, val] of Object.entries(currencies)) {
      let cycode = val.cycode;
      if (key === cy) continue;
      allCurrencies.push(
        <button
          type="button"
          key={key}
          className="btn btn-special-2"
          onClick={() => props.onClick(selectCY(key))}
        >
          {cycode}
        </button>
      );
      i++;
      if (i >= 4) break;
    }

    return allCurrencies;
  };

  const selectPM = (pmid, pmtid) => {
    if (!pmtid) return;
    return { setPM: pmid, setPMT: pmtid };
  };

  const setPM = (pmid, pmtid) => {
    return { setPM: pmid, setPMT: pmtid, setSLT: "none", setOVSLT: true };
  };

  const pmOptions = () => {
    const nodata = (
      <div className="mi-2-item field pr-1">
        <div className="select disabled">
          <div className="select-val">-</div>
          <div className="select-pointer">
            <img src={desc} alt="Desc" />
          </div>
        </div>
      </div>
    );
    if (!pmData) return nodata;
    const allPayMethods = [];
    const total = pmData.total;
    const major = parseInt(pmData.major);
    const paymethods = pmData.paymethods;
    if (!total) return nodata;
    allPayMethods.push(
      <button
        key={`pm0`}
        className={`btn btn-special-alt-3 option-val ${
          pm === "pmAll" ? "active" : ""
        }`}
        onClick={() => props.onClick(setPM("pmAll"))}
      >
        All payment methods
      </button>
    );
    allPayMethods.push(
      <span key="pmgap1" className="option-val gap">
        <hr />
      </span>
    );
    allPayMethods.push(
      <div key="sch1" className="options-search field">
        <span>
          <img src={searchIcon} alt="Search" />
        </span>
        <span>
          <input
            type="text"
            maxLength="32"
            placeholder="Search"
            autoComplete="false"
            onChange={(e) => props.onChange(setSearch(e.target.value))}
          />
          <button
            type="button"
            className="btn"
            onClick={(e) => props.onClick(setSearch("", true))}
          >
            <img src={close2} alt="Search" />
          </button>
        </span>
      </div>
    );
    let i = 0;
    for (let [key, val] of Object.entries(paymethods)) {
      if (i === major) {
        allPayMethods.push(
          <span key="pmgap2" className="option-val gap">
            <hr />
          </span>
        );
      }
      i++;
      let pmname = val.pmname,
        pmtype = val.pmtype;
      if (search) {
        let regex = new RegExp(search, "i");
        if (pmname.search(regex) < 0) continue;
      }
      allPayMethods.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            pm === key ? "active" : ""
          }`}
          onClick={(e) => props.onClick(setPM(key, pmtype))}
        >
          {pmname}
        </button>
      );
    }

    return (
      <div>
        <div className="mi-2-item field pr-1">
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("paymethod"))}
          >
            <div className="select-val">
              {pm !== "pmAll"
                ? pmVal
                  ? pmVal.pmname
                  : "-"
                : "All payment methods"}
            </div>
            <div className="select-pointer">
              <img src={slt === "paymethod" ? asc : desc} alt="Desc" />
            </div>
          </div>
        </div>
        {slt === "paymethod" ? (
          <div className="options">{allPayMethods}</div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const pmButtons = () => {
    const noData = (
      <>
        <button className="btn btn-special-2">-</button>
        <button className="btn btn-special-2">-</button>
        <button className="btn btn-special-2">-</button>
      </>
    );
    if (!pmData) return noData;
    const allPayMethods = [];
    const total = pmData.total;
    const paymethods = pmData.paymethods;
    if (!total) return noData;
    let i = 0,
      l = 0;
    for (let [key, val] of Object.entries(paymethods)) {
      let pmname = val.pmname,
        pmtype = val.pmtype;
      if (key === pm) continue;
      allPayMethods.push(
        <button
          type="button"
          key={key}
          className="btn btn-special-2"
          onClick={() => props.onClick(selectPM(key, pmtype))}
          title={pmname}
        >
          {pmname}
        </button>
      );
      i++;
      l += pmname.length;
      if (i >= 2 && l >= 28) {
        break;
      }
    }

    return allPayMethods;
  };

  const setOD = (data) => {
    if (!parseFloat(data) || parseFloat(data) < 1.05) {
      data = 1.05;
      $("#odds").val(data);
    } else if (parseFloat(data) > 9999.99) {
      data = 9999.99;
      $("#odds").val(data);
    }
    return { setOD: data };
  };

  const selectOD = (data) => {
    $("#odds").val(data);
    return { setOD: data };
  };

  const oddButtons = () => {
    const allOdds = {
      "od-1": "5.05",
      "od-2": "10.05",
      "od-3": "25.05",
      "od-4": "50.05",
      "od-5": "100.05",
    };
    const oddsButtons = [];
    for (let [key, val] of Object.entries(allOdds)) {
      if (parseFloat(val) === parseFloat(od)) continue;
      oddsButtons.push(
        <button
          type="button"
          key={key}
          className="btn btn-special-2"
          onClick={() => props.onClick(selectOD(val))}
        >
          {val}
        </button>
      );
    }

    return oddsButtons;
  };

  const viewOffers = (e) => {
    e.preventDefault();
    let pState = {
      title: "Buy Bet",
      newUrl: "/market/buy-bet",
      rto: "Buy-Bet",
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <form
      name="offersView"
      method="post"
      onSubmit={(e) => props.onSubmit(viewOffers(e))}
    >
      <div className="lm-m-bd">
        <div className="lm-m-item">
          <div className="mi-1">Currency</div>
          <div className="mi-2">
            {cyOptions()}
            <div className="mi-2-item">{cyButtons()}</div>
          </div>
        </div>
        <div className="lm-m-item">
          <div className="mi-1">Payment Method</div>
          <div className="mi-2">
            {pmOptions()}
            <div className="mi-2-item">{pmButtons()}</div>
          </div>
        </div>
        <div className="lm-m-item">
          <div className="mi-1">Total Odds</div>
          <div className="mi-2">
            <div className="mi-2-item field">
              <input
                type="number"
                name="odds"
                id="odds"
                placeholder="Enter odds"
                step=".01"
                min="1.05"
                max="9999.99"
                defaultValue={od}
                autoComplete="false"
                onChange={(e) => props.onChange(setOD(e.target.value))}
                required
              />
              <span className="mi-currency">Odds</span>
            </div>
            <div className="mi-2-item">{oddButtons()}</div>
          </div>
        </div>
        <div className="lm-m-item">
          <button className="btn btn-special-2" type="submit">
            View Offers
          </button>
        </div>
      </div>
    </form>
  );
};

export default BuyBet;
