import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/about.css";

const About = (props) => {
  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="about-main">
        <div className="am-bf">
          Before trading read the&nbsp;
          <Link
            to="/security-guide"
            onClick={(e) =>
              props.onClick(
                handleLink(
                  e,
                  "/security-guide",
                  "Security Guide",
                  "Security-Guide"
                )
              )
            }
          >
            SECURITY GUIDE
          </Link>
        </div>
        <div className="am-bd">
          <div className="am-hd">About Us</div>
          <div className="am-ab-item">
            <div className="am-ab-qt">What is Peer2Punt?</div>
            <div className="am-ab-as">
              Peer2Punt is a global P2P Bet trading platform that allows users
              to trade directly with each other and does not hold users' funds
              &minus; letting the Buyer send funds directly to the Seller
              instead.
              <br />
              <br />
              Peer2Punt allows Bet trading for any payment method and currency,
              ensuring fast and secure trading through multisig smart contracts
              and allowing its users to control the keys to escrow.
            </div>
          </div>
          <div className="am-ab-item">
            <div className="am-ab-qt">What are Peer2Punt Ratings?</div>
            <div className="am-ab-as">
              Peer2Punt ratings are a way for Peer2Punt to express its opinions
              and satisfaction with a user. They typically involve assigning a
              numerical score, on a scale of 0 to 5 stars, with 0 being the
              lowest and 5 being the highest.
              <br />
              <br />
              These ratings help potential buyers make informed decisions by
              considering the performance of sellers. Additionally, they provide
              valuable feedback to users, allowing them to identify areas of
              specialty and understand their constumers' needs better.
            </div>
          </div>
          <div className="am-ab-item">
            <div className="am-ab-qt">Why do Peer2Punt Ratings Matter?</div>
            <div className="am-ab-as">
              <strong>Peer2Punt ratings matter</strong> because they provide
              valuable insights into the quality and reliability of users' bets
              and experiences.
              <br />
              <br />
              They serve as a form of professional proof, giving potential
              buyers a glimps into the experiences of other users who have
              better predictions.
              <br />
              <br />
              By relying on the accumulated experiences of the users, Peer2Punt
              ratings can help potential buyers make more informed decisions and
              reduce the risk of making a poor choice. Futhermore, Peer2Punt
              ratings can also help sellers improve their offerings, as they can
              use this feedback to identify areas that need improvement.
              <br />
              <br />
              Also read&nbsp;
              <Link
                to="/help"
                title="Help Guides"
                onClick={(e) =>
                  props.onClick(handleLink(e, "/help", "Help Guide", "Help"))
                }
              >
                how to gain Peer2punt ratings.
              </Link>
            </div>
          </div>
          <div className="am-ab-item">
            <div className="am-ab-qt">How are we different?</div>
            <div className="am-ab-as">
              The core difference of our services is that we do not hold users'
              funds, allowing users to trade their bets in multisig escrows and
              stay anonymous while using any of our services.
              <br />
              <br />
              Peer2Punt - your keys, your bets!
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
