import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/faq.css";

const Faq = (props) => {
  const opData = props.opData;
  const pcData = props.pcData;
  const ulData = props.ulData;

  const ull1ideal = ulData ? ulData.levels.ull1.ullideal : "-";
  const ull2ideal = ulData ? ulData.levels.ull2.ullideal : "-";
  const ull3ideal = ulData ? ulData.levels.ull3.ullideal : "-";

  const ult3l1maxid = ulData ? ulData.limits.ult3.ull1.ulmax : "";
  const ult3l1maxpcVal =
    ult3l1maxid && pcData ? pcData.percentages[ult3l1maxid] : "";
  const ult3l1pcMax = ult3l1maxpcVal ? ult3l1maxpcVal.pcprice : "-";

  const ult3l2maxid = ulData ? ulData.limits.ult3.ull2.ulmax : "";
  const ult3l2maxpcVal =
    ult3l2maxid && pcData ? pcData.percentages[ult3l2maxid] : "";
  const ult3l2pcMax = ult3l2maxpcVal ? ult3l2maxpcVal.pcprice : "-";

  const ult3l3maxid = ulData ? ulData.limits.ult3.ull3.ulmax : "";
  const ult3l3maxpcVal =
    ult3l3maxid && pcData ? pcData.percentages[ult3l3maxid] : "";
  const ult3l3pcMax = ult3l3maxpcVal ? ult3l3maxpcVal.pcprice : "-";

  const ult4l1maxid = ulData ? ulData.limits.ult4.ull1.ulmax : "";
  const ult4l1maxopVal =
    ult4l1maxid && opData ? opData.prices[ult4l1maxid] : "";
  const ult4l1opMaxOD = ult4l1maxopVal ? ult4l1maxopVal.max : "-";

  const ult4l2maxid = ulData ? ulData.limits.ult4.ull2.ulmax : "";
  const ult4l2maxopVal =
    ult4l2maxid && opData ? opData.prices[ult4l2maxid] : "";
  const ult4l2opMaxOD = ult4l2maxopVal ? ult4l2maxopVal.max : "-";

  const ult4l3maxid = ulData ? ulData.limits.ult4.ull3.ulmax : "";
  const ult4l3maxopVal =
    ult4l3maxid && opData ? opData.prices[ult4l3maxid] : "";
  const ult4l3opMaxOD = ult4l3maxopVal ? ult4l3maxopVal.max : "-";

  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="fq-main">
        <div className="fq-bf">
          Before trading read the&nbsp;
          <Link
            to="/security-guide"
            onClick={(e) =>
              props.onClick(
                handleLink(
                  e,
                  "/security-guide",
                  "Security Guide",
                  "Security-Guide"
                )
              )
            }
          >
            SECURITY GUIDE
          </Link>
        </div>
        <div className="fq-bd">
          <div className="fq-m-hd">Frequently Asked Questions</div>
          <br />
          <div className="fq-hd">Basics</div>
          <br />
          <div className="item" id="what-is-peer2punt">
            <div className="fq-sub">What is Peer2Punt?</div>
            <div className="body">
              <p>
                Peer2Punt is a global P2P Bet trading platform that allows users
                to trade directly with each other, and it doesn't hold user's
                funds, letting the Buyer send funds directly to the Seller
                instead, which reduces trading time. Because Peer2Punt does not
                hold any money (neither Crypto, nor fiat) it is not subject to
                complex compliance procedures. All trades happen directly
                between user's crypto wallets or bank accounts.
              </p>
            </div>
          </div>
          <div className="item" id="how-does-peer2punt-work">
            <div className="fq-sub">How does Peer2Punt work?</div>
            <div className="body">
              <p>
                On Peer2Punt, all trades occur directly between buyers and
                sellers, and without a middleman involved. Every time a contract
                is created between two parties, Peer2Punt generates a unique
                multisig escrow Bet address. The seller sends Bet directly from
                his Peer2Punt account to the multisig escrow address, and when
                the Bet is locked in escrow, the buyer sends crypto or fiat to
                the seller. Then the seller releases the locked Bet from escrow
                using his payment password, and the buyer receives the Bet
                directly into his Peer2Punt account.
              </p>
            </div>
          </div>
          <div className="item" id="why-should-i-use-peer2punt">
            <div className="fq-sub">Why should I use Peer2Punt?</div>
            <div className="body">
              <ul>
                <li>Anonymous - Global P2P trades without KYC/AML.</li>
                <li>Free - No fees.</li>
                <li>
                  Secure - Multisig (P2P) contracts, you control a key to the
                  Bet in escrow.
                </li>
              </ul>
            </div>
          </div>
          <div className="item" id="how-do-i-start-trading">
            <div className="fq-sub">How do I start trading?</div>
            <div className="body">
              <ul>
                <li>Sign up with an e-mail and a password.</li>
                <li>
                  Click on the confirmation link you received in your e-mail.
                </li>
                <li>You are good to go!</li>
              </ul>
            </div>
          </div>
          <div className="item" id="do-i-need-to-download-anything">
            <div className="fq-sub">
              Do I need to download anything to start trading?
            </div>
            <div className="body">
              <p>
                No. As mentioned previously, all trades on the Peer2Punt trading
                platform happen directly between user's crypto wallets or bank
                accounts. So to use Peer2Punt, you only need to have a crypto
                wallet or bank account.
              </p>
            </div>
          </div>
          <div className="item" id="how-much-does-it-cost-to-use">
            <div className="fq-sub">
              How much does it cost to use Peer2Punt?
            </div>
            <div className="body">
              <p>
                No. As mentioned previously, there are no fees on the Peer2Punt
                trading platform:
              </p>
            </div>
          </div>
          <div className="item" id="how-fast-can-i-buy-or-sell-Bet">
            <div className="fq-sub">How fast can I buy or sell Bet?</div>
            <div className="body">
              <p>
                On Peer2Punt, your trades will usually take only as long as it
                takes for payment to be confirmed, and the Bet to be released.
              </p>
            </div>
          </div>
          <div className="item" id="available-cryptocurrencies">
            <div className="fq-sub">
              What cryptocurrencies are available at Peer2Punt?
            </div>
            <div className="body">
              <p>All the major ones and more to come.</p>
            </div>
          </div>
          <div className="item" id="currencies-and-locations">
            <div className="fq-sub">
              What fiat currencies and locations are available at Peer2Punt?
            </div>
            <div className="body">
              <p>
                Peer2Punt is a global P2P trading platform, therefore any fiat
                currency in the world is available. It's entirely up to the
                users who create the offers to decide which payment methods and
                fiat currencies they accept.
              </p>
            </div>
          </div>
          <div className="item" id="available-payment-methods">
            <div className="fq-sub">
              What payment methods are available at Peer2Punt?
            </div>
            <div className="body">
              <p>
                Peer2Punt supports more than 100 different payment methods. More
                information is available in the "
                <Link to="#what-payment-methods-are-available-at-exchange">
                  Payment methods
                </Link>
                " section.
              </p>
            </div>
          </div>
          <br />
          <div className="fq-hd">Security &amp; Privacy</div>
          <br />
          <div className="item" id="how-secure">
            <div className="fq-sub">How secure is trading on Peer2Punt?</div>
            <div className="body">
              <p>
                The main priority of Peer2Punt is keeping your Bets and data
                safe.
              </p>
              <p>
                Peer2Punt does not process any private information, therefore
                there is no need to worry about your data. To start trading on
                our platform, you only need to provide a valid e-mail address
                and create a password.
              </p>
              <p>
                Peer2Punt does not have any access to your funds. During a
                trade, the Bets are locked in multisig escrow, which requires 2
                (out of 3) keys to release the Bets, which means that no
                transaction can happen without the signature of one of the
                contract parties.
              </p>
            </div>
          </div>
          <div className="item" id="are-you-holding-my-funds">
            <div className="fq-sub">Are you holding my funds?</div>
            <div className="body">
              <p>
                No, we are not holding your funds. At Peer2Punt you are trading
                directly with your counterparty. We only hold one out of the
                three keys to the multisig escrow, which ensures safe trading
                for both buyers and sellers.
              </p>
            </div>
          </div>
          <div className="item" id="trusted-devices">
            <div className="fq-sub">What are Trusted Devices?</div>
            <div className="body">
              <p>
                Trusted Devices is an advanced level of security for your
                account. When it’s enabled, you're only allowed to log in from
                trusted devices, and every time you try to log in from a new
                device, you are required to confirm it through email before
                being able to log in. To enable the functionality, go to the
                "Profile" tab in your "Profile settings" and mark the checkbox
                "Trusted devices only".
              </p>
            </div>
          </div>
          <div className="item" id="trusted-devices-work">
            <div className="fq-sub">
              How does the "Trusted Devices" feature work?
            </div>
            <div className="body">
              <p>
                After turning on this security feature, every time a new device
                attempts to access your account, you will immediately be sent an
                email containing a unique code, which you'll have to enter on
                Peer2Punt in order to access your account from the new device.
                Otherwise, you would not be able to log in.
              </p>
              <p>
                In case the device has already been confirmed, you do not need
                to verify it again. Also, after you have entered the code, you
                will have an option to not remember the device. Therefore, the
                next time you log in from that device, you must confirm it
                again.
              </p>
            </div>
          </div>
          <div className="item" id="what-is-2fa">
            <div className="fq-sub">What is Two-Factor Authentication?</div>
            <div className="body">
              <p>
                Two-factor authentication (2FA) is an advanced level of security
                for your account. It requires you to enter a 6-digit code every
                time you log into your account. To use 2FA, Peer2Punt requires
                you to have a device with a 2FA application installed. We advise
                you to use Authy or Google Authenticator.
              </p>
            </div>
          </div>
          <div className="item" id="how-to-create-2fa">
            <div className="fq-sub">
              How do I turn on Two-factor authentication for my Peer2Punt
              account?
            </div>
            <div className="body">
              <p>
                Go to the "Two-factor authentication" tab on your "Profile
                settings" page and follow the instructions.
              </p>
            </div>
          </div>
          <div className="item" id="lost-2fa">
            <div className="fq-sub">
              What should I do if the Two-factor authentication code generator
              was lost?
            </div>
            <div className="body">
              <p>
                You can use&nbsp;
                <Link
                  target="_blank"
                  to="https://support.google.com/accounts/answer/1187538"
                >
                  one of your backup codes
                </Link>
                &nbsp; that you had saved in order to sign in, they were created
                when you initially set up Two-factor authentication. Otherwise,
                you can contact the support of the 2FA application you were
                using.
              </p>
              <p>
                The administrators of Peer2Punt have no right to reset your 2FA
                or help you in any other way.
              </p>
            </div>
          </div>
          <div className="item" id="wrong-2fa">
            <div className="fq-sub">
              The site says my 2fa password is wrong. What should I do?
            </div>
            <div className="body">
              <p>
                Invalid tokens are sometimes caused by incorrect device clock
                settings. Your clock must show the correct local time, time
                zone, and date to work properly. Phones must be set to use
                internet time to make sure the clock is synced properly. If you
                do not wish to sync your clock, Android phones have an option to
                correct for time errors inside the Authenticator app properties.
              </p>
            </div>
          </div>
          <br />
          <div className="fq-hd">Risks and precautionary measures</div>
          <br />
          <div className="item" id="how-i-can-find-more-about-user">
            <div className="fq-sub">
              How can I find out more about a user before trading?
            </div>
            <div className="body">
              <p>
                You can check the user’s profile before trading, here you’ll
                find:
              </p>
              <ul>
                <li>Reputation and user reviews;</li>
                <li>Statistics from previous trades;</li>
              </ul>
              <p>
                Reputation and reviews are critical in P2P trading, you can
                learn about the experiences of other people who have traded with
                this person before.
              </p>
              <p>
                Also, it is important to know the trading statistics of the
                user. For example, on the user’s profile, you can see how many
                trades they have done before, if there were any disputes, the
                traded amounts etc.
              </p>
            </div>
          </div>
          <div className="item" id="risk-first-trade-limit">
            <div className="fq-sub">Why should I set a First-trade limit?</div>
            <div className="body">
              <p>
                Since an important part of offering trades involves you
                receiving requests from new users - you can limit the potential
                damage new users could do to you by setting a first trade limit.
                This feature limits your first trade with new users to the exact
                amount you set. Also, offers with first-trade limits are less
                attractive to fraudulent buyers.
              </p>
            </div>
          </div>
          <div className="item" id="insecure-Bet">
            <div className="fq-sub">
              Why is it insecure to release Bet before the payment has been
              received?
            </div>
            <div className="body">
              <p>
                Never release Bet in favor of the buyer if you have not received
                the payment, even if the trader is reliable, "Verified" and
                trusted with a great reputation, since no one can be held
                responsible if the payment never arrives. In other words,
                release the Bet in favor of the buyer only if you have already
                received the funds, otherwise, you would only have yourself to
                blame.
              </p>
            </div>
          </div>
          <div className="item" id="measures-selling-Bet">
            <div className="fq-sub">
              What precautionary measures can I take when selling Bet?
            </div>
            <div className="body">
              <p>
                To avoid any fraud associated with the payment, and to protect
                yourself as much as possible, you should be aware of any and all
                risks associated with each payment method you consider.
              </p>
              <p>
                We suggest that you research your desired payment method, read
                its "Terms &amp; Conditions" then research and learn about other
                users' experiences/acc before using the payment method yourself.
              </p>
            </div>
          </div>
          <br />
          <div className="fq-hd">Account &amp; Profile</div>
          <br />
          <div className="item" id="change-password">
            <div className="fq-sub">How do I change my password?</div>
            <div className="body">
              <p>
                On the 'Profile settings' page, go to the 'Change password' tab.
              </p>
            </div>
          </div>
          <div className="item" id="forgot-password">
            <div className="fq-sub">
              What should I do if I forgot my password?
            </div>
            <div className="body">
              <p>
                Don’t worry. On the "Sign in" page, click "Forgot your
                password?' and follow the instructions.
              </p>
            </div>
          </div>
          <div className="item" id="not-receive-confirmation">
            <div className="fq-sub">
              What should I do if I did not receive confirmation instructions?
            </div>
            <div className="body">
              <p>
                On the "Sign in" page, click "Didn't receive confirmation
                instructions?" and follow the instructions.
              </p>
            </div>
          </div>
          <div className="item" id="change-my-email">
            <div className="fq-sub">How do I change my e-mail?</div>
            <div className="body">
              <p>
                On the "Profile settings" page, go to the "Profile" tab, there
                you will find a section with further instructions. Then confirm
                the change by clicking the link we sent to your old email
                address.
              </p>
            </div>
          </div>
          <div className="item" id="share-my-reputation">
            <div className="fq-sub">
              How can I share my reputation from other P2P trading platforms?
            </div>
            <div className="body">
              <p>
                Peer2Punt allows you to share your reputation from other P2P
                trading platforms with our traders. Go to the "P2P Profiles" tab
                on the "Profile settings" page and follow the instructions.
              </p>
              <p>
                Once you have successfully submitted your request, the
                administrator will review your profile (takes up to 24 hours),
                and your nickname from another P2P trading platform will be
                displayed on your profile at Peer2Punt.
              </p>
            </div>
          </div>
          <div className="item" id="setup-telegram-notification">
            <div className="fq-sub">
              How do I set up the Telegram Notification bot?
            </div>
            <div className="body">
              <p>
                Go to your "Account settings" and select the "Notifications"
                tab, and follow the instructions.
              </p>
            </div>
          </div>
          <div className="item" id="color-dot-indicators">
            <div className="fq-sub">
              What do the color-dot indicators over a user's avatar signify?
            </div>
            <div className="body">
              <ul>
                <li>Green - the user is online.</li>
                <li>Yellow - the user was online recently.</li>
                <li>Grey - the user is offline.</li>
              </ul>
            </div>
          </div>
          <div className="item" id="verified-status">
            <div className="fq-sub">What is "Verified" status?</div>
            <div className="body">
              <p>
                "Verified" users have completed the verification process by
                providing us with all of the necessary documents and
                information. Since voluntary verification has been removed, the
                users who had previously completed the process will continue to
                be granted all of the associated benefits. However we are not
                accepting new applications for verification.
              </p>
            </div>
          </div>
          <div className="item" id="verification-process">
            <div className="fq-sub">
              How do I complete the verification process?
            </div>
            <div className="body">
              <p>
                You do not need to complete verification to trade on Peer2Punt,
                and we no longer perform voluntary verification.
              </p>
            </div>
          </div>
          <div className="item" id="block-another-user">
            <div className="fq-sub">Can I block another user?</div>
            <div className="body">
              <p>
                Yes, to block another user, go to their profile page and press
                the "Block user" button. Blocking a user means:
              </p>
              <ul>
                <li>
                  They won't be able to see your offers in the offer list.
                </li>
                <li>
                  They won't be able to view your offers or create new contracts
                  with you.
                </li>
                <li>You will see the blocked status on their profile page.</li>
                <li>The blocked user won't see that you blocked them.</li>
                <li>
                  You can still view their offers and create new contracts with
                  them.
                </li>
              </ul>
            </div>
          </div>
          <div className="item" id="block-on-my-account">
            <div className="fq-sub">What does a block on my account mean?</div>
            <div className="body">
              <p>
                If a badge saying "Blocked" is placed on your profile page, this
                means you have been blocked by Peer2Punt's administrators.
              </p>
              <p>
                You can still access your account, but with the following
                limitations:
              </p>
              <ul>
                <li>You are unable to create new offers and contracts;</li>
                <li>Your offers are temporarily inactive;</li>
                <li>You can still complete ongoing contracts.</li>
              </ul>
              <p>
                Our support team usually sends an email explaining the reason
                for the block. If you have not received any information about
                being blocked, please contact us at&nbsp;
                <Link to="mailto:support@peer2punt.com">
                  support@peer2punt.com.
                </Link>
              </p>
            </div>
          </div>
          <div className="item" id="my-account-was-banned">
            <div className="fq-sub">
              What does it mean if my account was banned?
            </div>
            <div className="body">
              <p>
                If you have broken the terms of service or any other rules
                associated with using the platform, we reserve the right to ban
                your account. This implies that you would not be able to log
                into your account and use any of Peer2Punt’s services as long as
                your account remains in banned status. Our support team usually
                sends an email explaining the reason for the ban. If you have
                not received any information, please contact us at&nbsp;
                <Link to="mailto:support@peer2punt.com">
                  support@peer2punt.com.
                </Link>
              </p>
            </div>
          </div>
          <div className="item" id="my-account-was-deleted">
            <div className="fq-sub">
              What does it mean if my account was deleted?
            </div>
            <div className="body">
              <p>
                Your account can be deleted in the following cases, either you
                have deleted it at your sole discretion, or the administrators
                of the platform have deleted your account for violating the
                terms, or in accordance with the GDPR (more info in our&nbsp;
                <Link to="https://peer2punt.com/privacy-policy">
                  Privacy Policy
                </Link>
                ). In case your account was deleted, you will not be able to
                restore it.
              </p>
              <p>
                Our support team usually sends an email explaining the reason
                for the deletion. If you have not received any information,
                please contact us at&nbsp;
                <Link to="mailto:support@peer2punt.com">
                  support@peer2punt.com.
                </Link>
              </p>
            </div>
          </div>
          <div className="item" id="how-delete-my-account">
            <div className="fq-sub">How can I delete my account?</div>
            <div className="body">
              <p>
                Go to "Account settings" in the drop-down menu under your
                username, scroll to the bottom of the page, and click the
                "cancel your account" text. Note that your account will be
                permanently deleted and you will not be able to restore it.
              </p>
            </div>
          </div>
          <div className="fq-hd" id="what-is-the-rating-system">
            User Ratings &amp; Reviews
          </div>
          <div className="item" id="why-rating-useful">
            <div className="fq-sub">
              What is the user rating system and why is it useful?
            </div>
            <div className="body">
              <p>
                The user rating system shows the amount of trust other traders
                have in your counterparty. It shows whether he has been
                fulfilling his obligations to other parties, whether he has been
                making fast payments, is responsive, etc. The user rating system
                is a useful tool for traders - it is information about how the
                trader has behaved in the past. With a public rating and
                reputation, users are incentivized to continue being a good
                trader in the future. Every review on Peer2Punt consists of two
                parts: a rating given by the reviewer, and an optional comment.
              </p>
            </div>
          </div>
          <div className="item" id="how-rating-calculated">
            <div className="fq-sub">
              How is the user rating for each user calculated?
            </div>
            <div className="body">
              <p>
                The user rating for each user is calculated as an arithmetic
                average of all ratings given to that user by other traders.
              </p>
            </div>
          </div>
          <div className="item" id="review-another-trader">
            <div className="fq-sub">When can I review another trader?</div>
            <div className="body">
              <p>
                You can leave feedback about your counterparty after
                successfully completing a contract, or after a dispute has been
                resolved.
              </p>
            </div>
          </div>
          <br />
          <div className="fq-hd">Offers</div>
          <br />
          <div className="item" id="what-does-offer-mean">
            <div className="fq-sub">What is an "Offer"?</div>
            <div className="body">
              <p>
                An offer is a proposal to buy or sell Bet on Peer2Punt. To
                create a contract, users need to accept someone's offer.
              </p>
            </div>
          </div>
          <div className="item" id="how-can-my-offer-create">
            <div className="fq-sub">How can I create my own offer?</div>
            <div className="body">
              <p>
                To create your offer, you can click the “Create your offer”
                button located above the offer list, as well as on the “My
                Offers” page. To enable an offer, you need to check the
                “Enabled” checkbox at the bottom of the offer creation / editing
                page.
              </p>
            </div>
          </div>
          <div className="item" id="how-many-offers-can-i-create">
            <div className="fq-sub">How many offers can I create?</div>
            <div className="body">
              <p>
                You can create any number of offers you would like. The only
                limitation is the amount of simultaneously enabled offers you
                can have. For more information, check the "
                <Link to="#why-are-limits-need">Limits</Link>" section.
              </p>
            </div>
          </div>
          <div className="item" id="offer-price-limit">
            <div className="fq-sub">Are there any limits for offer prices?</div>
            <div className="body">
              <p>
                The price of Bet shall not deviate more than 500% from the
                market rate, regardless of the way the price is set up (EG. an
                exact number, tied to an exchange rate, an expression). This
                rule does not apply to private offers.
              </p>
            </div>
          </div>
          <div className="item" id="offer-balance">
            <div className="fq-sub">What is the "Offer Range"?</div>
            <div className="body">
              <p>
                When creating and editing an offer, the creator of the offer can
                specify the range of that offer - that is the minimum and
                maximum amount of betting odds, or minimum and maximum amount of
                funds, in fiat (or cryptocurrency) equivalent, that he wants to
                buy or sell through this offer. The range may change after each
                completed contract.
              </p>
            </div>
          </div>
          <div className="item" id="first-trade-limit">
            <div className="fq-sub">What is the “First-trade limit”?</div>
            <div className="body">
              <p>
                You can set a limit for the first trade with users that have not
                traded with you before.
              </p>
            </div>
          </div>
          <div className="item" id="payment-window">
            <div className="fq-sub">What is the "Payment window"?</div>
            <div className="body">
              <p>
                The payment window is time within which the payment must be made
                and the contract completed. After the Bets are deposited in
                escrow, the countdown begins. If the contract has not been
                completed within the specified time period (the payment window),
                it is possible to cancel the contract or start a dispute.
              </p>
            </div>
          </div>
          <div className="item" id="working-hours">
            <div className="fq-sub">What are "Working hours"?</div>
            <div className="body">
              <p>
                The working hours determine the time period that your offer will
                be active and visible to other traders in the offer list and/or
                available by the link. If the field "start" and "end" have the
                same value, your offer will be available 24/7.
              </p>
            </div>
          </div>
          <div className="item" id="payment-fields-differences">
            <div className="fq-sub">
              What is "Type", "Method", "Your instruction name" and "Payment
              details" in the Payment methods field?
            </div>
            <div className="body">
              <ul>
                <li>
                  <i>Type</i> is the payment method type. For example, a “SEPA
                  (EU) bank transfer” payment method is a “Bank wire” payment
                  type, and payment methods such as “QIWI” and “Yandex Money”
                  belong to “Online payment system”.
                </li>
                <li>
                  <i>Method</i> is the specific method used for the payment. It
                  can be a payment system such as “PayPal” or “QIWI”, etc.
                </li>
                <li>
                  <i>Name of your instruction</i> is the name of the instruction
                  that allows you to find your payment details in the future,
                  without needing to fill in everything again. This can be any
                  name that you find convenient, other users will not see this
                  name.
                </li>
                <li>
                  <i>Payment details</i> are your necessary payment data, such
                  as: account number, account name, or other necessary data for
                  the buyer to make the payment with the selected payment
                  method. This data will only be visible to the buyer after the
                  Bets are locked in escrow and the status of the contract has
                  changed to “In progress”.
                </li>
              </ul>
            </div>
          </div>
          <div className="item" id="should-i-add-payment-instruction-each-time">
            <div className="fq-sub">
              Should I add a new set of payment instructions every time I create
              an offer or contract?
            </div>
            <div className="body">
              <p>
                No, Peer2Punt will remember your payment instructions, they can
                be found under the name you had saved them under before, and the
                next time you create an offer or contract with the same Payment
                method, you will be able choose your payment instructions from
                the dropdown list.
              </p>
            </div>
          </div>
          <div className="item" id="my-payment-details">
            <div className="fq-sub">
              Do other users see my payment details with the description of my
              offer?
            </div>
            <div className="body">
              <p>
                The payment details that you specify when creating an offer or
                contract are ONLY shown to your counterparty in the necessary
                payment statuses of the contract. ONLY AFTER the contract status
                has changed to “In progress” (after successfully sending the Bet
                to escrow) the seller’s payment details are visible to the
                buyer. When the contract has been completed or cancelled, your
                counterparty can no longer access your payment details.
              </p>
              <p>
                Note that the description of the offer is visible to all users,
                even if they are not authorized.
              </p>
            </div>
          </div>
          <div className="item" id="can-i-make-private-offer">
            <div className="fq-sub">Can I make my offer private?</div>
            <div className="body">
              <p>
                Yes, you can make your offer private. This means that other
                users will not see it in the offer list, it will only be
                available by the link. To make your offer private, you need to
                check the “Private” checkbox on the offer creation or editing
                page.
              </p>
            </div>
          </div>
          <div className="item" id="can-i-temporarily-remove-offer">
            <div className="fq-sub">
              Can I make my offer temporarily unavailable?
            </div>
            <div className="body">
              <p>
                Yes, if you would like to make your offer temporarily
                unavailable, click the “Disable” button on the offer page. To
                activate the offer, click the "Enable" button.
              </p>
            </div>
          </div>
          <div className="item" id="how-do-i-delete-my-offer">
            <div className="fq-sub">How do I delete my offer?</div>
            <div className="body">
              <p>
                Go to the “My offers” page, open the offer and click “Delete”.
              </p>
            </div>
          </div>
          <div className="item" id="different-offer-status">
            <div className="fq-sub">What are the different offer statuses?</div>
            <div className="body">
              <ul>
                <li>
                  "Active" – the offer is visible to other users in the offer
                  list and/or available by the link.
                </li>
                <li>
                  "Inactive" – the offer is not visible to other users in the
                  offer list and not available through the link, for example,
                  due to insufficient offer balance, exceeding the limit for
                  active contracts, or working hours.
                </li>
                <li>
                  "Disabled" – the offer is not visible to other users in the
                  offer list and not available through the link. To activate it,
                  you need to enable it.
                </li>
              </ul>
            </div>
          </div>
          <div className="item" id="why-my-offer-inactive">
            <div className="fq-sub">Why did my offer become inactive?</div>
            <div className="body">
              <p>
                Enabled offers can automatically change from “Active” to
                “Inactive” status, and vice versa, in the following cases:
              </p>
              <ul>
                <li>
                  Offer hours. If the offer has working hours, then during these
                  working hours, it will be "Active", otherwise the status will
                  be "Inactive".
                </li>
                <li>
                  Limit for active contracts has been exceeded. In the event
                  that you have reached your limit for active contracts, your
                  offer will become “Inactive”, until the moment you’re able to
                  start a new contract according to your limits. For more
                  information, check the “Limits” section.
                </li>
                <li>
                  Suspension of account. If your account has been suspended,
                  your offer(s) become “Inactive”.
                </li>
              </ul>
              <p>
                In any case, the reason for “Inactive” status can be seen on
                your offer’s page.
              </p>
            </div>
          </div>
          <br />
          <div className="fq-hd">Contracts</div>
          <br />
          <div className="item" id="what-does-contract-mean">
            <div className="fq-sub">What does "Contract" mean?</div>
            <div className="body">
              <p>
                A contract is a trade to buy or sell Bet. When a user accepts an
                offer on the Peer2Punt trading platform, a contract is created.
              </p>
            </div>
          </div>
          <div className="item" id="how-many-contracts-can-i-create">
            <div className="fq-sub">How many Contracts can I create?</div>
            <div className="body">
              <p>
                You can have as many simultaneous active contracts as your limit
                level allows. More information is available in the “
                <Link to="#limits">Limits</Link>” section.
              </p>
            </div>
          </div>
          <div className="item" id="what-are-minimums-to-buy-and-sell">
            <div className="fq-sub">
              What is the minimum amount of Betting Odds I can buy or sell?
            </div>
            <div className="body">
              <p>The minimum trade amount is 1.50 Odds.</p>
            </div>
          </div>
          <div className="item" id="what-is-the-payment-password">
            <div className="fq-sub">What is the payment password?</div>
            <div className="body">
              <p>
                The payment password is the password used to encrypt the keys
                that are required to generate and access escrow. From the
                trader’s perspective, the payment password is used to deposit
                and release Bet, to and from escrow. Every trader is asked to
                create a payment password during the first contract, it can also
                be done in the Dashboard settings.
              </p>
            </div>
          </div>
          <div
            className="item"
            id="account-password-payment-password-difference"
          >
            <div className="fq-sub">
              What's the difference between an account password and a payment
              password?
            </div>
            <div className="body">
              <p>
                The&nbsp;<strong>account password</strong>&nbsp;is the password
                that you use to access your account and make changes to your
                profile.
              </p>
              <p>
                The&nbsp;<strong>payment password</strong>&nbsp;is used to
                encrypt the key to an escrow address, and to deposit Bet into
                escrow and release Bet from escrow.
              </p>
            </div>
          </div>
          <div className="item" id="change-payment-password">
            <div className="fq-sub">How do I change the payment password?</div>
            <div className="body">
              <p>
                The payment password may be changed in the 'Settings' tab of
                your 'Dashboard' settings.
              </p>
            </div>
          </div>
          <div className="item" id="contract-statuses-meaning">
            <div className="fq-sub">
              What are the different contract statuses and what do they mean?
            </div>
            <div className="body">
              <ul>
                <li>
                  <i>Pending</i> - The beginning of a contract workflow. Both
                  parties are confirming their payment passwords in order to
                  generate a unique multisig escrow address.
                </li>
                <li>
                  <i>Depositing</i> - The seller is currently in the process of
                  sending Bet to the multisig escrow address.
                </li>
                <li>
                  <i>Verifying</i> - Peer2Punt is verifying the Bet sent to
                  escrow.
                </li>
                <li>
                  <i>In progress</i> - The Bet is now locked in the multisig
                  escrow address, the buyer can now safely send the payment to
                  the seller.
                </li>
                <li>
                  <i>Paid</i> - The Bets are still locked in escrow, the buyer
                  has indicated that he sent the fiat payment (or cryptocurrency
                  depending on the payment method) to the seller.
                </li>
                <li>
                  <i>Completed</i> - The seller has released the Bet from escrow
                  to the buyer. The contract is finalized.
                </li>
                <li>
                  <i>Disputed</i> - One of the counterparties has started a
                  dispute due to problems or miscommunication.
                </li>
                <li>
                  <i>Resolved</i> — Dispute has been resolved. Resolved disputes
                  cannot be revised or reversed.
                </li>
              </ul>
            </div>
          </div>
          <div className="item" id="how-can-i-cancel-the-contract">
            <div className="fq-sub">How and when can I cancel a contract?</div>
            <div className="body">
              <p>You can only cancel a contract at certain stages:</p>
              <ul>
                <li>
                  "Pending" - Both the seller and the buyer can cancel the
                  contract.
                </li>
                <li>
                  “Depositing" - If 30 minutes has passed and Bet has not been
                  sent to the escrow, then the depositing window has expired,
                  and both parties can cancel the contract.
                </li>
                <li>
                  “In progress" - Only the buyer can cancel the contract. If the
                  payment window has expired, both parties can cancel the
                  contract.
                </li>
              </ul>
              <p>Neither party can cancel the contract in the other stages.</p>
            </div>
          </div>
          <div className="item" id="if-contract-canceled">
            <div className="fq-sub">
              What happens if the contract was cancelled?
            </div>
            <div className="body">
              <p>
                If a contract was cancelled before the Bets were sent to the
                escrow address, then the contract's status changes to
                “Cancelled” and both parties may continue creating new
                contracts.
              </p>
            </div>
          </div>
          <div className="item" id="wrong-amount-to-escrow">
            <div className="fq-sub">
              What happens if the seller sent the wrong amount of Odds to the
              escrow address?
            </div>
            <div className="body">
              <p>
                In the event of sending the wrong amount of Betting Odds to the
                escrow address, the contract will automatically be cancelled as
                soon as Peer2Punt successfully verifies the Bet.
              </p>
            </div>
          </div>
          <div className="item" id="buyer-not-sending-funds">
            <div className="fq-sub">
              The buyer is not sending the funds and the Bets are locked in
              escrow. What should I do?
            </div>
            <div className="body">
              <p>
                You should try contacting your counterparty in the contract's
                chat. When the payment window expires, you'll be able to cancel
                the contract if the status is “In progress” or start a dispute
                if the status is “Paid”.
              </p>
            </div>
          </div>
          <div className="item" id="change-contract-status-to-disputed">
            <div className="fq-sub">
              What should I do if the contract's status is disputed?
            </div>
            <div className="body">
              <p>
                A dispute is a contract status that indicates there are issues
                that both parties are unable to resolve by themselves. If you or
                your counterparty start a dispute, the support manager will
                contact you in the contract's chat within 12 hours after the
                dispute was started. In case you have been involved in a
                dispute, please familiarize yourself with the dispute rules.
              </p>
            </div>
          </div>
          <div className="item" id="disputes-resolved">
            <div className="fq-sub">How are disputes resolved?</div>
            <div className="body">
              <p>
                In case of a dispute, the parties are contacted by a support
                manager in the contract's chat window, and given further
                instructions on the dispute resolution process.
              </p>
              <p>
                After all of the required information has been received and
                analyzed, the support manager will make a decision and decide
                whether to allow a refund of Bet back to the seller, or to allow
                the buyer to release the Bet to his own wallet address.
              </p>
              <p>
                During the dispute process, the Bets remain locked in escrow.
              </p>
            </div>
          </div>
          <div className="item" id="contract-is-being-disputed">
            <div className="fq-sub">
              Does it affect my reputation if the contract is being disputed?
            </div>
            <div className="body">
              <p>
                No, the counterparty may not review you while the contract
                status is disputed, only the winner may submit a review.
                However, your trading statistics in your profile do contain
                information about disputes and their outcomes.
              </p>
            </div>
          </div>
          <br />
          <div className="fq-hd">Payment methods</div>
          <br />
          <div
            className="item"
            id="what-payment-methods-are-available-at-exchange"
          >
            <div className="fq-sub">
              What payment methods are available at Peer2Punt?
            </div>
            <div className="body">
              <p>
                You can find a full list of the payment methods available at
                Peer2Punt on the "
                <Link to="/payment-methods">Payment Methods</Link>" page in the
                Dashboard.
              </p>
              <p>
                Please note that when you search for payment methods that are
                NOT on this page, this search is based on the country selected,
                therefore you may return no results, despite the payment method
                existing, because the payment method only operates in a certain
                country.
              </p>
            </div>
          </div>
          <div className="item" id="can-i-suggest-a-new-payment-method">
            <div className="fq-sub">
              How can I suggest a new payment method for Peer2Punt?
            </div>
            <div className="body">
              <p>
                You are always able to propose a new payment method, and after
                moderation, it might be added to the list of supported payment
                methods.
              </p>
              <p>
                Open the “Payment methods” page in the Dashboard and go to the “
                <Link to="/payment-methods/new">
                  Add payment method (moderated)
                </Link>
                ” tab and fill in every required field.
              </p>
              <p>
                Note: The name of the payment method must be written exactly as
                the company represents itself (e.g. the correct version would be
                "PayPal", an incorrect version would be "paypal").
              </p>
            </div>
          </div>
          <div className="item" id="where-can-i-find-the-status-of-my-request">
            <div className="fq-sub">
              Where can I find the status of my request?
            </div>
            <div className="body">
              <p>
                The administrator of the platform will consider your request
                within the next 72 hours after you have proposed it. Afterwards,
                you will receive a notification about the status of your
                request.
              </p>
            </div>
          </div>
          <div className="fq-hd" id="limits">
            Limits
          </div>
          <p>
            We apply a specific limit level to every user on our trading
            platform. Limits restrict the number of simultaneous active offers
            and contracts a user can have. After successfully completing a
            specific number of contracts, along with other requirements, limit
            level increases.
          </p>
          <br />
          <div className="item" id="why-are-limits-need">
            <div className="fq-sub">Why are limits needed?</div>
            <div className="body">
              <p>
                We apply limits to users for the sake of improving the overall
                user experience, specifically your safety and level of comfort
                when using our trading platform.
              </p>
              <p>
                Some of the unique features offered by our platform could
                potentially become a target of any of the well known spam &amp;
                scam cases in the crypto-community, so we have taken the
                necessary steps to reduce this possibility so that unfair users
                cannot harm you. User limits help protect users.
              </p>
            </div>
          </div>
          <div className="item" id="kind-of-limits">
            <div className="fq-sub">
              What kind of limits are there on Peer2Punt?
            </div>
            <div className="body">
              <p>
                We give each user a specific limit level, and depending on this
                level, specific limits are applied.
              </p>
              <p>Each user is limited by:</p>
              <ul>
                <li>
                  The total number of contracts a user can simultaneously have
                </li>
                <li>
                  The total number of enabled offers a user can simultaneously
                  have (we do not limit the total number of offers you can have
                  - only enabled offers)
                </li>
                <li>
                  The minimum and maximum amount of Betting Odds that can be
                  traded in a single contract
                </li>
              </ul>
            </div>
          </div>
          <div className="item" id="limit-levels">
            <div className="fq-sub">What are the limit levels?</div>
            <div className="body">
              <p>
                In the table below, we have provided detailed information about
                the limits and conditions applied to each limit level
              </p>
              <div className="itemsTable">
                <table>
                  <tbody>
                    <tr>
                      <th className="level">Level</th>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <th className="max-contracts">Max contracts allowed</th>
                      <td>{ulData ? ulData.limits.ult1.ull1.ulmax : "-"}</td>
                      <td>{ulData ? ulData.limits.ult1.ull2.ulmax : "-"}</td>
                      <td>{ulData ? ulData.limits.ult1.ull3.ulmax : "-"}</td>
                    </tr>
                    <tr>
                      <th className="max-offers">Max active offers allowed</th>
                      <td>{ulData ? ulData.limits.ult2.ull1.ulmax : "-"}</td>
                      <td>{ulData ? ulData.limits.ult2.ull2.ulmax : "-"}</td>
                      <td>{ulData ? ulData.limits.ult2.ull3.ulmax : "-"}</td>
                    </tr>
                    <tr>
                      <th className="max-offers">Max price per offer</th>
                      <td>{"+" + ult3l1pcMax + "%"}</td>
                      <td>{"+" + ult3l2pcMax + "%"}</td>
                      <td>{"+" + ult3l3pcMax + "%"}</td>
                    </tr>
                    <tr>
                      <th className="max-offers">Ideal Peer2Punt Rating</th>
                      <td>{ull1ideal}</td>
                      <td>{ull2ideal}</td>
                      <td>{ull3ideal}</td>
                    </tr>
                    <tr>
                      <th className="max-trade-amount-Bet">
                        Max volume of one time trade in Betting Odds
                      </th>
                      <td>{ult4l1opMaxOD}</td>
                      <td>{ult4l2opMaxOD}</td>
                      <td>{ult4l3opMaxOD}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                You can find information about your current limit level in
                the&nbsp;
                <Link to="/dashboard">Dashboard</Link>.
              </p>
            </div>
          </div>
          <div className="item" id="reach-limits-level">
            <div className="fq-sub">How do I reach the next limit level?</div>
            <div className="body">
              <p>
                To reach the 2nd limit level, a user has to complete all of the
                following conditions:
              </p>
              <ul>
                <li>Successfully complete 10 contracts;</li>
                <li>Complete trades with 5 different users.</li>
              </ul>
              <p>
                To reach the 3rd limit level, a user has to complete all of the
                following conditions:
              </p>
              <ul>
                <li>Successfully complete 50 contracts;</li>
                <li>Reach 30 Bet in total traded volume.</li>
              </ul>
            </div>
          </div>
          <div className="item" id="increase-limit-level">
            <div className="fq-sub">
              Is it possible to increase my limit level beyond the 3rd level?
            </div>
            <div className="body">
              <p>
                These requests are considered on an individual basis. If you
                would like this, please send a request to&nbsp;
                <Link to="mailto:support@peer2punt.com">
                  support@peer2punt.com.
                </Link>
              </p>
            </div>
          </div>
          <div className="item" id="can-limit-conditions-changed">
            <div className="fq-sub">
              Can the limits and conditions mentioned above ever be changed?
            </div>
            <div className="body">
              <p>
                The price of Bet fluctuates regularly, and we reserve the right
                to change the limits and conditions provided above, so that they
                are consistent and relevant to the prices.
              </p>
              <p>
                If any changes are expected to occur, or do occur, we will
                immediately inform you through our primary contact methods.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
