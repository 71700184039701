import puntimage from "../assets/image/lm-img.png";
import xbet from "../assets/image/featured/1xbet-icon.png";
import besoccer from "../assets/image/featured/besoccer-icon.png";
import bet9ja from "../assets/image/featured/bet9ja-icon.png";
import bet365 from "../assets/image/featured/bet365-icon.png";
import betking from "../assets/image/featured/betking-icon.png";
import sofascore from "../assets/image/featured/sofascore-icon.png";
import sportybet from "../assets/image/featured/sportybet-icon.png";
import BuyBet from "./Landing/BuyBet";
import SellBet from "./Landing/SellBet";
import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "../Landing.css";
import $ from "jquery";

const Landing = (props) => {
  const mkt = props.mkt;
  const uLogin = props.uLogin;

  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  const handleClick = (data) => {
    return data;
  };

  const handleChange = (data) => {
    return data;
  };

  const handleSubmit = (data) => {
    return data;
  };

  const switchMKT = (data) => {
    return { setMKT: data };
  };

  const toggleFQ = (fq) => {
    if (!$(`#${fq}`).hasClass("show")) {
      $(`#${fq}`).addClass("show");
    } else {
      $(`#${fq}`).removeClass("show");
    }
    return true;
  };

  return (
    <>
      <div className="new-design">
        New design release! Better trading experience. Enjoy! &#10084;
      </div>
      <div className="land-main">
        <div className="lm-1">
          <div className="lm-1-item">
            <img src={puntimage} alt="Punt For Free" className="lm-img" />
          </div>
          <div className="lm-1-item">
            <div className="lm-title">
              P2P BET
              <br />
              TRADING
              <br />
              PLATFORM
            </div>
            <div className="lm-subtitle">
              Non-custodial Bet trading solution, we don't hold your funds.
            </div>
            <div className="lm-action">
              {!uLogin ? (
                <Link
                  title="Get Started"
                  to="/accounts/signup"
                  className="btn btn-gold"
                  onClick={(e) =>
                    props.onClick(
                      handleLink(
                        e,
                        "/accounts/signup",
                        "Get started",
                        "Sign-Up"
                      )
                    )
                  }
                >
                  Sign Up
                </Link>
              ) : (
                <Link
                  title="Create buy offer"
                  to="/create-buy-offer"
                  className="btn btn-gold"
                  onClick={(e) =>
                    props.onClick(
                      handleLink(
                        e,
                        "/create-buy-offer",
                        "Create buy offer",
                        "Create-Buy-Offer"
                      )
                    )
                  }
                >
                  Create Offer
                </Link>
              )}
              <span className="btn btn-special-alt">
                <svg
                  viewBox="0 0 600 600"
                  version="1.1"
                  id="svg9724"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#dde3f5"
                >
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="m 300.60937,-12.792969 c -173.60599,0 -315.214839,141.724839 -315.214839,315.404299 0,173.67945 141.608849,315.40429 315.214839,315.40429 173.606,0 315.21485,-141.72484 315.21485,-315.40429 0,-173.67946 -141.60885,-315.404299 -315.21485,-315.404299 z m 0,84.082031 c 128.13278,10e-7 231.13086,103.052738 231.13086,231.322268 0,128.26952 -102.99808,231.32226 -231.13086,231.32226 C 172.4766,533.93359 69.476562,430.88085 69.476562,302.61133 69.476563,174.3418 172.4766,71.289062 300.60937,71.289062 Z"
                      id="path390"
                      transform="matrix(0.95173205,0,0,0.95115787,13.901174,12.168794)"
                    ></path>
                    <path
                      d="m -398.20312,-131.11523 c -16.48395,0.26965 -35.23134,4.07707 -52.1504,14.84375 -22.55873,14.35556 -35.28801,36.895201 -41.42382,55.398433 -6.13582,18.503232 -7.98007,35.430567 -9.04883,53.7089845 -2.13752,36.5568355 0.013,79.3192215 2.44726,135.7246125 2.4343,56.40539 3.97483,99.19684 9.25391,135.43359 2.63954,18.11838 5.9374,34.82271 13.64453,52.72852 7.70713,17.90581 22.32937,39.26438 46.04102,51.62304 23.71165,12.35866 49.59532,12.11167 68.6875,8.17383 19.09217,-3.93784 34.67315,-10.80199 51.03711,-19.01562 32.7279,-16.42728 68.68701,-39.67157 116.31836,-69.98243 47.63135,-30.31085 83.91774,-53.04222 112.660152,-75.73242 14.371204,-11.3451 27.190429,-22.55221 38.84375,-38.17969 C -30.239257,157.9819 -19.05412,134.64 -20.207031,107.92578 -21.359943,81.21156 -34.516295,58.918905 -47.472656,44.353516 -60.429017,29.788127 -74.163789,19.72689 -89.458984,9.6621094 -120.04937,-10.467451 -158.15896,-29.987502 -208.22461,-56.082031 c -50.06565,-26.094529 -87.89452,-46.154619 -121.91601,-59.701169 -17.01075,-6.77328 -33.12393,-12.2705 -52.48438,-14.54883 -4.84011,-0.56958 -10.08348,-0.87309 -15.57812,-0.7832 z m 33.08203,148.781246 c 22.40613,10.204773 53.82076,26.297393 93.87109,47.171875 40.04989,20.874254 71.23529,37.409549 92.43359,49.931639 -20.04065,14.30187 -49.68432,33.46176 -87.78711,57.70899 -38.10291,24.24731 -68.01535,42.98677 -89.45898,55.08398 -2.36541,-24.50663 -4.13668,-59.75975 -6.08398,-104.88086 -1.94733,-45.121699 -3.21993,-80.396292 -2.97461,-105.015624 z"
                      id="path343"
                      transform="matrix(0.58387874,0.03211745,-0.03232889,0.58772279,477.39992,239.7183)"
                    ></path>
                  </g>
                </svg>
                What is Peer2Punt?
              </span>
            </div>
            <div className="lm-achieve">
              <div className="lm-ach-item">
                <div className="lm-ach-hd">4 YEARS</div>
                <div className="lm-ach-ft">in business</div>
              </div>
              <div className="lm-ach-item">
                <div className="lm-ach-hd">100+</div>
                <div className="lm-ach-ft">currencies</div>
              </div>
              <div className="lm-ach-item">
                <div className="lm-ach-hd">1000+</div>
                <div className="lm-ach-ft">deals closed</div>
              </div>
              <div className="lm-ach-item">
                <div className="lm-ach-hd">5000+</div>
                <div className="lm-ach-ft">registered users</div>
              </div>
            </div>
          </div>
          <div className="lm-1-item">
            <img src={puntimage} alt="Punt For Free" className="lm-img" />
          </div>
        </div>
        <div className="lm-2">
          <div className="lw-hd">WELCOME TO PEER2PUNT</div>
          <div className="lw-bd">
            P2P deals on your terms. Trade globally.
            <br />
            Using any payment system. For any currency.
          </div>
          <div className="lw-ft">
            <span
              className="btn btn-special-alt"
              onClick={(e) =>
                props.onClick(
                  handleLink(e, "/market/buy-bet", "Buy Bet", "Buy-Bet")
                )
              }
            >
              View all available offers
            </span>
          </div>
          <div className="lm-market">
            <div className="lm-m-box">
              <div className="lm-m-hd">
                <button
                  className={`btn btn-special-2 ${
                    mkt === "buy" ? "active" : null
                  }`}
                  onClick={() => props.onClick(switchMKT("buy"))}
                >
                  Buy Bet
                </button>
                <button
                  className={`btn btn-special-2 ${
                    mkt === "sell" ? "active" : null
                  }`}
                  onClick={() => props.onClick(switchMKT("sell"))}
                >
                  Sell Bet
                </button>
              </div>
              {mkt === "buy" ? (
                <BuyBet
                  cyData={props.cyData}
                  cy={props.cy}
                  pmData={props.pmData}
                  pm={props.pm}
                  od={props.od}
                  slt={props.slt}
                  search={props.search}
                  onClick={(e) => props.onClick(handleClick(e))}
                  onChange={(e) => props.onChange(handleChange(e))}
                  onSubmit={(e) => props.onSubmit(handleSubmit(e))}
                />
              ) : null}
              {mkt === "sell" ? (
                <SellBet
                  cyData={props.cyData}
                  cy={props.cy}
                  pmData={props.pmData}
                  pm={props.pm}
                  od={props.od}
                  slt={props.slt}
                  search={props.search}
                  onClick={(e) => props.onClick(handleClick(e))}
                  onChange={(e) => props.onChange(handleChange(e))}
                  onSubmit={(e) => props.onSubmit(handleSubmit(e))}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="lm-3">
          <div className="lm-3-hd">PEER2PUNT. WE CARE ABOUT YOUR PRIVACY.</div>
          <div className="lm-3-bd">
            <div className="lm-3-item">
              <div className="lm-3-icon">
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  id="secure"
                  className="icon glyph"
                  fill="#dde3f5"
                  width="75%"
                >
                  <g id="SVGRepo_iconCarrier">
                    <path d="M19.42,3.83,12.24,2h0A.67.67,0,0,0,12,2a.67.67,0,0,0-.2,0h0L4.58,3.83A2,2,0,0,0,3.07,5.92l.42,5.51a12,12,0,0,0,7.24,10.11l.88.38h0a.91.91,0,0,0,.7,0h0l.88-.38a12,12,0,0,0,7.24-10.11l.42-5.51A2,2,0,0,0,19.42,3.83ZM15.71,9.71l-4,4a1,1,0,0,1-1.42,0l-2-2a1,1,0,0,1,1.42-1.42L11,11.59l3.29-3.3a1,1,0,0,1,1.42,1.42Z"></path>
                  </g>
                </svg>
              </div>
              <div className="lm-3-title">SECURE</div>
              <div className="lm-3-info">Multisig smart contracts</div>
            </div>
            <div className="lm-3-item">
              <div className="lm-3-icon">
                <svg
                  viewBox="0 0 24 24"
                  fill="#dde3f5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="70%"
                >
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M22 8.29344C22 11.7692 19.1708 14.5869 15.6807 14.5869C15.0439 14.5869 13.5939 14.4405 12.8885 13.8551L12.0067 14.7333C11.4883 15.2496 11.6283 15.4016 11.8589 15.652C11.9551 15.7565 12.0672 15.8781 12.1537 16.0505C12.1537 16.0505 12.8885 17.075 12.1537 18.0995C11.7128 18.6849 10.4783 19.5045 9.06754 18.0995L8.77362 18.3922C8.77362 18.3922 9.65538 19.4167 8.92058 20.4412C8.4797 21.0267 7.30403 21.6121 6.27531 20.5876L5.2466 21.6121C4.54119 22.3146 3.67905 21.9048 3.33616 21.6121L2.45441 20.7339C1.63143 19.9143 2.1115 19.0264 2.45441 18.6849L10.0963 11.0743C10.0963 11.0743 9.3615 9.90338 9.3615 8.29344C9.3615 4.81767 12.1907 2 15.6807 2C19.1708 2 22 4.81767 22 8.29344ZM15.681 10.4889C16.8984 10.4889 17.8853 9.50601 17.8853 8.29353C17.8853 7.08105 16.8984 6.09814 15.681 6.09814C14.4635 6.09814 13.4766 7.08105 13.4766 8.29353C13.4766 9.50601 14.4635 10.4889 15.681 10.4889Z"
                      fill="#dde3f5"
                    ></path>
                  </g>
                </svg>
              </div>
              <div className="lm-3-title">NON-CUSTODIAL</div>
              <div className="lm-3-info">
                You control the keys to the
                <br />
                bets in escrow
              </div>
            </div>
            <div className="lm-3-item">
              <div className="lm-3-icon">
                <svg
                  viewBox="0 0 24 24"
                  fill="#dde3f5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="80%"
                >
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M4.6138 8.54479L4.1875 10.25H2C1.58579 10.25 1.25 10.5858 1.25 11C1.25 11.4142 1.58579 11.75 2 11.75H22C22.4142 11.75 22.75 11.4142 22.75 11C22.75 10.5858 22.4142 10.25 22 10.25H19.8125L19.3862 8.54479C18.8405 6.36211 18.5677 5.27077 17.7539 4.63538C16.9401 4 15.8152 4 13.5653 4H10.4347C8.1848 4 7.05988 4 6.24609 4.63538C5.43231 5.27077 5.15947 6.36211 4.6138 8.54479ZM6.5 21C8.12316 21 9.48826 19.8951 9.88417 18.3963L10.9938 17.8415C11.6272 17.5248 12.3728 17.5248 13.0062 17.8415L14.1158 18.3963C14.5117 19.8951 15.8768 21 17.5 21C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14C15.8399 14 14.4498 15.1558 14.0903 16.7065L13.6771 16.4999C12.6213 15.972 11.3787 15.972 10.3229 16.4999L9.90967 16.7065C9.55023 15.1558 8.16009 14 6.5 14C4.567 14 3 15.567 3 17.5C3 19.433 4.567 21 6.5 21Z"
                      fill="#dde3f5"
                    ></path>
                  </g>
                </svg>
              </div>
              <div className="lm-3-title">ANONYMOUS</div>
              <div className="lm-3-info">No verification required</div>
            </div>
          </div>
        </div>
        <div className="lm-4">
          <div className="lm-4-hd">
            <div className="lm-4-hd-text">GETTING STARTED</div>
            <div className="lm-4-hd-action">
              <span className="btn btn-special-alt">
                <svg
                  viewBox="0 0 600 600"
                  version="1.1"
                  id="svg9724"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#dde3f5"
                >
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="m 300.60937,-12.792969 c -173.60599,0 -315.214839,141.724839 -315.214839,315.404299 0,173.67945 141.608849,315.40429 315.214839,315.40429 173.606,0 315.21485,-141.72484 315.21485,-315.40429 0,-173.67946 -141.60885,-315.404299 -315.21485,-315.404299 z m 0,84.082031 c 128.13278,10e-7 231.13086,103.052738 231.13086,231.322268 0,128.26952 -102.99808,231.32226 -231.13086,231.32226 C 172.4766,533.93359 69.476562,430.88085 69.476562,302.61133 69.476563,174.3418 172.4766,71.289062 300.60937,71.289062 Z"
                      id="path390"
                      transform="matrix(0.95173205,0,0,0.95115787,13.901174,12.168794)"
                    ></path>
                    <path
                      d="m -398.20312,-131.11523 c -16.48395,0.26965 -35.23134,4.07707 -52.1504,14.84375 -22.55873,14.35556 -35.28801,36.895201 -41.42382,55.398433 -6.13582,18.503232 -7.98007,35.430567 -9.04883,53.7089845 -2.13752,36.5568355 0.013,79.3192215 2.44726,135.7246125 2.4343,56.40539 3.97483,99.19684 9.25391,135.43359 2.63954,18.11838 5.9374,34.82271 13.64453,52.72852 7.70713,17.90581 22.32937,39.26438 46.04102,51.62304 23.71165,12.35866 49.59532,12.11167 68.6875,8.17383 19.09217,-3.93784 34.67315,-10.80199 51.03711,-19.01562 32.7279,-16.42728 68.68701,-39.67157 116.31836,-69.98243 47.63135,-30.31085 83.91774,-53.04222 112.660152,-75.73242 14.371204,-11.3451 27.190429,-22.55221 38.84375,-38.17969 C -30.239257,157.9819 -19.05412,134.64 -20.207031,107.92578 -21.359943,81.21156 -34.516295,58.918905 -47.472656,44.353516 -60.429017,29.788127 -74.163789,19.72689 -89.458984,9.6621094 -120.04937,-10.467451 -158.15896,-29.987502 -208.22461,-56.082031 c -50.06565,-26.094529 -87.89452,-46.154619 -121.91601,-59.701169 -17.01075,-6.77328 -33.12393,-12.2705 -52.48438,-14.54883 -4.84011,-0.56958 -10.08348,-0.87309 -15.57812,-0.7832 z m 33.08203,148.781246 c 22.40613,10.204773 53.82076,26.297393 93.87109,47.171875 40.04989,20.874254 71.23529,37.409549 92.43359,49.931639 -20.04065,14.30187 -49.68432,33.46176 -87.78711,57.70899 -38.10291,24.24731 -68.01535,42.98677 -89.45898,55.08398 -2.36541,-24.50663 -4.13668,-59.75975 -6.08398,-104.88086 -1.94733,-45.121699 -3.21993,-80.396292 -2.97461,-105.015624 z"
                      id="path343"
                      transform="matrix(0.58387874,0.03211745,-0.03232889,0.58772279,477.39992,239.7183)"
                    ></path>
                  </g>
                </svg>
                Simple tutorial
              </span>
            </div>
          </div>
          <div className="lm-4-bd">
            <div className="lm-4-item">
              <div className="lm-4-step">STEP 1</div>
              <div className="lm-4-title">Contract creation</div>
              <div className="lm-4-info">
                Buyer and seller engage in a trade.
                <br />
                <br />
                Peer2Punt generates a unique multisig escrow for the Bet.
              </div>
            </div>
            <div className="lm-4-item">
              <div className="lm-4-step">STEP 2</div>
              <div className="lm-4-title">Payment transfer</div>
              <div className="lm-4-info">
                Seller provides the Bet to multisig escrow.
                <br />
                <br />
                Buyer pays the seller according to the agreed upon payment
                method.
              </div>
            </div>
            <div className="lm-4-item">
              <div className="lm-4-step">STEP 3</div>
              <div className="lm-4-title">Bet releasing & Contract closure</div>
              <div className="lm-4-info">
                Seller releases Bet from multisig escrow directly to the buyer.
                <br />
                <br />
                Contract is completed.
              </div>
            </div>
          </div>
        </div>
        <div className="lm-5">
          <div className="lm-5-hd">CREATE AN OFFER ON YOUR TERMS</div>
          <div className="lm-5-bd">
            <div className="lm-5-items">
              <span className="lm-5-item">Add your events</span>
              <span className="lm-5-item">Choose your punts</span>
              <span className="lm-5-item">Set your bets</span>
            </div>
            <div className="lm-5-action">
              {!uLogin ? (
                <Link
                  title="Get Started"
                  to="/accounts/signup"
                  className="btn btn-gold"
                  onClick={(e) =>
                    props.onClick(
                      handleLink(
                        e,
                        "/accounts/signup",
                        "Get started",
                        "Sign-Up"
                      )
                    )
                  }
                >
                  Try Peer2Punt
                </Link>
              ) : (
                <Link
                  title="Create buy offer"
                  to="/create-buy-offer"
                  className="btn btn-gold"
                  onClick={(e) =>
                    props.onClick(
                      handleLink(
                        e,
                        "/create-buy-offer",
                        "Create buy offer",
                        "Create-Buy-Offer"
                      )
                    )
                  }
                >
                  Create Offer
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="lm-6">
          <div className="lm-6-hd">FAQ</div>
          <div className="lm-6-fq">
            <div className="lm-fq-item">
              <div className="lm-fq-hd" onClick={(e) => toggleFQ("fq1")}>
                <div className="lm-fq-qt">What problems are we solving?</div>
                <div className="lm-fq-pointer">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
              <div className="lm-fq-bd" id="fq1">
                Offering fast and easy Bet trading without middle-party and
                verification.
              </div>
            </div>
            <div className="lm-fq-item">
              <div className="lm-fq-hd" onClick={(e) => toggleFQ("fq2")}>
                <div className="lm-fq-qt">How do I start?</div>
                <div className="lm-fq-pointer">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
              <div className="lm-fq-bd" id="fq2">
                Sign up with an email address and password, follow the
                confirmation link sent to your email address, engage in trades
                right away!
              </div>
            </div>
            <div className="lm-fq-item">
              <div className="lm-fq-hd" onClick={(e) => toggleFQ("fq3")}>
                <div className="lm-fq-qt">
                  What cryptocurrencies are available?
                </div>
                <div className="lm-fq-pointer">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
              <div className="lm-fq-bd" id="fq3">
                All the major ones and more.
              </div>
            </div>
            <div className="lm-fq-item">
              <div className="lm-fq-hd" onClick={(e) => toggleFQ("fq4")}>
                <div className="lm-fq-qt">What do I need to start trading?</div>
                <div className="lm-fq-pointer">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
              <div className="lm-fq-bd" id="fq4">
                Peer2Punt does not operate any betting platform, this means that
                in order to stake your bets you need to visit a betting website
                or shop.
              </div>
            </div>
            <div className="lm-fq-item">
              <div className="lm-fq-hd" onClick={(e) => toggleFQ("fq5")}>
                <div className="lm-fq-qt">First time visiting us?</div>
                <div className="lm-fq-pointer">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
              <div className="lm-fq-bd" id="fq5">
                We have guides for you on{" "}
                <Link
                  to="/how-to"
                  onClick={(e) =>
                    props.onClick(handleLink(e, "/how-to", "How to", "How-to"))
                  }
                >
                  how to start using our trading platform.
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="lm-7">
          <div className="lm-7-hd">FEATURED ON PEER2PUNT</div>
          <div className="lm-7-list">
            <img src={xbet} alt="1XBET" />
            <img src={besoccer} alt="BESOCCER" />
            <img src={bet9ja} alt="BET9JA" />
            <img src={bet365} alt="BET365" />
          </div>
          <div className="lm-7-list">
            <img src={betking} alt="BETKING" />
            <img src={sofascore} alt="SOFASCORE" />
            <img src={sportybet} alt="SPORTYBET" />
          </div>
          <div className="lm-7-text">...and more!</div>
          <div className="lm-7-note">
            Peer2Punt is not affiliated with any betting platform
            <br />
            or any live score platform or any payment platform,
            <br />
            whether they are listed on the Peer2Punt.com
            <br />
            website or not.
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/welcome" element="" />
      </Routes>
    </>
  );
};

export default Landing;
