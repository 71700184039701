import React from "react";
import RBOut from "./RightBar/RBOut";
import RBIn from "./RightBar/RBIn";
import "../RightBar.css";
import $ from "jquery";

const RightBar = (props) => {
  const uLogin = props.uLogin;
  const rb = props.rb;
  const rbDrop = props.rbDrop;
  if (rb === "on") $(".right-bar").addClass("show");
  else $(".right-bar").removeClass("show");
  if (rbDrop ) $(".rb-drop").slideDown(300);
  else $(".rb-drop").slideUp(300);

  const handleClick = (data) => {
    return data;
  };

  return (
    <>
      <div className="right-bar">
        {rb === "on" ? (
          !uLogin ? (
            <RBOut onClick={(e) => props.onClick(handleClick(e))} />
          ) : (
            <RBIn rbDrop={rbDrop} onClick={(e) => props.onClick(handleClick(e))} />
          )
        ) : null}
      </div>
    </>
  );
};

export default RightBar;
