import React from "react";
import { Routes, Route, Link } from "react-router-dom";

const Calendar = (props) => {
  const today = new Date();
  const [sCalendar, setsCalendar] = React.useState(today);
  const sDate = props.sDate ? props.sDate : today;

  const findMonthDays = (y, m) => {
    return new Date(y, m + 1, 0).getDate();
  };

  const findFirstDay = (y, m) => {
    return new Date(y, m, 1).getDay();
  };

  const changeToPrevMonth = () => {
    setsCalendar((pDate) => {
      const pMonth = pDate.getMonth() - 1;
      const pYear = pDate.getFullYear();
      return new Date(pYear, pMonth);
    });
  };

  const changeToNextMonth = () => {
    setsCalendar((pDate) => {
      const nMonth = pDate.getMonth() + 1;
      const nYear = pDate.getFullYear();
      return new Date(nYear, nMonth);
    });
  };

  const handleDateClick = (e, url, dt, date) => {
    e.preventDefault();
    if (date === today) setsCalendar(date);
    let setsDate = { date: date, dt: dt };
    let pState = {
      title: dt,
      newUrl: url,
      rto: "Calendar",
      rid: dt,
    };
    return { pState: pState, setsDate: setsDate };
  };

  const showCalendar = () => {
    const y = sCalendar.getFullYear();
    const m = sCalendar.getMonth();
    const mDays = findMonthDays(y, m);
    const fDay = findFirstDay(y, m);
    const tDays = 42;
    let pp = 0;

    const allDays = [];

    // For empty cells
    for (let p = 1; p <= fDay; p++) {
      allDays.push(
        <span key={`em-${p}`} className="dd2">
          &#8226;
        </span>
      );
      pp = p;
    }

    // Show actual days
    for (let d = 1; d <= mDays; d++) {
      const date = new Date(y, m, d);
      const isSelected = sDate && date.toDateString() === sDate.toDateString();
      const isToday = date.toDateString() === today.toDateString();
      let yr = date.getFullYear();
      let mt = date.getMonth() + 1;
      mt = mt < 10 ? `0${mt}` : mt;
      let dy = date.getDate();
      dy = dy < 10 ? `0${dy}` : dy;
      let dt = `${yr}-${mt}-${dy}`;
      dt = isToday ? "Today" : dt;
      let url = `/football/${dt.toLowerCase()}`;

      allDays.push(
        <Link
          to={url}
          key={`d-${d}`}
          className={`dd${isSelected ? " active" : ""}${
            isToday ? " today" : ""
          }`}
          onClick={(e) => props.onClick(handleDateClick(e, url, dt, date))}
        >
          {d}
        </Link>
      );
    }

    // For empty cells
    for (let l = mDays + pp; l < tDays; l++) {
      allDays.push(
        <span key={`em-${l}`} className="dd2">
          &#8226;
        </span>
      );
    }

    return <div className="days">{allDays}</div>;
  };

  return (
    <>
      <div className="calendar">
        <div className="month">
          <button className="hbtn mn" onClick={changeToPrevMonth}>
            <i className="fa fa-chevron-left"></i>
          </button>
          <span className="mn" id="mn">
            {sCalendar.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button className="hbtn mn" onClick={changeToNextMonth}>
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>
        <div className="days">
          <span className="dt">S</span>
          <span className="dt">M</span>
          <span className="dt">T</span>
          <span className="dt">W</span>
          <span className="dt">T</span>
          <span className="dt">F</span>
          <span className="dt">S</span>
        </div>
        <div className="days">
          <span className="df">Sun</span>
          <span className="df">Mon</span>
          <span className="df">Tue</span>
          <span className="df">Wed</span>
          <span className="df">Thu</span>
          <span className="df">Fri</span>
          <span className="df">Sat</span>
        </div>
        {showCalendar()}
        <Link
          to="/football/today/all"
          title="today"
          className="djump"
          onClick={(e) =>
            props.onClick(
              handleDateClick(e, "/football/today/all", "Today", today)
            )
          }
        >
          Today&#39;s Events <i className="fa fa-arrow-right"></i>
        </Link>
      </div>
      <Routes>
        <Route path="/" element="" />
        <Route path="/football" element="" />
        <Route path="/football/today" element="" />
      </Routes>
    </>
  );
};

export default Calendar;
