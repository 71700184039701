import React from "react";
import backIcon from "../assets/svg/back.png";
//import { Link } from "react-router-dom";
import asc from "../assets/svg/svgexport-15.png";
import desc from "../assets/svg/svgexport-6.png";
import infoerror from "../assets/svg/info-error.png";
import checked from "../assets/svg/svgexport-75.png";
import attatch from "../assets/svg/attatch.png";
import close from "../assets/svg/close.png";
import ongoing from "../assets/svg/ongoing.png";
import success from "../assets/svg/success.png";
import thumbup from "../assets/svg/thumb-up.png";
import thumbdown from "../assets/svg/thumb-down.png";
import "../assets/css/offer.css";

const BuyOffer = (props) => {
  const uLogin = props.uLogin;
  const ptData = props.ptData;
  const poData = props.poData;
  const urData = props.urData;
  const types = ptData ? ptData.types : null;
  const options = poData ? poData.options : null;
  const ratings = urData ? urData.ratings : null;
  const prTotal = ratings ? ratings.total : "0.00";
  const poTotal = ratings ? ratings.options : 0;
  const cyData = props.cyData;
  const pmData = props.pmData;
  const pt = props.pt;
  const bmData = props.bmData;
  const opData = props.opData;
  const sBOData = props.sBOData;
  const sBOCData = props.sBOCData;
  const bocErrors = props.bocErrors;
  const bocEMSG = props.bocEMSG;
  const offer = sBOData ? sBOData.offer : null;
  const boodt = offer ? offer.boodt : null;
  const boMinOD = offer ? offer.bood.min : 0;
  const boMaxOD = offer ? (boodt === "fixed" ? boMinOD : offer.bood.max) : 0;
  const od = props.od;
  const opPrice = offer ? offer.boprice : 1;
  const boEOP = props.boEOP;
  const excluded = boEOP.length;
  const selected = parseInt(poTotal) - parseInt(excluded);
  const boEOPR = props.boEOPR;
  const prSelected = parseFloat(prTotal) - parseFloat(boEOPR);
  const boW = props.boW;
  const boPM = props.boPM;
  const boPMVal = boPM ? (pmData ? pmData.paymethods[boPM] : null) : null;
  const bocy = offer ? offer.bocy : null;
  const bocy2 = cyData ? (offer ? cyData.currencies[bocy] : null) : null;
  const bocycode = bocy2 ? bocy2.cycode : "-";
  const slt = props.slt;
  const ngnUSD = cyData ? cyData.ngnusd : 1220;

  /*const handleLink = (e, url, title, rto, sBo) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState, setsBOData: sBo };
  };*/

  const viewOffers = (e) => {
    e.preventDefault();
    let pState = {
      title: "Buy Bet",
      newUrl: "/market/buy-bet",
      rto: "Buy-Bet",
      rid: null,
    };
    return { pState: pState };
  };

  const toggleSLT = (data) => {
    return { setSLT: data, setOVSLT: true };
  };

  const setOD = (data) => {
    return { setOD: data, setBOCErrors: { indexes: [0, 1, 2], bool: false } };
  };

  const getUSD = (cynum, amount) => {
    if (!cyData) return "0.50";
    const cy2 = cyData.currencies[cynum];
    let cyUSD = cy2.cyusd,
      usdAmount = parseFloat(amount) / parseFloat(ngnUSD),
      newAmount = parseFloat(cyUSD) * usdAmount;
    newAmount = parseFloat(newAmount.toFixed(2));
    return newAmount.toLocaleString("en-GB", 2);
  };

  const getODPrice = () => {
    if (!od || !opData || !sBOData)
      return (
        <div className="o-b-item field">
          <input
            type="text"
            name="amount"
            id="amount"
            value="0.0"
            readOnly
            autoComplete="false"
          />
          <span className="mi-currency">{bocycode}</span>
        </div>
      );
    const prices = opData.prices;
    const cyNum = offer.bocy;
    let price = 0.0;
    for (let [key, val] of Object.entries(prices)) {
      let maxOD = parseFloat(val.max),
        price2 = parseFloat(val.price);
      if (parseFloat(od) < maxOD) {
        price = price2;
        break;
      }
      if (key === "no need") {
        break;
      }
    }
    price = (opPrice / 100) * price + parseFloat(price);
    price = getUSD(cyNum, price);

    return (
      <div className="o-b-item field">
        <input
          type="text"
          name="amount"
          id="amount"
          value={price}
          readOnly
          autoComplete="false"
        />
        <span className="mi-currency">{bocycode}</span>
      </div>
    );
  };

  const setBOPM = (pmid) => {
    return {
      setBOPM: pmid,
      setSLT: "none",
      setOVSLT: true,
      setBOCErrors: { indexes: [3], bool: false },
    };
  };

  const optplchldr = () => {
    return (
      <div className="o-b-item op-box plchldr mt-2">
        <div className="op-types">
          <span className="op-t-item animated-gradient"></span>
          <span className="op-t-item animated-gradient"></span>
          <span className="op-t-item animated-gradient"></span>
          <span className="op-t-item animated-gradient"></span>
          <span className="op-t-item animated-gradient"></span>
          <span className="op-t-item animated-gradient"></span>
          <span className="op-t-item animated-gradient"></span>
          <span className="op-t-item animated-gradient"></span>
          <span className="op-t-item animated-gradient"></span>
        </div>
        <div className="op-options">
          <div className="op-o-group">
            <div className="op-o-hd">-</div>
            <div className="op-o-bd">
              <span className="op-o-item animated-gradient"></span>
              <span className="op-o-item animated-gradient"></span>
              <span className="op-o-item animated-gradient"></span>
            </div>
          </div>
          <div className="op-o-group">
            <div className="op-o-hd">-</div>
            <div className="op-o-bd">
              <span className="op-o-item animated-gradient"></span>
              <span className="op-o-item animated-gradient"></span>
              <span className="op-o-item animated-gradient"></span>
            </div>
          </div>
          <div className="op-o-group">
            <div className="op-o-hd">-</div>
            <div className="op-o-bd">
              <span className="op-o-item animated-gradient"></span>
              <span className="op-o-item animated-gradient"></span>
              <span className="op-o-item animated-gradient"></span>
            </div>
          </div>
        </div>
        <div className="op-info">
          <div className="op-r-box">
            <div className="op-ratings">
              <div className="op-r-item total">
                <span className="animated-gradient"></span>
                <span className="animated-gradient"></span>
                <span className="animated-gradient"></span>
              </div>
              <div className="op-r-item selected">
                <span className="animated-gradient"></span>
                <span className="animated-gradient"></span>
                <span className="animated-gradient"></span>
              </div>
            </div>
            <span className="btn animated-gradient"></span>
          </div>
        </div>
      </div>
    );
  };

  const setPT = (data) => {
    return { setPT: data };
  };

  const setBOEOP = (poid, porating) => {
    return {
      setBOEOP: poid,
      setBOEOPR: porating,
      setBOCErrors: { indexes: [4, 5], bool: false },
    };
  };

  const puntOptions = () => {
    const allTypes = [];
    const t1Options = [];
    const t2Options = [];
    const t3Options = [];
    const t4Options = [];
    const t5Options = [];
    const t7Options = [];
    const t8Options = [];
    const t9Options = [];

    allTypes.push(
      <button
        key={"pt0"}
        className={`btn btn-plain op-t-item ${pt === "ptAll" ? "active" : ""}`}
        onClick={() => props.onClick(setPT("ptAll"))}
      >
        {parseFloat(prTotal) ? (
          <span className="golden mr-1">&#9733;</span>
        ) : (
          ""
        )}
        All
      </button>
    );

    for (let [key, val] of Object.entries(types)) {
      let ptname = val.ptname,
        ptrating = ratings[key],
        ptrTotal = ptrating.total;
      allTypes.push(
        <button
          key={key}
          className={`btn btn-plain op-t-item ${pt === key ? "active" : ""}`}
          onClick={() => props.onClick(setPT(key))}
        >
          {parseFloat(ptrTotal) ? (
            <span className="golden mr-1">&#9733;</span>
          ) : (
            ""
          )}
          {ptname}
        </button>
      );
    }

    for (let [key, val] of Object.entries(options)) {
      let potype = val.potype,
        poname = val.poname,
        podesc = val.podesc,
        pospec = val.pospec,
        ptNum = `pt${potype}`,
        ptrating = ratings[ptNum],
        porating = ptrating[key];
      porating = porating.porating;
      potype = parseInt(potype);
      let tOptions =
        potype < 9
          ? potype < 8
            ? potype < 7
              ? potype < 5
                ? potype < 4
                  ? potype < 3
                    ? potype < 2
                      ? t1Options
                      : t2Options
                    : t3Options
                  : t4Options
                : t5Options
              : t7Options
            : t8Options
          : t9Options;
      poname = potype <= 2 || potype === 5 || potype === 8 ? poname : pospec;
      let pobtn = parseFloat(porating) ? (
        <button
          key={key}
          title={podesc ? podesc : val.poname}
          className={`btn btn-plain op-o-item ${
            parseFloat(porating) ? "dat" : ""
          } ${!boEOP.includes(key) ? "active" : ""}`}
          onClick={() => props.onClick(setBOEOP(key, porating))}
        >
          <div className="op-bar">
            <span>&#9733;</span>
            <small>{porating}</small>
          </div>
          <div className="op-name">
            <span>{poname}</span>
          </div>
        </button>
      ) : (
        <button
          key={key}
          title={podesc ? podesc : val.poname}
          className={`btn btn-plain op-o-item`}
          disabled
        >
          <div className="op-bar">
            <span>&#9733;</span>
            <small>0.00</small>
          </div>
          <div className="op-name">
            <span>{poname}</span>
          </div>
        </button>
      );
      tOptions.push(pobtn);
    }

    return (
      <div className="o-b-item op-box">
        <div className="op-types">{allTypes}</div>
        <div
          className={`op-options ${
            selected <= 0 || bocErrors[4] || bocErrors[5] ? "error" : ""
          }`}
        >
          {pt === "ptAll" || pt === "pt1" ? (
            <div className="op-o-group">
              <div className="op-o-hd">{types.pt1.ptname}</div>
              <div className="op-o-bd">{t1Options}</div>
            </div>
          ) : (
            ""
          )}
          {pt === "ptAll" || pt === "pt2" ? (
            <div className="op-o-group">
              <div className="op-o-hd">{types.pt2.ptname}</div>
              <div className="op-o-bd">{t2Options}</div>
            </div>
          ) : (
            ""
          )}
          {pt === "ptAll" || pt === "pt3" ? (
            <div className="op-o-group">
              <div className="op-o-hd">{types.pt3.ptname}</div>
              <div className="op-o-bd op-o-bd-7">{t3Options}</div>
            </div>
          ) : (
            ""
          )}
          {pt === "ptAll" || pt === "pt4" ? (
            <div className="op-o-group">
              <div className="op-o-hd">{types.pt4.ptname}</div>
              <div className="op-o-bd">{t4Options}</div>
            </div>
          ) : (
            ""
          )}
          {pt === "ptAll" || pt === "pt5" ? (
            <div className="op-o-group">
              <div className="op-o-hd">{types.pt5.ptname}</div>
              <div className="op-o-bd op-o-bd-5">{t5Options}</div>
            </div>
          ) : (
            ""
          )}
          {pt === "ptAll" || pt === "pt7" ? (
            <div className="op-o-group">
              <div className="op-o-hd">{types.pt7.ptname}</div>
              <div className="op-o-bd op-o-bd-3">{t7Options}</div>
            </div>
          ) : (
            ""
          )}
          {pt === "ptAll" || pt === "pt8" ? (
            <div className="op-o-group">
              <div className="op-o-hd">{types.pt8.ptname}</div>
              <div className="op-o-bd op-o-bd-7">{t8Options}</div>
            </div>
          ) : (
            ""
          )}
          {pt === "ptAll" || pt === "pt9" ? (
            <div className="op-o-group">
              <div className="op-o-hd">{types.pt9.ptname}</div>
              <div className="op-o-bd op-o-bd-2">{t9Options}</div>
            </div>
          ) : (
            ""
          )}
        </div>
        {bocErrors[4] || !selected ? (
          <div className="field-error error">
            <img src={infoerror} alt="Info" />
            {bocEMSG.bocmsg4}
          </div>
        ) : (
          ""
        )}
        {bocErrors[5] || !selected ? (
          <div className="field-error error">
            <img src={infoerror} alt="Info" />
            {bocEMSG.bocmsg5}
          </div>
        ) : (
          ""
        )}
        <div className="op-info">
          <div className="op-r-box">
            <div className="op-ratings">
              <div className="op-r-item total">
                <span>Available options:</span>
                <span>{poTotal}</span>
                <span className="golden">
                  (&#9733; = {parseFloat(prTotal).toFixed(2)})
                </span>
              </div>
              <div className="op-r-item selected">
                <span>Selected options:</span>
                <span>{selected}</span>
                <span className="golden">
                  (&#9733; = {prSelected.toFixed(2)})
                </span>
              </div>
            </div>
            {boEOP.length ? (
              <button
                title="Include all available options"
                className="btn btn-gold"
                onClick={() => props.onClick(setBOEOP("All", "All"))}
              >
                Select All
              </button>
            ) : (
              <button
                title="Include all available options"
                className="btn btn-gold"
                disabled
              >
                Select All
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const pmOptions = () => {
    const nodata = (
      <div>
        <div key="boslt1" className="o-b-item field d-flex pr-1">
          <div className="select disabled">
            <div className="select-val">-</div>
            <div className="select-pointer">
              <img src={desc} alt="Desc" />
            </div>
          </div>
        </div>
      </div>
    );
    if (!pmData || !sBOData) return nodata;
    const allPayMethods = [];
    for (let i = 1; i <= 7; i++) {
      let bopmNum = "bopm" + i,
        pmid = offer.bopm[bopmNum];
      if (!pmid) continue;
      let pmNum = pmid,
        pmVal = pmData.paymethods[pmNum],
        pmname = pmVal.pmname;
      allPayMethods.push(
        <button
          key={`bopm${i}`}
          className={`btn btn-special-alt-3 option-val ${
            boPM === pmid ? "active" : ""
          }`}
          onClick={() => props.onClick(setBOPM(pmid))}
        >
          {pmname}
        </button>
      );
    }

    return (
      <div>
        <div
          key="boslt1"
          className={`o-b-item field d-flex pr-1 ${
            bocErrors[3] ? "error" : ""
          }`}
        >
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("paymethod"))}
          >
            <div className="select-val">{boPMVal ? boPMVal.pmname : "-"}</div>
            <div className="select-pointer">
              <img src={slt === "paymethod" ? asc : desc} alt="Desc" />
            </div>
          </div>
        </div>
        {bocErrors[3] ? (
          <div className="field-error error">
            <img src={infoerror} alt="Info" />
            {bocEMSG.bocmsg3}
          </div>
        ) : (
          ""
        )}
        {slt === "paymethod" ? (
          <div className="options">{allPayMethods}</div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const boplchldr = () => {
    return (
      <div className="mk-offer plchldr">
        <div className="offer-item-2 u">
          <div className="offer-user">
            <span className="icon animated-gradient"></span>
            <span className="name animated-gradient"></span>
          </div>
          <div className="offer-rating">
            <span className="rating animated-gradient"></span>
            <span className="trades animated-gradient"></span>
          </div>
        </div>
        <div className="offer-item-2 l">
          <div className="o-i-group">
            <div className="offer-title-2 animated-gradient"></div>
            <div className="offer-price animated-gradient"></div>
          </div>
          <div className="o-i-group">
            <div className="offer-title-2 animated-gradient"></div>
            <div className="offer-limit">
              <span className="odds-range animated-gradient"></span>
              <span className="price-range animated-gradient"></span>
            </div>
          </div>
        </div>
        <div className="offer-item-2 l">
          <div className="o-i-group">
            <div className="offer-title-2 animated-gradient"></div>
            <div className="offer-price animated-gradient"></div>
          </div>
          <div className="o-i-group">
            <div className="offer-title-2 animated-gradient"></div>
            <div className="offer-price animated-gradient"></div>
          </div>
          <div className="o-i-group">
            <div className="offer-title-2 animated-gradient"></div>
            <div className="offer-price animated-gradient"></div>
          </div>
        </div>
        <div className="offer-item-2 m">
          <div className="offer-title-2 animated-gradient mb-2"></div>
          <div className="offer-methods">
            <span className="offer-method"></span>
            <span className="offer-method"></span>
            <span className="offer-method"></span>
            <span className="offer-method"></span>
            <span className="offer-method"></span>
            <span className="offer-method"></span>
          </div>
        </div>
        <div className="offer-item-2 t">
          <div className="offer-title-2 animated-gradient mb-2"></div>
          <div className="offer-price animated-gradient"></div>
        </div>
      </div>
    );
  };

  const buyOffer = () => {
    let bou = offer.bou,
      boprice = offer.boprice,
      boprange = offer.boprange,
      boprMin = boprange.min,
      boprMax = boprange.max,
      bopm = offer.bopm,
      bopm1 = bopm.bopm1,
      bopm2 = bopm.bopm2,
      bopm3 = bopm.bopm3,
      bopm4 = bopm.bopm4,
      bopm5 = bopm.bopm5,
      bopm6 = bopm.bopm6,
      bopm7 = bopm.bopm7,
      bobm = offer.bobm,
      bopw = offer.bopw,
      bott = offer.bott;
    const pm1 = bopm1 ? pmData.paymethods[bopm1] : null,
      pm2 = bopm2 ? pmData.paymethods[bopm2] : null,
      pm3 = bopm3 ? pmData.paymethods[bopm3] : null,
      pm4 = bopm4 ? pmData.paymethods[bopm4] : null,
      pm5 = bopm5 ? pmData.paymethods[bopm5] : null,
      pm6 = bopm6 ? pmData.paymethods[bopm6] : null,
      pm7 = bopm7 ? pmData.paymethods[bopm7] : null;
    const bm = bmData ? bmData.bookmakers[bobm] : null;
    const bmname = bm ? bm.bmname : "-";

    return (
      <div className="mk-offer">
        <div className="offer-item-2 u">
          <div className="offer-user">
            <span className="icon">{bou.bouname.substring(0, 1)}</span>
            <span className="name">
              {bou.bouname}&nbsp;
              <span
                className={`flag-icon flag-icon-${bou.boucc.toLowerCase()}`}
              ></span>
            </span>
          </div>
          <div className="offer-rating">
            <span className="rating">&#9733;: {bou.bourating}</span>
            <span className="trades">
              {bou.bouwin} wins, {bou.boutrades} trades
            </span>
          </div>
        </div>
        <div className="offer-item-2 l">
          <div className="o-i-group">
            <div className="offer-title-2">Price:</div>
            <div className="offer-price">+{boprice}%</div>
          </div>
          <div className="o-i-group">
            <div className="offer-title-2">Limits:</div>
            <div className="offer-limit">
              <span className="odds-range">
                {boMinOD}{" "}
                {boodt === "range" ? <span>&#8212; {boMaxOD}</span> : ""} Odds
              </span>
              {boprMin === boprMax ? (
                <span className="price-range">
                  {getUSD(bocy, boprMax)} {bocycode}
                </span>
              ) : (
                <span className="price-range">
                  {getUSD(bocy, boprMin)} &#8212; {getUSD(bocy, boprMax)}{" "}
                  {bocycode}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="offer-item-2 l">
          <div className="o-i-group">
            <div className="offer-title-2">Payment window:</div>
            <div className="offer-price">{bopw} min.</div>
          </div>
          <div className="o-i-group">
            <div className="offer-title-2">Bookmaker:</div>
            <div className="offer-price">{bmname}</div>
          </div>
          <div className="o-i-group">
            <div className="offer-title-2">Sport:</div>
            <div className="offer-price">Football</div>
          </div>
        </div>
        <div className="offer-item-2 m">
          <div className="offer-title-2 mb-1">Payment methods:</div>
          <div className="offer-methods">
            {pm1 ? <span className="offer-method">{pm1.pmname}</span> : ""}
            {pm2 ? <span className="offer-method">{pm2.pmname}</span> : ""}
            {pm3 ? <span className="offer-method">{pm3.pmname}</span> : ""}
            {pm4 ? <span className="offer-method">{pm4.pmname}</span> : ""}
            {pm5 ? <span className="offer-method">{pm5.pmname}</span> : ""}
            {pm6 ? <span className="offer-method">{pm6.pmname}</span> : ""}
            {pm7 ? <span className="offer-method">{pm7.pmname}</span> : ""}
          </div>
        </div>
        <div className="offer-item-2 t">
          <div className="offer-title-2">Offer details:</div>
          <div className="offer-price">{bott}</div>
        </div>
      </div>
    );
  };

  const setBOW = (bool) => {
    return {
      setBOW: { val: bool },
    };
  };

  const createBOC = () => {
    if (!uLogin) return { setSN: true };
    return { createBOC: true };
  };

  const bocplchldr = () => {
    const allPlchdr = [];
    for (let i = 1; i <= 3; i++) {
      allPlchdr.push(
        <div key={`boc${i}`} className="o-b-h-item plchldr">
          <div className="h-hd">
            <div className="offer-user">
              <span className="icon animated-gradient">&nbsp;</span>
              <span className="name animated-gradient"></span>
            </div>
            <div className="h-time animated-gradient"></div>
          </div>
          <div className="h-bd">
            <div className="h-bd-group">
              <div className="h-bd-group-1">
                <div className="group">
                  <div className="h-bd-tt animated-gradient"></div>
                  <div className="offer-price animated-gradient"></div>
                </div>
                <div className="group">
                  <div className="h-bd-tt animated-gradient"></div>
                  <div className="offer-price animated-gradient"></div>
                </div>
                <div className="group">
                  <div className="h-bd-tt animated-gradient"></div>
                  <div className="offer-price animated-gradient"></div>
                </div>
              </div>
              <div className="h-bd-group-1">
                <div className="h-bd-tt animated-gradient"></div>
                <div className="offer-methods">
                  <span className="offer-method"></span>
                </div>
                <div className="h-bd-tt animated-gradient"></div>
              </div>
            </div>
            <div className="h-bet">
              <div className="h-bet-bd">
                <div className="h-bets">
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                  <span className="h-bet-item"></span>
                </div>
                <div className="h-result"></div>
              </div>
              <div className="user-feedback">
                <div className="h-bd-tt animated-gradient"></div>
                <div className="u-fb-group">
                  <span className="u-fb-item u-fb-message"></span>
                  <span className="u-fb-item u-fb-thumb"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return allPlchdr;
  };

  const offerContracts = () => {
    if (!sBOCData) return "";
    const nodata = <div className="o-b-h-item nodata">No history</div>;
    const total = sBOCData.total;
    const contracts = sBOCData.contracts;
    if (!total) return nodata;
    const allCN = [];
    for (let [key, val] of Object.entries(contracts)) {
      let cndt = val.cndt,
        cnu = val.cnu,
        cnuname = cnu.cnuname,
        cnucc = cnu.cnucc,
        cnpr = val.cnpr,
        cnod = val.cnod,
        cntp = parseInt(val.cntp),
        cnpm = val.cnpm,
        cnbet = val.cnbet,
        cnfb = val.cnfb;
      let pm = pmData.paymethods[cnpm];
      const bocPN = () => {
        const allPN = [];
        for (let i = 1; i <= 30; i++) {
          let pnNum = `pn_${i}`,
            pnVal = cnbet[pnNum];
          allPN.push(
            <span key={`pn${i}`} className="h-bet-item" title={pnVal}>
              {cntp >= i ? (
                pnVal !== "Ongoing" ? (
                  pnVal !== "Won" ? (
                    <img src={close} alt="Result" />
                  ) : (
                    <img src={success} alt="Result" />
                  )
                ) : (
                  <img src={ongoing} alt="Result" />
                )
              ) : (
                ""
              )}
            </span>
          );
        }
        return allPN;
      };
      allCN.push(
        <div key={key} className="o-b-h-item">
          <div className="h-hd">
            <div className="offer-user">
              <span className="icon">{cnuname.substring(0, 1)}</span>
              <span className="name">
                {cnuname}&nbsp;
                <span
                  className={`flag-icon flag-icon-${cnucc.toLowerCase()}`}
                ></span>
              </span>
            </div>
            <div className="h-time">{cndt}</div>
          </div>
          <div className="h-bd">
            <div className="h-bd-group">
              <div className="h-bd-group-1">
                <div className="group">
                  <div className="h-bd-tt">Price:</div>
                  <div className="offer-price">+{cnpr}%</div>
                </div>
                <div className="group">
                  <div className="h-bd-tt">Odds:</div>
                  <div className="offer-price">{cnod}</div>
                </div>
                <div className="group">
                  <div className="h-bd-tt">Events:</div>
                  <div className="offer-price">{cntp}</div>
                </div>
              </div>
              <div className="h-bd-group-1">
                <div className="h-bd-tt">Payment method:</div>
                <div className="offer-methods">
                  <span className="offer-method">{pm.pmname}</span>
                </div>
                <div className="h-bd-tt">Bet result:</div>
              </div>
            </div>
            <div className="h-bet">
              <div className="h-bet-bd">
                <div className="h-bets">{bocPN()}</div>
                <div className="h-result">
                  <span title={cnbet.status}>
                    {cnbet.status !== "Ongoing" ? (
                      cnbet.status !== "Won" ? (
                        <img src={close} alt="Result" />
                      ) : (
                        <img src={success} alt="Result" />
                      )
                    ) : (
                      <img src={ongoing} alt="Result" />
                    )}
                    <div>{cnbet.status}</div>
                  </span>
                </div>
              </div>
              <div className="user-feedback">
                <div className="h-bd-tt">Buyer feedback:</div>
                <div className="u-fb-group">
                  <span className="u-fb-item u-fb-message">{cnfb.msg}</span>
                  <span className="u-fb-item u-fb-thumb">
                    {cnfb.type === "liked" ? (
                      <img src={thumbup} alt="Thumb" />
                    ) : (
                      <img src={thumbdown} alt="Thumb" />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return allCN;
  };

  /*const addFile = (e) => {
    const files = e.target.files;
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    setUserFiles([...userFiles, ...fileNames]);
  };*/

  return (
    <>
      <div className="new-design">
        New design release! Better trading experience. Enjoy! &#10084;
      </div>
      <div className="offer-main">
        <button
          className="btn btn-plain offer-nav"
          onClick={(e) => props.onClick(viewOffers(e))}
        >
          <img src={backIcon} alt="Back" className="mr-1" />
          Back to all offers
        </button>
        <div className="offer-hd">Buy Bet</div>
        {sBOData ? buyOffer() : boplchldr()}

        <div className="offer-body">
          <div className="of-box-1">
            <div className="of-box-item">
              <div className="of-title">
                <span className="o-t-item">I want to buy Bet of</span>
              </div>
              <div className="of-body">
                <div className="o-b-group-1">
                  {boodt ? (
                    boodt === "range" ? (
                      <div
                        className={`o-b-item field ${
                          (boMinOD && parseFloat(od) < boMinOD) ||
                          (boMaxOD && parseFloat(od) > boMaxOD) ||
                          bocErrors[0] ||
                          bocErrors[1] ||
                          bocErrors[2]
                            ? "error"
                            : ""
                        }`}
                      >
                        <input
                          type="number"
                          name="odds"
                          id="odds"
                          step=".01"
                          defaultValue={od}
                          autoComplete="false"
                          onChange={(e) =>
                            props.onChange(setOD(e.target.value))
                          }
                        />
                        <span className="mi-currency">Odds</span>
                      </div>
                    ) : (
                      <div className={`o-b-item field`}>
                        <input
                          type="number"
                          name="odds"
                          id="odds"
                          step=".01"
                          defaultValue={boMinOD}
                          readOnly
                          autoComplete="false"
                        />
                        <span className="mi-currency">Odds</span>
                      </div>
                    )
                  ) : (
                    <div className={`o-b-item field`}>
                      <input type="number" name="odds" id="odds" disabled />
                      <span className="mi-currency">Odds</span>
                    </div>
                  )}
                  ={getODPrice()}
                </div>
                {bocErrors[0] ? (
                  <div className="field-error error">
                    <img src={infoerror} alt="Info" />
                    {bocEMSG.bocmsg0}
                  </div>
                ) : (
                  ""
                )}
                {bocErrors[1] ||
                (boodt === "range" && boMinOD && parseFloat(od) < boMinOD) ? (
                  <div className="field-error error">
                    <img src={infoerror} alt="Info" />
                    {bocEMSG.bocmsg1 + " " + boMinOD}
                  </div>
                ) : (
                  ""
                )}
                {bocErrors[2] ||
                (boodt === "range" && boMaxOD && parseFloat(od) > boMaxOD) ? (
                  <div className="field-error error d-inline-flex">
                    <img src={infoerror} alt="Info" />
                    {bocEMSG.bocmsg2 + " " + boMaxOD}
                  </div>
                ) : (
                  ""
                )}
                <div className="o-b-note dual">
                  <span>Enter the amount you want to buy</span>
                </div>
              </div>
            </div>
            <div className="of-box-item">
              <div className="of-title">
                <span className="o-t-item">Payment method</span>
              </div>
              <div className="of-body">{pmOptions()}</div>
            </div>
            <div className="of-box-item">
              <div className="of-title">
                <span className="o-t-item">Options filter</span>
              </div>
              <div className="o-b-info">
                Choose which punt options the seller may include or exclude from
                the Bet. All available options are selected (included) by
                default.
              </div>
              <div className="of-body">
                {!ptData || !poData || !urData ? optplchldr() : puntOptions()}
              </div>
            </div>
            <div className="of-box-item nobb">
              <div className="o-b-group-3">
                <div className="o-b-item">
                  {!boW ? (
                    <button
                      className="checkmark-icon btn"
                      onClick={(e) => props.onClick(setBOW(true))}
                    ></button>
                  ) : (
                    <button
                      className="checkmark-icon btn checked"
                      onClick={(e) => props.onClick(setBOW(false))}
                    >
                      <img src={checked} alt="Checked" />
                    </button>
                  )}
                </div>
                <div className="o-b-item">
                  {!boW ? (
                    <strong onClick={(e) => props.onClick(setBOW(true))}>
                      Wager seller's Peer2Punt Ratings{" "}
                      <em className="golden">
                        (&#9733; = {prSelected.toFixed(2)})
                      </em>
                    </strong>
                  ) : (
                    <strong onClick={(e) => props.onClick(setBOW(false))}>
                      Wager seller's Peer2Punt Ratings{" "}
                      <em className="golden">
                        (&#9733; = {prSelected.toFixed(2)})
                      </em>
                    </strong>
                  )}
                </div>
              </div>
              <div className="o-b-info">
                When this box is checked, the seller will lose their{" "}
                <strong>Accumulated Peer2Punt Ratings</strong> on each selected
                punt option in the <strong>Options filter</strong> box, if the
                received Bet is lost.
              </div>
            </div>
            <div className="o-b-group-7">
              {!selected ||
              (boMinOD && parseFloat(od) < boMinOD) ||
              (boMaxOD && parseFloat(od) > boMaxOD) ? (
                <button className="btn btn-gold" disabled>
                  Create contract for {boodt === "range" ? od : boMinOD} Odds
                </button>
              ) : (
                <button
                  className="btn btn-gold"
                  onClick={(e) => props.onClick(createBOC())}
                >
                  Create contract for {boodt === "range" ? od : boMinOD} Odds
                </button>
              )}
            </div>
          </div>
          <div className="of-box-2">
            <div className="inner">
              <div className="o-b-tt">Contract history</div>
              <div className="of-history-box">
                {!sBOCData ? bocplchldr() : offerContracts()}
              </div>
            </div>
          </div>
          <div className="of-box-2 d-none">
            <div className="inner">
              <div className="of-box-item nobb">
                <div className="o-b-tt2">
                  <div className="o-b-group-4">
                    <div className="offer-user">
                      <span className="icon">r</span>
                      <span className="name">
                        renfree&nbsp;
                        <span className="flag-icon flag-icon-se"></span>
                      </span>
                    </div>
                    <div className="offer-rating">
                      <span className="rating">★: 21893.10</span>
                    </div>
                  </div>
                  <div className="o-b-group-5">
                    <div className="offer-rating">
                      <span className="trades">303 wins, 0 trades</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="o-b-chatbox"></div>
              <div className="o-b-typebox">
                <div className="o-b-tb-item">
                  <button className="btn btn-gold-alt-2 o-b-tb-item attatch">
                    <img src={attatch} alt="Attach" />
                  </button>
                  {`<input
                      type="file"
                      onChange={(e) => addFile(e)}
                      accept=".jpeg, .png, .jpg, .pdf"
                      ref={uploadFileRef}
                      multiple
                    />`}
                </div>
                <div className="o-b-tb-item field">
                  <input
                    type="text"
                    id="chatmsg"
                    maxLength="5000"
                    placeholder="Type your message here..."
                  />
                </div>
                <div className="o-b-tb-item">
                  <button className="btn btn-gold o-b-tb-item">Send</button>
                </div>
              </div>
              <div className="o-b-files">
                <div className="o-b-f-item">
                  <div className="o-b-file">
                    <div className="o-b-ficon">
                      <img src={attatch} alt="Attach" />
                    </div>
                    <div className="o-b-fname">image1.jpg (20.0KB)</div>
                  </div>
                  <div className="o-b-faction">
                    <button className="btn btn-gold-alt">Remove</button>
                  </div>
                </div>
                <div className="o-b-f-item">
                  <div className="o-b-file">
                    <div className="o-b-ficon">
                      <img src={attatch} alt="Attach" />
                    </div>
                    <div className="o-b-fname">image1.jpg (20.0KB)</div>
                  </div>
                  <div className="o-b-faction">
                    <button className="btn btn-gold-alt">Remove</button>
                  </div>
                </div>
                <div className="o-b-f-item">
                  <div className="o-b-file">
                    <div className="o-b-ficon">
                      <img src={attatch} alt="Attach" />
                    </div>
                    <div className="o-b-fname">image1.jpg (20.0KB)</div>
                  </div>
                  <div className="o-b-faction">
                    <button className="btn btn-gold-alt">Remove</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyOffer;
