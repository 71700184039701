import React from "react";
import { Link } from "react-router-dom";
import asc from "../assets/svg/svgexport-15.png";
import desc from "../assets/svg/svgexport-6.png";
import searchIcon from "../assets/svg/svgexport-93.png";
import close2 from "../assets/svg/close-2.png";
import "../assets/css/market.css";
import $ from "jquery";

const Market = (props) => {
  const uLogin = props.uLogin;
  const cyData = props.cyData;
  const cy = props.cy;
  const cyVal = cyData ? cyData.currencies[cy] : "";
  const pmData = props.pmData;
  const pm = props.pm;
  const pmVal = pm !== "pmAll" && pmData ? pmData.paymethods[pm] : "";
  const pmtData = props.pmtData;
  const pmt = props.pmt;
  const pmtVal = pmt !== "pmtAll" && pmtData ? pmtData.paytypes[pmt] : "";
  const od = props.od;
  const boData = props.boData;
  const ngnUSD = cyData ? cyData.ngnusd : 1220;
  const cyFiltered = [];
  const allFiltered = [];
  const opg = props.opg;
  const slt = props.slt;
  const search = props.search;
  let j = 0,
    k = 0,
    tpg = 0;

  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  const toggleSLT = (data) => {
    return { setSLT: data, setOVSLT: true };
  };

  const setSearch = (data, reset) => {
    if (reset) $(".options-search input").val("");
    data = data.length > 32 ? data.substring(0, 32) : data;
    return { setSearch: { data: data } };
  };

  const setCY = (data) => {
    return { setCY: data, setSLT: "none", setOVSLT: true, delay: true };
  };

  const cyOptions = () => {
    const nodata = (
      <div>
        <div key="flt1" className="filter-item field d-flex pr-1">
          <div className="select disabled">
            <div className="select-val">-</div>
            <div className="select-pointer">
              <img src={desc} alt="Desc" />
            </div>
          </div>
        </div>
      </div>
    );
    if (!cyData) return nodata;
    const allCurrencies = [];
    const total = cyData.total;
    const major = parseInt(cyData.major);
    const currencies = cyData.currencies;
    if (!total) return nodata;
    allCurrencies.push(
      <div key="sch1" className="options-search field">
        <span>
          <img src={searchIcon} alt="Search" />
        </span>
        <span>
          <input
            type="text"
            maxLength="32"
            placeholder="Search"
            autoComplete="false"
            onChange={(e) => props.onChange(setSearch(e.target.value))}
          />
          <button
            type="button"
            className="btn"
            onClick={(e) => props.onClick(setSearch("", true))}
          >
            <img src={close2} alt="Search" />
          </button>
        </span>
      </div>
    );
    let i = 0;
    for (let [key, val] of Object.entries(currencies)) {
      if (i === major) {
        allCurrencies.push(
          <span key="cygap2" className="option-val gap">
            <hr />
          </span>
        );
      }
      i++;
      let cycode = val.cycode;
      if (search) {
        let regex = new RegExp(search, "i");
        if (cycode.search(regex) < 0) continue;
      }
      allCurrencies.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            cy === key ? "active" : ""
          }`}
          onClick={() => props.onClick(setCY(key))}
        >
          {cycode}
        </button>
      );
    }

    return (
      <div>
        <div key="flt1" className="filter-item field d-flex pr-1">
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("currency"))}
          >
            <div className="select-val">{cyVal ? cyVal.cycode : "-"}</div>
            <div className="select-pointer">
              <img src={slt === "currency" ? asc : desc} alt="Desc" />
            </div>
          </div>
        </div>
        {slt === "currency" ? (
          <div className="options">{allCurrencies}</div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const setOD = (data) => {
    if (!parseFloat(data) || parseFloat(data) < 1.05) {
      data = 1.05;
      $("#odds").val(data);
    } else if (parseFloat(data) > 9999.99) {
      data = 9999.99;
      $("#odds").val(data);
    }
    return { setOD: data };
  };

  const setPMT = (pmtid) => {
    return { setPMT: pmtid, setPM: "pmAll", setSLT: "none", setOVSLT: true };
  };

  const pmtOptions = () => {
    const nodata = (
      <div>
        <div key="flt3" className="filter-item field d-flex pr-1">
          <div className="select disabled">
            <div className="select-val">-</div>
            <div className="select-pointer">
              <img src={desc} alt="Desc" />
            </div>
          </div>
        </div>
      </div>
    );
    if (!pmtData) return nodata;
    const allPayTypes = [];
    const total = pmtData.total;
    const paytypes = pmtData.paytypes;
    if (!total) return nodata;
    allPayTypes.push(
      <button
        key={`pmt0`}
        className={`btn btn-special-alt-3 option-val ${
          pmt === "pmtAll" ? "active" : ""
        }`}
        onClick={() => props.onClick(setPMT("pmtAll"))}
      >
        All payment types
      </button>
    );
    for (let [key, val] of Object.entries(paytypes)) {
      let pmtname = val.pmtname;
      allPayTypes.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            pmt === key ? "active" : ""
          }`}
          onClick={() => props.onClick(setPMT(key))}
        >
          {pmtname}
        </button>
      );
    }

    return (
      <div>
        <div key="flt3" className="filter-item field d-flex pr-1">
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("paytype"))}
          >
            <div className="select-val">
              {pmt !== "pmtAll"
                ? pmtVal
                  ? pmtVal.pmtname
                  : "-"
                : "All payment types"}
            </div>
            <div className="select-pointer">
              <img src={slt === "paytype" ? asc : desc} alt="Desc" />
            </div>
          </div>
        </div>
        {slt === "paytype" ? <div className="options">{allPayTypes}</div> : ""}
      </div>
    );
  };

  const setPM = (pmid) => {
    return { setPM: pmid, setSLT: "none", setOVSLT: true };
  };

  const pmOptions = () => {
    const nodata = (
      <div>
        <div key="flt4" className="filter-item field d-flex pr-1">
          <div className="select disabled">
            <div className="select-val">-</div>
            <div className="select-pointer">
              <img src={desc} alt="Desc" />
            </div>
          </div>
        </div>
      </div>
    );
    if (!pmData) return nodata;
    const allPayMethods = [];
    const total = pmData.total;
    const major = parseInt(pmData.major);
    const paymethods = pmData.paymethods;
    if (!total) return nodata;
    allPayMethods.push(
      <button
        key={`pm0`}
        className={`btn btn-special-alt-3 option-val ${
          pm === "pmAll" ? "active" : ""
        }`}
        onClick={(e) => props.onClick(setPM("pmAll"))}
      >
        All payment methods
      </button>
    );
    allPayMethods.push(
      <span key="pmgap1" className="option-val gap">
        <hr />
      </span>
    );
    allPayMethods.push(
      <div key="sch1" className="options-search field">
        <span>
          <img src={searchIcon} alt="Search" />
        </span>
        <span>
          <input
            type="text"
            maxLength="32"
            placeholder="Search"
            autoComplete="false"
            onChange={(e) => props.onChange(setSearch(e.target.value))}
          />
          <button
            type="button"
            className="btn"
            onClick={() => props.onClick(setSearch("", true))}
          >
            <img src={close2} alt="Search" />
          </button>
        </span>
      </div>
    );
    let i = 0;
    for (let [key, val] of Object.entries(paymethods)) {
      if (i === major) {
        allPayMethods.push(
          <span key="pmgap2" className="option-val gap">
            <hr />
          </span>
        );
      }
      i++;
      let pmname = val.pmname,
        pmtype = val.pmtype;
      if (pmt !== "pmtAll") {
        if (pmt !== pmtype) continue;
      }
      if (search) {
        let regex = new RegExp(search, "i");
        if (pmname.search(regex) < 0) continue;
      }
      allPayMethods.push(
        <button
          key={key}
          className={`btn btn-special-alt-3 option-val ${
            pm === key ? "active" : ""
          }`}
          onClick={() => props.onClick(setPM(key))}
        >
          {pmname}
        </button>
      );
    }

    return (
      <div>
        <div key="flt4" className="filter-item field d-flex pr-1">
          <div
            className="select"
            onClick={(e) => props.onClick(toggleSLT("paymethod"))}
          >
            <div className="select-val">
              {pm !== "pmAll"
                ? pmVal
                  ? pmVal.pmname
                  : "-"
                : "All payment methods"}
            </div>
            <div className="select-pointer">
              <img src={slt === "paymethod" ? asc : desc} alt="Desc" />
            </div>
          </div>
        </div>
        {slt === "paymethod" ? (
          <div className="options">{allPayMethods}</div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const getUSD = (cynum, amount) => {
    if (!cyData) return "0.50";
    const cy2 = cyData.currencies[cynum];
    let cyUSD = cy2.cyusd,
      usdAmount = parseFloat(amount) / parseFloat(ngnUSD),
      newAmount = parseFloat(cyUSD) * usdAmount;
    newAmount = parseFloat(newAmount.toFixed(2));
    return newAmount.toLocaleString("en-GB", 2);
  };

  const plchldr = () => {
    const allPlchdr = [];
    for (let i = 1; i <= 20; i++) {
      allPlchdr.push(
        <div key={`boplchldr${i}`} className="mkt-offer plchldr">
          <div className="offer-item u">
            <div className="offer-user">
              <span className="icon animated-gradient"></span>
              <span className="name animated-gradient"></span>
            </div>
            <div className="offer-rating">
              <span className="rating animated-gradient"></span>
              <span className="trades animated-gradient"></span>
            </div>
          </div>
          <div className="offer-item p">
            <div className="offer-title animated-gradient"></div>
            <div className="offer-price animated-gradient"></div>
          </div>
          <div className="offer-item l">
            <div className="offer-title animated-gradient"></div>
            <div className="offer-limit">
              <span className="odds-range animated-gradient"></span>
              <span className="price-range animated-gradient"></span>
            </div>
          </div>
          <div className="offer-item m">
            <div className="offer-title"></div>
            <div className="offer-methods">
              <span className="offer-method animated-gradient"></span>
              <span className="offer-method animated-gradient"></span>
              <span className="offer-method animated-gradient"></span>
            </div>
          </div>
          <div className="offer-item p">
            <div className="offer-title animated-gradient"></div>
            <div className="offer-price animated-gradient"></div>
          </div>
        </div>
      );
    }

    return allPlchdr;
  };

  const setOPG = (data) => {
    return { setOPG: data };
  };

  const createOffer = (e) => {
    if(!uLogin) return {setSN: true};
    let pState = {
      title: "Create buy offer",
      newUrl: "/create-buy-offer",
      rto: "Create-Buy-Offer",
      rid: null,
    };
    return { pState: pState };
  };

  const viewOffer = (e, link, name) => {
    e.preventDefault();
    let pState = {
      title: "Buy Bet from " + name,
      newUrl: "/offer/buy/" + link,
      rto: "Offer",
      rid: null,
    };
    return { pState: pState, setsBOData: true };
  };

  const buyOffers = () => {
    if (!boData)
      return (
        <select>
          <option value="">-</option>
        </select>
      );
    const allOffers1 = [];
    const allOffers2 = [];
    const allOffers3 = [];
    const allOffers4 = [];
    const allOffers5 = [];
    const allOffers6 = [];
    const total = boData.total;
    const offers = boData.offers;
    if (!total)
      return (
        <select>
          <option value="">-</option>
        </select>
      );
    let max = 100;
    for (let [key, val] of Object.entries(offers)) {
      if (j >= max) break;
      let bou = val.bou,
        boprice = val.boprice,
        bood = val.bood,
        boodMin = bood.min,
        boodMax = bood.max,
        boprange = val.boprange,
        boprMin = boprange.min,
        boprMax = boprange.max,
        bocy = val.bocy,
        bopm = val.bopm,
        bopm1 = bopm.bopm1,
        bopm2 = bopm.bopm2,
        bopm3 = bopm.bopm3,
        bopm4 = bopm.bopm4,
        bopm5 = bopm.bopm5,
        bopm6 = bopm.bopm6,
        bopm7 = bopm.bopm7,
        boodt = val.boodt,
        bott = val.bott,
        bolink = val.bolink;
      const pm1 = bopm1 ? pmData.paymethods[bopm1] : null,
        pm2 = bopm2 ? pmData.paymethods[bopm2] : null,
        pm3 = bopm3 ? pmData.paymethods[bopm3] : null,
        pm4 = bopm4 ? pmData.paymethods[bopm4] : null,
        pm5 = bopm5 ? pmData.paymethods[bopm5] : null,
        pm6 = bopm6 ? pmData.paymethods[bopm6] : null,
        pm7 = bopm7 ? pmData.paymethods[bopm7] : null,
        bocy2 = cyData ? cyData.currencies[bocy] : null,
        bocycode = bocy2 ? bocy2.cycode : "";
      const allPMID = [];
      if (bopm1) allPMID.push(bopm1);
      if (bopm2) allPMID.push(bopm2);
      if (bopm3) allPMID.push(bopm3);
      if (bopm4) allPMID.push(bopm4);
      if (bopm5) allPMID.push(bopm5);
      if (bopm6) allPMID.push(bopm6);
      if (bopm7) allPMID.push(bopm7);
      if (bocy !== cy) {
        allFiltered.push(val);
        continue;
      }
      if (parseFloat(boodMin) > parseFloat(od)) {
        if (bocy === cy) {
          cyFiltered.push(val);
          continue;
        }
        allFiltered.push(val);
        continue;
      }
      if (parseFloat(boodMax) < parseFloat(od)) {
        if (bocy === cy) {
          cyFiltered.push(val);
          continue;
        }
        allFiltered.push(val);
        continue;
      }
      if (pmt !== "pmtAll") {
        if (pmt === "pmt1") {
          if (!pm1) {
            if (bocy === cy) {
              cyFiltered.push(val);
              continue;
            }
            allFiltered.push(val);
            continue;
          }
        }
        if (pmt === "pmt2") {
          if (!pm2) {
            if (bocy === cy) {
              cyFiltered.push(val);
              continue;
            }
            allFiltered.push(val);
            continue;
          }
        }
        if (pmt === "pmt3") {
          if (!pm3) {
            if (bocy === cy) {
              cyFiltered.push(val);
              continue;
            }
            allFiltered.push(val);
            continue;
          }
        }
        if (pmt === "pmt4") {
          if (!pm4) {
            if (bocy === cy) {
              cyFiltered.push(val);
              continue;
            }
            allFiltered.push(val);
            continue;
          }
        }
        if (pmt === "pmt5") {
          if (!pm5) {
            if (bocy === cy) {
              cyFiltered.push(val);
              continue;
            }
            allFiltered.push(val);
            continue;
          }
        }
        if (pmt === "pmt6") {
          if (!pm6) {
            if (bocy === cy) {
              cyFiltered.push(val);
              continue;
            }
            allFiltered.push(val);
            continue;
          }
        }
        if (pmt === "pmt7") {
          if (!pm7) {
            if (bocy === cy) {
              cyFiltered.push(val);
              continue;
            }
            allFiltered.push(val);
            continue;
          }
        }
      }
      if (pm !== "pmAll") {
        if (!allPMID.includes(pm)) {
          if (bocy === cy) {
            cyFiltered.push(val);
            continue;
          }
          allFiltered.push(val);
          continue;
        }
      }
      const data = (
        <Link
          to={`/offer/buy/${bolink}`}
          key={key}
          className="mkt-offer"
          onClick={(e) => props.onClick(viewOffer(e, bolink, bou.bouname))}
        >
          <div className="offer-item u">
            <div className="offer-user">
              <span className="icon">{bou.bouname.substring(0, 1)}</span>
              <span className="name">
                {bou.bouname}&nbsp;
                <span
                  className={`flag-icon flag-icon-${bou.boucc.toLowerCase()}`}
                ></span>
              </span>
            </div>
            <div className="offer-rating">
              <span className="rating">&#9733;: {bou.bourating}</span>
              <span className="trades">
                {bou.bouwin} wins, {bou.boutrades} trades
              </span>
            </div>
          </div>
          <div className="offer-item p">
            <div className="offer-title">Price:</div>
            <div className="offer-price">+{boprice}%</div>
          </div>
          <div className="offer-item l">
            <div className="offer-title">Limits:</div>
            <div className="offer-limit">
              <span className="odds-range">
                {boodMin}{" "}
                {boodt === "range" ? <span>&#8212; {boodMax}</span> : ""} Odds
              </span>
              {boprMin === boprMax ? (
                <span className="price-range">
                  {getUSD(bocy, boprMax)} {bocycode}
                </span>
              ) : (
                <span className="price-range">
                  {getUSD(bocy, boprMin)} &#8212; {getUSD(bocy, boprMax)}{" "}
                  {bocycode}
                </span>
              )}
            </div>
          </div>
          <div className="offer-item m">
            <div className="offer-title"></div>
            <div className="offer-methods">
              {pm1 ? <span className="offer-method">{pm1.pmname}</span> : ""}
              {pm2 ? <span className="offer-method">{pm2.pmname}</span> : ""}
              {pm3 ? <span className="offer-method">{pm3.pmname}</span> : ""}
              {pm4 ? <span className="offer-method">{pm4.pmname}</span> : ""}
              {pm5 ? <span className="offer-method">{pm5.pmname}</span> : ""}
              {pm6 ? <span className="offer-method">{pm6.pmname}</span> : ""}
              {pm7 ? <span className="offer-method">{pm7.pmname}</span> : ""}
            </div>
          </div>
          <div className="offer-item p">
            <div className="offer-title"></div>
            <div className="offer-price">{bott}</div>
          </div>
          <div className="offer-item a">
            <div className="offer-title"></div>
            <div className="offer-action">
              <button className="btn btn-gold">Buy Bet</button>
            </div>
          </div>
        </Link>
      );

      j++;
      if (allOffers1.length < 20) {
        tpg = 1;
        allOffers1.push(data);
        continue;
      }
      if (allOffers2.length < 20) {
        tpg = 2;
        allOffers2.push(data);
        continue;
      }
      if (allOffers3.length < 20) {
        tpg = 3;
        allOffers3.push(data);
        continue;
      }
      if (allOffers4.length < 20) {
        tpg = 4;
        allOffers4.push(data);
        continue;
      }
      if (allOffers5.length < 20) {
        tpg = 5;
        allOffers5.push(data);
        continue;
      }
      if (allOffers6.length < 20) {
        tpg = 6;
        allOffers6.push(data);
        continue;
      }
    }

    const allOffers =
      opg > 1
        ? opg > 2
          ? opg > 3
            ? opg > 4
              ? opg > 5
                ? allOffers6
                : allOffers5
              : allOffers4
            : allOffers3
          : allOffers2
        : allOffers1;

    const pagination = (
      <div key="opg" className="pagination">
        {opg > 1 ? (
          <button
            key="opgp"
            className="btn pag-item page-nav"
            onClick={(e) => props.onClick(setOPG(opg - 1))}
          >
            <svg viewBox="0 0 6 12" fill="#dde3f5" aria-hidden="true">
              <path d="M0.12659 5.6707L5.26514 0.1357C5.4335 -0.0455414 5.70608 -0.0452367 5.87415 0.136638C6.0421 0.318488 6.04167 0.613073 5.87329 0.794456L1.0406 6.00002L5.87346 11.2056C6.04182 11.387 6.04225 11.6814 5.87433 11.8632C5.79007 11.9544 5.67969 12 5.56931 12C5.45921 12 5.34927 11.9547 5.26516 11.8642L0.12659 6.32932C0.0455022 6.24218 0 6.12358 0 6.00002C0 5.87646 0.0456324 5.75801 0.12659 5.6707Z"></path>
            </svg>
          </button>
        ) : (
          <button key="opgp" className="btn pag-item page-nav">
            <svg viewBox="0 0 6 12" fill="#dde3f5" aria-hidden="true">
              <path d="M0.12659 5.6707L5.26514 0.1357C5.4335 -0.0455414 5.70608 -0.0452367 5.87415 0.136638C6.0421 0.318488 6.04167 0.613073 5.87329 0.794456L1.0406 6.00002L5.87346 11.2056C6.04182 11.387 6.04225 11.6814 5.87433 11.8632C5.79007 11.9544 5.67969 12 5.56931 12C5.45921 12 5.34927 11.9547 5.26516 11.8642L0.12659 6.32932C0.0455022 6.24218 0 6.12358 0 6.00002C0 5.87646 0.0456324 5.75801 0.12659 5.6707Z"></path>
            </svg>
          </button>
        )}
        <button
          key="opg1"
          className={`btn pag-item page-num ${opg === 1 ? "active" : ""}`}
          onClick={(e) => props.onClick(setOPG(1))}
        >
          1
        </button>
        {allOffers2.length ? (
          <button
            key="opg2"
            className={`btn pag-item page-num ${opg === 2 ? "active" : ""}`}
            onClick={(e) => props.onClick(setOPG(2))}
          >
            2
          </button>
        ) : (
          ""
        )}
        {allOffers3.length ? (
          <button
            key="opg3"
            className={`btn pag-item page-num ${opg === 3 ? "active" : ""}`}
            onClick={(e) => props.onClick(setOPG(3))}
          >
            3
          </button>
        ) : (
          ""
        )}
        {allOffers4.length ? (
          <button
            key="opg4"
            className={`btn pag-item page-num ${opg === 4 ? "active" : ""}`}
            onClick={(e) => props.onClick(setOPG(4))}
          >
            4
          </button>
        ) : (
          ""
        )}
        {allOffers5.length ? (
          <button
            key="opg5"
            className={`btn pag-item page-num ${opg === 5 ? "active" : ""}`}
            onClick={(e) => props.onClick(setOPG(5))}
          >
            5
          </button>
        ) : (
          ""
        )}
        {allOffers6.length ? (
          <button
            key="opg6"
            className={`btn pag-item page-num ${opg === 6 ? "active" : ""}`}
            onClick={(e) => props.onClick(setOPG(6))}
          >
            6
          </button>
        ) : (
          ""
        )}
        {opg < tpg ? (
          <button
            key="opgn"
            className="btn pag-item page-nav"
            onClick={(e) => props.onClick(setOPG(opg + 1))}
          >
            <svg viewBox="0 0 6 12" fill="#dde3f5" aria-hidden="true">
              <path d="M5.87341 5.6707L0.734858 0.1357C0.566499 -0.0455414 0.293923 -0.0452367 0.125846 0.136638C-0.0421 0.318488 -0.041666 0.613073 0.126714 0.794456L4.9594 6.00002L0.126541 11.2056C-0.041818 11.387 -0.0422519 11.6814 0.125673 11.8632C0.209928 11.9544 0.320308 12 0.430689 12C0.540787 12 0.650733 11.9547 0.734837 11.8642L5.87341 6.32932C5.9545 6.24218 6 6.12358 6 6.00002C6 5.87646 5.95437 5.75801 5.87341 5.6707Z"></path>
            </svg>
          </button>
        ) : (
          <button key="opgn" className="btn pag-item page-nav">
            <svg viewBox="0 0 6 12" fill="#dde3f5" aria-hidden="true">
              <path d="M5.87341 5.6707L0.734858 0.1357C0.566499 -0.0455414 0.293923 -0.0452367 0.125846 0.136638C-0.0421 0.318488 -0.041666 0.613073 0.126714 0.794456L4.9594 6.00002L0.126541 11.2056C-0.041818 11.387 -0.0422519 11.6814 0.125673 11.8632C0.209928 11.9544 0.320308 12 0.430689 12C0.540787 12 0.650733 11.9547 0.734837 11.8642L5.87341 6.32932C5.9545 6.24218 6 6.12358 6 6.00002C6 5.87646 5.95437 5.75801 5.87341 5.6707Z"></path>
            </svg>
          </button>
        )}
      </div>
    );

    if (allOffers.length) allOffers.push(pagination);

    const noOffer = (
      <div className="nooffer">
        <div className="nooffer-hd">No offers found</div>
        <div className="nooffer-sub">
          No results matching your search criteria. Try adjusting the search by
          changing the amount or payment method. Or create your offer.
        </div>
        <div className="nooffer-action">
          <button
            className="btn btn-gold"
            onClick={(e) => props.onClick(createOffer())}
          >
            Create my offer
          </button>
        </div>
      </div>
    );

    return allOffers.length ? allOffers : noOffer;
  };

  const otherCYBuyOffers = () => {
    const filteredOffers = [];
    let max = 20;
    for (let i = 0; i < cyFiltered.length; i++) {
      if (k >= max) {
        break;
      }
      let val = cyFiltered[i],
        key = `boc${i}`,
        bou = val.bou,
        boprice = val.boprice,
        bood = val.bood,
        boodMin = bood.min,
        boodMax = bood.max,
        boprange = val.boprange,
        boprMin = boprange.min,
        boprMax = boprange.max,
        bocy = val.bocy,
        bopm = val.bopm,
        bopm1 = bopm.bopm1,
        bopm2 = bopm.bopm2,
        bopm3 = bopm.bopm3,
        bopm4 = bopm.bopm4,
        bopm5 = bopm.bopm5,
        bopm6 = bopm.bopm6,
        bopm7 = bopm.bopm7,
        boodt = val.boodt,
        bott = val.bott,
        bolink = val.bolink;
      const pm1 = bopm1 ? pmData.paymethods[bopm1] : null,
        pm2 = bopm2 ? pmData.paymethods[bopm2] : null,
        pm3 = bopm3 ? pmData.paymethods[bopm3] : null,
        pm4 = bopm4 ? pmData.paymethods[bopm4] : null,
        pm5 = bopm5 ? pmData.paymethods[bopm5] : null,
        pm6 = bopm6 ? pmData.paymethods[bopm6] : null,
        pm7 = bopm7 ? pmData.paymethods[bopm7] : null,
        bocy2 = cyData ? cyData.currencies[bocy] : null,
        bocycode = bocy2 ? bocy2.cycode : "";
      k++;
      filteredOffers.push(
        <Link
          to={`/offer/buy/${bolink}`}
          key={key}
          className="mkt-offer"
          onClick={(e) => props.onClick(viewOffer(e, bolink, bou.bouname))}
        >
          <div className="offer-item u">
            <div className="offer-user">
              <span className="icon">{bou.bouname.substring(0, 1)}</span>
              <span className="name">
                {bou.bouname}&nbsp;
                <span
                  className={`flag-icon flag-icon-${bou.boucc.toLowerCase()}`}
                ></span>
              </span>
            </div>
            <div className="offer-rating">
              <span className="rating">&#9733;: {bou.bourating}</span>
              <span className="trades">
                {bou.bouwin} wins, {bou.boutrades} trades
              </span>
            </div>
          </div>
          <div className="offer-item p">
            <div className="offer-title">Price:</div>
            <div className="offer-price">+{boprice}%</div>
          </div>
          <div className="offer-item l">
            <div className="offer-title">Limits:</div>
            <div className="offer-limit">
              <span className="odds-range">
                {boodMin}{" "}
                {boodt === "range" ? <span>&#8212; {boodMax}</span> : ""} Odds
              </span>
              {boprMin === boprMax ? (
                <span className="price-range">
                  {getUSD(bocy, boprMax)} {bocycode}
                </span>
              ) : (
                <span className="price-range">
                  {getUSD(bocy, boprMin)} &#8212; {getUSD(bocy, boprMax)}{" "}
                  {bocycode}
                </span>
              )}
            </div>
          </div>
          <div className="offer-item m">
            <div className="offer-title"></div>
            <div className="offer-methods">
              {pm1 ? <span className="offer-method">{pm1.pmname}</span> : ""}
              {pm2 ? <span className="offer-method">{pm2.pmname}</span> : ""}
              {pm3 ? <span className="offer-method">{pm3.pmname}</span> : ""}
              {pm4 ? <span className="offer-method">{pm4.pmname}</span> : ""}
              {pm5 ? <span className="offer-method">{pm5.pmname}</span> : ""}
              {pm6 ? <span className="offer-method">{pm6.pmname}</span> : ""}
              {pm7 ? <span className="offer-method">{pm7.pmname}</span> : ""}
            </div>
          </div>
          <div className="offer-item p">
            <div className="offer-title"></div>
            <div className="offer-price">{bott}</div>
          </div>
          <div className="offer-item a">
            <div className="offer-title"></div>
            <div className="offer-action">
              <button className="btn btn-gold">Buy Bet</button>
            </div>
          </div>
        </Link>
      );
    }

    return filteredOffers;
  };

  const otherBuyOffers = () => {
    const filteredOffers = [];
    let max = 20;
    for (let i = 0; i < allFiltered.length; i++) {
      if (k >= max) {
        break;
      }
      let val = allFiltered[i],
        key = `boo${i}`,
        bou = val.bou,
        boprice = val.boprice,
        bood = val.bood,
        boodMin = bood.min,
        boodMax = bood.max,
        boprange = val.boprange,
        boprMin = boprange.min,
        boprMax = boprange.max,
        bocy = val.bocy,
        bopm = val.bopm,
        bopm1 = bopm.bopm1,
        bopm2 = bopm.bopm2,
        bopm3 = bopm.bopm3,
        bopm4 = bopm.bopm4,
        bopm5 = bopm.bopm5,
        bopm6 = bopm.bopm6,
        bopm7 = bopm.bopm7,
        boodt = val.boodt,
        bott = val.bott,
        bolink = val.bolink;
      const pm1 = bopm1 ? pmData.paymethods[bopm1] : null,
        pm2 = bopm2 ? pmData.paymethods[bopm2] : null,
        pm3 = bopm3 ? pmData.paymethods[bopm3] : null,
        pm4 = bopm4 ? pmData.paymethods[bopm4] : null,
        pm5 = bopm5 ? pmData.paymethods[bopm5] : null,
        pm6 = bopm6 ? pmData.paymethods[bopm6] : null,
        pm7 = bopm7 ? pmData.paymethods[bopm7] : null,
        bocy2 = cyData ? cyData.currencies[bocy] : null,
        bocycode = bocy2 ? bocy2.cycode : "";
      k++;
      filteredOffers.push(
        <Link
          to={`/offer/buy/${bolink}`}
          key={key}
          className="mkt-offer"
          onClick={(e) => props.onClick(viewOffer(e, bolink, bou.bouname))}
        >
          <div className="offer-item u">
            <div className="offer-user">
              <span className="icon">{bou.bouname.substring(0, 1)}</span>
              <span className="name">
                {bou.bouname}&nbsp;
                <span
                  className={`flag-icon flag-icon-${bou.boucc.toLowerCase()}`}
                ></span>
              </span>
            </div>
            <div className="offer-rating">
              <span className="rating">&#9733;: {bou.bourating}</span>
              <span className="trades">
                {bou.bouwin} wins, {bou.boutrades} trades
              </span>
            </div>
          </div>
          <div className="offer-item p">
            <div className="offer-title">Price:</div>
            <div className="offer-price">+{boprice}%</div>
          </div>
          <div className="offer-item l">
            <div className="offer-title">Limits:</div>
            <div className="offer-limit">
              <span className="odds-range">
                {boodMin}{" "}
                {boodt === "range" ? <span>&#8212; {boodMax}</span> : ""} Odds
              </span>
              {boprMin === boprMax ? (
                <span className="price-range">
                  {getUSD(bocy, boprMax)} {bocycode}
                </span>
              ) : (
                <span className="price-range">
                  {getUSD(bocy, boprMin)} &#8212; {getUSD(bocy, boprMax)}{" "}
                  {bocycode}
                </span>
              )}
            </div>
          </div>
          <div className="offer-item m">
            <div className="offer-title"></div>
            <div className="offer-methods">
              {pm1 ? <span className="offer-method">{pm1.pmname}</span> : ""}
              {pm2 ? <span className="offer-method">{pm2.pmname}</span> : ""}
              {pm3 ? <span className="offer-method">{pm3.pmname}</span> : ""}
              {pm4 ? <span className="offer-method">{pm4.pmname}</span> : ""}
              {pm5 ? <span className="offer-method">{pm5.pmname}</span> : ""}
              {pm6 ? <span className="offer-method">{pm6.pmname}</span> : ""}
              {pm7 ? <span className="offer-method">{pm7.pmname}</span> : ""}
            </div>
          </div>
          <div className="offer-item p">
            <div className="offer-title"></div>
            <div className="offer-price">{bott}</div>
          </div>
          <div className="offer-item a">
            <div className="offer-title"></div>
            <div className="offer-action">
              <button className="btn btn-gold">Buy Bet</button>
            </div>
          </div>
        </Link>
      );
    }

    return filteredOffers;
  };

  return (
    <>
      <div className="new-design">
        New design release! Better trading experience. Enjoy! &#10084;
      </div>
      <div className="market-main">
        <div className="mkt-hd">Buy Bet</div>
        <div className="mkt-sub">
          <div className="mkt-sub-item title">
            Buy Bet from other users using any payment method and currency
          </div>
          <div className="mkt-sub-item actions">
            <Link
              to="/help"
              onClick={(e) =>
                props.onClick(handleLink(e, "/how-to", "How to", "How-to"))
              }
            >
              How to start?
            </Link>
            <button
              className="btn btn-gold"
              onClick={(e) => props.onClick(createOffer())}
            >
              Create Offer
            </button>
          </div>
        </div>
        <div className="mkt-filter">
          {cyOptions()}
          <div key="flt2" className="filter-item field">
            <input
              type="number"
              name="odds"
              id="odds"
              placeholder="Enter odds"
              step=".01"
              min="1.05"
              max="9999.99"
              defaultValue={od}
              autoComplete="false"
              onChange={(e) => props.onChange(setOD(e.target.value))}
            />
            <span className="mi-currency">Odds</span>
          </div>
          {pmtOptions()}
          {pmOptions()}
        </div>
        <div className="mkt-body">
          <div className="mkt-header">
            <div className="header-item">Seller</div>
            <div className="header-item">Price&nbsp;&darr;</div>
            <div className="header-item">Limits</div>
            <div className="header-item">Payment methods</div>
            <div className="header-item">Offer details</div>
            <div className="header-item">Sorted by Price&nbsp;&darr;</div>
          </div>
          {!boData ? plchldr() : buyOffers()}
        </div>
        {j <= 5 ? (
          cyFiltered.length ? (
            <>
              <div className="mkt-sub">
                <div className="mkt-sub-item title">
                  Other offers with same currency
                </div>
              </div>
              <div className="mkt-body">
                <div className="mkt-header">
                  <div className="header-item">Seller</div>
                  <div className="header-item">Price&nbsp;&darr;</div>
                  <div className="header-item">Limits</div>
                  <div className="header-item">Payment methods</div>
                  <div className="header-item">Offer details</div>
                  <div className="header-item">Sorted by Price&nbsp;&darr;</div>
                </div>
                {otherCYBuyOffers()}
              </div>
            </>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {j <= 5 && k <= 15 ? (
          allFiltered.length ? (
            <>
              <div className="mkt-sub">
                <div className="mkt-sub-item title">
                  Other offers with other payment methods and currencies
                </div>
              </div>
              <div className="mkt-body">
                <div className="mkt-header">
                  <div className="header-item">Seller</div>
                  <div className="header-item">Price&nbsp;&darr;</div>
                  <div className="header-item">Limits</div>
                  <div className="header-item">Payment methods</div>
                  <div className="header-item">Offer details</div>
                  <div className="header-item">Sorted by Price&nbsp;&darr;</div>
                </div>
                {otherBuyOffers()}
              </div>
            </>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Market;
