import logo from "../assets/image/logo.png";
import logoAlt from "../assets/image/logo-alt.png";
import footballIcon from "../assets/svg/football.png";
import toggleIcon from "../assets/svg/svgexport-1.png";
import noticeIcon from "../assets/svg/svgexport-69.png";
import noticeIcon2 from "../assets/svg/notice.png";
import userIcon from "../assets/svg/svgexport-2.png";
import seetingsIcon from "../assets/svg/svgexport-59.png";
import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/header.css";

const HeaderIn = (props) => {
  const uData = props.uData;
  const contractTotal = 2;
  const noticeTotal = 21;

  const goHome = (e) => {
    e.preventDefault();
    const pState = {
      title: "Bets, Buy & Sell",
      newUrl: "/welcome",
      rto: "Welcome",
      rid: null,
    };

    return { pState: pState };
  };

  const buyOffers = (e) => {
    e.preventDefault();
    let pState = {
      title: "Buy Bet",
      newUrl: "/market/buy-bet",
      rto: "Buy-Bet",
      rid: null,
    };
    return { pState: pState, setOPG: 1 };
  };

  const sellOffers = (e) => {
    e.preventDefault();
    let pState = {
      title: "Sell Bet",
      newUrl: "/market/sell-bet",
      rto: "Sell-Bet",
      rid: null,
    };
    return { pState: pState, setOPG: 1 };
  };

  const goToSports = (e) => {
    e.preventDefault();
    let pState = {
      title: "Sports",
      newUrl: "/sports/football",
      rto: "Sports",
      rid: null,
    };
    return { pState: pState };
  };

  const toggleRB = () => {
    return { setRB: true, setOV0: true };
  };

  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-sm p-0">
          <div className="header-upper-wrap">
            <div className="header-box">
              <Link
                to="/welcome"
                className="navbar-brand brand-box"
                onClick={(e) => props.onClick(goHome(e))}
              >
                <img src={logo} alt="" className="brand" />
                <img src={logoAlt} alt="" className="brand-alt" />
              </Link>
            </div>
            <div className="header-box wide">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="navbar-nav">
                  <div className="nav-group">
                    <li className="nav-item start-click">
                      <Link
                        to="/market/buy-bet"
                        onClick={(e) => props.onClick(buyOffers(e))}
                      >
                        Buy Bet
                      </Link>
                    </li>
                    <li className="nav-item start-click">
                      <Link
                        to="/market/sell-bet"
                        onClick={(e) => props.onClick(sellOffers(e))}
                      >
                        Sell Bet
                      </Link>
                    </li>
                    <li className="nav-item empty"></li>
                    <li className="nav-item start-click">
                      <Link
                        to="/sports/football"
                        onClick={(e) => props.onClick(goToSports(e))}
                      >
                        <img src={footballIcon} alt="Sports" className="mr-1" />
                        Sports
                      </Link>
                    </li>
                  </div>
                  <div className="nav-group">
                    <li className="nav-item start-click">
                      <Link
                        title="Notifications"
                        to="/accounts/notifications"
                        onClick={(e) =>
                          props.onClick(
                            handleLink(
                              e,
                              "/accounts/notifications",
                              "Notifications",
                              "Notifications"
                            )
                          )
                        }
                      >
                        <img
                          src={noticeTotal ? noticeIcon2 : noticeIcon}
                          alt="Notification"
                          className="mr-1"
                        />
                      </Link>
                    </li>
                    <li className="nav-item empty p-0"></li>
                    <li className="nav-item start-click">
                      <Link
                        title="My Contracts"
                        to="/accounts/contracts"
                        onClick={(e) =>
                          props.onClick(
                            handleLink(
                              e,
                              "/accounts/contracts",
                              "My Contracts",
                              "My-Contracts"
                            )
                          )
                        }
                      >
                        My Contracts
                        {contractTotal ? (
                          <span className="nbb-item ml-1">{contractTotal}</span>
                        ) : (
                          ""
                        )}
                      </Link>
                    </li>
                    <li className="nav-item empty p-0"></li>
                    <li className="nav-item start-click">
                      <Link
                        title="My Offers"
                        to="/accounts/profile"
                        onClick={(e) =>
                          props.onClick(
                            handleLink(
                              e,
                              "/accounts/offers",
                              "My Offers",
                              "My-Offers"
                            )
                          )
                        }
                      >
                        My Offers
                      </Link>
                    </li>
                    <li className="nav-item empty p-0"></li>
                    <li className="nav-item start-click">
                      <Link
                        title="My Profile"
                        to="/accounts/profile"
                        onClick={(e) =>
                          props.onClick(
                            handleLink(
                              e,
                              "/accounts/profile",
                              "My Profile",
                              "My-Profile"
                            )
                          )
                        }
                      >
                        <img src={userIcon} alt="Profile" className="mr-1" />
                        {!uData ? (
                          <span className="unm animated-gradient"></span>
                        ) : (
                          <>
                          {uData.user.unm}<span class={`flag-icon flag-icon-${uData.user.uct} ml-1`}></span></>
                        )}
                      </Link>
                    </li>
                    <li className="nav-item empty p-0"></li>
                    <li className="nav-item start-click">
                      <Link
                        title="Account Settings"
                        to="/accounts/settings"
                        onClick={(e) =>
                          props.onClick(
                            handleLink(
                              e,
                              "/accounts/settings",
                              "Account Settings",
                              "Account-Settings"
                            )
                          )
                        }
                      >
                        <img src={seetingsIcon} alt="Settings" />
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
              <button
                className="navbar-toggler p-0"
                type="button"
                onClick={() => props.onClick(toggleRB())}
              >
                <img src={toggleIcon} alt="Toggle" />
              </button>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default HeaderIn;
