import HeaderOut from "./components/HeaderOut";
import HeaderIn from "./components/HeaderIn";
import About from "./components/About";
import SecurityGuide from "./components/SecurityGuide";
import ContactUs from "./components/ContactUs";
import TermsOfService from "./components/TermsOfService";
import DisputeRules from "./components/DisputeRules";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Help from "./components/Help";
import Faq from "./components/Faq";
import SignUp from "./components/SignUp";
import Confirm from "./components/Confirm";
import LogIn from "./components/LogIn";
import Forgot from "./components/Forgot";
import ResetSent from "./components/ResetSent";
import Confirmation from "./components/Confirmation";
import Resent from "./components/Resent";
import Verify from "./components/Verify";
import Expired from "./components/Expired";
import ResetPass from "./components/ResetPassword";
import Reset from "./components/Reset";
import BuyOffer from "./components/BuyOffer";
import Main from "./components/Main";
import Landing from "./components/Landing";
import BuyBetMKT from "./components/BuyBetMKT";
import SellBetMKT from "./components/SellBetMKT";
import CreateSellOffer from "./components/CreateSellOffer";
import Overlay from "./components/Overlay";
import RightBar from "./components/RightBar";
import SideNotice from "./components/SideNotice";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import React from "react";
import LoadingBar from "react-top-loading-bar";
import $ from "jquery";
import "./assets/css/flag-icon.css";
import {
  deviceType,
  isMobile,
  osVersion,
  osName,
  browserName,
  mobileVendor,
  mobileModel,
} from "react-device-detect";

function App() {
  //const phpGetURL = "http://192.168.0.100/get.php";
  //const phpSendURL = "http://192.168.0.100/send.php";
  //const phpGetURL = "http://127.0.0.1/get.php";
  //const phpSendURL = "http://127.0.0.1/send.php";
  const phpGetURL = "https://peer2punt.com/get.php";
  const phpSendURL = "https://peer2punt.com/send.php";
  //const phpGetURL = "http://localhost:8000/get.php";
  //const phpSendURL = "http://localhost:8000/send.php";
  const fullUrl = document.URL;
  const url = new URL(fullUrl);
  const urlPath = url.pathname;
  const urlArray = urlPath ? urlPath.split("/") : [""];
  const urlParam1 = urlArray[1]
    ? urlArray[1].match(/([a-zA-Z0-9-]{3,})?/)
    : undefined;
  const urlParam2 = urlArray[2]
    ? urlArray[2].match(/([a-zA-Z0-9-]{3,})?/)
    : undefined;
  const urlParam3 = urlArray[3]
    ? urlArray[3].match(/([a-zA-Z0-9-]{8,})?/)
    : undefined;
  const page = urlParam1 ? urlParam1[0] : undefined;
  const market =
    page === "market" ? (urlParam2 ? urlParam2[0] : undefined) : undefined;
  const accounts =
    page === "accounts" ? (urlParam2 ? urlParam2[0] : undefined) : undefined;
  const offer =
    page === "offer" ? (urlParam2 ? urlParam2[0] : undefined) : undefined;
  const offerID =
    offer === "buy" || offer === "sell"
      ? urlParam3
        ? urlParam3[0]
        : undefined
      : undefined;

  const dd = urlParam2 ? urlParam2[0] : "today";
  const cc = urlParam3 ? urlParam3[0] : "";

  const initialsSport = "sp1";
  const initialPT = "ptAll";
  const initialsCountry = "cc0";
  const initialeView = "Upcoming";
  const initialmView = "Matches";
  const initialCY = "cy76";
  const initialTZ = "tz38";
  const initialPM = "pmAll";
  const initialPMT = "pmtAll";
  const initialOH = ["oh1", "oh2"];
  const initialOD = "5.05";
  const initialPC = "pc1";
  const initialBOEOPR = 0.0;
  const initialSUErrors = [
    false, // [0] No Email Address
    false, // [1] Invalid Email Address
    false, // [2] Email Address already register
    false, // [3] No Nickname
    false, // [4] Invalid Nickname
    false, // [5] Nickname Already Taken
    false, // [6] No Timezone
    false, // [7] No Password
    false, // [8] Invalid Password
    false, // [9] No Confirm Password
    false, // [10] Invalid Confirm Password
    false, // [11] Must agree
    false, // [12] Server Error Message
  ];
  const initialLIErrors = [
    false, // [0] No Email Address
    false, // [1] Invalid Email Address
    false, // [2] Email Address not registered
    false, // [3] Email Address not confirmed
    false, // [4] No password
    false, // [5] Incorrect Password
    false, // [6] Server Error Message
  ];
  const initialFPErrors = [
    false, // [0] No Email Address
    false, // [1] Invalid Email Address
    false, // [2] Email Address not registered
    false, // [3] Email Address not verified
    false, // [4] Server Error Message
    false, // [5] Email resent success
  ];
  const initialRPErrors = [
    false, // [0] No Password
    false, // [1] Invalid Password
    false, // [2] No Confirm Password
    false, // [3] Invalid Confirm Password
    false, // [4] Server Error Message
  ];
  const initialCFErrors = [
    false, // [0] No Email Address
    false, // [1] Invalid Email Address
    false, // [2] Email Address not registered
    false, // [3] Email Address already verified
    false, // [4] Server Error Message
    false, // [5] Email resent success
  ];
  const initialCRSErrors = [
    false, // [0] Invalid currency
    false, // [1] Invalid minimum odds
    false, // [2] Invalid maximum odds
    false, // [3] Invalid price
    false, // [4] Please choose and add at least 1 payment method
    false, // [5] Invaiid payment method 1
    false, // [6] Invaiid payment method 2
    false, // [7] Invaiid payment method 3
    false, // [8] Invaiid payment method 4
    false, // [9] Invaiid payment method 5
    false, // [10] Invaiid payment method 6
    false, // [11] Invaiid payment method 7
    false, // [12] Invaiid available hours
    false, // [13] Invaiid payment window
    false, // [14] Invaiid bookmaker
    false, // [15] Invaiid title
    false, // [16] Invaiid description
  ];
  const initialCRSSettings = [
    true, //24 hour access
    false, //workdays only
    "pw2", //payment window
    "bm1", //preferred bookmaker
    true, //Enable offer after creation
    false, //Make offer private
  ];
  const initialBOCErrors = [
    false, // [0] Invalid currency
    false, // [1] Mininum odds exceeded
    false, // [2] Maxinum odds exceeded
    false, // [3] Invalid payment method
    false, // [4] Must select at least 1 option
    false, // [5] Invalid excluded option(s)
  ];
  const initialSNErrors = [
    false, // [0] Duplicate offer
    false, // [1] Duplicate contract
  ];
  const [uDevice, setuDevice] = React.useState(false);
  const [uLogin, setuLogin] = React.useState(false);
  const [uData, setuData] = React.useState(null);
  const [sn, setSN] = React.useState(false);
  const [snErrors, setSNErrors] = React.useState(initialSNErrors);
  const [sSport, setsSport] = React.useState(initialsSport);
  const [sDate, setsDate] = React.useState(new Date());
  const [ptData, setPTData] = React.useState(null);
  const [poData, setPOData] = React.useState(null);
  const [ccData, setccData] = React.useState(null);
  const [sCountry, setsCountry] = React.useState(initialsCountry);
  const [cyData, setcyData] = React.useState(null);
  const [tzData, setTZData] = React.useState(null);
  const [pmData, setpmData] = React.useState(null);
  const [pmtData, setpmtData] = React.useState(null);
  const [pcData, setpcData] = React.useState(null);
  const [ohData, setohData] = React.useState(null);
  const [ulData, setulData] = React.useState(null);
  const [pwData, setpwData] = React.useState(null);
  const [bmData, setbmData] = React.useState(null);
  const [boData, setboData] = React.useState(null);
  const [soData, setsoData] = React.useState(null);
  const [opData, setopData] = React.useState(null);
  const [sBOData, setsBOData] = React.useState(null);
  //const [sSOData, setsSOData] = React.useState(null);
  const [sBOCData, setsBOCData] = React.useState(null);
  const [urData, setURData] = React.useState(null);
  const [mView, setmView] = React.useState(initialmView);
  const [eView, seteView] = React.useState(initialeView);
  const [rb, setRB] = React.useState("off");
  const [rbDrop, setRBDrop] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [slt, setSLT] = React.useState("none");
  const [ov0, setOV0] = React.useState("off");
  const [ovSLT, setOVSLT] = React.useState("off");
  const [mkt, setMKT] = React.useState("buy");
  const [pt, setPT] = React.useState(initialPT);
  const [cy, setCY] = React.useState(initialCY);
  const [pm, setPM] = React.useState(initialPM);
  const [boPM, setBOPM] = React.useState(null);
  const [boEOP, setBOEOP] = React.useState([]);
  const [boEOPR, setBOEOPR] = React.useState(initialBOEOPR);
  const [boW, setBOW] = React.useState(false);
  const [myPM, setMyPM] = React.useState([]);
  const [myPMData, setMyPMData] = React.useState([]);
  const [pmt, setPMT] = React.useState(initialPMT);
  const [suErrors, setSUErrors] = React.useState(initialSUErrors);
  const [suEMSG, setSUEMSG] = React.useState({
    sumsg0: "Please enter your email address",
    sumsg1: "Please enter a valid email address (example@email.com)",
    sumsg2: "This email is already in use",
    sumsg3: "Please enter your nickname",
    sumsg4: "Please provide a valid nickname (exAm.pl_E)",
    sumsg5: "This nickname is not available",
    sumsg6: "Please choose a timezone",
    sumsg7: "Please enter your password",
    sumsg8: "Password must include the following:",
    sumsg9: "Please confirm your password",
    sumsg10: "Passwords do not match",
    sumsg11:
      "Please read and accept the terms and conditions and privacy policy",
  });
  const [suSEMSG, setSUSEMSG] = React.useState(null);
  const [signUpData, setSignUpData] = React.useState([
    "", //Email
    "", //Nickname
    initialTZ, //timezone
    "", //password
    "", //confirm password
    "", //referral code
    false, //agree
  ]);
  const [liErrors, setLIErrors] = React.useState(initialLIErrors);
  const [liEMSG, setLIEMSG] = React.useState({
    limsg0: "Please enter your email address",
    limsg1: "Please enter a valid email address (example@email.com)",
    limsg2: "We couldn't find an account with that email address",
    limsg3: "Please confirm your email address",
    limsg4: "Please enter your password",
    limsg5: "Incorrect email address or password",
  });
  const [liSEMSG, setLISEMSG] = React.useState(null);
  const [logInData, setLogInData] = React.useState([
    "", //Email
    "", //password
    false, //remember
  ]);
  const [fpErrors, setFPErrors] = React.useState(initialFPErrors);
  const [fpEMSG, setFPEMSG] = React.useState({
    fpmsg0: "Please enter your email address",
    fpmsg1: "Please enter a valid email address (example@email.com)",
    fpmsg2: "We couldn't find an account with that email address",
    fpmsg3: "Please confirm your email address",
    fpmsg5: "Email was resent successfully",
  });
  const [fpSEMSG, setFPSEMSG] = React.useState(null);
  const [forgotData, setForgotData] = React.useState([
    "", //Email
  ]);
  const [rpErrors, setRPErrors] = React.useState(initialRPErrors);
  const [rpEMSG, setRPEMSG] = React.useState({
    rpmsg0: "Please enter your new password",
    rpmsg1: "Password must include the following:",
    rpmsg2: "Please confirm your new password",
    rpmsg3: "Passwords do not match",
  });
  const [rpSEMSG, setRPSEMSG] = React.useState(null);
  const [resetData, setResetData] = React.useState([
    "", //new password
    "", //confirm new password
  ]);
  const [cfErrors, setCFErrors] = React.useState(initialCFErrors);
  const [cfEMSG, setCFEMSG] = React.useState({
    cfmsg0: "Please enter your email address",
    cfmsg1: "Please enter a valid email address (example@email.com)",
    cfmsg2: "We couldn't find an account with that email address",
    cfmsg3: "Email address already confirmed",
    cfmsg5: "Email was resent successfully",
  });
  const [cfSEMSG, setCFSEMSG] = React.useState(null);
  const [confData, setConfData] = React.useState([
    "", //Email
  ]);
  const [crsErrors, setCRSErrors] = React.useState(initialCRSErrors);
  const [crsEMSG, setCRSEMSG] = React.useState({
    crsmsg0: "Invalid currency",
    crsmsg1: "Invalid minimum odds",
    crsmsg2: "Invalid maximum odds",
    crsmsg3: "Invalid price",
    crsmsg4: "Please choose and add at least 1 payment method",
    crsmsg5: "Invaiid payment method 1",
    crsmsg6: "Invaiid payment method 2",
    crsmsg7: "Invaiid payment method 3",
    crsmsg8: "Invaiid payment method 4",
    crsmsg9: "Invaiid payment method 5",
    crsmsg10: "Invaiid payment method 6",
    crsmsg11: "Invaiid payment method 7",
    crsmsg12: "Invaiid available hours",
    crsmsg13: "Invaiid payment window",
    crsmsg14: "Invaiid bookmaker",
    crsmsg15: "Invaiid title",
    crsmsg16: "Invaiid description",
  });
  const [crsSettings, setCRSSettings] = React.useState(initialCRSSettings);
  const [crsTitle, setCRSTitle] = React.useState(null);
  const [crsDesc, setCRSDesc] = React.useState(null);
  const [bocErrors, setBOCErrors] = React.useState(initialBOCErrors);
  const [bocEMSG, setBOCEMSG] = React.useState({
    bocmsg0: "Invalid currency",
    bocmsg1: "Minimum odds for this offer is ",
    bocmsg2: "Maximum odds for this offer is ",
    bocmsg3: "Invalid payment method",
    bocmsg4: "Must select at least 1 option",
    bocmsg5: "Invalid excluded option(s)",
  });
  const [oh, setOH] = React.useState(initialOH);
  const [od, setOD] = React.useState(initialOD);
  const [opg, setOPG] = React.useState(1);
  const [odt, setODType] = React.useState("fixed");
  const [opMin, setOPMin] = React.useState("op1");
  const [opMax, setOPMax] = React.useState("op1");
  const [pc, setPC] = React.useState(initialPC);
  const [evData, setevData] = React.useState(null);
  const [evTPPTData, setevTPPTData] = React.useState(null);
  const [evFRPTData, setevFRPTData] = React.useState(null);
  const [nevData, setnevData] = React.useState(null);
  const [stateData, setStateData] = React.useState({
    title: "Welcome",
    //newUrl: "http://192.168.0.100/welcome",
    //newUrl: "http://127.0.0.1/welcome",
    ///newUrl: "https://peer2punt.com/welcome",
    newUrl: "http://localhost:3000/welcome",
    rto: "Welcome",
    rid: null,
  });
  const device = isMobile
    ? "Device Type: " +
      deviceType +
      "<br/>" +
      "Device Vendor: " +
      mobileVendor +
      "<br/>" +
      "Device Model: " +
      mobileModel +
      "<br/>" +
      "Operating System: " +
      osName +
      " " +
      osVersion +
      "<br/>" +
      "Browser Name: " +
      browserName
    : "Device Type: PC<br/>" +
      "Operating System: " +
      osName +
      " " +
      osVersion +
      "<br/>" +
      "Browser Name: " +
      browserName;
  const loading = React.useRef(null);

  const userOffset = React.useCallback(() => {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth() + 1,
      d = date.getDate(),
      h = date.getHours(),
      min = date.getMinutes();
    let hours = h < 10 ? "0" + h : h;
    let month = m < 10 ? "0" + m : m;
    let day = d < 10 ? "0" + d : d;
    let minutes = min < 10 ? "0" + min : min;
    const userOffset =
      y + "-" + month + "-" + day + " " + hours + ":" + minutes + ": 00";
    return userOffset;
  }, []);

  const sendDevice = React.useCallback(() => {
    if (uDevice) return;
    let date = new Date();
    $.ajax({
      type: "POST",
      url: phpSendURL,
      dataType: "json",
      data: { send: "sdvc", device: device, date: date },
      success(data) {
        let status = data.status === "true" ? true : false;
        setuDevice(status);
      },
    });
  }, [uDevice, device]);

  const getLogin = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gli" },
      success(data) {
        let status = data.status === "true" ? true : false;
        setuLogin(status);
      },
    });
  }, []);

  const getUser = React.useCallback(() => {
    if (!uLogin) setuData(null);
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gud" },
      success(data) {
        let total = data.total;
        if (!total) {
          setuData(null);
        } else {
          setuData(data);
        }
      },
    });
  }, [uLogin]);

  const pState = (data) => {
    setStateData(data);
    let title = data.title,
      //newUrl = `http://192.168.0.100${data.newUrl}`,
      //newUrl = `http://127.0.0.1${data.newUrl}`,
      newUrl = `https://peer2punt.com${data.newUrl}`,
      //newUrl = `http://localhost:3000${data.newUrl}`,
      rto = data.rto,
      rid = data.rid;
    let full_url = document.URL; // Get current url
    if (newUrl) {
      title = "Peer2Punt - " + title;
    } else {
      newUrl = full_url;
    }
    window.history.pushState(
      { rto: rto, rid: rid, rurl: full_url },
      title,
      newUrl
    );
    document.title = title;
    window.scrollTo(0, 0);
    setSLT("none");
    setOVSLT(true);
    setSN(false);
    if (rto === "Offer") {
      setOD(initialOD);
      setBOPM(null);
      setPT(initialPT);
      setBOEOP([]);
      setBOEOPR(initialBOEOPR);
      setBOW(false);
    }
  };

  if (page === undefined) {
    pState({
      title: "Bets, Buy & Sell",
      newUrl: "/welcome",
      rto: "Welcome",
      rid: null,
    });
  }

  if (page === "accounts" && accounts === "confirm" && !signUpData[0]) {
    pState({
      title: "Bets, Buy & Sell",
      newUrl: "/welcome",
      rto: "Welcome",
      rid: null,
    });
  }

  if (page === "accounts" && accounts === "reset-sent" && !forgotData[0]) {
    pState({
      title: "Bets, Buy & Sell",
      newUrl: "/welcome",
      rto: "Welcome",
      rid: null,
    });
  }

  if (page === "accounts" && accounts === "resent" && !confData[0]) {
    pState({
      title: "Bets, Buy & Sell",
      newUrl: "/welcome",
      rto: "Welcome",
      rid: null,
    });
  }

  if (page === "offer" && offer === "buy" && !offerID) {
    pState({
      title: "Bets, Buy & Sell",
      newUrl: "/welcome",
      rto: "Welcome",
      rid: null,
    });
  }

  if (page === "offer" && offer === "sell" && !offerID) {
    pState({
      title: "Bets, Buy & Sell",
      newUrl: "/welcome",
      rto: "Welcome",
      rid: null,
    });
  }

  const handleClick = (data1) => {
    if (data1.pState) {
      loading.current.continuousStart();
      $.ajax({
        type: "POST",
        url: phpGetURL,
        dataType: "json",
        data: { get: "gli" },
        success(data) {
          let status = data.status === "true" ? true : false;
          setuLogin(status);
          pState(data1.pState);
          loading.current.complete();
        },
      });
    }
    if (data1.setSN) setSN(data1.setSN);
    if (data1.closeSN) {
      setSN(false);
      setSNErrors(initialSNErrors);
    }
    if (data1.setsSport) setsSport(data1.setsSport);
    if (data1.setsDate) {
      setevData(null);
      setsCountry(initialsCountry);
      setmView(initialmView);
      if (data1.setsDate.dt !== "Today") {
        seteView("All");
      } else {
        seteView(initialeView);
      }
      setTimeout(() => {
        setsDate(data1.setsDate.date);
      }, 1000);
    }
    if (data1.setsCountry) {
      setsCountry(data1.setsCountry);
    }
    if (data1.setmView) {
      setmView(data1.setmView);
      if (data1.setmView === "Top") {
        setevTPPTData(null);
        getTopPT();
      }
      if (data1.setmView === "Free") {
        setevFRPTData(null);
        getFreePT();
      }
    }
    if (data1.seteView) {
      seteView(data1.seteView);
    }
    if (data1.setSearch) {
      setSearch(data1.setSearch.data);
    }
    if (data1.setRB) {
      if (rb === "on") {
        setRB("off");
        setRBDrop(false);
      } else {
        setRB("on");
      }
    }
    if (data1.setRBDrop) {
      rbDrop ? setRBDrop(false) : setRBDrop(true);
    }
    if (data1.setSLT) {
      setSLT(data1.setSLT);
      setSearch("");
    }
    if (data1.setOV0) {
      ov0 === "on" ? setOV0("off") : setOV0("on");
    }
    if (data1.setOVSLT) {
      ovSLT === "on" ? setOVSLT("off") : setOVSLT("on");
    }
    if (data1.setMKT) {
      setMKT(data1.setMKT);
      setCY(initialCY);
    }
    if (data1.setPT) {
      setPT(data1.setPT);
    }
    if (data1.setCY) {
      if (data1.delay) {
        loading.current.continuousStart();
        setOV0("on");
        setTimeout(() => {
          setCY(data1.setCY);
          setOPG(1);
          loading.current.complete();
          setOV0("off");
        }, 2000);
      } else {
        setCY(data1.setCY);
        setOPG(1);
      }
    }
    if (data1.setPM) {
      setPM(data1.setPM);
      setOPG(1);
    }
    if (data1.setBOPM) {
      setBOPM(data1.setBOPM);
    }
    if (data1.setBOEOP) {
      let val = data1.setBOEOP,
        index = boEOP.includes(val) ? boEOP.indexOf(val) : boEOP.length;
      setBOEOP((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        if (val === "All") return [];
        if (boEOP.includes(val)) return [...left, ...right];
        return [...left, val, ...right];
      });
      if (data1.setBOEOPR) {
        let val2 = data1.setBOEOPR;
        if (val2 === "All") {
          setBOEOPR(0.0);
        } else if (boEOP.includes(val)) {
          setBOEOPR(parseFloat(boEOPR) - parseFloat(data1.setBOEOPR));
        } else {
          setBOEOPR(parseFloat(boEOPR) + parseFloat(data1.setBOEOPR));
        }
      }
    }
    if (data1.setBOW) {
      setBOW(data1.setBOW.val);
    }
    if (data1.setPMT) {
      setPMT(data1.setPMT);
      setOPG(1);
    }
    if (data1.setOH) {
      let index = data1.setOH.index,
        val = data1.setOH.val,
        val2 = data1.setOH.val2;
      setOH((prev) => {
        let left = prev.slice(0, index);
        let right = val2 ? [val2] : prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.setOD) {
      setOD(data1.setOD);
      setOPG(1);
    }
    if (data1.setOPG) {
      setOPG(data1.setOPG);
      window.scrollTo(0, 0);
    }
    if (data1.setODType) {
      setODType(data1.setODType);
      if (data1.setODType === "fixed") setOPMax(opMin);
    }
    if (data1.setOPMin) {
      setOPMin(data1.setOPMin);
    }
    if (data1.setOPMax) {
      setOPMax(data1.setOPMax);
    }
    if (data1.setPC) {
      setPC(data1.setPC);
    }
    if (data1.addPM) {
      setMyPM(data1.addPM);
    }
    if (data1.addPMData) {
      setMyPMData(data1.addPMData);
    }
    if (data1.removePM) {
      let index = data1.removePM.index;
      setMyPM((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, ...right];
      });
      setMyPMData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, ...right];
      });
    }
    if (data1.setSUEMSG) {
      setSUEMSG(data1.setSUEMSG);
    }
    if (data1.setSUErrors) {
      let indexes = data1.setSUErrors.indexes,
        bool = data1.setSUErrors.bool;
      indexes.map((index) =>
        setSUErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setSignUpData) {
      let index = data1.setSignUpData.index;
      let val = data1.setSignUpData.val;
      let select = data1.setSignUpData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setSignUpData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.signUP) {
      loading.current.continuousStart();
      setSUErrors(initialSUErrors);
      setCFErrors(initialCFErrors);
      $.ajax({
        type: "POST",
        url: phpSendURL,
        dataType: "json",
        data: {
          send: "ssu",
          offset: userOffset(),
          date: new Date(),
          signUpData: signUpData,
          device: device,
        },
        success(data) {
          loading.current.complete();
          let status = data.status,
            index = parseInt(data.index),
            msg = data.msg;
          if (status === "error") {
            setSUErrors((prev) => {
              let left = prev.slice(0, index);
              let right = prev.slice(index + 1);
              return [...left, true, ...right];
            });
            setSUSEMSG(msg);
          }
          if (status === "passed") {
            setConfData((prev) => {
              let left = prev.slice(0, 0);
              let right = prev.slice(0 + 1);
              return [...left, signUpData[0], ...right];
            });
            pState({
              title: "Confirm Email",
              newUrl: "/accounts/confirm",
              rto: "Confirm",
              rid: null,
            });
          }
        },
      });
    }
    if (data1.setLIEMSG) {
      setLIEMSG(data1.setLIEMSG);
    }
    if (data1.setLIErrors) {
      let indexes = data1.setLIErrors.indexes,
        bool = data1.setLIErrors.bool;
      indexes.map((index) =>
        setLIErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setLogInData) {
      let index = data1.setLogInData.index;
      let val = data1.setLogInData.val;
      let select = data1.setLogInData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setLogInData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.logIn) {
      loading.current.continuousStart();
      setLIErrors(initialLIErrors);
      $.ajax({
        type: "POST",
        url: phpSendURL,
        dataType: "json",
        data: {
          send: "sli",
          offset: userOffset(),
          date: new Date(),
          logInData: logInData,
          device: device,
        },
        success(data) {
          loading.current.complete();
          let status = data.status,
            index = parseInt(data.index),
            msg = data.msg;
          if (status === "error") {
            setLIErrors((prev) => {
              let left = prev.slice(0, index);
              let right = prev.slice(index + 1);
              return [...left, true, ...right];
            });
            setLISEMSG(msg);
          }
          if (status === "passed") {
            setuLogin(true);
            pState({
              title: "Bets, Buy & Sell",
              newUrl: "/welcome",
              rto: "Welcome",
              rid: null,
            });
          }
        },
      });
    }
    if (data1.setFPEMSG) {
      setFPEMSG(data1.setFPEMSG);
    }
    if (data1.setFPErrors) {
      let indexes = data1.setFPErrors.indexes,
        bool = data1.setFPErrors.bool;
      indexes.map((index) =>
        setFPErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setForgotData) {
      let index = data1.setForgotData.index;
      let val = data1.setForgotData.val;
      let select = data1.setForgotData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setForgotData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.forgot) {
      loading.current.continuousStart();
      setFPErrors(initialFPErrors);
      $.ajax({
        type: "POST",
        url: phpSendURL,
        dataType: "json",
        data: {
          send: "sfp",
          offset: userOffset(),
          date: new Date(),
          forgotData: forgotData,
          device: device,
        },
        success(data) {
          loading.current.complete();
          let status = data.status,
            index = parseInt(data.index),
            msg = data.msg;
          if (status === "error") {
            setFPErrors((prev) => {
              let left = prev.slice(0, index);
              let right = prev.slice(index + 1);
              return [...left, true, ...right];
            });
            setFPSEMSG(msg);
          }
          if (status === "passed") {
            if (accounts === "reset-sent") {
              setFPErrors((prev) => {
                let left = prev.slice(0, 5);
                let right = prev.slice(5 + 1);
                return [...left, true, ...right];
              });
            } else {
              pState({
                title: "Reset Instruction Sent",
                newUrl: "/accounts/reset-sent",
                rto: "Reset-Sent",
                rid: null,
              });
            }
          }
        },
      });
    }
    if (data1.setRPEMSG) {
      setRPEMSG(data1.setRPEMSG);
    }
    if (data1.setRPErrors) {
      let indexes = data1.setRPErrors.indexes,
        bool = data1.setRPErrors.bool;
      indexes.map((index) =>
        setRPErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setResetData) {
      let index = data1.setResetData.index;
      let val = data1.setResetData.val;
      let select = data1.setResetData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setResetData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.reset) {
      loading.current.continuousStart();
      setRPErrors(initialRPErrors);
      $.ajax({
        type: "POST",
        url: phpSendURL,
        dataType: "json",
        data: {
          send: "srp",
          offset: userOffset(),
          date: new Date(),
          resetData: resetData,
          device: device,
        },
        success(data) {
          loading.current.complete();
          let status = data.status,
            index = parseInt(data.index),
            msg = data.msg;
          if (status === "error") {
            setRPErrors((prev) => {
              let left = prev.slice(0, index);
              let right = prev.slice(index + 1);
              return [...left, true, ...right];
            });
            setRPSEMSG(msg);
          }
          if (status === "passed") {
            pState({
              title: "Reset Success",
              newUrl: "/accounts/reset",
              rto: "Reset",
              rid: null,
            });
          }
        },
      });
    }
    if (data1.setCFEMSG) {
      setCFEMSG(data1.setCFEMSG);
    }
    if (data1.setCFErrors) {
      let indexes = data1.setCFErrors.indexes,
        bool = data1.setCFErrors.bool;
      indexes.map((index) =>
        setCFErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setConfData) {
      let index = data1.setConfData.index;
      let val = data1.setConfData.val;
      let select = data1.setConfData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setConfData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.conf) {
      loading.current.continuousStart();
      setCFErrors(initialCFErrors);
      $.ajax({
        type: "POST",
        url: phpSendURL,
        dataType: "json",
        data: {
          send: "scf",
          offset: userOffset(),
          date: new Date(),
          confData: confData,
          device: device,
        },
        success(data) {
          loading.current.complete();
          let status = data.status,
            index = parseInt(data.index),
            msg = data.msg;
          if (status === "error") {
            setCFErrors((prev) => {
              let left = prev.slice(0, index);
              let right = prev.slice(index + 1);
              return [...left, true, ...right];
            });
            setCFSEMSG(msg);
          }
          if (status === "passed") {
            if (accounts === "resent" || accounts === "confirm") {
              setCFErrors((prev) => {
                let left = prev.slice(0, 5);
                let right = prev.slice(5 + 1);
                return [...left, true, ...right];
              });
            } else {
              pState({
                title: "Confirm Email",
                newUrl: "/accounts/resent",
                rto: "Resent",
                rid: null,
              });
            }
          }
        },
      });
    }
    if (data1.signOut) {
      loading.current.continuousStart();
      $.ajax({
        type: "POST",
        url: phpSendURL,
        dataType: "json",
        data: {
          send: "sso",
          offset: userOffset(),
          date: new Date(),
        },
        success(data) {
          loading.current.complete();
          let status = data.status;
          if (status === "passed") {
            setuLogin(false);
            pState({
              title: "Bets, Buy & Sell",
              newUrl: "/welcome",
              rto: "Welcome",
              rid: null,
            });
          }
        },
      });
    }
    if (data1.setCRSErrors) {
      let indexes = data1.setCRSErrors.indexes,
        bool = data1.setCRSErrors.bool;
      indexes.map((index) =>
        setCRSErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setCRSEMSG) {
      setCRSEMSG(data1.setCRSEMSG);
    }
    if (data1.setCRSSettings) {
      let index = data1.setCRSSettings.index;
      let val = data1.setCRSSettings.val;
      let select = data1.setCRSSettings.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      if (index === 0) setOH(initialOH);
      setCRSSettings((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.createSell) {
      loading.current.continuousStart();
      setSNErrors(initialSNErrors);
      setCRSErrors(initialCRSErrors);
      $.ajax({
        type: "POST",
        url: phpSendURL,
        dataType: "json",
        data: {
          send: "scs",
          offset: userOffset(),
          date: new Date(),
          cy: cy,
          odt: odt,
          opMin: opMin,
          opMax: opMax,
          pc: pc,
          myPM: myPM,
          oh: oh,
          crsSettings: crsSettings,
          crsTitle: crsTitle,
          crsDesc: crsDesc,
        },
        success(data) {
          loading.current.complete();
          let status = data.status,
            index = parseInt(data.index);
          if (status === "error") {
            if (index === 48) {
              index = 0;
              setSNErrors((prev) => {
                let left = prev.slice(0, index);
                let right = prev.slice(index + 1);
                return [...left, true, ...right];
              });
              setSN(true);
            } else {
              setCRSErrors((prev) => {
                let left = prev.slice(0, index);
                let right = prev.slice(index + 1);
                return [...left, true, ...right];
              });
            }
          }
          if (status === "passed") {
            // do something
          }
          if (status === "login") {
            setSN(true);
          }
        },
      });
    }
    if (data1.setBOCErrors) {
      let indexes = data1.setBOCErrors.indexes,
        bool = data1.setBOCErrors.bool;
      indexes.map((index) =>
        setBOCErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setBOCEMSG) {
      setBOCEMSG(data1.setBOCEMSG);
    }
    if (data1.createBOC) {
      loading.current.continuousStart();
      setSNErrors(initialSNErrors);
      setBOCErrors(initialBOCErrors);
      $.ajax({
        type: "POST",
        url: phpSendURL,
        dataType: "json",
        data: {
          send: "scbc",
          offset: userOffset(),
          date: new Date(),
          link: offerID,
          od: od,
          pm: boPM,
          boEOP: boEOP,
          boW: boW,
        },
        success(data) {
          loading.current.complete();
          let status = data.status,
            index = parseInt(data.index);
          if (status === "error") {
            if (index === 48) {
              index = 1;
              setSNErrors((prev) => {
                let left = prev.slice(0, index);
                let right = prev.slice(index + 1);
                return [...left, true, ...right];
              });
              setSN(true);
            } else {
              setBOCErrors((prev) => {
                let left = prev.slice(0, index);
                let right = prev.slice(index + 1);
                return [...left, true, ...right];
              });
            }
          }
          if (status === "passed") {
            // do something
          }
          if (status === "login") {
            setSN(true);
          }
        },
      });
    }
  };

  const handleChange = (data1) => {
    if (data1.setSearch) {
      setSearch(data1.setSearch.data);
    }
    if (data1.setOD) {
      setOD(data1.setOD);
      setOPG(1);
    }
    if (data1.setSUErrors) {
      let indexes = data1.setSUErrors.indexes,
        bool = data1.setSUErrors.bool;
      indexes.map((index) =>
        setSUErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setSignUpData) {
      let index = data1.setSignUpData.index;
      let val = data1.setSignUpData.val;
      let select = data1.setSignUpData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setSignUpData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.setLIErrors) {
      let indexes = data1.setLIErrors.indexes,
        bool = data1.setLIErrors.bool;
      indexes.map((index) =>
        setLIErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setLogInData) {
      let index = data1.setLogInData.index;
      let val = data1.setLogInData.val;
      let select = data1.setLogInData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setLogInData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.setFPErrors) {
      let indexes = data1.setFPErrors.indexes,
        bool = data1.setFPErrors.bool;
      indexes.map((index) =>
        setFPErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setForgotData) {
      let index = data1.setForgotData.index;
      let val = data1.setForgotData.val;
      let select = data1.setForgotData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setForgotData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.setRPErrors) {
      let indexes = data1.setRPErrors.indexes,
        bool = data1.setRPErrors.bool;
      indexes.map((index) =>
        setRPErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setResetData) {
      let index = data1.setResetData.index;
      let val = data1.setResetData.val;
      let select = data1.setResetData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setResetData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.setCFErrors) {
      let indexes = data1.setCFErrors.indexes,
        bool = data1.setCFErrors.bool;
      indexes.map((index) =>
        setCFErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setConfData) {
      let index = data1.setConfData.index;
      let val = data1.setConfData.val;
      let select = data1.setConfData.select;
      if (select) {
        setSLT("none");
        setOVSLT(true);
      }
      setConfData((prev) => {
        let left = prev.slice(0, index);
        let right = prev.slice(index + 1);
        return [...left, val, ...right];
      });
    }
    if (data1.setCRSErrors) {
      let indexes = data1.setCRSErrors.indexes,
        bool = data1.setCRSErrors.bool;
      indexes.map((index) =>
        setCRSErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
    if (data1.setCRSTitle) {
      setCRSTitle(data1.setCRSTitle.data);
    }
    if (data1.setCRSDesc) {
      setCRSDesc(data1.setCRSDesc.data);
    }
    if (data1.setBOCErrors) {
      let indexes = data1.setBOCErrors.indexes,
        bool = data1.setBOCErrors.bool;
      indexes.map((index) =>
        setBOCErrors((prev) => {
          let left = prev.slice(0, index);
          let right = prev.slice(index + 1);
          return [...left, bool, ...right];
        })
      );
    }
  };

  const handleSubmit = (data1) => {
    if (data1.pState) {
      loading.current.continuousStart();
      $.ajax({
        type: "POST",
        url: phpGetURL,
        dataType: "json",
        data: { get: "gli" },
        success(data) {
          let status = data.status === "true" ? true : false;
          setuLogin(status);
          pState(data1.pState);
          loading.current.complete();
        },
      });
    }
  };

  $(window).on("popstate", function () {
    let state = window.history.state;
    setStateData(state);
    let rto = state.rto;
    if (!state || rto === "Welcome") {
      let title = "Peer2Punt - Bets, Buy & Sell";
      //let url = "http://localhost:3000/welcome";
      //url = "http://127.0.0.1/welcome",
      //url = "http://192.168.0.100/welcome",
      let url = "https://peer2punt.com/welcome";
      window.history.replaceState(fullUrl, title, url);
      document.title = title;
    }
  });

  const getPuntTypes = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gpt" },
      success(data) {
        setPTData(data);
      },
    });
  }, []);

  const getPuntOptions = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gpo" },
      success(data) {
        setPOData(data);
      },
    });
  }, []);

  const getCountries = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gcc", spcc: sCountry },
      success(data) {
        setccData(data);
        if (cc === "all") setsCountry(initialsCountry);
      },
    });
  }, [sCountry, cc]);

  const getCurrency = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gcy" },
      success(data) {
        setcyData(data);
      },
    });
  }, []);

  const getTZ = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gtz" },
      success(data) {
        setTZData(data);
      },
    });
  }, []);

  const getPayMethods = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gpm" },
      success(data) {
        setpmData(data);
      },
    });
  }, []);

  const getPercentages = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gpc" },
      success(data) {
        setpcData(data);
      },
    });
  }, []);

  const getOfferHours = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "goh" },
      success(data) {
        setohData(data);
      },
    });
  }, []);

  const getUsersLimits = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gul" },
      success(data) {
        setulData(data);
      },
    });
  }, []);

  const getPayWindows = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gpw" },
      success(data) {
        setpwData(data);
      },
    });
  }, []);

  const getBookmakers = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gbm" },
      success(data) {
        setbmData(data);
      },
    });
  }, []);

  const getPayTypes = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gpmt" },
      success(data) {
        setpmtData(data);
      },
    });
  }, []);

  const getBuyOffers = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gbo" },
      success(data) {
        setboData(data);
      },
    });
  }, []);

  const getBuyOffer = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gsbo", link: offerID, pm: pm },
      success(data) {
        if (data.total) {
          setsBOData(data);
          if (data.offer.boodt === "fixed") setOD(data.offer.bood.min);
          if (!boPM) {
            if (data.offer.bospm === "true") {
              setBOPM(pm);
            } else {
              setBOPM(data.offer.bopm.bopm1);
            }
          }
        } else {
          setsBOData(null);
        }
      },
    });
  }, [offerID, boPM, pm]);

  const getBOContracts = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gsbc", link: offerID },
      success(data) {
        offerID ? setsBOCData(data) : setsBOCData(null);
      },
    });
  }, [offerID]);

  const getUserRatings = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gur", link: offerID },
      success(data) {
        data.total ? setURData(data) : setURData(null);
      },
    });
  }, [offerID]);

  const getSellOffers = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gso" },
      success(data) {
        setsoData(data);
      },
    });
  }, []);

  const getOddsPrices = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gop" },
      success(data) {
        setopData(data);
      },
    });
  }, []);

  const getTopPT = () => {
    if (!evData) return;
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gevptt", offset: userOffset(), events: evData },
      success(data) {
        setevTPPTData(data);
      },
    });
  };

  const getFreePT = () => {
    if (!evData) return;
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gevptf", offset: userOffset(), events: evData },
      success(data) {
        setevFRPTData(data);
      },
    });
  };

  const getNextEV = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gnev", offset: userOffset(), date: dd },
      success(data) {
        setnevData(data);
      },
    });
  }, [userOffset, dd]);

  const getEvents = React.useCallback(() => {
    $.ajax({
      type: "POST",
      url: phpGetURL,
      dataType: "json",
      data: { get: "gev", offset: userOffset(), date: dd },
      success(data) {
        setevData(data);
        if (data.day === "Today") getNextEV();
        return true;
      },
    });

    return false;
  }, [userOffset, dd, getNextEV]);

  React.useEffect(() => {
    $(function () {
      userOffset();
      sendDevice();
      getLogin();
      getUser();
      getPuntTypes();
      getPuntOptions();
      getCountries();
      getCurrency();
      getTZ();
      getPayMethods();
      getPercentages();
      getOfferHours();
      getUsersLimits();
      getPayWindows();
      getBookmakers();
      getPayTypes();
      getBuyOffers();
      getBuyOffer();
      getBOContracts();
      getUserRatings();
      getSellOffers();
      getOddsPrices();
      getEvents();
    });
  }, [
    userOffset,
    sendDevice,
    getLogin,
    getUser,
    getPuntTypes,
    getPuntOptions,
    getCountries,
    getTZ,
    getCurrency,
    getPayMethods,
    getPercentages,
    getOfferHours,
    getUsersLimits,
    getPayWindows,
    getBookmakers,
    getPayTypes,
    getBuyOffers,
    getBuyOffer,
    getBOContracts,
    getUserRatings,
    getSellOffers,
    getOddsPrices,
    getEvents,
  ]);

  return (
    <>
      {uLogin ? (
        <HeaderIn
          uData={uData}
          sSport={sSport}
          onClick={(value) => handleClick(value)}
        />
      ) : (
        <HeaderOut onClick={(value) => handleClick(value)} />
      )}
      <LoadingBar color="#fd7b00" ref={loading} />
      <main>
        {sn ? (
          <SideNotice
            uLogin={uLogin}
            snErrors={snErrors}
            onClick={(value) => handleClick(value)}
          />
        ) : (
          ""
        )}
        {!page || page === "welcome" ? (
          <Landing
            uLogin={uLogin}
            cyData={cyData}
            pmData={pmData}
            ulData={ulData}
            cy={cy}
            pm={pm}
            od={od}
            mkt={mkt}
            slt={slt}
            search={search}
            onClick={(value) => handleClick(value)}
            onChange={(value) => handleChange(value)}
            onSubmit={(value) => handleSubmit(value)}
          />
        ) : (
          ""
        )}
        {page === "market" ? (
          market === "sell-bet" ? (
            <SellBetMKT
              uLogin={uLogin}
              cyData={cyData}
              pmData={pmData}
              pmtData={pmtData}
              ulData={ulData}
              soData={soData}
              cy={cy}
              pm={pm}
              pmt={pmt}
              od={od}
              opg={opg}
              slt={slt}
              search={search}
              onClick={(value) => handleClick(value)}
              onChange={(value) => handleChange(value)}
              onSubmit={(value) => handleSubmit(value)}
            />
          ) : (
            <BuyBetMKT
              uLogin={uLogin}
              cyData={cyData}
              pmData={pmData}
              pmtData={pmtData}
              ulData={ulData}
              boData={boData}
              cy={cy}
              pm={pm}
              pmt={pmt}
              od={od}
              opg={opg}
              slt={slt}
              search={search}
              onClick={(value) => handleClick(value)}
              onChange={(value) => handleChange(value)}
              onSubmit={(value) => handleSubmit(value)}
            />
          )
        ) : (
          ""
        )}
        {/* {page === "create-buy-offer" ? (
          <CreateBuyOffer
            uData={uData}
            cyData={cyData}
            pmData={pmData}
            pmtData={pmtData}
            pcData={pcData}
            ohData={ohData}
            ulData={ulData}
            pwData={pwData}
            bmData={bmData}
            opData={opData}
            cy={cy}
            pm={pm}
            pmt={pmt}
            oh={oh}
            slt={slt}
            search={search}
            odt={odt}
            opMin={opMin}
            opMax={opMax}
            pc={pc}
            myPM={myPM}
            myPMData={myPMData}
            crbTitle={crbTitle}
            crbDesc={crbDesc}
            crbErrors={crbErrors}
            crbEMSG={crbEMSG}
            crbSettings={crbSettings}
            onClick={(value) => handleClick(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : (
          ""
        )} */}
        {page === "create-sell-offer" ? (
          <CreateSellOffer
            uData={uData}
            cyData={cyData}
            pmData={pmData}
            pmtData={pmtData}
            pcData={pcData}
            ohData={ohData}
            ulData={ulData}
            pwData={pwData}
            bmData={bmData}
            opData={opData}
            cy={cy}
            pm={pm}
            pmt={pmt}
            oh={oh}
            slt={slt}
            search={search}
            odt={odt}
            opMin={opMin}
            opMax={opMax}
            pc={pc}
            myPM={myPM}
            myPMData={myPMData}
            crsTitle={crsTitle}
            crsDesc={crsDesc}
            crsErrors={crsErrors}
            crsEMSG={crsEMSG}
            crsSettings={crsSettings}
            onClick={(value) => handleClick(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : (
          ""
        )}
        {page === "about" ? (
          <About onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "security-guide" ? (
          <SecurityGuide onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "contact-us" ? (
          <ContactUs onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "terms-of-service" ? (
          <TermsOfService onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "dispute-rules" ? (
          <DisputeRules onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "terms-and-conditions" ? (
          <TermsAndConditions onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "privacy-policy" ? (
          <PrivacyPolicy onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "help" || page === "how-to" ? (
          <Help onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "faq" ? (
          <Faq
            opData={opData}
            pcData={pcData}
            ulData={ulData}
            onClick={(value) => handleClick(value)}
          />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "signup" ? (
          <SignUp
            signUpData={signUpData}
            tzData={tzData}
            slt={slt}
            search={search}
            suErrors={suErrors}
            suEMSG={suEMSG}
            suSEMSG={suSEMSG}
            onClick={(value) => handleClick(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "confirm" ? (
          <Confirm
            confData={confData}
            cfErrors={cfErrors}
            cfEMSG={cfEMSG}
            cfSEMSG={cfSEMSG}
            onClick={(value) => handleClick(value)}
          />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "login" ? (
          <LogIn
            logInData={logInData}
            liErrors={liErrors}
            liEMSG={liEMSG}
            liSEMSG={liSEMSG}
            onClick={(value) => handleClick(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "forgot" ? (
          <Forgot
            forgotData={forgotData}
            fpErrors={fpErrors}
            fpEMSG={fpEMSG}
            fpSEMSG={fpSEMSG}
            onClick={(value) => handleClick(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "reset-sent" ? (
          <ResetSent
            forgotData={forgotData}
            fpErrors={fpErrors}
            fpEMSG={fpEMSG}
            fpSEMSG={fpSEMSG}
            onClick={(value) => handleClick(value)}
          />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "reset-password" ? (
          <ResetPass
            resetData={resetData}
            rpErrors={rpErrors}
            rpEMSG={rpEMSG}
            rpSEMSG={rpSEMSG}
            onClick={(value) => handleClick(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "reset" ? (
          <Reset onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "confirmation" ? (
          <Confirmation
            confData={confData}
            cfErrors={cfErrors}
            cfEMSG={cfEMSG}
            cfSEMSG={cfSEMSG}
            onClick={(value) => handleClick(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "resent" ? (
          <Resent
            confData={confData}
            cfErrors={cfErrors}
            cfEMSG={cfEMSG}
            cfSEMSG={cfSEMSG}
            onClick={(value) => handleClick(value)}
          />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "verify" ? (
          <Verify onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "accounts" && accounts === "expired" ? (
          <Expired onClick={(value) => handleClick(value)} />
        ) : (
          ""
        )}
        {page === "offer" && offer === "buy" ? (
          <BuyOffer
            uLogin={uLogin}
            ptData={ptData}
            poData={poData}
            cyData={cyData}
            pmData={pmData}
            pmtData={pmtData}
            sBOData={sBOData}
            sBOCData={sBOCData}
            bocErrors={bocErrors}
            bocEMSG={bocEMSG}
            urData={urData}
            ulData={ulData}
            bmData={bmData}
            opData={opData}
            pt={pt}
            boPM={boPM}
            boEOP={boEOP}
            boEOPR={boEOPR}
            boW={boW}
            slt={slt}
            od={od}
            onClick={(value) => handleClick(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : (
          ""
        )}
        {page === "sports" ? (
          <Main
            dd={dd}
            sDate={sDate}
            ccData={ccData}
            sCountry={sCountry}
            mView={mView}
            eView={eView}
            evData={evData}
            evTPPTData={evTPPTData}
            evFRPTData={evFRPTData}
            nevData={nevData}
            stateData={stateData}
            onClick={(value) => handleClick(value)}
          />
        ) : (
          ""
        )}
      </main>
      <Overlay
        rb={rb}
        ov0={ov0}
        ovSLT={ovSLT}
        onClick={(value) => handleClick(value)}
      />
      <RightBar
        uLogin={uLogin}
        rb={rb}
        rbDrop={rbDrop}
        onClick={(value) => handleClick(value)}
      />
      <Footer onClick={(value) => handleClick(value)} />
      <Routes>
        <Route path="/welcome" element="" />
      </Routes>
    </>
  );
}

export default App;
