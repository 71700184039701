import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/contact-us.css";

const ContactUs = (props) => {
  const handleLink = (e, url, title, rto) => {
    e.preventDefault();
    let pState = {
      title: title,
      newUrl: url,
      rto: rto,
      rid: null,
    };
    return { pState: pState };
  };

  return (
    <>
      <div className="contact-main">
        <div className="cm-bf">
          Before trading read the&nbsp;
          <Link
            to="/security-guide"
            onClick={(e) =>
              props.onClick(
                handleLink(
                  e,
                  "/security-guide",
                  "Security Guide",
                  "Security-Guide"
                )
              )
            }
          >
            SECURITY GUIDE
          </Link>
        </div>
        <div className="cm-bd">
          <div className="cm-hd">Contact us</div>
          <div className="cm-links">
            <div className="cm-link">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.031 20">
                <path
                  d="M12.015 11C10.062 11 0 2.891 0 2.891V2a2 2 0 0 1 2-2h20.031a2 2 0 0 1 2 2l-.016 1s-9.953 8-12 8zm0 2.75c2.141 0 12-7.75 12-7.75l.016 12a2 2 0 0 1-2 2H2.002a2 2 0 0 1-2-2L.016 6s10.046 7.75 11.999 7.75z"
                  fill="#dde3f5"
                  fillRule="evenodd"
                />
                <script xmlns="" id="webrtc-control-b" />
              </svg>
              <span>Support:</span>
              &nbsp;
              <Link to="mailto:support@peer2punt.com">
                support@peer2punt.com
              </Link>
            </div>
            <div className="cm-link">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.031 20">
                <path
                  d="M12.015 11C10.062 11 0 2.891 0 2.891V2a2 2 0 0 1 2-2h20.031a2 2 0 0 1 2 2l-.016 1s-9.953 8-12 8zm0 2.75c2.141 0 12-7.75 12-7.75l.016 12a2 2 0 0 1-2 2H2.002a2 2 0 0 1-2-2L.016 6s10.046 7.75 11.999 7.75z"
                  fill="#dde3f5"
                  fillRule="evenodd"
                />
                <script xmlns="" id="webrtc-control-b" />
              </svg>
              <span>Dispute related questions: </span>
              &nbsp;
              <Link to="mailto:dispute@peer2punt.com">
                dispute@peer2punt.com
              </Link>
            </div>
            <div className="cm-link">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.031 20">
                <path
                  d="M12.015 11C10.062 11 0 2.891 0 2.891V2a2 2 0 0 1 2-2h20.031a2 2 0 0 1 2 2l-.016 1s-9.953 8-12 8zm0 2.75c2.141 0 12-7.75 12-7.75l.016 12a2 2 0 0 1-2 2H2.002a2 2 0 0 1-2-2L.016 6s10.046 7.75 11.999 7.75z"
                  fill="#dde3f5"
                  fillRule="evenodd"
                />
                <script xmlns="" id="webrtc-control-b" />
              </svg>
              <span>Marketing: </span>
              &nbsp;
              <Link to="mailto:marketing@peer2punt.com">
                marketing@peer2punt.com
              </Link>
            </div>
          </div>
          <div className="cm-social">
            <div className="cm-s-item">
              <div className="cm-s-hd">Twitter (X)</div>
              <div className="cm-s-links">
                <div className="cm-s-link">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30.763 25"
                    >
                      <path
                        d="M30.763 2.959a12.558 12.558 0 0 1-3.625.994A6.323 6.323 0 0 0 29.913.461a12.634 12.634 0 0 1-4.008 1.532 6.317 6.317 0 0 0-10.754 5.756 17.92 17.92 0 0 1-13.01-6.594 6.319 6.319 0 0 0 1.953 8.427 6.293 6.293 0 0 1-2.859-.79v.08a6.316 6.316 0 0 0 5.063 6.188 6.346 6.346 0 0 1-1.663.221 6.253 6.253 0 0 1-1.188-.114 6.318 6.318 0 0 0 5.9 4.383A12.735 12.735 0 0 1 0 22.164 17.86 17.86 0 0 0 9.675 25c11.61 0 17.957-9.617 17.957-17.957q0-.41-.017-.817a12.783 12.783 0 0 0 3.148-3.267z"
                        fill="#dde3f5"
                      />
                      <script xmlns="" id="webrtc-control-b" />
                    </svg>
                    @peer2punt
                  </span>
                  <span>Main twitter</span>
                </div>
                <div className="cm-s-link">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30.763 25"
                    >
                      <path
                        d="M30.763 2.959a12.558 12.558 0 0 1-3.625.994A6.323 6.323 0 0 0 29.913.461a12.634 12.634 0 0 1-4.008 1.532 6.317 6.317 0 0 0-10.754 5.756 17.92 17.92 0 0 1-13.01-6.594 6.319 6.319 0 0 0 1.953 8.427 6.293 6.293 0 0 1-2.859-.79v.08a6.316 6.316 0 0 0 5.063 6.188 6.346 6.346 0 0 1-1.663.221 6.253 6.253 0 0 1-1.188-.114 6.318 6.318 0 0 0 5.9 4.383A12.735 12.735 0 0 1 0 22.164 17.86 17.86 0 0 0 9.675 25c11.61 0 17.957-9.617 17.957-17.957q0-.41-.017-.817a12.783 12.783 0 0 0 3.148-3.267z"
                        fill="#dde3f5"
                      />
                      <script xmlns="" id="webrtc-control-b" />
                    </svg>
                    @peer2puntspanish
                  </span>
                  <span>Spanish twitter</span>
                </div>
                <div className="cm-s-link">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30.763 25"
                    >
                      <path
                        d="M30.763 2.959a12.558 12.558 0 0 1-3.625.994A6.323 6.323 0 0 0 29.913.461a12.634 12.634 0 0 1-4.008 1.532 6.317 6.317 0 0 0-10.754 5.756 17.92 17.92 0 0 1-13.01-6.594 6.319 6.319 0 0 0 1.953 8.427 6.293 6.293 0 0 1-2.859-.79v.08a6.316 6.316 0 0 0 5.063 6.188 6.346 6.346 0 0 1-1.663.221 6.253 6.253 0 0 1-1.188-.114 6.318 6.318 0 0 0 5.9 4.383A12.735 12.735 0 0 1 0 22.164 17.86 17.86 0 0 0 9.675 25c11.61 0 17.957-9.617 17.957-17.957q0-.41-.017-.817a12.783 12.783 0 0 0 3.148-3.267z"
                        fill="#dde3f5"
                      />
                      <script xmlns="" id="webrtc-control-b" />
                    </svg>
                    @offerspeer2punt
                  </span>
                  <span>Newest and best offers</span>
                </div>
              </div>
              <div className="cm-s-item">
                <div className="cm-s-hd">Telegram</div>
                <div className="cm-s-links">
                  <div className="cm-s-link">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30.001 25"
                      >
                        <path
                          d="M22.98 24.724a1.486 1.486 0 0 0 1.384.181 1.45 1.45 0 0 0 .905-1.05c1.086-5.105 3.721-18.026 4.71-22.67a.976.976 0 0 0-.325-.948 1 1 0 0 0-1-.175C23.416 2 7.275 8.059.678 10.5a1.028 1.028 0 0 0 .064 1.954c2.959.885 6.843 2.116 6.843 2.116s1.815 5.481 2.761 8.269a1.1 1.1 0 0 0 .754.72 1.083 1.083 0 0 0 1.014-.259l3.87-3.654s4.465 3.274 7 5.078zM9.218 13.878l2.1 6.923.466-4.384 12.73-11.483a.347.347 0 0 0 .041-.471.355.355 0 0 0-.47-.08l-14.868 9.5z"
                          fill="#dde3f5"
                          fillRule="evenodd"
                        />
                        <script xmlns="" id="webrtc-control-b" />
                      </svg>
                      @peer2punt
                    </span>
                    <span>English</span>
                  </div>
                  <div className="cm-s-link">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30.001 25"
                      >
                        <path
                          d="M22.98 24.724a1.486 1.486 0 0 0 1.384.181 1.45 1.45 0 0 0 .905-1.05c1.086-5.105 3.721-18.026 4.71-22.67a.976.976 0 0 0-.325-.948 1 1 0 0 0-1-.175C23.416 2 7.275 8.059.678 10.5a1.028 1.028 0 0 0 .064 1.954c2.959.885 6.843 2.116 6.843 2.116s1.815 5.481 2.761 8.269a1.1 1.1 0 0 0 .754.72 1.083 1.083 0 0 0 1.014-.259l3.87-3.654s4.465 3.274 7 5.078zM9.218 13.878l2.1 6.923.466-4.384 12.73-11.483a.347.347 0 0 0 .041-.471.355.355 0 0 0-.47-.08l-14.868 9.5z"
                          fill="#dde3f5"
                          fillRule="evenodd"
                        />
                        <script xmlns="" id="webrtc-control-b" />
                      </svg>
                      @peer2puntrussian
                    </span>
                    <span>Русскоязычный</span>
                  </div>
                  <div className="cm-s-link">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30.001 25"
                      >
                        <path
                          d="M22.98 24.724a1.486 1.486 0 0 0 1.384.181 1.45 1.45 0 0 0 .905-1.05c1.086-5.105 3.721-18.026 4.71-22.67a.976.976 0 0 0-.325-.948 1 1 0 0 0-1-.175C23.416 2 7.275 8.059.678 10.5a1.028 1.028 0 0 0 .064 1.954c2.959.885 6.843 2.116 6.843 2.116s1.815 5.481 2.761 8.269a1.1 1.1 0 0 0 .754.72 1.083 1.083 0 0 0 1.014-.259l3.87-3.654s4.465 3.274 7 5.078zM9.218 13.878l2.1 6.923.466-4.384 12.73-11.483a.347.347 0 0 0 .041-.471.355.355 0 0 0-.47-.08l-14.868 9.5z"
                          fill="#dde3f5"
                          fillRule="evenodd"
                        />
                        <script xmlns="" id="webrtc-control-b" />
                      </svg>
                      @peer2puntasia
                    </span>
                    <span>Asia</span>
                  </div>
                  <div className="cm-s-link">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30.001 25"
                      >
                        <path
                          d="M22.98 24.724a1.486 1.486 0 0 0 1.384.181 1.45 1.45 0 0 0 .905-1.05c1.086-5.105 3.721-18.026 4.71-22.67a.976.976 0 0 0-.325-.948 1 1 0 0 0-1-.175C23.416 2 7.275 8.059.678 10.5a1.028 1.028 0 0 0 .064 1.954c2.959.885 6.843 2.116 6.843 2.116s1.815 5.481 2.761 8.269a1.1 1.1 0 0 0 .754.72 1.083 1.083 0 0 0 1.014-.259l3.87-3.654s4.465 3.274 7 5.078zM9.218 13.878l2.1 6.923.466-4.384 12.73-11.483a.347.347 0 0 0 .041-.471.355.355 0 0 0-.47-.08l-14.868 9.5z"
                          fill="#dde3f5"
                          fillRule="evenodd"
                        />
                        <script xmlns="" id="webrtc-control-b" />
                      </svg>
                      @peer2puntespanol
                    </span>
                    <span>Español</span>
                  </div>
                  <div className="cm-s-link">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30.001 25"
                      >
                        <path
                          d="M22.98 24.724a1.486 1.486 0 0 0 1.384.181 1.45 1.45 0 0 0 .905-1.05c1.086-5.105 3.721-18.026 4.71-22.67a.976.976 0 0 0-.325-.948 1 1 0 0 0-1-.175C23.416 2 7.275 8.059.678 10.5a1.028 1.028 0 0 0 .064 1.954c2.959.885 6.843 2.116 6.843 2.116s1.815 5.481 2.761 8.269a1.1 1.1 0 0 0 .754.72 1.083 1.083 0 0 0 1.014-.259l3.87-3.654s4.465 3.274 7 5.078zM9.218 13.878l2.1 6.923.466-4.384 12.73-11.483a.347.347 0 0 0 .041-.471.355.355 0 0 0-.47-.08l-14.868 9.5z"
                          fill="#dde3f5"
                          fillRule="evenodd"
                        />
                        <script xmlns="" id="webrtc-control-b" />
                      </svg>
                      @disputeatp2p
                    </span>
                    <span>Disputes</span>
                  </div>
                  <div className="cm-s-link">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30.001 25"
                      >
                        <path
                          d="M22.98 24.724a1.486 1.486 0 0 0 1.384.181 1.45 1.45 0 0 0 .905-1.05c1.086-5.105 3.721-18.026 4.71-22.67a.976.976 0 0 0-.325-.948 1 1 0 0 0-1-.175C23.416 2 7.275 8.059.678 10.5a1.028 1.028 0 0 0 .064 1.954c2.959.885 6.843 2.116 6.843 2.116s1.815 5.481 2.761 8.269a1.1 1.1 0 0 0 .754.72 1.083 1.083 0 0 0 1.014-.259l3.87-3.654s4.465 3.274 7 5.078zM9.218 13.878l2.1 6.923.466-4.384 12.73-11.483a.347.347 0 0 0 .041-.471.355.355 0 0 0-.47-.08l-14.868 9.5z"
                          fill="#dde3f5"
                          fillRule="evenodd"
                        />
                        <script xmlns="" id="webrtc-control-b" />
                      </svg>
                      @offerspeer2punt
                    </span>
                    <span>Offers</span>
                  </div>
                </div>
              </div>
              <div className="cm-s-item">
                <div className="cm-s-hd">Other services</div>
                <div className="cm-s-links">
                  <div className="cm-s-link">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 11.617 25"
                      >
                        <path
                          d="M11.618 8.095H7.66V5.504a1.057 1.057 0 0 1 1.1-1.2h2.792V.015L7.708.004c-4.268 0-5.24 3.195-5.24 5.24V8.1H0v4.414h2.468v12.49H7.66V12.509h3.5z"
                          fill="#dde3f5"
                        />
                        <script xmlns="" id="webrtc-control-b" />
                      </svg>
                      @peer2punt
                    </span>
                    <span>Facebook</span>
                  </div>
                  <div className="cm-s-link">
                    <span>
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                            fill="#dde3f5"
                          ></path>{" "}
                          <path
                            d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                            fill="#dde3f5"
                          ></path>{" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                            fill="#dde3f5"
                          ></path>{" "}
                        </g>
                      </svg>
                      @peer2punt
                    </span>
                    <span>Instagram</span>
                  </div>
                  <div className="cm-s-link">
                    <span>
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.49614 7.13176C9.18664 6.9549 8.80639 6.95617 8.49807 7.13509C8.18976 7.31401 8 7.64353 8 8V16C8 16.3565 8.18976 16.686 8.49807 16.8649C8.80639 17.0438 9.18664 17.0451 9.49614 16.8682L16.4961 12.8682C16.8077 12.6902 17 12.3589 17 12C17 11.6411 16.8077 11.3098 16.4961 11.1318L9.49614 7.13176ZM13.9844 12L10 14.2768V9.72318L13.9844 12Z"
                            fill="#dde3f5"
                          ></path>{" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 12C0 8.25027 0 6.3754 0.954915 5.06107C1.26331 4.6366 1.6366 4.26331 2.06107 3.95491C3.3754 3 5.25027 3 9 3H15C18.7497 3 20.6246 3 21.9389 3.95491C22.3634 4.26331 22.7367 4.6366 23.0451 5.06107C24 6.3754 24 8.25027 24 12C24 15.7497 24 17.6246 23.0451 18.9389C22.7367 19.3634 22.3634 19.7367 21.9389 20.0451C20.6246 21 18.7497 21 15 21H9C5.25027 21 3.3754 21 2.06107 20.0451C1.6366 19.7367 1.26331 19.3634 0.954915 18.9389C0 17.6246 0 15.7497 0 12ZM9 5H15C16.9194 5 18.1983 5.00275 19.1673 5.10773C20.0989 5.20866 20.504 5.38448 20.7634 5.57295C21.018 5.75799 21.242 5.98196 21.4271 6.23664C21.6155 6.49605 21.7913 6.90113 21.8923 7.83269C21.9973 8.80167 22 10.0806 22 12C22 13.9194 21.9973 15.1983 21.8923 16.1673C21.7913 17.0989 21.6155 17.504 21.4271 17.7634C21.242 18.018 21.018 18.242 20.7634 18.4271C20.504 18.6155 20.0989 18.7913 19.1673 18.8923C18.1983 18.9973 16.9194 19 15 19H9C7.08058 19 5.80167 18.9973 4.83269 18.8923C3.90113 18.7913 3.49605 18.6155 3.23664 18.4271C2.98196 18.242 2.75799 18.018 2.57295 17.7634C2.38448 17.504 2.20866 17.0989 2.10773 16.1673C2.00275 15.1983 2 13.9194 2 12C2 10.0806 2.00275 8.80167 2.10773 7.83269C2.20866 6.90113 2.38448 6.49605 2.57295 6.23664C2.75799 5.98196 2.98196 5.75799 3.23664 5.57295C3.49605 5.38448 3.90113 5.20866 4.83269 5.10773C5.80167 5.00275 7.08058 5 9 5Z"
                            fill="#dde3f5"
                          ></path>{" "}
                        </g>
                      </svg>
                      @peer2punt
                    </span>
                    <span>YouTube</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
