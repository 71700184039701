import Ov0 from "./Overlay/Ov0";
import OvSLT from "./Overlay/OvSLT";
import React from "react";

const Overlay = (props) => {
  const rb = props.rb;
  const ov0 = props.ov0;
  const ovSLT = props.ovSLT;
  const handleClick = (data) => {
    return data;
  };

  return (
    <>
      {ovSLT === "on" ? (
        <OvSLT onClick={(e) => props.onClick(handleClick(e))} />
      ) : null}
      {ov0 === "on" ? (
        <Ov0 rb={rb} onClick={(e) => props.onClick(handleClick(e))} />
      ) : null}
    </>
  );
};

export default Overlay;
