import close from "../assets/svg/svgexport-60.png";
import infoIcon from "../assets/svg/info.png";
import "../assets/css/sidenotice.css";
import React from "react";

const SideNotice = (props) => {
  const uLogin = props.uLogin;
  const snErrors = props.snErrors;
  const closeSN = () => {
    return { closeSN: true };
  };

  return (
    <>
      {!uLogin ? (
        <div className="side-notice">
          <div className="sn-hd">
            <div className="sn-hd-item">
              <img src={infoIcon} alt="Info" />
              Please log in to your account
            </div>
            <div className="sn-hd-item">
              <button
                type="button"
                className="btn"
                onClick={(e) => props.onClick(closeSN())}
              >
                <img src={close} alt="Search" />
              </button>
            </div>
          </div>
          <div className="sn-bd">
            In order to access the full functionality of the platform, you need
            to log in or create an account.
          </div>
        </div>
      ) : (
        ""
      )}
      {snErrors[0] ? (
        <div className="side-notice">
          <div className="sn-hd">
            <div className="sn-hd-item">
              <img src={infoIcon} alt="Info" />
              Duplicate offers not allowed
            </div>
            <div className="sn-hd-item">
              <button
                type="button"
                className="btn"
                onClick={(e) => props.onClick(closeSN())}
              >
                <img src={close} alt="Search" />
              </button>
            </div>
          </div>
          <div className="sn-bd">You already have a similar offer</div>
        </div>
      ) : (
        ""
      )}
      {snErrors[1] ? (
        <div className="side-notice">
          <div className="sn-hd">
            <div className="sn-hd-item">
              <img src={infoIcon} alt="Info" />
              Duplicate contracts not allowed
            </div>
            <div className="sn-hd-item">
              <button
                type="button"
                className="btn"
                onClick={(e) => props.onClick(closeSN())}
              >
                <img src={close} alt="Search" />
              </button>
            </div>
          </div>
          <div className="sn-bd">
            You already have an active contract on this offer
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SideNotice;
