import Calendar from "./LeftDiv/Calendar";
import Countries from "./LeftDiv/Countries";
import React from "react";

const LeftDiv = (props) => {
  return (
    <>
      <div className="leftdiv">
        <div className="mini-nav">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            focusable="false"
            role="img"
            className="ico-svg mr-1"
          >
            <path
              d="m7.02 11.46 1.9 1.9-.54 1.63-2.13-.02h-.06l-2.92-1.46 1.36-2.05h2.39zm5.32.92 1.01.6-.12.23a.114.114 0 0 1-.02.024l-.04.036-3.43 1.71c-.02.01-.06.01-.06.01h-.51l.4-1.21v.01l2.77-1.41zM6.53 1l3.31.06 3.32 1.7.05.05 1.73 3.41c.013.013.018.036.02.049v.221l-1.39-.17-.84-2.56.05-.38-3.19-1.62H7.22L8.83 3.5 7.1 5.25l-2.94.5-.78-2.47-.14.07-1.51 3.02v2.89h.8l1.49 1.76-1.36 2.05-1.65-3.31A.143.143 0 0 1 1 9.711V6.19c0-.003.044-.093.117-.24l.101-.205.06-.12.283-.566.078-.156.162-.323.163-.325.236-.47.144-.286.126-.252.122-.242.045-.09.063-.125.05-.03h.01c.01 0 .02-.02.02-.02L6.2 1.01A.143.143 0 0 1 6.249 1h.281zm6.97 6.07 1.47.18v2.47c0 .02-.01.06-.01.06L13.7 12.3l-1.18-.7-.39-2.302L13.5 7.07zM7.08 6.02l1.28 2.44-1.14 2.25H4.74L3.19 8.89l.93-2.37 2.96-.5zm2.43-2.14 2.66.55.72 2.18-1.26 2.05-2.55-.42-1.34-2.57 1.77-1.79z"
              fill="onColor.primary"
            ></path>
          </svg>
          Football predictions
        </div>
        <button className="showcal btn btn-special">
          <div className="inner">
            <i className="fa fa-calendar-alt"></i>
          </div>
        </button>
        <Calendar
          sDate={props.sDate}
          onClick={(value) => props.onClick(value)}
        />
        <Countries
          dd={props.dd}
          ccData={props.ccData}
          sCountry={props.sCountry}
          onClick={(value) => props.onClick(value)}
        />
      </div>
    </>
  );
};

export default LeftDiv;
