import securityGuide from "../assets/image/security-guide.jpg";
import React from "react";
//import { Routes, Route, Link } from "react-router-dom";
import "../assets/css/security-guide.css";

const SecurityGuide = () => {
  return (
    <>
      <div className="sg-juke">
        <img src={securityGuide} alt="Security Guide" />
      </div>
      <div className="sg-bd">
        We are extremely concerned about your security and safety while using
        our platform. Here we have gathered several security related points that every user should be aware of. Before engaging in any contract on the Peer2Punt trading platform, please familiarize yourself with this guide.
        <div className="sg-topic">If you are looking to sell Bets</div>
        <div className="sg-sub">Send Bets only through escrow</div>
        You should never send Bets to anywhere other than the escrow generated by Peer2Punt. The details of the escrow are only shown after
        both parties have confirmed their payment passwords and the contract status has changed to “Depositing”.
        <div className="sg-sub">Never release bets from escrow until the payment was received</div>
        You should release bets ONLY AFTER you have double checked that the
        payment has been received and its amount reflects the amount of the
        deal.
        <br />
        <br />
        Remember: The payment part of the contract happens without Peer2Punt's
        involvement. Fiat payment is transferred directly from the buyer to the seller.
        <br />
        <br />
        NOTE: A payment receipt provided by your counterparty is not a 100%
        guarantee, you are not safe to continue unless you see the balance of
        your fiat account reflect the amount of the payment.
        <div className="sg-sub">Be aware of reversible payment methods</div>
        Be aware of any risks associated with the payment method you aim to use. Note that some payment methods are riskier than others. For payment methods like “PayPal” be aware of the risk of the payment being reversed.
        <div className="sg-topic">If you are looking to buy Bets</div>
        <div className="sg-sub">Do not send the payment until the Bets are locked in escrow</div>
        Sending money any earlier is highly insecure. Only after the contract
        status has changed to “In progress”, are the Bets safely locked in
        escrow, and only then, you are welcome to proceed with the payment.
        <br />
        <br />
        Note: You are expected to make the payment within the payment window,
        otherwise, your counterparty will be able to cancel the contract.
        <div className="sg-sub">Do not forget to press the “I've sent the payment” button after the payment has been made</div>
        You should press the button right after you've made the payment.
        Otherwise, after the payment window runs out, your counterparty will be able to cancel the contract and return the Bets back to himself.
        <br />
        <br />
        Note: When the button is pressed and the status of the contract has
        changed to “Paid”, the contract cannot be cancelled.
        <div className="sg-topic">General</div>
        <div className="sg-sub">Do not open a suspicious link in the contract's chat</div>
        Peer2Punt does not generate nor share any links in the contract's chat. Only open links you are familiar with.
        <br />
        <br />
        If it seems like the administrator has shared a link, please note that
        messages from Peer2Punt's administrators are <span className="golden">highlighted in gold</span>.
        <div className="sg-sub">Do not enter your account data on any other website</div>
        We would never request your account details anywhere other than on our
        websites, that is those with the peer2punt domain name.
        <div className="sg-sub">Never reveal your payment password</div>
        Your payment password is your key to accessing the escrow. You are the
        sole owner of that key, and no one else, including Peer2Punt, should
        know it.
        <div className="sg-sub">Communicate only in the contract's chat</div>
        Avoid texting in Telegram, Whatsapp, etc. In case you require assistance from our side, we would only be able to assess the information in the contract's chat.
        <div className="sg-sub">Pay attention to users reputation</div>
        To better protect yourself, always pay attention to the user's
        reputation and feedbacks — they reflect the experience other users have had dealing with this trader in the past.
        <br />
        <br />
        Please report any inappropriate behavior that contradicts any of these
        rules to support@peer2punt.com.
        <div className="sg-sub">Reach us</div>
        <div className="sg-link">Peer2Punt trading platform: peer2punt.com</div>
        <div className="sg-link">E-mail: support@peer2punt.com</div>
        <div className="sg-link">Facebook: facebook.com/peer2punt</div>
        <div className="sg-link">Youtube: youtube.com/peer2punt</div>
      </div>
    </>
  );
};

export default SecurityGuide;
